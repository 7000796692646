<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div *ngIf="!cargando;else cargandoBlock" class="container-fluid"
    style="border: 0px solid green; min-height: 100%; max-height: auto;">
    
    <div *ngIf="validacionAprobada();else noValidadoBlock" class="row" style="border: 0px solid blue;">
      
    <div class="contenedor-cards-simil-tabla">
      <div class="card-simil-tabla" *ngFor="let mutual of mutualesPaciente">
        <div class="titulo_tarjeta D-DINCondensed-Bold">
          {{mutual.nomFin}}
        </div>
        <p>Nro. de Carnet: <strong>{{mutual.nroCarnet}}</strong></p>
        <p>
          Admisión Digital:
          <mat-icon mat-list-icon [style.color]="mutual.webCheckIn == 'S' ? 'green' : 'red'" [style.vertical-align]="'middle'">{{ mutual.webCheckIn == 'S' ? 'done' : 'close' }}</mat-icon>
        </p>
        <button 
          *ngIf="mutual.mutual!=14"
          (click)="openDialogConfirmacion(mutual)"
          class="boton mat-focus-indicator mat-flat-button mat-button-base mat-warn ng-tns-c186-6 ng-star-inserted">
          Eliminar
        </button>
      </div>
    </div>
    
   
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="border: 0px solid red;">
        <div class="row" *ngIf="mutualesPaciente.length == 0">
          <div class="alert alert-danger">
            <h6>No tenés coberturas asociadas.</h6>
          </div>
        </div>

        <!-- COMENTADO HASTA QUE SE VALIDEN LAS COBERTURAS -->
        <!-- <div class="botones">
        <button (click)="openDialogAgregarCobertura()" class="boton" style="float: left;">Agregar Cobertura</button>
      </div>       -->

      </div>
    </div>

    <ng-template #noValidadoBlock>
      <div *ngIf="validacionPendiente();else sinValidarBlock">
        Tu usuario aún se encuentra en <b>proceso de VALIDACIÓN.</b> <br><br>

        Vas a poder acceder a tus Coberturas y realizar otras gestiones desde el Portal, una vez que se haya completado
        el proceso de validación. <br>

        *tiempo estimado de validación de usuarios: 72hs
      </div>
      <ng-template #sinValidarBlock>
        <div style="margin-top: 3vh;">
          Te recordamos que para poder acceder a tus Coberturas y realizar otras gestiones desde el Portal, es necesario
          que se validen tus datos. <br>
          *para completar los datos personales sugerimos tener cerca el DNI.</div>
        <button class="btn boton_validar" (click)="goToValidar()" style="margin-top: 3vh;"> Validá tu identidad</button>
      </ng-template>
    </ng-template>
  </div>
  <ng-template #cargandoBlock>
    <div>
      <mat-spinner> </mat-spinner>
    </div>
  </ng-template>
</body>

</html>