import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hemodinamia',
  templateUrl: './hemodinamia.component.html',
  styleUrls: ['./hemodinamia.component.css']
})
export class HemodinamiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
