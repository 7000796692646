<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <div class="container">
        <h5>Primer piso. (0221) 427-0191, interno 1218.</h5>
        <h5>Teléfono directo (0221) 483-8350.</h5>
        <strong>Mail:</strong> nefrodialisis_srl@yahoo.com.ar

        <hr />

        <h6><strong>Servicios</strong></h6>
        <p>
          <a href="#">Tratamiento integral de la insuficiencia renal crónica</a>
        </p>

        <p>Hemodiálisis de lunes a sábados de 7 a 22</p>

        <p>Diálisis peritoneal ambulatoria</p>

        <p>Trasplante renal</p>

        <p><a href="#">Nefrología clínica</a></p>

        <p>Litiasis renal. Diagnóstico, tratamiento y prevención.</p>

        <p>Enfermedades renales. Diagnóstico y tratamiento.</p>

        <p>Hipertensión arterial. Diagnóstico, tratamiento y prevención.</p>

        <p>Hipertensión y embarazo. Diagnóstico, tratamiento y prevención.</p>

        <p>Infecciones del tracto urinario. Diagnóstico y tratamiento.</p>

        <p>Nefrología diabética y trastornos metabólicos</p>

        <p>(gota, dislipemia, hiperuricemia, ácido úrico)</p>

        <p>
          <a href="#">Traslado de pacientes para tratamiento de hemodiálisis</a>
        </p>

        <p><a href="#">Atención a particulares y coberturas</a></p>

        <p><a href="#">Tecnología y estándares de seguridad</a></p>

        <hr />

        <strong>Profesionales</strong>
        <p>
          Dra. Miriam del Amo (MP 15649) Martes de 14.30 a 17. Miércoles de
          13.20 a 16.30
        </p>
        <p>
          <strong>Atención con turnos solicitados al (0221) 483-8350</strong>
        </p>
        <p>
          Dr. Ibar Miguel Flores Miércoles de 15.30 a 17 y jueves de 10.30 a 12
          y de 15.30 a 17.
        </p>
        <p><strong>Atención por orden de llegada</strong></p>
        <p><strong>Consulte</strong></p>
        <p>
          El trabajo interdisciplinario con el staff profesional de jerarquía
          con que cuenta la institución permiten los excelentes resultados
          obtenidos. El equipo profesional está constituido en su totalidad por
          especialistas en nefrología de reconocida trayectoria y se completa
          con nutricionista, asistente social, psicólogo, cirujanos vasculares,
          cirujanos generales y urólogos de nutrida experiencia en la
          especialidad.
        </p>

        <hr />

        <p>
          <strong
            >Tratamiento integral de la insuficiencia renal crónica</strong
          >
        </p>
        <p>Hemodiálisis de lunes a sábados de 7 a 22</p>
        <p>
          La hemodiálisis es el procedimiento más utilizado en la actualidad y
          de más rápido acceso en los casos de urgencia. Para poder realizarla
          es necesario extraer sangre del cuerpo del paciente y hacerla circular
          por medio de un tubo estéril hacia el filtro de diálisis que es un
          componente de la máquina o riñón artificial. Este filtro está dividido
          en dos espacios por medio de una membrana semipermeable: por un lado
          pasa la sangre y por el otro el líquido de diálisis (dializado). Esta
          membrana es muy delgada y contiene poros que permiten el paso de las
          sustancias de desecho o tóxicas y del agua desde la sangre hacia el
          líquido de diálisis no permitiendo el paso de glóbulos rojos, blancos,
          proteínas de tamaño grande, hormonas, etc.
        </p>
        <p>
          Para entender mejor por qué algunas sustancias atraviesan la membrana
          y otras no, es útil representarse un saquito de té en una taza con
          agua. Al introducir el saquito comenzamos a observar que el agua
          comienza a teñirse por las sustancias del té que pueden pasar a través
          de la bolsa pero, la hoja de té permanece dentro del saco debido a que
          su tamaño le impide atravesarla.
        </p>
        <p>
          Este proceso se denomina difusión (dos líquidos en contacto, de
          diferente concentración, tienden a igualarse). En la diálisis la
          sangre está cargada de sustancias tóxicas y el líquido de diálisis no
          las contiene, por lo tanto, esta diferencia de concentración de un
          lado al otro es lo que determina el pasaje de sustancias desde la
          sangre al dializado.
        </p>
        <p>
          Todo este proceso es dinámico ya que la sangre circula constantemente
          por el filtro: sale sangre limpia que retorna al paciente y entra
          sangre con toxinas, al igual que, sale dializado con toxinas y entra
          dializado sin ellas, existiendo siempre la diferencia de concentración
          necesaria para poder "limpiar las toxinas" en forma continua. Por otra
          parte, también se necesita extraer el agua que se ha acumulado en el
          cuerpo ya que esta sobrecarga de líquido provoca presión alta y un
          aumento en el trabajo del corazón con el riesgo que esto implica. La
          manera de realizarlo es generar mecánicamente un aumento en la presión
          del compartimiento de la sangre dentro del filtro que empuja al
          líquido contra la membrana forzándolo a atravesarla hacia el
          compartimiento del dializado por donde es eliminado. Este proceso se
          denomina ósmosis o ultra filtración. Ahora bien, para que el
          tratamiento sea eficaz debe cumplir determinados requisitos: el tiempo
          de duración, la frecuencia con que se realiza y la cantidad de sangre
          que podemos hacer circular por el riñón artificial. El tiempo de
          duración de cada sesión de hemodiálisis es 4 horas y la frecuencia es
          de tres sesiones por semana. Estos parámetros pueden variar de acuerdo
          al criterio médico pero, son los indicados generalmente.
        </p>

        <hr />

        <p>
          <strong
            >Traslado de pacientes para tratamiento de hemodiálisis</strong
          >
        </p>
        <p>
          Los pacientes pueden ser traslados desde su domicilio en cualquier
          punto de la provincia de Buenos Aires hasta el Hospital en cómodas
          unidades, bien equipadas y con personal responsable, con la única
          restricción de los tiempos de traslado que pueden estar
          contraindicados por cuestiones médicas. Aquellos pacientes o
          familiares que deseen utilizar este servicio cubierto por las obras
          sociales, pueden solicitarlo personalmente en la oficina
          administrativa de Nefrología y Diálisis.
        </p>

        <p><strong>Diálisis peritonial ambulatoria</strong></p>
        <p>
          Es otra modalidad de diálisis que consiste en utilizar la membrana
          natural que recubre por dentro la cavidad abdominal, los intestinos y
          otros órganos. Esta membrana peritoneal o peritoneo está surcada por
          miles de pequeños vasos sanguíneos que aportan la sangre que
          necesitamos "limpiar de toxinas" y cumple la función de membrana
          semipermeable (como el filtro de hemodiálisis).
        </p>
        <p>
          Para poder realizar la diálisis es necesario entonces introducir en la
          cavidad abdominal dializado fresco que toma contacto con el peritoneo,
          entonces, se produce la diferencia de concentración necesaria para que
          las toxinas de la sangre que circula por la membrana peritoneal pasen
          al dializado (difusión), el cual es evacuado luego de algunas horas (4
          a 6) hacia el exterior por medio de un tubo flexible denominado
          catéter peritoneal. Este proceso se repite 3 o 4 veces al día, es
          realizado por usted mismo y es absolutamente necesario un
          entrenamiento previo riguroso.
        </p>
        <p>
          El catéter peritoneal es colocado mediante una operación sencilla y
          permanece insertado en forma permanente.
        </p>

        <p><strong>Trasplante renal</strong></p>
        <p>
          Es otra opción para el tratamiento de la insuficiencia renal crónica.
          Consiste en implantar en su cuerpo un riñón humano sano de otra
          persona la cual puede ser un familiar directo (donante vivo
          relacionado) o de una persona recién fallecida (donante cadavérico) y
          además en ocasiones la ley de trasplantes contempla la donación de
          personas allegadas directas caso esposa/o como ejemplo (donante vivo
          no relacionado). Para poder realizar un trasplante deben cumplirse
          determinados requisitos tanto del enfermo como del donante a saber:
        </p>
        <ul>
          <li>
            Debe estar contemplado por la ley, lo que significa que no cualquier
            persona puede donar sus órganos en los casos de donante vivo no
            relacionado, por ejemplo: un amigo.
          </li>
          <li>
            Es absolutamente necesario que haya compatibilidad con la sangre y
            los tejidos del donante para un trasplante exitoso, para ello, deben
            realizarse pruebas de compatibilidad previas al implante ya que una
            buena compatibilidad de tejidos favorece que el sistema inmune no
            reaccione contra el injerto (rechazo).
          </li>
          <li>
            En la situación de donante vivo relacionado, el dador debe ser
            profundamente evaluado en su estado de salud ya que es condición no
            presentar enfermedades.
          </li>
          <li>
            El receptor del injerto también debe cumplir con estudios que
            evalúan su condición física previa al trasplante a fin de determinar
            si es posible o no realizarlo, así como detectar situaciones
            patológicas que deban ser corregidas para evitar complicaciones
            luego de realizado el implante.
          </li>
        </ul>

        <p>
          En cuanto a los aspectos técnicos del procedimiento quirúrgico, el
          implante del riñón sano se realiza en la zona abdominal cercana a la
          raíz del miembro inferior conectándose las venas y arterias del órgano
          con las del paciente para la adecuada llegada de la sangre además de
          unir el uréter con la vejiga para la correcta eliminación de la orina
          filtrada. En general la operación tiene una duración de 2 a 4 hs. Debe
          tenerse en cuenta que los donantes vivos deberán permanecer internados
          luego de la cirugía aproximadamente una semana y el enfermo
          trasplantado si no surgen complicaciones aproximadamente dos semanas.
        </p>

        <p>
          <strong
            >Ahora bien, ¿que puede ocurrir luego de realizado el trasplante? En
            este punto y dado lo extenso de las respuestas podemos resumir las
            siguientes eventualidades para ser consultadas con su médico de
            cabecera:</strong
          >
        </p>
        <ul>
          <li>
            <strong>Rechazo del injerto:</strong> sabemos que el cuerpo humano
            mediante el sistema inmune reconoce los cuerpos extraños en nuestro
            organismo y trata de eliminarlos. Es por ello que si bien se busca
            la mayor compatibilidad con el órgano a implantar esta no es
            absoluta, siendo entonces necesario disminuir la respuesta normal a
            rechazar los cuerpos extraños mediante drogas inmunosupresoras
            favoreciendo de este modo la prevención del rechazo. Ud. será
            instruido sobre los síntomas para la detección precoz de un rechazo
            agudo.
          </li>
          <li>
            <strong>Menor resistencia a las infecciones:</strong> dado que
            debemos administrar drogas inmunosupresoras para disminuir la
            posibilidad de un rechazo, también estamos disminuyendo la respuesta
            normal a otros cuerpos extraños como bacterias, virus, etc. Por lo
            que se debe estar muy atento a los síntomas de infección que su
            médico le informará oportunamente.
          </li>
          <li>
            <strong>Complicaciones de la cirugía:</strong>toda intervención
            quirúrgica conlleva un riesgo, por lo tanto es oportuno que aclare
            con su equipo de trasplante las distintas situaciones que pueden
            presentarse.
          </li>
        </ul>

        <p>
          Finalmente debe saber que el trasplante renal no es una cura, que
          deberá ser consciente que puede no durar toda la vida ya que su
          organismo puede rechazar el órgano a pesar de la medicación, deberá
          para un mejor éxito estar en permanente contacto con el equipo de
          trasplante, cumplir con las indicaciones del mismo ya que tomará
          medicación y necesitará de controles periódicos del funcionamiento del
          injerto por el resto de su vida.
        </p>
        <p>
          Si se cumplen todos los pasos necesarios, la posibilidad de éxito es
          muy elevada permitiéndole a Ud. sentirse mejor, sin diálisis, con
          posibilidad de inserción casi total a sus actividades, una dieta menos
          restringida.
        </p>

        <hr />

        <h6><strong>Nefrología clínica</strong></h6>
        <p>
          <strong
            >Diagnóstico, tratamiento y prevención de la litiasis renal</strong
          >
        </p>

        <p>
          La litiasis o cálculo renal es una formación sólida producida a partir
          del depósito de sustancias que están en la orina. El 5 por ciento de
          los pacientes en los países occidentales padecen esta enfermedad que
          tiene una recurrencia de hasta del 80 por ciento a los 10 años del
          primer episodio. Por este motivo es muy importante el estudio de los
          factores metabólicos predisponentes para su formación y poder evitar o
          enlentecer la recidiva.
        </p>
        <p>
          La nefrolitiasis por sales de calcio representa el 70 por ciento de
          todo slos tipos y más frecuente en varones. La edad de inicio de la
          formación está en general entre los 20 y 39 años pero no siempre el
          defecto metabólico está ligado a exceso de calcio en la orina. Otras
          causas son por ácido úrico, estruvita, entre otras.
        </p>
        <p>
          Los cálculos pueden ser tan pequeños como un grano de arena o tan
          grandes como una pelota de golf. A veces los preparados vitamínicos o
          suplementos nutricionales favorecen su aparición. Si los cálculos no
          se mueven no producen dolor; este aparece como cólico cuando el
          cálculo comienza desplazarse. Por lo tanto puede ser una enfermedad
          muchas veces asintomática.
        </p>

        <p>
          <strong>Diagnóstico y tratamiento de enfermedades renales</strong>
        </p>
        <p>
          Las enfermedades que afectan a los elementos filtrantes del riñón
          (glomérulos y túbulos) se presentan con mucha frecuencia y pueden
          conducir a la insuficiencia renal si no son tratados en forma precoz.
          Las formas más frecuentes son las secundarias a hipertensión arterial
          o diabetes. Cuando no se conoce otra afectación se las denomina
          primarias o idiopáticas y de acuerdo al segmento afectado,
          glomerulopatías o nefritis túbulointestinales. Pueden dar pocos
          síntomas locales y se manifiestan por hematuria (sangre en la orina),
          proteniuria (proteínas en la orina); aumento de la presión arterial,
          edemas o alteraciones en la química sanguínea. En muchas oportunidades
          es necesario realizar una biopsia renal para su correcto diagnóstico.
        </p>

        <p>
          <strong>Diagnóstico y tratamiento de la hipertensión arterial</strong>
        </p>
        <p>
          La hipertensión arterial es una hallazgo frecuente en los pacientes
          con afectación renal, pero también la presión arterial elevada,
          deteriora los riñones. Por ese motivo es muy importante su estudio y
          diferenciación de las formas primarias y secundarias. En esta última
          (10% de los casos), una vez corregida la causa, se cura la
          hipertensión arterial, a diferencia de la forma primaria (o
          idiopática, 90% de los casos) en que se la trata sin lograr su
          curación.
        </p>
        <p>
          La hipertensión arterial se caracteriza por presentar una presión
          máxima o sistólica superior a 139 mmHg o una presión mínima o
          diastólica mayor a 89 mmHG.
        </p>
        <p>
          La hipertensión arterial se asocia a tasas de morbilidad y mortalidad
          elevadas pero que se normalizan con el tratamiento adecuado lo que
          demuestra la importancia de su diagnóstico y control.
        </p>

        <p><strong>Hipertensión arterial y embarazo</strong></p>
        <p>
          La hipertensión arterial durante el embarazo es una de las
          complicaciones más frecuentes ya que se presenta entre el 7 y el 10
          por ciento de las mujeres gestantes. Incluye una gran diversidad de
          procesos que tienen en común la presencia de valores elevados de
          tensión arterial. Es causa de complicaciones para la mamá y el bebé ya
          que puede producir retraso de crecimiento intrauterino, parto
          prematuro, muerte intrauterina o cuadros convulsivos y edemas en la
          mamá. El gran porcentaje de estos síntomas desaparecen con el parto y
          el puerperio. Pero si así no fuese, es necesario el seguimiento
          nefrológico para evaluar otras enfermedades renales.
        </p>

        <hr />

        <h6><strong>Atención a particulares y coberturas</strong></h6>
        <p>
          Asistencia completa y de alta calidad y con la cobertura de las más
          importantes obras sociales y coberturas de medicina prepaga. Aquellas
          personas que no tienen ninguna cobertura médica también serán
          atendidas en forma gratuita, a través de los planes de asistencia del
          Ministerio de Salud de la Provincia.
        </p>

        <hr />

        <h6><strong>Tecnología y estándares de seguridad</strong></h6>
        <ul>
          <li>
            Los equipos Nipro Surdial y Fresenius 4008 B, como así también un
            equipo portátil para áreas críticas de Terapia Intensiva y Unidad
            Coronaria, aseguran un tratamiento confiable y que mantiene los
            niveles de calidad deseados a partir de un estricto control en forma
            permanente, siguiendo las indicaciones de seguridad y mantenimiento
            que los mismos fabricantes recomiendan para garantizar un
            funcionamiento eficiente.
          </li>
          <li>
            La esterilización de circuitos para la distribución de agua de
            osmosis es un tema de vital importancia en los tratamientos de
            diálisis y para eso se cuenta con un sistema de esterilización por
            ozono. Pero para garantizar que todos los procesos de purificación y
            esterilización sean efectivos y lleguen con seguridad al tratamiento
            de los pacientes, contamos con cañerías de acero inoxidable, con una
            rugosidad interna de 0.2 micrones que evitan la existencia de
            componentes no deseados como el biofilm. Los accesorios de
            interconexión de cañerías, las acometidas del tanque y las válvulas
            son de tipo sanitario.
          </li>
          <li>
            Como el agua es un elemento esencial en los tratamientos de diálisis
            debe estar controlado en forma permanente para asegurar su calidad y
            pureza. Actualmente, la calidad microbiología y físico química del
            agua para hemodiálisis ha adquirido una importancia sustancial en el
            tratamiento dialítico a tal punto de inferir en la morbi-mortalidad
            de pacientes en hemodiálisis crónica. Para esto, adoptamos el
            proceso de purificación más eficiente y utilizado, que es la osmosis
            inversa. De esta manera, el agua que se utiliza para los
            tratamientos mantiene sus valores de pureza inalterables y su
            calidad asegurada. También se efectúan controles bacteriológicos y
            físico químicos mensuales para garantizar un agua ultrapura y libre
            de microorganismos y sus endotoxinas.
          </li>
          <li>
            Pero como la calidad de la atención no sólo está referida a
            tecnología y cuestiones de higiene y seguridad, también comprende la
            comodidad de las personas. Por ello, incorporamos una balanza
            especial que permite pesar a los pacientes en silla de ruedas, sin
            necesidad de incómodos movimientos que perturban y resultan
            molestos.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
