import { Component, OnInit,ElementRef, HostListener, ViewChild  } from '@angular/core';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

export interface UserData {
  apellido: string;
  campania_actualizacion: string;
  codigoPostal: string;
  domicilio: string;
  email: string;
  estadoValidacion?: string;
  fechaNacimiento: Date;
  localidad: string;
  masculino: boolean;
  nroDocumento: string;
  permisos?: string;
  socioNro?: string;
  telefonoParticular: string;
  tipoDocumento: string;
  usuario: string;
  fotoPerfil: String;
  nombres: string;
}

@Component({
  selector: 'app-menu-sandwich',
  templateUrl: './menu-sandwich.component.html',
  styleUrls: ['./menu-sandwich.component.css']
})
export class MenuSandwichComponent implements OnInit {
  userData: UserData;
  entradas: any[];
  loading: boolean;
  entrada_seleccionada: any;
  subentrada_seleccionada: any;
  fotoPaciente: any;
  userData_recuperado: boolean;
  isMenuOpen: boolean = false;  
  cargando: boolean = true;
  es_visitante: boolean;
  isSubMenuOpen = false;


  constructor(
    private userService: UserService,
    public dialog: MatDialog, 
    public authenticationService: AuthenticationService,
    public router: Router,
    private _sanitizer: DomSanitizer,
  ) {
    this.userData_recuperado = false;
  }

  ngOnInit(): void {
    this.getUserData();
    if(!this.es_visitante){
      this.cargarEntradas();
      this.cargarEntradasSeleccionadas();
      this.vincular_path_con_menu();
      this.cargando = false;
    }  
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const menuElement = document.getElementById('mobile-menu');
    const targetElement = event.target as HTMLElement;
  
    if (
      !menuElement.contains(targetElement) &&
      !this.isSubMenuOpen &&
      !targetElement.classList.contains('opcion_menu_mis_datos') && // Añade aquí las clases de tus botones de submenú
      !targetElement.classList.contains('opcion_menu')
    ) {
      this.isMenuOpen = false;
    }
  }

  openSubMenu(): void {
    this.isSubMenuOpen = true;
  }


  getSafeImageUrl(imagePath: string) {
    return this._sanitizer.bypassSecurityTrustUrl(imagePath);
  }
  
  getUserData(){
    this.userService.getUserData().subscribe(
      usuario => {
        this.userData = usuario;
        this.userData_recuperado = true;

        
        this.fotoPaciente = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
        + this.userData.fotoPerfil);
        if (this.userData.tipoDocumento === '9') {
         // this.temporal = true;
        }

        this.es_visitante = this.userData.usuario==="visitante" ? true : false;
      },
      error => {
        console.log(error);
        this.es_visitante = true;
      }
    );
    this.loading=true; 
  }

  async cargarEntradas(){
    await this.userService.obtenerEntradasDeMenu().toPromise().then(
      (data) => {
        if (data.ok) {
         this.ordenarVectorEntradas( data.elementos);           
        }
      }
    ); 
  }

  async ordenarVectorEntradas(vector) {
    let arreglodeItems = {};
    await vector.forEach((item) => {    
      if (!arreglodeItems.hasOwnProperty(item.nombre_menu)) {
        arreglodeItems[item.nombre_menu] = [];
      }
      arreglodeItems[item.nombre_menu].push(item);
    });
    
  //this.itemsMenu = arreglodeItems;
  this.entradas= this.generarEntradasOrdenadas(arreglodeItems); 
  }

generarEntradasOrdenadas(arreglo){
  let arregloJson= JSON.stringify(arreglo);
  let objeto=JSON.parse(arregloJson);
  let arreglito=[];
  for (const key in objeto) {
    arreglito.push(objeto[key]);
  }
  //console.log(arreglito)
  this.loading = false;

  return arreglito;
}

selecciona_entrada(entrada){
  this.entrada_seleccionada = entrada.nombre_menu;
  this.subentrada_seleccionada = '';
  
  //guardo en localStorage la entrada seleccionada y la subentrada por defecto
  sessionStorage.setItem('entrada_seleccionada', entrada.nombre_menu);
  
}

selecciona_subentrada(subentrada){
  if(subentrada.nombre_submenu == 'Nefrología y diálisis'){
    window.open('https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-11', '_blank');      
  } 
  else {
    if(subentrada.nombre_submenu == 'Terapia Intensiva'){
      window.open('https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-no-ambulatorios/#seccion-5', '_blank');
    }else{        
      this.router.navigate([subentrada.url]);
      this.subentrada_seleccionada = subentrada.nombre_submenu;
      //guardo en localStorage la subentrada seleccionada
      sessionStorage.setItem('subentrada_seleccionada', subentrada.nombre_submenu);
    }
  }  
  this.cerrarSideNav();
}

vincular_path_con_menu(){
  switch (window.location.pathname) {
    case '/turnos':{
      this.entrada_seleccionada = 'Turnos';
      this.subentrada_seleccionada = 'Reservar un turno con un médico';      
      break;
    }
    case '/turnos-estudios':{
      this.entrada_seleccionada = 'Turnos';
      this.subentrada_seleccionada = 'Reservar un turno para un estudio';
      break;
    }
    case '/turnos-reservados':{
      this.entrada_seleccionada = 'Turnos';
      this.subentrada_seleccionada = 'Reservados';
      break;
    }
    case '/mis-coberturas':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Coberturas';
      break;
    }
    case '/mis-ordenes':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Órdenes';
      break;
    }
    case '/facturas':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Facturas';
      break;
    }
    case '/editar-datos':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Editar Datos';
      break;
    }
    case '/historial-clinico':{
      this.entrada_seleccionada = 'Historial clínico';
      break;
    }
    case '/tramites-de-cirugías':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Trámites de cirugías';
      break;
    }
    case '/prequirurgicos-preparacion':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Prequirúrgicos y preparación';
      break;
    }
    case '/admision':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Admisión';
      break;
    }
    case '/derechos-normas':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Derechos del paciente y normas de convivencia';
      break;
    }
    case '/visitas-egresos':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Visitas y egresos';
      break;
    }
    case '/nefrologia-dialisis':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Nefrología y diálisis';
      break;
    }
    case '/terapia-intensiva':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Terapia Intensiva';
      break;
    }
    case '/hospital-de-dia':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Hospital de día';
      break;
    }
    case '/egresos':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Egresos';
      break;
    }
    case '/register':{
        this.entrada_seleccionada = 'Validar identidad';
        this.subentrada_seleccionada = '';           
      break;
    }
    default:{
      this.entrada_seleccionada = '';
      this.subentrada_seleccionada = '';
      break;
    }
  }         
}

cerrar_menus(){
  this.entrada_seleccionada = '';
  this.subentrada_seleccionada = '';
  this.cerrarSideNav();  
}

click_foto(){
  this.entrada_seleccionada = 'Paciente';
  this.subentrada_seleccionada = 'Editar Datos';
}

verificarNombreMenu(nombre_menu){
   if( (nombre_menu == "Paciente") ){
    return true;
   } else {
    return false;
   }
}

noEsCerrarSesion(nombre_submenu){
  return (nombre_submenu != 'Cerrar sesión') ? true : false;
}

logout(){
  this.dialog.open(DialogConfirmacionComponent, {
    //width: '50%',
    data: {
      titulo: 'Confirmar cerrar sesión',
      mensaje: 'cerrar sesión',   
      estasPor: true   
    },
    hasBackdrop: false,
    panelClass: 'dialog-sin-overflow'
  }).afterClosed().subscribe((confirma: boolean) => {
    if (confirma) {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      sessionStorage.clear();
    }
  });
} 

login(){
  this.router.navigate(['/login']);
  sessionStorage.clear();
}

verificar_menu_visitante(nombre_menu){
  if( (nombre_menu == 'Turnos') || (nombre_menu == 'Información útil') ){
    return true;
  } else {
    return false;
  }
}  
cargarEntradasSeleccionadas(){
  this.entrada_seleccionada = sessionStorage.getItem('entrada_seleccionada');
  this.subentrada_seleccionada = sessionStorage.getItem('subentrada_seleccionada');
  //console.log('entrada seleccionada: '+this.entrada_seleccionada);
  //console.log('subentrada_seleccionada: '+this.subentrada_seleccionada);
}
noEsLaultimaSubEntrada(entrada,subentrada){
  let indice = entrada.indexOf(subentrada);
  let tam = entrada.length - 1;  
  return (indice == tam) ? false : true;
}

cerrarSideNav(){
  this.isMenuOpen = false;
}

hayEntradaSeleccionada(){
  return this.entrada_seleccionada!=null? true : false;
}
haySubentradaSeleccionada(){
  return this.subentrada_seleccionada!=''? true : false;
}

tieneNombre(){
  let tiene_nombre = true;
  if(this.userData.nombres==null || this.userData.nombres==''){
    tiene_nombre = false;
  }
 
  return tiene_nombre;
}

validacionPendiente(){    
  return (this.userData.tipoDocumento == '9' && (this.userData.estadoValidacion=='PENDIENTE' || this.userData.estadoValidacion=='CARGANDO')) ;
}

validacionSinValidar(){
  return (this.userData.tipoDocumento == '9' && (this.userData.estadoValidacion!='PENDIENTE' && this.userData.estadoValidacion!='CARGANDO'));
}

goToValidar(){
  this.entrada_seleccionada = 'Validar identidad';
  sessionStorage.setItem('entrada_seleccionada', 'Validar identidad');
  sessionStorage.setItem('voyAValidar', 'si');
  // this.router.navigate(['/register']);
  window.location.href='/register';
}
}
