import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-normas-de-convivencia',
  templateUrl: './normas-de-convivencia.component.html',
  styleUrls: ['./normas-de-convivencia.component.css']
})
export class NormasDeConvivenciaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
