<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">

      <div class="container">
        <p>Si para su cirugía necesita algún tipo de prótesis, por favor comuníquese con una antelación de 48 horas con
          su médico/a para asegurarse que el pedido se haya realizado y se haya confirmado la provisión. </p>
        <p>Si tiene prescripta alguna medicación deberá informárselo al/la médico/a y traerla desde su casa.</p>
        <p>Pasar por el área de Admisión Internación en planta baja para realizar la <b>reserva de una habitación</b> y
          retirar la siguiente documentación que deberá traer firmada el día de la cirugía:</p>


        <ul>
          <li><strong>Normas de funcionamiento interno.</strong></li>
          <li><strong>Reglamento</strong></li>
          <li>
            <strong>Consentimiento informado (el cirujano, a su vez, le hará firmar
              uno específico).</strong>
          </li>
        </ul>

        <p>
          Recuerde que el Hospital cuenta con habitaciones compartidas e individuales con acompañante y con distintos
          tipos de confort. Podrá elegir la que su obra social le cubra, quedando sujeto a la disponibilidad del
          momento.
        </p>

        <h4 class="mt-4">Requisitos para IOMA</h4>
        <ul>
          <!-- <li><strong>Dos juegos de fotocopias.</strong></li> -->
          <li>
            <strong>Orden de internación (no requiere bonos ni autorización).</strong>
          </li>
          <!-- <li>
            <strong>Documento Nacional de Identidad del/de la paciente, primera y segunda
              hoja.</strong>
          </li>
          <li><strong>Carnet del/de la paciente.</strong></li>
          <li>
            <strong>En caso de que el/la paciente no sea el/la titular de la obra social,
              deberá adjuntar también fotocopias del Documento Nacional de
              Identidad y carnet del afiliado directo.</strong>
          </li> -->
        </ul>

        <h4 class="mt-4">Requisitos para otras obras sociales:</h4>
        <p>Consultar en Admisión Internación</p>
        <p><b>Teléfono: (+54 0221) 412 9400, interno 1506</b></p>
        <p><b>Mail: admisiones@hospitalespanol.org.ar</b></p>


      </div>
    </div>
  </div>
</div>