<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Diagnóstico Prenatal y de la Mujer</h4>
        <div class="container">
          <p>
            Para solicitar turno comunicarse al:
          </p>
          <p>
            Teléfono: <strong>(0221) 412-9400, interno 1319</strong> de lunes a
            viernes de 7:15 a 17:00hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            <img
              src="../../assets/images/ecocardiogramas/icono_whatsapp.png"
            />
            WhatsApp: <strong> +54 9 221
            476-9962 </strong> de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Turno presencial: Central de Turnos, Planta Baja de lunes a
            viernes de 7:00 a 21:30hs y sábados de 7:00 a 14:30hs.
          </p>
  
          <p><strong>Equipo Médico: </strong></p>
  
          <p>Ginecología y Obstetricia: Dra. Paula Montenegro | Dra. Evangelina Calzone | Dra. Maria Fernanda Ibañez | Dra. Ángeles Acuña</p>
  
          <p><strong>Estudios:</strong></p>
          <ul>
            <li>Ecografía ginecológica | Ecografía ginecológica con transductor endovaginal</li>
            <li>Ecografía obstétrica | Ecodoppler obstétrico fetal</li>
            <li>Scan fetal | Translucencia nucal | NTPLUs</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</body>

</html>