<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Laboratorio</h4>
        <div class="container">
          
          <p><strong>CONTACTO | CONSULTAS</strong></p>
  
          <p>
            Comunicarse al  (+54 0221) 412-9440, línea directa | (+54 0221) 4129400, interno  1502
          </p>
  
          <p>
            Horario de atención: de lunes a sábados desde las 7hs.
          </p>
  
          <p>
            Se le informará sobre condiciones administrativas y de preparación previas a la extracción.
          </p>
  
          <p>
            *el servicio se encuentra en el Subsuelo del Hospital.
          </p>
  
          <p>
            <a href="{{linkWhatsapp}}" target="_blank"><img src="../../assets/images/laboratorio/icono_whatsapp.png"/><strong> Autorizaciones de órdenes por WhatsApp:</strong></a> (+54 0221) 15-420-0794
          </p>
  
          <p><strong>Extracción: </strong>por orden de llegada. Prioridad: embarazadas | bebés | pre quirúrgicos | trasplantados y personas con discapacidad.</p>
  
          <p>
            <strong>Entrega de resultados: </strong> lunes a viernes de 7hs a 21hs y sábados de 7hs a 18.30hs. Los mismos también, pueden consultarse dentro del Sitio.
          </p>
  
          <p>
            <strong>IMPORTANTE: </strong> la extracción en el laboratorio es por orden de llegada, de lunes a sábados desde las 7hs, pero si usted además, 
            precisa estudios de Hemoterapia se recomienda primero dirigirse a Hemoterapia para autorizar la orden. Una vez, realizado el trámite dirigirse a Laboratorio 
            dónde le darán los tubos para que en Hemoterapia se puedan tomar todas las muestras necesarias para ser entregadas luego en Laboratorio. De esta manera, 
            evitará que sea necesario sacarse sangre dos veces.
          </p>
  
          <p>
            Ayuno, es imprescindible no ingerir alimentos sólidos o líquidos (excepto agua) dentro del horario de ayuno que necesite su estudio.
          </p>
  
          <p>
            Orina completa, el día del análisis guardar la primera orina de la mañana (o al menos con tres horas de retención) en el envase esterilizado provisto por el 
            laboratorio; cerrarlo bien y evitar contaminación del mismo.
          </p>
  
          <h6><strong>Dirección técnica</strong></h6>
          <p>Bioq. Graciela Etcheverry</p>
  
          <h6><strong>Técnicos</strong></h6>
          <p>Estela Apaza | Celeste Gómez | Mariana Peluffo | Cristina Siebenhaar | Rocio Hañek | Noelia Fiorentino | María Bugueiro</p>
  
          <h6><strong>Administración</strong></h6>
          <p>Julio Galeano | Darío Rojo | Marcela Zaniratto | Cintia Rodríguez | Yamila Ocaño | David Goitea | Florencia Medina | Analía Almada</p>
  
          <h6><strong>Mucamas</strong></h6>
          <p>Stella Maris Cornell | Sandra Litterini | Isabel Serrano</p>
  
          <p><strong>Acreditación | MA3 de calidad en la atención que cumple con los más altos estándares internacionales.</strong></p>
          <p> - Amplio menú prestacional</p>
          <p> - Informatización de los procesos de trabajo en el marco de cumplimiento de normas de calidad</p>
          <p> - Rapidez y confiabilidad en los resultados</p>
          <p> - Participación activa en los procesos de vigilancia epidemiológica establecidos por el Min. De Salud de la Provincia de Bs.As y de la Nación.</p>
        </div>
      </div>
    </div>
  </div>
  
</body>

</html>