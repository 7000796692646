

<div *ngIf="data.ok" class="barra-inicial barra-inicial-celeste">
    <!-- <button mat-icon-button class="pos" [mat-dialog-close]="data.ok">
        <mat-icon color="warn">close</mat-icon>
    </button> -->
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">    
</div>
<div *ngIf="!data.ok" class="barra-inicial barra-inicial-roja">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">    
</div>

<div *ngIf="data.titulo">
    <h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%;">{{ data.titulo }}</h1>
    <mat-divider class="divisor"></mat-divider>
</div>

<div mat-dialog-content>
    <div *ngIf="data.ok; else resultadoNegativo">
        <p class="alert alert-success p-1 m-1 align-middle text-center" style="max-width: 500px;">
            {{ data.mensaje }}
        </p>
    </div>
    <ng-template #resultadoNegativo>
        <p class="alert alert-danger p-1 m-1 align-middle text-center" style="max-width: 500px;">
            {{ data.mensaje }}
        </p>
    </ng-template>
</div>