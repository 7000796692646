<!DOCTYPE html>
<html lang="es">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="robots" content="noindex">

  <title>Document</title>
</head>

  <body>
  
      <div class="container-fluid">
  
        <div class="text-center">
          <mat-form-field appearance="outline" class="latoregular">
            <mat-label class="latoregular">Especialidad</mat-label>
            <mat-select class="latoregular" (selectionChange)="onChangeEspecialidad($event.value)" disableOptionCentering>
                <mat-option *ngFor="let especialidad of especialidades" [value]="especialidad">
                    {{ especialidad.nombre | toTitleCase }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div *ngIf="loadingProfesionales">
          <div
              class="d-flex justify-content-center">
              <mat-spinner [diameter]="60"></mat-spinner>                
          </div>
        </div>
        <div *ngIf="!loadingProfesionales"
          class="container"
        >
          <table
            id="turnos-tabla"
            class="table"
            style="text-align: center"
          
          >
            <thead>
              <tr>
                <th scope="col">Profesional</th>
                <th scope="col">Especialidad</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of profesionales">
                <td class="centrado">
                  {{ p.apellido | toTitleCase }}
                </td>
                <td class="centrado">
                  <p *ngFor="let e of p.especialidades"> {{e.nombre | toTitleCase}}  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h6 style="text-align: center" *ngIf= "profesionales && profesionales.length == 0 "> No hay profesionales para esta especialidad</h6>
        </div>
      </div>
    
  </body>
</html>






