import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EstudiosService } from '../_services/estudios.service';
import {  Observable } from 'rxjs';
import { UserService } from '../_services/user.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { DialogCompartirComponent } from '../dialog-compartir/dialog-compartir.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';

class Idatos_validacion{
  estadoValidacion: string;
  tipoDocumento: string;
}

@Component({
  selector: 'app-historial-clinico',
  templateUrl: './historial-clinico.component.html',
  styleUrls: ['./historial-clinico.component.css'],
})
export class HistorialClinicoComponent implements OnInit {
  //CREO EL OBSERVABLE QUE VA A CONSUMIR LOS CAMBIOS DE ESTADO
  estadoValidacion$: Observable<string>;

  estadoValidacion: string;
  userData;
  estudios: any = [];
  selectedEstudio;
  selectedEstudios;
  random;
  urlEstudioPdf = null;
  estudiosImagenes;
  urlEpicrisis;
  private horusRestfullUrl: string;
  esTemporal: boolean;
  urlEstudioPdfComp: string;
  msjeEstad: string;
  funEstad: string;
  urlSitioPac: string;
  hashValido: boolean;
  urlGuardada: string;
  copiado: boolean = false;
  esAndroid: boolean;
  existePDFComp: boolean = true;
  existePDFEstudio: boolean = true;
  cargando: boolean = true;
  datos_validacion: Idatos_validacion = new Idatos_validacion();
  error_alert: boolean = false;

  constructor(
    private router: Router,
    private estudiosService: EstudiosService,
    private userService: UserService,
    private urlResolver: UrlResolverService,
    private dialog: MatDialog
  ) {
    this.getUrlPacientes();
    this.getMobile();
  }

  getMobile() {
    if (/android/i.test(navigator.userAgent)) {
      this.esAndroid = true;
    } else {
      this.esAndroid = false;
    }
  }

  ngOnInit() {
    //ME SUBSCRIBO A LOS CAMBIOS EN EL OBSERVABLE
    this.userService.getUserData().subscribe(
      x => {
        this.datos_validacion.estadoValidacion = x.estadoValidacion;
        this.datos_validacion.tipoDocumento = x.tipoDocumento;

        if(this.validacionAprobada()){
          this.estudios = [];
          this.urlResolver
            .getHorusRestfullUrl()
            .toPromise()
            .then((x) => {
              this.horusRestfullUrl = x.mensaje;
            });
          this.getClinicalHistory();    
        }else{
          this.cargando = false;
        }
      }
    )   
  }

  getUrlPacientes() {
    this.urlResolver.getUrlPacientes().subscribe(
      (respGetUrlPac) => {
        this.urlSitioPac = respGetUrlPac.mensaje;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  getClinicalHistory() {
    this.userService.userData.subscribe(
      (userData) => {
        this.userData = userData;
        if (this.userData.tipoDocumento == '9') {
          this.esTemporal = true;
        } else {
          this.esTemporal = false;
          this.estudiosService
            .getClinicalHistory(userData)
            .toPromise()
            .then(
              (data) => {
                if (data.paginador.elementos) {
                  // console.log(data.paginador.elementos)
                  this.estudios = data.paginador.elementos.sort(
                    (a, b) =>                      
                      <any>new Date(b.fecha).getTime() -
                      <any>new Date(a.fecha).getTime()
                  );
                  // console.log(this.estudios);                  
                  
                }
                this.cargando = false;
              },
              (error) => {
                // alert('No se pudo obtener su historial, intente mas tarde');
                let data = {
                  ok: false,
                  mensaje: "No se pudo obtener su historial, intente mas tarde",
                }
                this.openDialog(data);
                this.error_alert = true;
                this.cargando = false;
              }
            );
        }
      },
      (error) => {
        console.log(error);
      }
    );    
  }   
  
  setHashHC() {
    let urlVerHC = '';
    this.estudiosService.setHashHC(this.userData).subscribe(
      (respSetHash) => {
        if (respSetHash.ok) {
          urlVerHC = this.urlSitioPac + '/ver-historial-clinico/' + respSetHash.hash;
          this.hashValido = true;
        } else {
          this.hashValido = false;
        }
        this.dialog.open(DialogCompartirComponent, {
          width: '550px',            
          data: {hashValido: this.hashValido, url: urlVerHC},
          panelClass:'dialog-sin-overflow'
        })
      },
      (error) => {
        console.log(error);
      }
    );
  }

  navigateTo(){
    // this.router.navigate(['/estudios-externos']);
    window.location.href='/estudios-externos';    
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    inputElement.setSelectionRange(0,99999);
    document.execCommand('copy');   
    document.body.removeChild(inputElement); 
    this.copiado = true;
  }

  selectEstudio(estudio) {
    this.estudiosService.localStorageSetEstudio(estudio);
    // this.router.navigate(['episodio']);
    window.location.href='/episodio';
  }

  deleteDocument(estudio){
   
    this.dialog.open(DialogConfirmacionComponent, {
      data: {
        titulo: 'Confirmar borrado',
        mensaje: 'eliminar el documento con los siguientes datos:',
        observacionEstudio: estudio.observacion,
        fechaEstudio: estudio.fechast,    
        estasPor: true    
      },
      panelClass: 'dialog-sin-overflow'
    })
    .afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.estudiosService.bajaRegistroHCEPacientes(estudio, this.userData.usuario).subscribe((respuesta)=>
        {
          if(respuesta.ok){
            this.openDialog(respuesta);
          }
        })
    }})
  }

  openDialog(respuesta){
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {ok:respuesta.ok, mensaje:respuesta.mensaje},
      panelClass: 'dialog-sin-overflow'
    })
  }

  verificarEstado(estudio){
    let pasa = false;
    if(typeof estudio.estado === 'undefined'){
      pasa = true;
    } else{
      if(estudio.estado == 'INF' || estudio.estado == 'ENA'){        
        pasa = true;
      }
    }   
    return pasa;    
  }

  validacionAprobada(){      
    return this.datos_validacion.tipoDocumento != '9';
  }


  validacionPendiente(){    
    return (this.datos_validacion.tipoDocumento == '9' && (this.datos_validacion.estadoValidacion=='PENDIENTE' ||
    this.datos_validacion.estadoValidacion=='CARGANDO')) ;
  }

  goToValidar(){
    sessionStorage.setItem('voyAValidar', 'si');
    // this.router.navigate(['/register']);
    window.location.href='/register';
  }

  mensajeBoton(tipoEpisodio:string){
    switch (tipoEpisodio) {
      case 'Internacion':
        return 'VER INTERNACIÓN'
      case 'Estudio':
        return 'VER ESTUDIO'
      case 'Registro paciente':
        return 'VER REGISTRO'
      case 'Registros de medicos':
        return 'VER REGISTRO'   
      default:
        break;
    }
  }
}
      
  
