<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header" style="text-align: center;">
            <h5 class="modal-title" id="RecuperarUsuarioModalLabel">
                Recuperación de datos
            </h5>       
        </div>
        <div class="modal-body">
            <form *ngIf="!recoverUsernameFormReady" [formGroup]="recoverUsernameForm" (ngSubmit)="recoverUsername()">
                <div class="form-group">
                    <!-- <label for="mail">Ingrese su email</label>
                    <input type="text" formControlName="mail" class="form-control" [ngClass]="{
            'is-invalid': recoveredUsersFormSubmitted && ru.mail.errors
            }" /> -->
                    <mat-form-field class="mat-form-field">
                        <mat-label>Ingresá tu mail</mat-label>
                        <input matInput type="text" formControlName="mail" required>                            
                    </mat-form-field>
            
                    <div *ngIf="recoveredUsersFormSubmitted && ru.mail.errors" class="alert alert-danger">
                        <div *ngIf="ru.mail.errors.required">Ingresá el mail de la cuenta que querés recuperar</div>
                        <div *ngIf="ru.mail.errors.pattern">Debe ser un mail valido: ejemplo@gmail.com</div>
                        <div *ngIf="ru.mail.errors.incorrect">
                            Este mail no está registrado
                        </div>
                    </div>


                </div>
                <button type="submit" [disabled]="!respuesta" class="btn btn-info" style="float: right;" [class.spinnerButton]="!respuesta"> 
                    Enviar
                </button>
                <!-- <mat-spinner [diameter]="30" [hidden]="respuesta" 
                    style="display: inline-block;
                            margin-right: 10px;
                            margin-bottom: -5px;">
                </mat-spinner> -->
                
                <button mat-dialog-close [disabled]="!respuesta" class="btn btn-secondary">
                    Cancelar
                </button>
            </form>
            <div *ngIf="recoverUsernameFormReady">
                <h6>Se encontraron estos usuarios registrados con el mail ingresado, elegí cuál querés recuperar</h6>
                <form [formGroup]="recoveredUsersForm" (ngSubmit)="onSubmitRecoveredUsersForm()" class="formulario">
                    <div class="form-group formulario">
                        <div class="box_user_recuperado" *ngFor="let user of recoveredUsers">
                            
                            <label class="radio-container">
                                <div class="box_datos">
                                    <input type="radio" formControlName="user" class="form-control radio" [ngClass]="{
                                        'is-invalid': recoveredUsersFormSubmitted && rec.user.errors
                                    }" [value]="user" />
                                    <ul>
                                        <li><strong>Nombre: </strong>{{ user.fullName | toTitleCase }} </li>
                                        <li><strong>Fecha de Nacimiento: </strong>{{ user.str_fechaNacimiento | censoredBirthDate }} </li>
                                        <li><strong>Usuario: </strong>{{ user.username }} </li>
                                    </ul>
                                </div>
                            </label>
                            
                        </div>
                        <div *ngIf="recoveredUsersFormSubmitted && rec.user.errors" class="alert alert-danger alerta">
                            <div *ngIf="rec.user.errors.required">
                                Seleccioná el usuario a recuperar presionando el botón circular
                            </div>
                        </div>
                    </div>                   
                    

                    
                    <button (click)="closeRecoveredUsersFormModal();cerrarDialog()" class="btn btn-secondary">
                        Cancelar
                    </button>
                    <button style="float: right;" type="submit" class="btn btn-info" [disabled]="!respuesta || rec.user.value.documento==null ">
                        Enviar
                    </button>
                    <mat-spinner [diameter]="30" [hidden]="respuesta" 
                        style="margin-right: 10px;
                            margin-bottom: -5px;
                            float: right;
                            top: 0vh;
                            left: 8vh;">
                    </mat-spinner>
                </form>
                
            </div>
        </div>
    </div>
</div>
