import { Component, OnInit } from '@angular/core';
import { ComunicacionService } from '../_services/comunicacion.service';
import { MatDialog } from '@angular/material/dialog';
import { UrlResolverService } from '../_services/urlResolver.service';

export interface Noticia {
  idComunicacion: number;
  nombreImagen: string;
  titulo: string;
  fechaPublicacion: string;
  texto: string;
  fechaFinPublicacion: string;
}

@Component({
  selector: 'app-dialog-expandir-imagenes',
  templateUrl: './dialog-expandir-imagenes.component.html',
  styleUrls: ['./dialog-expandir-imagenes.component.css']
})

export class DialogExpandirImagenesComponent implements OnInit {

  files: string;
  IDAPLICACION;
  listaNoticiasTotal: Noticia[];
  noticiasCargadas: boolean = false;
  noticias: [];
  hayNoticias: boolean = false;

  constructor(
    public comunicacionService: ComunicacionService,
    public urlResolver: UrlResolverService,
    private dialog: MatDialog,
  ) {
    this.IDAPLICACION = 32;
  }

  ngOnInit() {
    this.getUrlComunicacion();
    this.getNoticiasFraccionadas();
    // this.files = this.comunicacionService.getUrlComunicacion();
  }
  
  async getUrlComunicacion() {
    await this.urlResolver.getComunicacionUrl().toPromise().then(x => {
      // console.log(x);
      // this.comunicacionUrl = x.mensaje;
      // this.comunicacionUrl = '/home/Repositorio_Digital/comunicacion/imagenes_noticias/desarrollo/';
      this.files = this.comunicacionService.getUrlComunicacion() + x.mensaje;
    });
  }

  async getNoticiasFraccionadas() {
    await this.comunicacionService.getNoticiasFraccionadas(
      this.IDAPLICACION,
      0,
      10
    ).toPromise().then((res) => {
      // console.log(res)
      if (res.length !== 0) {
        this.noticias = res.elementos.filter(this.filtrarPorNull);
        //console.log(this.noticias)
        this.noticiasCargadas = true;
      } else {
        this.hayNoticias = false;
      }
    }, (error) => {
      console.log(error,"hika");
    });
  }

  filtrarPorNull(noticia: Noticia) {
    return noticia.nombreImagen != null && noticia.nombreImagen != 'null';
  }

  onItemChange($event: any): void {
    console.log('Carousel onItemChange', $event);
  }
}
