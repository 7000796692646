import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IServicio } from '../_models/servicio.model';

@Component({
  selector: 'app-reservar-turno',
  templateUrl: './reservar-turno.component.html',
  styleUrls: ['./reservar-turno.component.css']
})
export class ReservarTurnoComponent implements OnInit {
  listado_servicios: IServicio[];
  loading: boolean;
  
  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loading = false;
    this.cargarServicios();
  }

  cargarServicios(){ // despues se podria llamar a un servicio y que la info esté en BD??
    this.listado_servicios = [
      {
        id: 1,
        id_seccion: 1,
        nombre: 'Cardiología',
        descripcion: "Electrocardiogramas | Electrofisiología | "+
        "Ergometrías (prueba de esfuerzo de 12 derivaciones) | Estudio de perfusión miocárdica de esfuerzo |"+
        "Reposo spect gatillado | Ecodoppler Cardiáco | Ecodoppler de vasos periféricos | Holter de 24hs |"+ 
        "Monitoreo ambulatorio de presión | ECG de selñal promediada | Test de la marcha |" +
        "Control de marcapasos.",
        cols:1,
        rows:1
      },
      {
        id: 2,
        id_seccion: 2,
        nombre: "Diagnóstico por imágenes",
        descripcion: "Ecocardiograma | Ecografía General "+
        "| Diagnóstico Prenatal y de la Mujer | Mamografías | Radiología | Resonancia Magnética "+
        "| Imágenes en 3D | Tomografía Computarizada Multislice",
        cols:1,
        rows:1
      },
      {
        id: 3,
        id_seccion: 4,
        nombre: "Fisioterapia",
        descripcion: null,
        cols:1,
        rows:1
      },      
      {
        id: 4,
        id_seccion: 5,
        nombre: "Gastroenterología",
        descripcion: 'Video endoscopía alta y baja diagnóstica | Rectoscopia'+
        '| Extracción de cuerpo extraño alto y bajo | Ligadura de varices esofágicas |'+
        'Dilatación esofágica | Dilatación colónica | Colocación de prótesis esofágica | Polipectomía colónica '+
        '| Polipectomía gástrica |    Gastrostomía endoscópica | Hemostasia de lesión sangrante gástrica '+
        '| Hemostasia de lesión sangrante colónica | Mucosectomía colonica | Mucosectomía gástrica '+
        '| Test de aire espirado para sobrecrecimiento bacteriano| test de aire espirado para helicobacter pylori'+
        'Ph metriaesofágica/gástrica | Manometría anorectal| Manometría esofágica | Biofeedback '+
        '| Ecoendoscopía alta y baja diagnóstica | Ecoendoscopía alta y baja con punción '+
        '| Extracción de cálculo coledociano | Colocación de prótesis biliar',
        cols:1,
        rows:2
      },
      {
        id: 5,
        id_seccion: 6,
        nombre: 'Hematología - Hemoterapia',
        descripcion: 'Estudio hematológico en sangre periférica | Estudio de hemostasia y trombosis |'+
        'Anti coagulados | Punciones ganglionares | Punciones de médula ósea | Biopsias de médula ósea |'+
        'Tratamiento quimioterápicos Grupo y factor RH | Genotipo RH | Identificación de anticuerpos irregulares |'+
        'Titulación de anticuerpos | Pruebas de cooms directa e indirecta | Inmunohematología obstétrica y perinatal |'+
        'Asesoramiento inmunohematológico al paciente y al médico tratante | Transfusiones de hemocomponentes'+
        '(sangre, plasma, plaquetas, crioprecipitados) en ambulatorios (según normas de procedimiento del servicio)'+
        'e internados.',
        cols:1,
        rows:2
      },
      {
        id: 6,
        id_seccion: 7,
        nombre: 'Hemodinamia',
        descripcion: 'Ecografía vascular endoluminal| Angiografías coronocarias|'+
          'Rotablator Angioplastias coronarias | Angioplastias de carótidas vertebrales e'+ 
          'intercerebrales | Embolizaciones (aneurismas cerebrales, malformaciones vasculares,'+
            'tumores) | Filtro de vena cava permanente y transitorio | Angioplastía y'+
            'Angiografía en el infarto agudo de miocardio | Angioplastía y Angiografía'+ 
            'periférica y eplacnicaAngiografía y angioplastía de miembros superiores '+
            'e inferiores | Angiografía y angioplastía de troncos supraórticos | Angiografía'+
            'y angioplastía de territorios esplacnicos | Embolizaciones y quimioembolizaciones '+
            '| Angiografía y angioplastía renal | Dosaje de renina | Valvuloplastías | '+
        'Tratamiento endovascular de aneurisma de aorta | Electrofisiología | '+
        'Estudios diagnósticos | Ablación por radiofrecuencia | Marcapasos | '+
        'Cardiodesfebriladores | Angiografía y Angioplastía | Cateterismo pediátrico | '+
          'diagnóstico y terapéutico | Angioplastía de estenosis de ramas de arteria pulmonar, '+
          'de coartación de aorta y angioplastia periférica | Valvuloplastia pulmonar | '+
            'aortica y mitral | Embolizaciones, cierre de doctos y comunicaciones '+
            'interauriculares',
        cols:3,
        rows:1
      },
      // {
      //   id: 7,
      //   id_seccion: 8,
      //   nombre: 'Hospital de dia',
      //   descripcion: 'Tratamientos de quimioterapia | Inmunoterapia | Planes de Hidratación'+ 
      //   '| Infusión de drogas -vía subcutánea y endovenosa-| Transfusiones de sedimento '+
      //   'globular y plaquetas | Tratamientos inmunosupresores para pacientes trasplantados| '+
      //   'Tratamientos antibióticos y recuperación post anestésica de cirugías menores.',
      //   cols:1,
      //   rows:1
      // },
      {
        id: 8,
        id_seccion: 9,
        nombre: 'Laboratorio',
        descripcion: null,
        cols:1,
        rows:1
      },
      {
        id: 9,
        id_seccion: 10,
        nombre: 'Medicina nuclear',
        descripcion: 'Curva de captación tiroidea | Centellografía de tiroides/mediastino '+
        '| Centellografía de paratiroides (con Sestamibi) | Centellografía de paratiroides '+
        '(con técnica de sustracción Talio/Tecnecio) | SPECT de tiroides | Barrido corporal '+
        'para CA de tiroides (post operatorio) | Barrido corporal para CA de tiroides '+
        '(post dosis terapéutica de Iodo 131) | Barrido corporal para CA de tiroides (de '+
          'control oncológico) | Dosis terapéutica de Iodo 131 (para tirotoxicosis) | '+
          'Dosis terapéutica de Iodo 131 (para CA tiroideo) | SPECT de perfusión miocárdica '+
          '(de reposo) | SPECT de perfusión miocárdica (esfuerzo y reposo) | SPECT de '+
          'perfusión miocárdica (con apremio farmacológico) | SPECT con Talio 201 (para '+
            'evaluación de viabilidad miocárdica) | Estudios GATILLADOS | Radiocardiograma '+
            '(de reposo) | Radiocardiograma (esfuerzo y reposo) | Centellografía estática '+
            'en Cámara Gamma de: perfusión pulmonar - ventilación pulmonar - glándulas '+
            'salivales hepática - esplénica - cerebral - ósea (un área) - renal | '+
            'Flebografía isotópica | Radiorrenograma (basal) Radiorrenograma (con '+
              'Furosemida) | Residuo vesical | Tránsito esofágico | Centellograma óseo '+
              'en tres tiempos | Centellograma óseo corporal total | Barrido corporal '+
              'total con Galio 67 | Estudio de hemorragia digestiva en Cámara Gamma | '+
              'Estudio de divertículo de Meckel | Cisternografía | Linfografía (marcación '+
                'de ganglio centinela para cirugía radioguiada) | SPECT pulmonar | '+
                'SPECT óseo | SPECT hepato-esplénico | SPECT renal',
        cols:3,
        rows:1
      },
      {
        id: 10,
        id_seccion: 12,
        nombre: 'Oftalmología',
        descripcion: null,
        cols:1,
        rows:1
      },
      {
        id: 11,
        id_seccion: 13,
        nombre: 'Trasplante renal',
        descripcion: null,
        cols:1,
        rows:1
      }
    ]
  }
}
