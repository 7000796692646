<div class="dialog">



    <div *ngIf="data.titulo == 'Confirmar borrado' || data.titulo=='Confirmar cancelar turno'"
     class="barra-inicial barra-inicial-roja">
        <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
    </div>

    <div *ngIf="data.titulo == 'Confirmar cerrar sesión'"
        class="barra-inicial barra-inicial-naranja">
        <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
    </div>

    <div *ngIf="data.titulo!='Confirmar cerrar sesión' 
            && data.titulo!='Confirmar borrado'
            && data.titulo!='Confirmar cancelar turno'" class="barra-inicial barra-inicial-celeste">
        <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
    </div>


    <div mat-dialog-title *ngIf="!data.estasPor">{{ data.titulo }}</div>


    <div mat-dialog-content>
        <p *ngIf="data.estasPor" class="texto-estas-por latoregular">Estás por <b>{{ data.mensaje }}</b></p>
        <p *ngIf="!data.estasPor">{{data.mensaje}}</p>
        <div *ngIf="data.nombreMutual != null">
            <ul>
                <li><b>Nombre: </b>{{data.nombreMutual}} </li>
                <li><b>Número de carnet: </b>{{data.nroCarnet}}</li>
            </ul>
        </div>

        <div *ngIf="data.observacionEstudio != null">
            <ul>
                <li><b>Observación: </b>{{data.observacionEstudio}} </li>
                <li><b>Fecha: </b>{{data.fechaEstudio}}</li>
            </ul>
        </div>

        <div *ngIf="data.turno != null">
            <ul>
                <li><b>Profesional: </b>{{data.turno.profesional}} </li>
                <li><b>Fecha y hora: </b>{{data.turno.fecha}}, a las {{data.turno.hora}}hs.</li>
            </ul>
        </div>
    </div>


    <mat-divider class="divisor"></mat-divider>
    <!-- <mat-progress-bar [value]="50" mode="determinate"></mat-progress-bar> -->


    <div mat-dialog-actions class="d-flex justify-content-center">
        <!-- <button 
        hidden="{{data.cerrar.deshabilitado}}" 
        mat-raised-button 
        [color]="data.cerrar.color" 
        (click)="cerrarDialogo()">
        {{ data.cerrar.texto }}
        </button> -->
        <div class="boton-dialog boton-cerrar" (click)="cerrarDialogo()">
            Cancelar
        </div>
        <!-- <button 
        mat-raised-button 
        [color]="data.confirmar.color" 
        (click)="confirmado()"
        [disabled]="buttonDisabled && data.esperarTimer" 
        style="align-items: center;">

        <p *ngIf="buttonDisabled && data.esperarTimer; else confirmar" style="margin-bottom: -2vh; display: flex; flex-direction: row; height: 50px; justify-content: center;">
            <mat-spinner [diameter]="25" color="primary" style="margin-top: 5px;"></mat-spinner>
            {{ timeLeft }}
        </p>   
        <ng-template #confirmar>
            Confirmar       
        </ng-template>
        </button> -->

        <div class="boton-dialog boton-confirmar" style="align-items: center;">
            <p *ngIf="buttonDisabled && data.esperarTimer; else confirmar"
                style="margin-bottom: -2vh; display: flex; flex-direction: row; height: 50px; justify-content: center;">
                <mat-spinner [diameter]="25" color="primary" style="margin-top: 5px;"></mat-spinner>
                {{ timeLeft }}
            </p>
            <ng-template #confirmar>
                <div (click)="confirmado()">
                    Confirmar
                </div>
            </ng-template>
        </div>
    </div>

</div>