<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1 m-0 p-0">
    <div id="turnos-body" class="col">
    <ng-template #NoHistoricoTemplate> <h6>No posee turnos</h6> </ng-template>
    <div class="col-md w-100 p-0 m-0">
      <div class="row mx-0 px-0 mt-1 mb-3">
        <div
          class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 align-self-center"
        >
          <h4 class="mb-0 pb-0">Historial de turnos</h4>
        </div>
        <div
          class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right"
        >          
          <button mat-raised-button class="volver" routerLink="/turnos">
            <mat-icon>arrow_back</mat-icon>Volver
          </button>

        </div>
        





      </div>

      <div *ngIf="loading">
        <h6>Se estan cargando los turnos, aguarde un momento</h6>
      </div>
      <div *ngIf="!loading">
        <div
          *ngIf="historico.length > 0; else NoHistoricoTemplate"
          class="container px-0 mx-auto"
        >
          <table
            id="tabla-turnos"
            class="table table-bordered"
            style="text-align: center"
          >
            <thead>
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Hora</th>
                <th scope="col">Establecimiento</th>
                <th class="opcional" scope="col">Especialidad</th>
                <th scope="col">Profesional</th>
                <th scope="col">Estado</th>
                <th scope="col">Preparaci&oacute;n</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let turno of historico; let i = index" [attr.id]="i">
                <td class="align-middle">{{ turno.str_fecha }}</td>
                <td class="align-middle">{{ turno.str_hora }}</td>
                <td class="align-middle">{{ turno.establecimiento }}</td>
                <td class="opcional align-middle">
                  {{ turno.especialidad | toTitleCase }}
                </td>
                <td class="align-middle">
                  {{ turno.profesional | toTitleCase }}
                </td>
                <!-- {{ turno.establecimiento | toTitleCase }} -->
                <td *ngIf="turno.asistio" class="align-middle">Asistió</td>
                <td
                  *ngIf="!turno.asistio && !turno.cancelar"
                  class="align-middle"
                >
                  Ausente
                </td>
                <td
                  *ngIf="!turno.asistio && turno.cancelar"
                  class="align-middle"
                >
                  <button
                    [hidden]="turno.bono"
                    id="cancelarTurno"
                    (click)="abrirDialogConfirmacion(turno)"
                    class="btn btn-danger"
                  >
                    Cancelar
                  </button>
                </td>
                <td class="align-middle">
                  <a *ngFor="let estudio of turno.estudiosPedido" href="{{estudio.linkPreparacion}}" target="_blank" [hidden]="!estudio.linkPreparacion">
                    <mat-icon style="cursor: pointer; color: #0a3d6e" matTooltip="Ver">picture_as_pdf</mat-icon>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
