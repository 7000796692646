import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecografia-pediatrica',
  templateUrl: './ecografia-pediatrica.component.html',
  styleUrls: ['./ecografia-pediatrica.component.css']
})
export class EcografiaPediatricaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
