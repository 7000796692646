import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString',
})
export class SplitStringPipe implements PipeTransform {
  transform(value: string): string {
    let splits = value.split('.');
    if (splits.length > 1) {
      return splits.pop();
    } else {
      return '';
    }
  }
}
