<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="d-flex justify-content-center" *ngIf="cargando">
        <mat-spinner></mat-spinner>
      </div>
  
      <div class="col-8 text-center" *ngIf="!cargando">
        <h6 class="subtitulo"> Admisi&oacute;n Web - Paso 1 </h6>
        <p>
          Est&aacute;s comenzando la admisi&oacute;n web, esto te llevar&aacute; algunos
          pasos. <br />El primero es confirmar tus datos personales, que son los
          siguientes:
        </p>
  
          <form [formGroup]="webCheckInForm" (ngSubmit)="guardarFormWebCheckIn()">
            <mat-form-field appareance="outline">
              <input
                matInput
                placeholder=""
                formControlName="paciente"
              />
            </mat-form-field>
            <br>
            <mat-form-field appareance="outline">
              <mat-select formControlName="tipoDni">
                <mat-option value="0">DNI</mat-option>
                <mat-option value="1">CED.Bs.As</mat-option>
                <mat-option value="2">C.FED.</mat-option>
                <mat-option value="3">LIB.CIV.</mat-option>
                <mat-option value="4">LIB.ENR.</mat-option>
                <mat-option value="5">OTRO</mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appareance="outline">
              <input matInput placeholder="" formControlName="nroDni" />
            </mat-form-field>
            <br/>
            <mat-form-field appearance="outline">
              <mat-label>Telefono</mat-label>
              <input matInput formControlName="telefono" />
            </mat-form-field>
            <br/>
            <mat-form-field appearance="outline">
              <mat-label>Domicilio</mat-label>
              <input
                matInput
                formControlName="domicilio"
                [value]="webCheckInForm.get('domicilio').value | toTitleCase"
              />
            </mat-form-field>
            <br/>
            <mat-form-field appearance="outline">
              <mat-label>Localidad</mat-label>
              <input
                matInput
                formControlName="localidad"
                [value]="webCheckInForm.get('localidad').value | toTitleCase"
              />
            </mat-form-field>
            <br/>
            <button
              mat-flat-button color="warn"
              class="boton"
              (click)="cancelar()"
              style="margin-right: 3%"
              >
              Cancelar
            </button>
            <button
              mat-flat-button color="primary"
              class="boton"
              type="submit"
              >
              Confirmar datos
            </button>
          </form>
      </div>
    </div>
  </div>
</body>

</html>