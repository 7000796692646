<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Tomografía Computarizada Multislice</h4>
        <div class="container">
          <p>
            Para solicitar turno comunicarse al:
          </p>
          <p>
            Teléfono: <strong>(0221) 412-9400, interno 1319</strong> de lunes a
            viernes de 7:15 a 17:00hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            <img
              src="../../assets/images/ecocardiogramas/icono_whatsapp.png"
            />
            WhatsApp: <strong> +54 9 221
            476-9962 </strong> de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Turno presencial: Central de Turnos, Planta Baja de lunes a
            viernes de 7:00 a 21:30hs y sábados de 7:00 a 14:30hs.
          </p>
  
          <p><strong>Equipo Médico: </strong></p>
            <p>Dr. Guillermo Lopetegui | Dr. Francisco Miraglia | Dr. Marcelo Sabbione | Dr. Marcelo Hid 
              | Dra. Paula Delgado | Dra. Maricel De Battista
            </p>
          
          <p><strong>Estudios:</strong></p>
          <ul>
            <li>Tomografía Computada Multislice con y sin contraste | Angiotomografía</li>
            <li>Tomografía con reconstrucción 3D</li>
            <li>Práctica Intervencionista guiada por Tomografía</li>
            <li>Bloqueo Neuroforaminal guiado por TC (Dr. Francisco Miraglia)</li>
          </ul>
  
        </div>
      </div>
    </div>
  </div>
  
</body>

</html>