import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../_services/authentication.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  currentUser;
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(async (x) => {
      this.currentUser = x;
    });
  }
}
