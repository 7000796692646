import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-mensaje-turno',
  templateUrl: './dialog-mensaje-turno.component.html',
  styleUrls: ['./dialog-mensaje-turno.component.css']
})
export class DialogMensajeTurnoComponent implements OnInit {

  tieneWebCheckIn;
  constructor(
    public dialogRef: MatDialogRef<DialogMensajeTurnoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.tieneWebCheckIn = this.data.tieneWebCheckIn;
  }

  cerrarDialogo(){
    this.dialogRef.close();
  }
}