import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'censoredBirthDate'
})
export class censoredBirthDatePipe implements PipeTransform {
  constructor(){}
  transform(value: string): any {
    let censoredPart = value.substring(value.length-2, value.length);
    let firstPart = value.substring(0, value.length-2);
    censoredPart = censoredPart.replace(/\d/g, "*")
    
    
    return (firstPart + censoredPart )
  }
}