<div class="container-fluid">
  <mat-progress-bar class="barra-progreso-celeste" mode="indeterminate" *ngIf="cargando" style="margin-top: 1%;"></mat-progress-bar>

  <div class="row d-flex justify-content-center mt-1 m-0 p-0" *ngIf="!turnoEncontrado && !cargando">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-6">
      <mat-card style="margin-top: 5%;">
        <mat-card-header>
          <mat-card-title>No se puede cancelar el turno. Ingrese al
            <a href="https://pacientes.hospitalespanol.org.ar" style="color: rgb(43, 100, 132); text-decoration: underline;">Sitio de Pacientes</a> 
              para intentar cancelarlo.</mat-card-title>
        </mat-card-header>
      </mat-card>
    </div>
  </div>

  <div class="row d-flex justify-content-center mt-1 m-0 p-0" *ngIf="turnoEncontrado && !cargando" >
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-4">
      <mat-card style="margin-top: 5%;">
        <mat-card-header>
          <mat-card-title>Cancelar Turno</mat-card-title>
        </mat-card-header>
        <mat-card-content style="text-align: center;">
          <p>¿Esta seguro/a que quiere cancelar el turno?</p>
          <p><strong>Profesional:</strong> {{turno.profesional}}</p>
          <p><strong>Fecha - Hora:</strong> {{turno.str_fecha}} - {{turno.str_hora}}</p>
        </mat-card-content>
        <mat-card-actions style="text-align: center;">
          <button mat-raised-button color="warn" type="button" (click)="cancelar()" [disabled]="canceloCorrecto">Si</button>
          <button mat-raised-button color="primary" type="button" (click)="salir()">No</button>
        </mat-card-actions>
        <p [ngClass]="alerta.cssClass">{{alerta.mensaje}}</p>
      </mat-card><br>
    </div>
  </div>
</div>
