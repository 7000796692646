<html>

<head>
    <meta name="robots" content="noindex">
</head>

<body>
    <div class="contenedor">
        <div class="subtitulo size-reducido">
            <p>Los turnos para estudios se solicitan directamente con el servicio que corresponda (Por teléfono,
                Whatsapp o presencial)</p> <br>

            <p>En los siguientes links vas a encontrar más información</p>
        </div>

        <div class="grid-container">
            <!-- <div *ngFor="let servicio of listado_servicios" class="grid_{{servicio.id}}"> -->
            <div *ngFor="let servicio of listado_servicios">
                <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-{{servicio.id_seccion}}"
                    target="_blank" rel="noopener noreferrer">
                    <div class="link"> {{ servicio.nombre }} </div>
                </a>
                <!-- <div id="descripcion_servicio"> {{ servicio.descripcion }} </div>      -->
            </div>
        </div>
    </div>


    <!-- <mat-grid-list cols="3" rowHeight="120px">
            <mat-grid-tile 
                *ngFor="let servicio of listado_servicios"
                [colspan]="servicio.cols"
                [rowspan]="servicio.rows">    
                <div>
                    <div id="nombre_servicio">
                        {{ servicio.nombre }}
                    </div>
        
                    <div id="descripcion_servicio">
                        {{ servicio.descripcion }}
                    </div>           
                </div>        
            </mat-grid-tile>
        </mat-grid-list> -->
</body>

</html>