import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tramites-administrativos',
  templateUrl: './tramites-administrativos.component.html',
  styleUrls: ['./tramites-administrativos.component.css']
})
export class TramitesAdministrativosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
