<div class="row bottom p-0 m-0 align-middle">
  <p
    class="p-2 m-auto"
    style="font-size: 13px; height: 100%; margin-top: 1%; text-align: center"
  >
    <img src="../../assets/images/footer/logo_footer.png" />
    Copyright ® 2019 | Reserva de Turnos Telefónicos: (0221) 412-9470 | Calle 9
    Nº175 e/ 35 y 36 | La Plata | Buenos Aires | República Argentina | Sitio del
    Paciente v3.19
  </p>
</div>
