import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmacion',
  templateUrl: './dialog-confirmacion.component.html',
  styleUrls: ['./dialog-confirmacion.component.css'],
})
export class DialogConfirmacionComponent implements OnInit {
  buttonDisabled = true;
  timeLeft = 5;

  constructor(
    public dialogo: MatDialogRef<DialogConfirmacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }

  confirmado(): void {
    this.dialogo.close(true);
  }

  ngOnInit() {
    const interval = setInterval(() => {
      this.timeLeft--;
      if (this.timeLeft === 0) {
        clearInterval(interval);
        this.buttonDisabled = false;
      }
    }, 1000);

    var botonCerrar = document.getElementById("boton-cerrar");
    botonCerrar.style.backgroundColor = this.data.cerrar.color;

  }
}
