import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'censoredDni'
})
export class censoredDniPipe implements PipeTransform {
  constructor(){}
  transform(value: string): any {
    let censoredPart = value.substring(0, (value.length-3));
    let lastPart = value.substring(value.length-3, value.length);
    censoredPart = censoredPart.replace(/\d/g, "*")
    
    
    return (censoredPart + lastPart)
  }
}