import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estudios-prequirurgicos',
  templateUrl: './estudios-prequirurgicos.component.html',
  styleUrls: ['./estudios-prequirurgicos.component.css']
})
export class EstudiosPrequirurgicosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
