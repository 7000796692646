import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-dialog-valoracion-usuario',
  templateUrl: './dialog-valoracion-usuario.component.html',
  styleUrls: ['./dialog-valoracion-usuario.component.css']
})

export class DialogValoracionUsuarioComponent implements OnInit {

  cargando: boolean = true;
  loadingSubmit: boolean = false;
  mostrarAlerta: boolean = false;

  formValoracion = new FormGroup({
    valoracion: new FormControl(),
    comentario: new FormControl()
  });

  constructor(
    public dialogRef: MatDialogRef<DialogValoracionUsuarioComponent>,
    public formbBuilder: FormBuilder,
    public userService: UserService
  ) { }

  async ngOnInit() {
    
    this.formValoracion = this.formbBuilder.group({
      valoracion: [null, [Validators.required]],
      comentario: '',
    })

    this.cargando = false;
  }

  cerrarDialogo() {
    this.dialogRef.close();
  }

  async guardar() {
    if (this.formValoracion.invalid) {
      console.log('invalido')
      this.mostrarAlerta = true;
    } else {
      this.mostrarAlerta = false;
      this.loadingSubmit = true;
      
      await this.userService.setValoracionUsuario(this.formValoracion.get('valoracion').value, this.formValoracion.get('comentario').value).toPromise().then(rpSetValoracion => {
        // console.log(rpSetValoracion)
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.cerrarDialogo();
      })
    }
  }
}
