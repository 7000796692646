<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div mat-dialog-title class="modal-header" style="text-align: center;">
            <h5 class="modal-title" id="MensajeAyudaModalLabel">
                ¿Tenés problemas para iniciar sesión?
            </h5>       
        </div>
        <mat-dialog-content class="modal-body" style="margin: 0.5%;">
            <mat-label id="inicio">Si no recordás tu contraseña, en el siguiente botón podés generar una nueva ingresando tu email:</mat-label>
            <div class="col align-self-center opcion-login" style="text-align: center;" (click)="openDialogOlvideMisDatos()">            
                <span class="material-symbols-outlined iconitos">badge</span>
                <p> Recuperar mis datos </p>                   
            </div>
            <mat-label>Te ofrecemos los siguientes consejos:</mat-label>
            <ul>
                <li>Usualmente tu nombre de usuario coincide con la primera parte de tu email. El número de documento NO es el nombre de usuario.</li>
                <li>Recordá activar y/o desactivar las mayúsculas.</li>
                <li>Verificá tener el teclado numérico activado en caso de utilizarlo.</li>
                <li>Verificá que no haya espacios en blanco antes o después de tu nombre de usuario y contraseña.</li>
                <li>Si utilizás el autocompletar de Google, verificá que se autocomplete la contraseña actual y no una anterior. Podés hacerlo presionando el 
                    botón <button
                    class="showbt"
                    mat-stroked-button
                  >
                    MOSTRAR
                  </button> para visualizarla.</li>
            </ul>

            <mat-label>Si ya seguiste los consejos, y no lográs reestablecer tu contraseña, comunicate con Soporte Técnico para resolver el inconveniente:</mat-label>
            <div class="col align-self-center opcion-login" style="text-align: center;" (click)="openDialogSoporteTecnico()">            
                <span class="material-symbols-outlined iconitos">handyman</span>
                <p>Soporte técnico</p>                   
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="center" style="margin-bottom: 2px;">
            <button (click)="cerrarDialog()" class="btn btn-secondary">
                Volver al Inicio de Sesión
            </button>
        </mat-dialog-actions>

        <a id="recuperarDatosLink" href="javascript:void(0);" (click)="scrollToElement('inicio')" style="display: none;">IR AL INICIO</a>


        
    </div>
</div>

