import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { User, UserAdapter } from '../_models/user';
import { UrlResolverService } from './urlResolver.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UserService {
  
  
    

  userData: Observable<any>;
  userDataSubject: BehaviorSubject<any>;
  private baseUrl: string;
  private SMUrl: string;
  private sucursal;
  private horusRestfullUrl: string;
  private thotRestfullUrl: string;
  constructor(
    private http: HttpClient,
    private adapter: UserAdapter,
    private urlResolver: UrlResolverService
  ) {
    
    this.userDataSubject = new BehaviorSubject<any>(null);
    this.userData = this.userDataSubject.asObservable();
    (this.baseUrl = AppComponent.testUrl),
      (this.sucursal = AppComponent.sucursal); // 1 ESPAÑOL
    this.SMUrl = AppComponent.SMApi;
    this.getUrl();
    this.getThotRestfullUrl();
  }

  

  getUrl() {
    this.urlResolver
      .getHorusRestfullUrl()
      .subscribe((x) => (this.horusRestfullUrl = x.mensaje));
  }

  getThotRestfullUrl() {
    this.urlResolver
      .getThotRestfullUrl()
      .subscribe((x) => (
        // console.log(x),
        this.thotRestfullUrl = x.mensaje
      ));
  }
  
  passwordChange(formValue) {
    let body = new FormData();
    body.append('actual', formValue.actual);
    body.append('nueva', formValue.confirmPassword);
    return this.http.post<any>(this.baseUrl + '/users/password-change', body);
  }

  
  getUserData(): Observable<any> {
    this.userData = this.http.get<any>(this.baseUrl + '/seguro/user');
    return (this.userData);
  } 

  getUserDataExt(nombreUsuario) {
    let body = new FormData();
    body.append('nombreUsuario', nombreUsuario);
    return this.http.post<any>(this.baseUrl + '/userDataExt', body);
  }

  recoverPassword(formValue) {
    let body = new FormData();
    body.append('username', formValue.username.toLowerCase());
    body.append('sucursal', this.sucursal);
    return this.http.post<any>(this.baseUrl + '/users/password-recover', body);
  }

  recoverUsername(formValue): Observable<User[]> {
    let body = new FormData();
    // body.append('dni', formValue.dni);
    body.append('mail', formValue.mail.toLowerCase());
    body.append('sucursal', this.sucursal);
    return this.http
      .post<any>(this.baseUrl + '/users/username-con-mail', body)
      .pipe(
        // Adapt each item in the raw data array

        map((data: any[]) => data.map((item) => this.adapter.adapt(item)))
      );
  }

  sendMail(formValue) {
    let body = new FormData();
    // body.append('dni', formValue.dni);
    body.append('user', JSON.stringify(this.adapter.serialize(formValue.user)));
    body.append('sucursal', this.sucursal);
    return this.http.post<any>(this.baseUrl + '/users/send-mail', body);
  }

  supportMessage(formValue) {
    let body = new FormData();
    body.append('nombreApellido', formValue.nombreApellido);
    body.append('email', formValue.email.toLowerCase());
    body.append('asunto', formValue.asunto);
    body.append('mensaje', formValue.mensaje);
    body.append('sucursal', this.sucursal);

    return this.http.post<any>(this.baseUrl + '/users/support-message', body);
  }

  register(formValue) {
    let body = new FormData();
    body.append('firstName', formValue.firstName);
    body.append('lastName', formValue.lastName);
    body.append('mail', formValue.mail.toLowerCase());
    body.append('nombreUsuario', formValue.nombreUsuario.toLowerCase());
    body.append('dni', formValue.dni);
    body.append('tel', formValue.tel);
    body.append('date', moment(formValue.date).toString());
    body.append('gender', formValue.gender);
    body.append('location', formValue.location);
    body.append('city', formValue.city);
    body.append('cod', formValue.cod);
    body.append('sucursal', this.sucursal);
    body.append('tipoDocumento', formValue.tipoDocumento)
    return this.http.post<any>(this.baseUrl + '/users/register', body);
  }
  
  validar(formValue) {
    //console.log(formValue);    
    this.getThotRestfullUrl();
    let body = {
      dni: formValue.dni,
      tipoDni: formValue.tipoDni,
      dniTemporal: formValue.dniTemporal,
      fotoFrenteDNI: formValue.fotoFrenteDNI.valor,
      nombreFotoFrenteDNI: formValue.fotoFrenteDNI.nombre,
      fotoPaciente: formValue.fotoPaciente.valor,
      nombreFotoPaciente: formValue.fotoPaciente.nombre,
      usuario: formValue.nombreUsuario,
      idFinanciador: formValue.idFinanciador,
      nroCarnet: formValue.nroCarnet,
      idTipoValidacion: formValue.idTipoValidacion
    };
    
    return this.http.post<any>(this.thotRestfullUrl + '/api/validacion/generarSolicitudValidarUsuario', body);
  }

  getSolicitudesValidacion(body) {
    // console.log(body)
    return this.http.post<any>(this.thotRestfullUrl + '/api/validacion/getValidaciones', body);
  }

  generarSolicitud(formValue) {
    this.getThotRestfullUrl();
    let body = {
      dni: formValue.dni,
      tipoDni: formValue.tipoDni,
      dniTemporal: formValue.dniTemporal,
      usuario: formValue.nombreUsuario,
      idFinanciador: formValue.idFinanciador,
      nroCarnet: formValue.nroCarnet,
      idTipoValidacion: formValue.idTipoValidacion,

      fotoFrenteDNI: formValue.fotoFrenteDNI.valor,
      nombreFotoFrenteDNI: formValue.fotoFrenteDNI.nombre,
      fotoPaciente: formValue.fotoPaciente.valor,
      nombreFotoPaciente: formValue.fotoPaciente.nombre,
    };

    // return this.http.post<any>('http://localhost:8090/thot_restfull/api/validacion/generarSolicitud', body);

    return this.http.post<any>(this.thotRestfullUrl + '/api/validacion/generarSolicitud', body);
  }

  

  obtenerEntradasDeMenu() {
    return this.http.get<any>(this.baseUrl + '/menu/getElementosMenu');
  }

  obtenerCoberturasPaciente() {
    return this.http.get<any>(this.baseUrl + '/coberturas-paciente');
  }
  obtenerCoberturasPacienteTurnos(dia, matricula) {
    let params = new HttpParams()
      .set('dia', dia)
      .set('matricula', matricula);
    return this.http.get<any>(this.baseUrl + '/coberturas-paciente', {params});
  }

  obtenerUltimaCoberturaPaciente() {
    return this.http.get<any>(this.baseUrl + '/ultima-coberturas-paciente');
  }

  obtenerDeudaPaciente() {
    return this.http.get<any>(this.baseUrl + '/deuda-paciente');
  }

  obtenerDeudaSocio() {
    return this.http.get<any>(this.baseUrl + '/deuda-socio');
  }

  getWebCheckIn() {
    return this.http.get<any>(this.baseUrl + '/web-check-in');
  }

  setWebCheckin() {
    return this.http.get<any>(this.baseUrl + '/set-web-check-in');
  }

  guardarDatosUsuario(formValue) {
    let body = new FormData();

    body.append('telefono', formValue.telefono);
    body.append('domicilio', formValue.domicilio);
    body.append('localidad', formValue.localidad);

    return this.http.post<any>(this.baseUrl + '/users/guardarDatosUsuario', body);
  }

  setUltimaCobertura(formValue) {
    let body = new FormData();

    body.append('codigoMutual', formValue.mutual);
    body.append('nroCarnet', formValue.nroCarnet);

    return this.http.post<any>(this.baseUrl + '/users/setUltimaCobertura', body);
  }

  autorizarConsulta(turnoAutorizar) {
    let body = new FormData();

    body.append('matricula', turnoAutorizar.matricula);
    body.append('fecha', turnoAutorizar.fecha);
    body.append('desdeHora', turnoAutorizar.desdeHora);
    body.append('orden', turnoAutorizar.orden);
    body.append('pin', turnoAutorizar.pinSeguridad);
    
    return this.http.post<any>(this.baseUrl + '/users/autorizarConsulta', body);
  }

  crearBoletaAmbulatoria(boletaCrear) {
    let body = new FormData();

    body.append('usuario', boletaCrear.usuario);
    body.append('matricula', boletaCrear.matricula);
    body.append('fecha', boletaCrear.fecha);
    body.append('desdeHora', boletaCrear.desdeHora);
    body.append('orden', boletaCrear.orden);
    body.append('importe', boletaCrear.importe);

    return this.http.post<any>(this.baseUrl + '/users/crearBoletaAmbulatoria', body);
  }

  obtenerImportesBoleta(boleta) {
    let body = new FormData();

    body.append('nroBoleta', boleta.nroBoleta);

    return this.http.post<any>(this.baseUrl + '/users/obtenerImportesBoleta', body);
  }

  liquidarBoleta(boleta) {
    let body = new FormData();

    body.append('nroBoleta', boleta.nroBoleta);

    return this.http.post<any>(this.baseUrl + '/users/liquidarBoleta', body);
  }

  registrarLlegadaPaciente(turnoAutorizar) {
    let body = new FormData();

    body.append('matricula', turnoAutorizar.matricula);
    body.append('fecha', turnoAutorizar.fecha);
    body.append('desdeHora', turnoAutorizar.desdeHora);
    body.append('orden', turnoAutorizar.orden);

    return this.http.post<any>(this.baseUrl + '/users/registrarLlegadaPaciente', body);
  }

  aceptarTerminos() {
    return this.http.get<any>(
      this.baseUrl + '/users/aceptar-terminos-y-condiciones'
    );
  }

  guardarEstadistica(mensaje, funcion) {
    let body = new FormData();

    body.append('mensaje', mensaje);
    body.append('funcion', funcion);
    return this.http.post<any>(
      this.baseUrl + '/users/guardar-estadistica',
      body
    );
  }

  registrarTerminosYCondiciones(usuario) {
    let form = {
      usuario: usuario.usuario,
      tipoDocumento: usuario.tipoDocumento,
      nroDocumento: usuario.nroDocumento,
      apellido: usuario.apellido,
    };
    return this.http.post<any>(
      this.horusRestfullUrl + '/api/hcdigital/registro-terminos-y-condiciones',
      form
    );
  }

  actualizarDatos(form, fechaNacimiento) {
    // console.log(form)
    let body = new FormData();

    body.append('email', form.email);
    body.append('telefono', form.telefono);
    body.append('fechaNacimiento', fechaNacimiento);
    body.append('domicilio', form.domicilio);
    body.append('localidad', form.localidad);
    body.append('cp', form.cp);
    body.append('fotoPerfil', form.fotoPerfil.valor);
    body.append('nombreFotoPerfil', form.fotoPerfil.nombre);
    
    return this.http.post<any>(this.baseUrl + '/users/actualizarDatos', body);
  }

  actualizarFlagUsuarioCampania(){      
    return this.http.get<any>(
      this.baseUrl + '/users/actualizarFlagCampania'  
    );
  }

  actualizarCobertura(mutual) {
    let body = new FormData();

    body.append('usuario', mutual.usuario);
    body.append('idMutual', mutual.mutual);
    body.append('nroCarnet', mutual.nroCarnet);
    body.append('estado', mutual.estado);

    return this.http.post<any>(this.baseUrl + '/users/actualizarCobertura', body);
  }

  recuperarTiposDeDocumentos(){
    return this.http.get<any>(this.baseUrl + '/users/recuperarTiposDeDocumentos');
  }

  guardarAlertaRedireccion(ruta: string, usuario: any) {
    let body = new FormData();
    body.append('ruta', ruta);
    body.append('usuario', usuario);
    return this.http.post<any>(this.baseUrl + '/users/guardarAlertaRedireccion', body);
  }

  recuperarUsuarios(form){
    // console.log(form);
    let body = new FormData();
    body.append('nroDocumento', form.dni);
    body.append('tipoDocumento', form.tipoDocumento);
    return this.http.post<any>(this.baseUrl + '/users/recuperarUsuarios', body);    
  }

  cambiarEstado(usuario, estado) {
    let body = new FormData();

    body.append('usuario', usuario);
    body.append('estado', estado);    
    
    return this.http.post<any>(this.baseUrl + '/users/cambiarEstado', body);
  }

  setValoracionUsuario(valoracion, comentario) {
    let body = new FormData();

    body.append('valoracion', valoracion);
    body.append('comentario', comentario);    
    
    return this.http.post<any>(this.baseUrl + '/users/setValoracionUsuario', body);
  }
}