<html>

<head>
    <meta name="robots" content="noindex">
</head>

<body>
    <div class="texto contenedor">
        <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-11"
            target="_blank" rel="noopener noreferrer">
            <div class="link"> Diálisis y Nefrología </div>
        </a>

        <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-8"
            target="_blank" rel="noopener noreferrer">
            <div class="link"> Hospital de Día </div>
        </a>

        <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-no-ambulatorios/#seccion-3"
            target="_blank" rel="noopener noreferrer">
            <div class="link"> Neonatología </div>
        </a>

        <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-no-ambulatorios/#seccion-5"
            target="_blank" rel="noopener noreferrer">
            <div class="link"> Terapia Intensiva | UTI </div>
        </a>

        <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-13"
            target="_blank" rel="noopener noreferrer">
            <div class="link"> Trasplante </div>
        </a>

        <a href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-no-ambulatorios/#seccion-4"
            target="_blank" rel="noopener noreferrer">
            <div class="link"> Unidad de Cuidados Intensivos | UCO </div>
        </a>

    </div>
</body>

</html>