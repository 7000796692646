import { Component, OnInit } from '@angular/core';
import { TurnosService } from '../_services/turnos.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-llegada-paciente',
  templateUrl: './dialog-llegada-paciente.component.html',
  styleUrls: ['./dialog-llegada-paciente.component.css']
})

export class DialogLlegadaPacienteComponent implements OnInit {

  turnoAutorizar: any;
  cargando: boolean = true;

  constructor(
    private turnoService: TurnosService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.obtenerDatosTurno();
  }

  async obtenerDatosTurno() {
    this.turnoAutorizar = await this.turnoService.getTurnoAdmision();
    this.cargando = false;
    // console.log(this.turnoAutorizar);
  }

  finalizar() {
    this.dialog.closeAll();
    this.turnoService.setTurnoAdmision(null);
    this.router.navigate(['turnos-reservados']);
  }
}