<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Cardiología</h4>
        <div class="container">
          <p>
            Para solicitar turno para <strong>Electrocardiograma</strong>, comunicarse al
            <strong>(0221) 412-9470</strong>, de
            lunes a viernes de 7:15 a 17:00hs, sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Para solicitar turno para <strong>Holter 24hs (MAPA)</strong>, comunicarse al
            <strong>(0221) 412-9400, interno 1369</strong> de
            lunes a viernes de 9:00 a 16:00hs.
          </p>
        </div>
      </div>
    </div>
  </div>

</body>

</html>