import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-registro-exitoso',
  templateUrl: './dialog-registro-exitoso.component.html',
  styleUrls: ['./dialog-registro-exitoso.component.css']
})
export class DialogRegistroExitosoComponent implements OnInit {
  email: String;
  emailModif: String;

  constructor(public dialogRef: MatDialogRef<DialogRegistroExitosoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {email: string}) { 
      this.email = this.data.email;
      const regex = /(.{3})(?=@)/g;
      this.emailModif= this.email.replace(regex, '***');
    }

  ngOnInit(): void {
  }

  cerrarDialogo(){
    this.dialogRef.close();
    window.location.href = "/login";
  }
}
