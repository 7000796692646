<!-- <div style="display: flex;">

 
  <section *ngIf="!version_mobile; else version_mobileBlock"> 
      <div *ngIf="!es_path_login(); else login_path">
        <app-menu-lateral class="menu-lateral"></app-menu-lateral>

        <div class="contenido-relativo1">  
          <div class="contenido-pag">
            <router-outlet> </router-outlet>
          </div>                
          <img class="fondo-pag" src="../assets/images/fondo_general.png">
          <img class="marca-de-agua" src="../assets/images/marca_de_agua.png"> 
           
        </div>

      </div>

      <ng-template #login_path>          
        <div class="contenido-relativo2">
          <router-outlet></router-outlet>
        </div>
      </ng-template>
  </section>

  
  <ng-template #version_mobileBlock>
      <div *ngIf="!es_path_login(); else login_path">      
          <app-menu-sandwich class="menu-sandwich"> </app-menu-sandwich>
      </div>

      <ng-template #login_path>          
        <div class="contenido-relativo2">
          <router-outlet></router-outlet>
        </div>
      </ng-template>
  </ng-template>

</div> -->

<div style="display: flex">
  <section *ngIf="!es_path_login(); else login_path">
    <section *ngIf="!version_mobile;else version_mobileBlock">
      <app-menu-lateral class="menu-lateral"></app-menu-lateral>

        <div class="contenido-relativo1">  
          <div class="contenido-pag">
            <router-outlet> </router-outlet>
          </div>                
          <img class="fondo-pag" src="../assets/images/fondo_general.png">
          <img class="marca-de-agua" src="../assets/images/marca_de_agua.png"> 
           
        </div>
    </section>
    <ng-template #version_mobileBlock>
      <app-menu-sandwich class="menu-sandwich"> </app-menu-sandwich>
    </ng-template>
  </section>
  <ng-template #login_path>
    <div class="contenido-relativo2">
      <router-outlet></router-outlet>
    </div>
  </ng-template>

</div>