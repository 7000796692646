<div class="barra-inicial barra-inicial-celeste">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
  </div>
  
  <h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%; text-align: center;">¡Te damos la bienvenida! </h1>
  
  
  <div mat-dialog-content style="text-align: center;">      
        Tus datos de ingreso (usuario | clave) se enviaron a tu correo <b>{{this.emailModif}}</b>
  </div>
  
  
  <div mat-dialog-actions class="d-flex justify-content-center">
    <button 
          class="boton-dialog boton-confirmar"
          color="primary"
          (click)="cerrarDialogo()"        
          > Aceptar
    </button>
  </div>   
  