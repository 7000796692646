<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <div class="container">
        <h5>Unidad de Terapia Intensiva. (0221) 427-0191, interno 1402</h5>

        <p>
          <a href="#"><strong>Visitas de 12 a 12.30 y de 20 a 20.30</strong></a>
        </p>
        <p>
          <a href="#"><strong>Informes</strong></a>
        </p>
        <p>
          <a href="#"><strong>Normas para mdicos y familiares</strong></a>
        </p>
        <p>
          <a href="#"><strong>Médicos de cabecera</strong></a>
        </p>
        <p>
          <a href="#"><strong>Artículos de uso personal</strong></a>
        </p>
        <p>
          <a href="#"><strong>Medicamentos</strong></a>
        </p>

        <hr />

        <p>
          Todos los pacientes son vigilados en forma constante con un
          equipamiento de electromedicina de ltima generación, que sirve de
          apoyo a la humana y reconocida labor del personal médico y paramédico.
          Son trece monitores multiparámetricos uno para cada cama- que miden en
          forma constante la presión arterial, la temperatura, la saturación de
          oxígeno, la frecuencia cardíaca y otros módulos de control que pueden
          incorporarse según la complejidad del paciente.
        </p>
        <p>
          Además de tener la ventaja de no tener que abordar constamentemente al
          paciente para obtener información valiosísima para el cuidado de su
          salud y de permitir mayor privacidad y tranquilidad, la conexión de
          estos monitores a una red, posibilita que en una pantalla, el personal
          médico y paramédico pueda tener de un vistazo los parámetros de cada
          uno de los pacientes internados.
        </p>

        <h6><strong>Visitas de 12 a 12.30 y de 20 a 20.30</strong></h6>
        <p>
          Un familiar por vez, previo a un meticuloso lavado de manos. No existe
          límite de personas a ingresar, si lo hacen de a uno y cumplen con lo
          estipulado. Se prohibe el ingreso de menores de 15 años. Se inicia
          sólo cuando el coordinador de Enfermería del Área lo dispone. También
          este lapso de tiempo puede ajustarse a imprevistos y ese es el motivo
          por el cual pueden impedirle el acceso o bien pedirle que se retire
          aún dentro del horario de visita y únicamente volverá a entrar cuando
          el coordinador de Enfermería se lo indique o bien en el próximo
          horario de visita.
        </p>

        <h6><strong>Informes</strong></h6>
        <p>
          Están a cargo del médico de guardia sólo al finalizar el horario de
          visita del mediodía, excepto en pacientes que hayan ingresado después
          de este horario o bien cuando se produzcan cambios de importancia en
          el estado o en el tratamiento del paciente. No se dan informes
          telefónicos.
        </p>

        <h6><strong>Normas para médicos y familiares</strong></h6>
        <p>
          Los familiares de los pacientes que sean médicos y que no pertenezcan
          al staff deberán ajustarse a las normas de ingreso y no podrán entrar
          fuera del horario de visitas. Sólo tendrán acceso a la historia
          clínica, hoja de enfermería, hoja de indicaciones médicas, resultados
          de procedimientos, entre otras, sólo si el coordinador médico del Área
          lo autoriza por escrito. No podrán tomar controles de signos vitales o
          modificar algún tratamiento o aparatología.
        </p>

        <h6><strong>Médicos de cabecera</strong></h6>
        <p>
          Los médicos de guardia del Área darán informes telefónicos o
          personalmente a colegas que no pertenecen a la institución fuera de
          horario, siempre y cuando estén disponibles y la dinámica de trabajo
          lo permita.
        </p>

        <h6><strong>Artículos de uso personal</strong></h6>
        <p>
          La enfermera le informará sobre los artículos de uso personal
          necesarios durante la internación. El Área sólo aceptará la entrega de
          pañales si el paciente los necesita. Se aconseja no portar joyas o
          dinero. Le rogamos se responsabilice de las prótesis dentales y
          objetos similares de valor. La institución no se hace responsable de
          su deterioro o pérdida.
        </p>

        <h6><strong>Medicamentos</strong></h6>
        <p>
          Debe poner en conocimiento del coordinador de Enfermería, los
          medicamentos prescriptos antes del ingreso. El personal sólo está
          autorizado a administrar la medicación consignada en la hoja de
          indicaciones médicas y las camareras sólo puede servir la dieta
          señalada por el profesional. El médico interno de guardia podrá
          solucionar cualquier problema de urgencia.
        </p>
      </div>
    </div>
  </div>
</div>
