import { Component, OnInit } from '@angular/core';
import { Observable} from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { UrlResolverService } from '../_services/urlResolver.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogActualizarContrasenaComponent} from '../dialog-actualizar-contrasena/dialog-actualizar-contrasena.component';
import { DialogTerminosYCondicionesComponent} from '../dialog-terminos-y-condiciones/dialog-terminos-y-condiciones.component'
import { UserService } from '../_services/user.service';
import { DialogCampaniaDeActualizacionComponent } from '../dialog-campania-de-actualizacion/dialog-campania-de-actualizacion.component';
import { ComunicacionService } from '../_services/comunicacion.service';
import { element } from 'protractor';
import { log } from 'console';
import { TurnosService } from '../_services/turnos.service';
import { EstudiosService } from '../_services/estudios.service';
import * as moment from 'moment';
declare var $: any;

export interface UserData {
  apellido: string;
  campania_actualizacion: string;
  codigoPostal: string;
  domicilio: string;
  email: string;
  estadoValidacion?: string;
  fechaNacimiento: Date;
  localidad: string;
  masculino: boolean;
  nroDocumento: string;
  permisos?: string;
  socioNro?: string;
  telefonoParticular: string;
  tipoDocumento: string;
  usuario: string;
  fotoPerfil: String;
  nombres: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  estadoValidacion$: Observable<string>;
  idSitio: number = 32;
  estadoValidacion: string;
  currentUser: User;
  submitted = false;
  temporal = false;
  flagCampaniaActualizacion: string;
  userData: UserData;
  success;
  mensaje;
  cargando: boolean = true;
  noticias;
  hayNoticias: boolean = true;
  noticiasCargadas: boolean = false;
  turnosReservados: any;
  existenTurnos: boolean;
  loadingTurnos: boolean;
  estudios: any[];
  existenEstudios: boolean;
  loadingEstudios: boolean;
  validacionPendiente_bool: boolean;
  validacionDenegada: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private urlResolver: UrlResolverService,
    public dialog: MatDialog,
    private userService: UserService,
    private comunicacionService: ComunicacionService,
    private turnosService: TurnosService,
    private estudiosService: EstudiosService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;  
  }

  async ngOnInit() {

    if (this.verificarTerminosYCondiciones()) {
      if (this.verificarActualizarContrasena()) {
        this.verificarCampaniaActualizacion();
      }
    }
    await this.getUserData(); 

    await this.getNoticiasFraccionadasHomePortal();

    await this.obtenerTurnosReservadosPaciente();
    
  }
  
  async getNoticiasFraccionadasHomePortal() {
    await this.comunicacionService.getNoticiasFraccionadasHomePortal().toPromise().then((res) => {
      if (res.length !== 0) {
        this.noticias = res.elementos.filter(this.filtrarPorNull);
        this.hayNoticias = this.noticias.length !== 0;
      } else {
        this.hayNoticias = false;
      }
    }, (error) => {
      console.log(error,"hika");
    })
  }

  async getUserData(){
    this.loadingEstudios = true;

    await this.userService.getUserData().toPromise().then(usuario => {
      this.userData = usuario; 
      if (this.userData.estadoValidacion === 'DENEGADA'){
        this.validacionDenegada = true;
      }
      
      if(!this.validacionPendiente_bool){ 
        this.estudios = [];
        
        this.getClinicalHistory();
           
      };                       
    }, error => {
      console.log(error);
    });

    this.cargando = false;
  }

  async obtenerTurnosReservadosPaciente() {
    this.loadingTurnos = true;
    const hoy = moment().format('DD/MM/YYYY');
    await this.turnosService.getTurnosReservadosPaciente().toPromise().then((response) => {
      // console.log(response)
      this.turnosReservados = response.turnos;

      // filtro los turnos con fecha hoy
      this.turnosReservados = this.turnosReservados.filter((turno) => turno.str_fecha === hoy);
      this.existenTurnos = this.turnosReservados.length > 0;
    }, (error) => {
      console.log(error);      
    });
    this.loadingTurnos = false;
  }

  async getClinicalHistory() {
    await this.estudiosService
    .getClinicalHistory(this.userData)
    .toPromise()
    .then(
      (data) => {
        // console.log(data.paginador.elementos)
        if (data.paginador.elementos) {
          // Filtra los estudios que están en la semana actual
          this.estudios = data.paginador.elementos
            .filter(estudio => {
              const fechaEstudio = moment(estudio.fechast, 'DD/MM/YYYY');
              return fechaEstudio >= moment().subtract(7, 'days') && fechaEstudio <= moment() && estudio.tipoEpisodio === 'Estudio';
            })
            .sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());  
          // console.log(this.estudios);
        }
      this.existenEstudios = this.estudios.length > 0;
      // console.log(this.existenEstudios);
      }, (error) => {
        console.log(error);              
      }
    );
    this.loadingEstudios = false;
  }
 
  openModalFun() {
    // document.getElementById('openModalButton').click();
    this.dialog.open(DialogCampaniaDeActualizacionComponent, {
      width: '500px',
      disableClose: false
    }).afterClosed().subscribe(
      (x) => {},
      (error) => {
        console.log(error);        
      }
    );
  }

  closeModalFun() {
    document.getElementById('FirstLoginModal').style.display = 'none';
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  filtrarPorNull(noticia) {
    return noticia.nombreImagen != null && noticia.nombreImagen != 'null';
  }

  obtenerFlagCampaniaAct(){
    this.urlResolver.getCampaniaAct().subscribe(
      (respFlagCampania) => {
        this.flagCampaniaActualizacion = respFlagCampania.mensaje;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  verificarActualizarContrasena(){
    if(localStorage.getItem('lastLog') !== 'false'){
      this.abrirDialogActualizarContrasena(false);
      return false;    
    }else{
      return true;
    }
  }

  verificarTerminosYCondiciones(){
    if (this.currentUser.terminosYCondiciones === 'ASO') {
      this.abrirDialogTerminosYCondiciones();
      return false;
    } 
    else {
      return true;
    } 
  }

  verificarCampaniaActualizacion(){
    if (this.currentUser.campaniaActualizacion === 'N'){
      this.openModalFun();      
    } 
  }

  abrirDialogActualizarContrasena(mostrarCancel) {
    this.dialog
    .open(DialogActualizarContrasenaComponent, {
      width: '500px',
      disableClose: true,
      data:{mostrarCancelar:mostrarCancel},
      panelClass: 'dialog-sin-overflow'

    }).afterClosed().subscribe(
      (cambiado:boolean) => {
        return false;
      },
      (error) => {
        console.log(error);        
      }
    );
  }

  abrirDialogTerminosYCondiciones() {
    this.dialog.open(DialogTerminosYCondicionesComponent, {
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed()
    .subscribe(
      (aceptado: boolean) => {
        if (!aceptado) {
          this.dialog.closeAll();
          this.logout();
        }
        else{
          this.verificarActualizarContrasena();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  tieneNombre(){
    let tiene_nombre = true;
    if(this.userData.nombres==null || this.userData.nombres==''){
      tiene_nombre = false;
    }
    return tiene_nombre;
  }

  validacionPendiente(){    
    this.validacionPendiente_bool = (this.userData.tipoDocumento == '9');
  }

  selectEstudio(estudio) {
    this.estudiosService.localStorageSetEstudio(estudio);
    window.location.href='/episodio';
  }
}
