<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <div class="container">
        <p>Turnos y autorizaciones</p>
        <ul>
          <li [hidden]="!hayMsje"><p><a href="{{linkWhatsapp}}" target="_blank"><img src="../../assets/images/ecocardiogramas/icono_whatsapp.png"/>: (+54 0221) 476-9962</a></p></li>
        </ul>
        <p> De Lunes a Viernes de 7:15 a 21:30hs. y S&aacute;bados de 7:15 a 14:30 hs.</p>
      </div>
      <div class="container">
        <p>Consultas</p>
        <ul>
          <li><p><strong>Tel&eacute;fono: </strong> (+54 0221) 412-9400, interno 1319</p></li>
        </ul>
        <p> De Lunes a Viernes de 8:30 a 16:00 hs.</p>
      </div>
    </div>
  </div>
</div>
