import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogOlvideMisDatosComponent } from '../dialog-olvide-mis-datos/dialog-olvide-mis-datos.component';
import { DialogSoporteTecnicoComponent } from '../dialog-soporte-tecnico/dialog-soporte-tecnico.component';

@Component({
  selector: 'app-dialog-mensaje-ayuda',
  templateUrl: './dialog-mensaje-ayuda.component.html',
  styleUrls: ['./dialog-mensaje-ayuda.component.css']
})
export class DialogMensajeAyudaComponent implements OnInit, AfterViewInit {

  constructor(private dialog: MatDialog, private el: ElementRef) {
    
   }


  ngAfterViewInit(): void {
    setTimeout(() => {
      const recuperarDatosLink = this.el.nativeElement.querySelector('#recuperarDatosLink');
      if (recuperarDatosLink) {
        recuperarDatosLink.click();
      } else {
        console.error("No se encontró el enlace 'recuperarDatosLink'.");
      }
    }, 500); // Ajusta el tiempo de espera según tus necesidades
  }

  

  ngOnInit(): void {
  }

  cerrarDialog(){
    this.dialog.closeAll();
  }

  openDialogOlvideMisDatos(){
    this.dialog.open(DialogOlvideMisDatosComponent, { 
      hasBackdrop: false,
    })
  }

  openDialogSoporteTecnico(){    
    this.dialog.open(DialogSoporteTecnicoComponent, {
      hasBackdrop: false,
      data: { openedFromAyudaDialog: true },
    })
  }

  scrollToElement(elementId: string) {
    const element = this.el.nativeElement.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
