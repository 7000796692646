<!-- <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'> -->

<div style="display: flex;">
    <div class="menu-sandwich">
        <button mat-button class="boton-sandwich" (click)="sidenav.toggle()">   
            <img src="../../assets/images/menu-lateral/icono-menu.png" id="icono-menu">
   
            <img src="../../assets/images/menu-lateral/texto-menu.png" id="texto-menu">
        
        </button>
       
        <div *ngIf="!es_visitante" class="titulo_menu_sandwich">
            {{subentrada_seleccionada ? subentrada_seleccionada : entrada_seleccionada}}
        </div>
    </div>
    <div class="mat-drawer-backdrop ng-star-inserted"></div>
    <mat-sidenav-container [hasBackdrop]="true" class="sidenav-container">
            
        <mat-sidenav #sidenav id="mobile-menu" mode="over" [(opened)]="isMenuOpen" class="sidenav" >

        <!-- Icono de Portal de Pacientes -->
        <div class="contenedor-iconoPortal">
            <img src="../../assets/images/menu-lateral/icono_portal_de_pacientes.png" id="icono_portal_de_pacientes"
            routerLink='/home' (click)="cerrar_menus()" *ngIf="!es_visitante;else icono_noClickeableBlock">
            
            <ng-template #icono_noClickeableBlock>
                <img src="../../assets/images/menu-lateral/icono_portal_de_pacientes.png" id="icono_portal_de_pacientes"
                (click)="login()">
            </ng-template>            
        </div>
        
        <!-- Entradas para No Visitantes -->
        <div *ngIf="!es_visitante;else visitanteBlock">
            
            <div class="contenedor-fotoPerfil">

                <!-- foto de perfil o icono -->
                <div 
                *ngIf="(userData_recuperado && userData.fotoPerfil);else iconoBlock"
                id="contenedor-fotoPerfil">                      
                    <img 
                        [src]="fotoPaciente" 
                        class="foto-perfil"
                        routerLink='/editar-datos' 
                        (click)="click_foto();cerrarSideNav()">
                    <img 
                    src="../../assets/images/menu-lateral/marco_foto.png"                    
                    id="marco-fotoPerfil">                                                   
                </div>            
                <ng-template #iconoBlock >
                        <img src="../../assets/images/menu-lateral/icono_mis_datos.png" id="img_icono_mis_datos"
                        routerLink='/editar-datos' (click)="click_foto()">
                </ng-template>
            </div>

            <div *ngFor="let entrada of entradas">
                
                <!-- #no tiene submenus (Historial Clínico)-->
                <div *ngIf="entrada.length == 1;else tiene_sub_menus">
                    <!-- #entrada menu seleccionada -->
                    <div class='opcion_menu_sinsubmenu_seleccionada'                    
                    *ngIf="entrada_seleccionada == entrada[0].nombre_menu;else entradaNoSeleccionada">
                        {{ entrada[0].nombre_menu }}
                    </div>
                    <ng-template #entradaNoSeleccionada>
                        <div class="opcion_menu"
                        (click)="selecciona_entrada(entrada[0]);cerrarSideNav()"
                        routerLink={{entrada[0].url}}>
                            {{ entrada[0].nombre_menu }}
                        </div>               
                    </ng-template>
                </div>
                
                <ng-template #tiene_sub_menus>
                        <div *ngIf="userData_recuperado">
                            
                            <!-- la entrada está seleccionada -->
                            <div *ngIf="entrada_seleccionada == entrada[0].nombre_menu;else entradaNoSeleccionada">

                                <!-- es menu Paciente -->
                                <div *ngIf="verificarNombreMenu(entrada[0].nombre_menu);else noEsMenuPaciente1">      
                                        <div *ngIf="tieneNombre();else noTieneNombre" class="opcion_menu_mis_datos_seleccionada"> 
                                            {{ userData.nombres | toTitleCase }}
                                        </div>
                                        <ng-template #noTieneNombre>
                                            <div class="opcion_menu_mis_datos_seleccionada">
                                                {{ userData.apellido | toTitleCase }}
                                            </div>
                                        </ng-template>                                                                   
                                </div>
                                <ng-template #noEsMenuPaciente1>
                                    <div class="opcion_menu_seleccionada"
                                    *ngIf="entrada_seleccionada == entrada[0].nombre_menu;else entradaNoSeleccionada">
                                            {{ entrada[0].nombre_menu }}
                                    </div>
                                </ng-template>                            
                            </div>
                        </div>

                        <ng-template #entradaNoSeleccionada>
                            <!-- es menu Paciente -->
                            <div 
                            routerLink='/editar-datos' (click)="click_foto()"
                            *ngIf="verificarNombreMenu(entrada[0].nombre_menu);else noEsMenuPaciente2">                                                    
                                <div *ngIf="tieneNombre();else noTieneNombre" class="opcion_menu_mis_datos"> 
                                    {{ userData.nombres | toTitleCase }}
                                </div>
                                <ng-template #noTieneNombre>
                                    <div class="opcion_menu_mis_datos">
                                        {{ userData.apellido | toTitleCase }}
                                    </div>
                                </ng-template>                                                            
                            </div>
                            <div class="opcion_menu_mis_datos" 
                                id="opcion_menu_cerrar_sesion"
                                *ngIf="verificarNombreMenu(entrada[0].nombre_menu)"
                                (click)="logout()">
                                Cerrar sesión
                            </div>
                            <div *ngIf="verificarNombreMenu(entrada[0].nombre_menu)">
                                <div *ngIf="validacionPendiente()" class="validacion_pendiente">
                                    Validación pendiente
                                </div> 
                                <div *ngIf="validacionSinValidar() && entrada_seleccionada=='validar'" class="boton_validar_seleccionado">
                                    Validá tu identidad
                                </div>
                                <div *ngIf="validacionSinValidar() && entrada_seleccionada!='validar'" class="boton_validar" (click)="goToValidar()">
                                    Validá tu identidad
                                </div> 
                            </div>                                  

                            <ng-template #noEsMenuPaciente2>
                                <div class="opcion_menu"
                                (click)="selecciona_entrada(entrada[0]); ">
                                    {{ entrada[0].nombre_menu }}
                                </div>
                            </ng-template>
                        </ng-template>
                        
                        <div *ngIf="entrada_seleccionada == entrada[0].nombre_menu">
                            <div class="contenedor_submenus">
                                <div *ngFor="let subEntrada of entrada">
                                    <!-- entrada submenu seleccionada -->
                                    <div *ngIf="(subEntrada.nombre_submenu==subentrada_seleccionada);else subentradaNoSeleccionada">
                                        <div class="opcion_submenu_seleccionada" *ngIf="noEsLaultimaSubEntrada(entrada,subEntrada);else ultima_subentrada">
                                            {{ subEntrada.nombre_submenu }}
                                        </div>
                                        <ng-template #ultima_subentrada>
                                            <div class="ultimaSubEntrada_seleccionada">
                                                {{ subEntrada.nombre_submenu }}
                                            </div>
                                        </ng-template>
                                    </div>
                                    <ng-template #subentradaNoSeleccionada>
                                        <!-- no es el submenu de cerrar sesion ni el de una ultima subentrada -->
                                        <div class="opcion_submenu"
                                        *ngIf="(noEsCerrarSesion(subEntrada.nombre_submenu) && noEsLaultimaSubEntrada(entrada,subEntrada));else otroBloque"
                                        (click)="selecciona_subentrada(subEntrada)">
                                            {{subEntrada.nombre_submenu}}                                                                  
                                        </div>
                                        
                                        <ng-template #otroBloque> <!-- submenu Cerrar Sesión -->
                                            <div class="opcion_submenu_cerrar_sesion" (click)="logout()"
                                            *ngIf="!noEsCerrarSesion(subEntrada.nombre_submenu);else ultimaSubEntradaBlock">
                                                {{subEntrada.nombre_submenu}}
                                            </div>
                                            <ng-template #ultimaSubEntradaBlock>
                                                <div class="ultimaSubEntrada" (click)="selecciona_subentrada(subEntrada)">
                                                    {{subEntrada.nombre_submenu}}
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    
                                        <ng-template #cerrarSesionBlock>
                                            <div class="opcion_submenu_cerrar_sesion"
                                            (click)="logout()">
                                                {{ subEntrada.nombre_submenu }}
                                            </div>
                                        </ng-template>  
                                    </ng-template>
                                </div>                    
                            </div>
                            <div *ngIf="verificarNombreMenu(entrada[0].nombre_menu)">
                                <div *ngIf="validacionPendiente()" class="validacion_pendiente">
                                    Validación pendiente
                                </div> 
                                <div *ngIf="validacionSinValidar()" class="boton_validar" (click)="goToValidar()">
                                    Validá tu identidad
                                </div>
                            </div>
                        </div>
                        
                </ng-template>
            </div>
        </div>

            <ng-template #visitanteBlock>    
                <div class="opcion_menu_mis_datos"
                id="iniciar_sesion"
                (click)="login()" >
                    <!-- <div class="containerFoto" >                                    -->
                        Iniciar sesión
                </div> 

                
                
            </ng-template>  
        </mat-sidenav>

        <mat-sidenav-content class="sidenav-content">
            <div class="contenido-relativo1-mobile">
                <div class="contenido-pag-mobile">
                    <router-outlet> </router-outlet>
                </div>                
                <img class="fondo-pag-mobile" src="../assets/images/fondo_general.png">
                <img class="marca-de-agua-mobile" src="../assets/images/marca_de_agua.png"> 
            </div>
        </mat-sidenav-content>

    </mat-sidenav-container>
</div>