<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Ecografía General</h4>
  
        <div class="container">
          <p>
            Para solicitar turno comunicarse al:
          </p>
          <p>
            Teléfono: <strong>(0221) 412-9400, interno 1319</strong> de lunes a
            viernes de 7:15 a 17:00hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            <img
              src="../../assets/images/ecocardiogramas/icono_whatsapp.png"
            />
            WhatsApp: <strong> +54 9 221
            476-9962 </strong> de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Turno presencial: Central de Turnos, Planta Baja de lunes a
            viernes de 7:00 a 21:00hs y sábados de 7:00 a 14:30hs.
          </p>
  
          <p><strong>Equipo Médico: </strong></p>
          <p>Dr. Carlos Nass | Dr. Julio Tello | Dr. Francisco Miraglia | Dr. Luis Illanes | Dr. Ignacio Iturbide | Dr. Marcelo Sabbione</p>
          
          <p><strong>Estudios:</strong></p>
          <ul>
            <li>Ecografía de tiroides | Ecografía de testículos</li>
          
            <li>Ecografía completa de abdomen | Ecografía hepática, biliar, esplénica o torácica</li>
            
            <li>Ecografía de vejiga o próstata | Ecografía renal bilateral</li>
          
            <li>Ecografía de aorta abdominal | Ecografía ginecológica</li>
  
            <li>Ecografía ginecológica con transductor endovaginal | Ecografía prostática con transductor endorectal</li>
  
            <li>Ecografía de cadera | Ecografía músculo esquelética</li>
  
            <li>Ecodoppler color | Intervencionismo guiado por ecografía</li>
  
            <li>Biopsia multiprostática (Dr. Luis Illanes)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
</body>

</html>