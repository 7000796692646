import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-recuperar-datos',
  templateUrl: './dialog-recuperar-datos.component.html',
  styleUrls: ['./dialog-recuperar-datos.component.css']
})
export class DialogRecuperarDatosComponent implements OnInit {
  public email: String;
  public emailModif: String;

  constructor(
    public dialogRef: MatDialogRef<DialogRecuperarDatosComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    let  partes =  this.data.mensaje.split(' ');
    this.email= partes[partes.length - 1];
    const regex = /(.{3})(?=@)/g;
    this.emailModif= this.email.replace(regex, '***');
  }

  cerrarDialogo(){
    this.dialogRef.close();
  }

}
