import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../_models/user";
import { Factura } from "../_models/factura";
import { AppComponent } from "../app.component";

@Injectable({ providedIn: "root" })
export class FacturasService {
  private baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.testUrl;
  }

  getFacturasDeGastos(): Observable<any> {
    return this.http.get<Factura>(this.baseUrl + "/facturas-de-gastos");
  }

  getFacturasDeHonorarios(): Observable<any> {
    return this.http.get<Factura>(this.baseUrl + "/facturas-de-honorarios");
  }
}
