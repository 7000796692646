<div class="container-fluid container-filtros">
    <div class="d-flex justify-content-center" style="margin-top: 10%" *ngIf="loadingFiltros; else mostrarFiltros">
        <mat-spinner [diameter]="60"></mat-spinner>
    </div>

    <ng-template #mostrarFiltros>
        <!-- BUSCADOR DE TURNOS DISPONIBLES -->
        <div class="display-flex">
            
            <div class="container-fluid display-flex-row-direction">
                <form [formGroup]="filtroTurnosForm">
                    <div class="grillaFiltros">
                        <mat-form-field appearance="outline" class="latoregular">
                            <mat-label class="latoregular">Especialidad</mat-label>
                            <mat-select class="latoregular" formControlName="especialidad" disableOptionCentering>
                                <mat-option *ngFor="let especialidad of this.especialidadesFiltradas"
                                    [value]="especialidad">
                                    {{ especialidad.nombre | toTitleCase }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Cobertura</mat-label>
                            <mat-select formControlName="financiador" disableOptionCentering>
                                <mat-option *ngFor="let financiador of this.listaFinanciadores" [value]="financiador">
                                    {{ financiador.nombre | toTitleCase }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field appearance="outline" >
                            <mat-label>Establecimiento</mat-label>
                            <mat-select formControlName="establecimiento" disableOptionCentering panelClass="panelPos" >
                                <mat-option *ngFor="let establecimiento of establecimientosFiltrados" [value]="establecimiento">
                                    {{ establecimiento.nombreCompleto | toTitleCase }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline">
                            <mat-label>Profesional</mat-label>
                            <input type="text" placeholder="Escribí acá su nombre" aria-label="Profesional" matInput
                                formControlName="profesional" [matAutocomplete]="auto" (keyup)="onKeyUp()"
                                name="telephone" id="telephone" />
                            <mat-icon matSuffix>search</mat-icon>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <div *ngIf="tresCaracteres">
                                    <mat-option *ngFor="let profesional of profesionalesFiltrados"
                                        [value]="profesional">
                                        {{ profesional.apellido | toTitleCase }}
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
                        </mat-form-field>

                        <!-- <div class="centrado padding-wrap">
                            <button mat-raised-button color="primary" (click)=" buscarTurnos()" style="border-radius: 20px;">
                                <mat-icon >search</mat-icon>
                                Buscar
                            </button>
                        </div>  -->
                    </div>
                    <mat-chip-list #chipList aria-label="Filtros selecccionados" class="mat-chip-list-wrapper">
                        <mat-chip *ngFor="let filtro of filtroTurnosForm.value | keyvalue"
                            (removed)="removeFiltro(filtro)" [hidden]="getOcultarChip(filtro)"
                            style="margin-bottom: 1%">
                            {{ this.getNombreFiltro(filtro.value) | toTitleCase }}

                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    </mat-chip-list>
                </form>
                <div class="profesional-no-aparece" (click)="toggleCard()">   
                    <mat-icon>live_help</mat-icon> 
                    <div>¿El/La profesional no aparece?</div>
                </div>
                <div class="card" [class.visible]="card_prof_visible">
                <!-- Contenido del mat-card -->
                
                    <span>
                        Si el/la profesional que estás buscando no se encuentra en esta grilla
                        de turnos, comunicate a la Central de Turnos
                        <strong>(0221) 412 9470 de lunes a viernes de 7.15 a 17.00hs y sábados de
                            7.15 a 14.00hs.</strong>
                        Podés conocer el plantel completo de profesionales
                        <a (click)="goToprofesionales()" class="alert-link pointer">
                            AQUÍ
                        </a>
                    </span>
                </div>
                
                
            </div>
        </div>
    </ng-template>
</div>
<div *ngIf="calcularFormVacio()" class="search-results">
    <!--Esto hay que sacarlo cuando controlemos del sp el problema-->

    <div *ngIf="loading && desde == 0; else mostrarResultados" class="loading-spinner" style="height: 100vh;">
        <!-- Spinner arriba -->
        <div>
        <mat-spinner [diameter]="60"></mat-spinner>
        </div>
        
        <span>Cargando profesionales</span>        
    </div>
    <ng-template #mostrarResultados>
        <!-- TABLA DE TURNOS ENCONTRADOS -->
        <div *ngIf="!existenTurnos; else mostrarTurnos">
            <div class="alert alert-danger no-profesionales-para-filtros-seleccionados">
                No se encontraron profesionales para los filtros seleccionados
            </div>
        </div>

        <ng-template #mostrarTurnos>
            <figure class="figure-infinite-scroll">
                <div class="container-infinite-scroll" [scrollWindow]="false" infiniteScroll
                    [infiniteScrollDistance]="6" (scrolled)="onScroll()" [infiniteScrollDisabled]="loading">
                    <div class="box-card" *ngFor="let profesional of profesionalesFiltrosActuales">
                        <!-- <div class="box-turnos-disponibles">
                        <div class="circulo-turnos verde" [ngClass]="{
                            verde: profesional.infoTurnos.cantTurnosLibres > 0,
                            rojo: profesional.infoTurnos.cantTurnosLibres == 0}">
                        </div>
                        <div class="row">
                            <div class="box-texto-turnos-disponibles column1">
                                <p class="D-DINCondensed texto-turnos-disponibles">
                                    {{ profesional.infoTurnos.cantTurnosLibres }} TURNOS
                                    DISPONIBLES
                                </p>
                            </div>

                            <button *ngIf="profesional.infoTurnos.cantTurnosLibres > 0" id="boton_primer_turno_libre"
                                (click)="clickPrimerTurnoLibre(profesional)" mat-raised-button class="boton column2"
                                style="margin: 1vh 1vw" matTooltip="Reservar" matTooltipPosition="right">
                                <div class="row">
                                    <div class="column1">Primer turno libre:</div>
                                    <div class="column2">
                                        {{ profesional.primer_turno_libre.str_fecha }}
                                        {{ profesional.primer_turno_libre.str_hora }}hs.
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div> -->

                        <div class="box-profesional-turnos" [id]="profesional.nroMatricula">
                            <div class="box-profesional">
                                <div class="nombre-profesional D-DINCondensed-Bold">
                                    {{ profesional.apellido }}
                                </div>
                                <div class="imagen-profesional">
                                    <img class="img-prof" src="./../../assets/images/turnos/ICONO-NEUTRO.gif" alt="" />
                                </div>
                            </div>
                            
                            <div class="box-turnos">
                                <div>
                                        <div *ngFor="let agendasDia of profesional.diasAtencion">
                                            <!-- Todavía no se ha hecho click en un día en particular -->
                                            <button class="diaAtencionBox" (click)="consultaPorDia(profesional, agendasDia)"
                                                *ngIf="profesional.nroMatricula != profesionalConsultado">
                                                <div>
                                                    <div *ngIf="agendasDia[0].especialidad[0].codigo" class="texto-especialidad"><b>{{agendasDia[0].especialidad[0].nombre | toTitleCase }}</b>&nbsp;</div>
                                                    <div  *ngIf="agendasDia[0].especialidad[0].codigo" style="width: 100%;">
                                                    <mat-divider class="divider"></mat-divider>
                                                    </div>
                                                    <div style="margin-left: 0; padding-left: 0;"><b>{{agendasDia[0].nombreDia }} </b>&nbsp;</div>
                                                </div>
                                                <div class="horacont">
                                                    <p class="hora" *ngFor="let agenda of agendasDia">{{ agenda.desdeHora }}hs. - {{ agenda.hastaHora }}hs.&nbsp;</p>
                                                 </div>
                                                <div *ngIf="agendasDia.length > 0">
                                                    ({{ agendasDia[0].servicio }}<span id="otraSucursal">{{ chequearSucursal(agendasDia[0].sucursal) }}</span>)
                                                </div>
                                            </button>
                                        </div>
                                    <div *ngIf="profesional.nroMatricula == profesionalConsultado"
                                        style="min-width: 45vw">
                                        <div class="d-flex justify-content-center"
                                            style="margin-top: 1%; margin-bottom: 2%"
                                            *ngIf="loadingConsultaPorDia; else mostrarDetalleConsulta">
                                            <mat-spinner [diameter]="40"></mat-spinner>
                                        </div>
                                        <ng-template #mostrarDetalleConsulta>
                                            <div class="detalle-consulta">
                                                <div class="container-tabla-horarios">
                                                    <div *ngIf="hayTurnos === true;
                                                            else sinTurnosDisponibles">
                                                            <div id="horarios-para-los-dias">
                                                                Horarios para los días
                                                                <b>{{ diaSeleccionado[0].nombreDia }}</b> de
                                                                <p>
                                                                    <ng-container *ngFor="let agenda of diaSeleccionado; let last = last">
                                                                        <b>{{ agenda.desdeHora }} a {{ agenda.hastaHora }}hs.</b>
                                                                        <span *ngIf="!last"> | </span>
                                                                    </ng-container>
                                                                </p>
                                                            </div>                                                            
                                                        <figure class="container-tabla-fechas">
                                                            <table class="tabla-fechas">
                                                                <div *ngFor="let dia of devolucionDiaSeleccionado">
                                                                    <th class="head-tabla-fechas">
                                                                        {{ formatDate(dia[0].str_fecha) }}                                                                        
                                                                    </th>
                                                                    <ng-container *ngFor="let agenda of dia">
                                                                        <tr *ngFor="let turnoLibre of agenda.listadoTurnos">
                                                                            <button class="hora-tabla-fechas" (click)="quiereTurno(turnoLibre.str_hora, dia[0].str_fecha, turnoLibre.numero, profesional, agenda.str_horaInicioAtencion, agenda.str_vigenciaDesde)">
                                                                              {{ turnoLibre.str_hora }}
                                                                            </button> 
                                                                        </tr>
                                                                    </ng-container >
                                                                </div>
                                                            </table>
                                                        </figure>
                                                    </div>
                                                    <ng-template #sinTurnosDisponibles>
                                                        <div class="alert alert-danger">
                                                            No hay turnos disponibles para los días
                                                            {{ diaSeleccionado[0].nombreDia }}
                                                        </div>
                                                    </ng-template>
                                                </div>

                                                <div class="container-boton-volver">
                                                    <button class="boton boton-volver" mat-raised-button
                                                        (click)="volverFn()">
                                                        <div>
                                                            <mat-icon id="play_arrow">play_arrow</mat-icon>
                                                            Cambiar día                                                            
                                                        </div>
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="box-especialidad">
                            <div class="box-texto-especialidad D-DINCondensed-Bold">
                                <div class="texto-especialidad">
                                    <div *ngFor="let especialidad of profesional.especialidades">
                                        {{ especialidad.nombre }} <br />
                                    </div>
                                </div>
                            </div>

                            <div class="barra-especialidad">
                                <div class="barra"></div>
                            </div>

                            <!--<div class="ver-mas" [id]="profesional.nroMatricula + 'verMas'">
                            <div *ngIf="!profesional.open; else verMenos">
                                <button mat-icon-button style="width: 100%; text-align: center"
                                    (click)="this.desplegarCalendario(profesional)" [disableRipple]="true"
                                    class="boton-ver-mas-menos">
                                    <div class="box-boton">
                                        <span class="D-DINCondensed-Bold texto-ver-mas-menos">VER MÁS</span>

                                        <img class="imagen-mas" src="./../../assets/images/turnos/SIGNO-MAS.gif"
                                            alt="" />
                                    </div>
                                </button>
                            </div>
                            <ng-template #verMenos>
                                <button mat-icon-button style="width: 100%; text-align: center"
                                    (click)="this.plegarCalendario(profesional)" [disableRipple]="true"
                                    class="boton-ver-mas-menos">
                                    <div class="box-boton">
                                        <span class="D-DINCondensed-Bold texto-ver-mas-menos">VER MENOS</span>

                                        <img class="imagen-mas" src="./../../assets/images/turnos/SIGNO-MENOS.gif"
                                            alt="" />
                                    </div>
                                </button>
                            </ng-template>
                        </div> -->
                        </div>
                    
                    </div>
                    <div class="container px-0 mx-auto" style="padding-top: 2%"></div>

                    <div class="alert alert-danger" *ngIf="errorAlCargarTurnos">
                        <h6>No se pudieron cargar más turnos</h6>
                    </div>

                    <div class="d-flex justify-content-center" style="margin-top: 1%; margin-bottom: 2%"
                        *ngIf="loading && desde > 0">
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </div>
                </div>
                <div class="foot">
                    Hospital Español - Dedicados a cuidar la salud de las personas
                </div>
            </figure>
            
        </ng-template>
    </ng-template>
</div>