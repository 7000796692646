<html>

<head>
  <meta name="robots" content="noindex">
</head>


<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1 m-0 p-0">
  
      <div class="col" *ngIf="cargandoInfo">
        <mat-progress-bar class="barra-progreso-celeste" mode="indeterminate"></mat-progress-bar>
      </div>
  
      <div class="col" *ngIf="hashInvalido">
        <h4>No se pudo cargar el historial clinico.</h4>
      </div>
  
      <div *ngIf="hashValido">
  
        <div *ngIf="!selectedEstudio">
          <h4>Historial Cl&iacute;nico</h4><br>
          
          <div class="container">
            <div *ngIf="verUserData">            
                <p><b>Nombre y apellido:</b> {{userDataExt.apellido}} </p>   
              
                <p><b>DNI:</b> {{userDataExt.nroDocumento}} </p>
              
            </div>
            
            <figure class="table-container">
              <table id="tabla-historial" class="table">
                <thead>
                  <tr style="text-align: center">
                    <th scope="col">Fecha</th>
                    <th scope="col">Episodio</th>
                    <th class="opcional" scope="col">Especialidad</th>
                    <th class="opcional" scope="col">Observacion</th>
                    <th scope="col">Profesional</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    (click)="estudio.tipoEpisodio == 'Estudio' ||
                      (estudio.tipoEpisodio == 'Internacion' && estudio.tiene)
                      ? setSelectedEstudio(estudio)
                      : false"
                    [className]="estudio.tipoEpisodio == 'Estudio' ||
                      (estudio.tipoEpisodio == 'Internacion' && estudio.tiene)
                      ? 'hoverable'
                      : null"
                    *ngFor="let estudio of estudios"
                  >
                    <td>{{ estudio.fechast }}</td>
  
                    <td>{{ estudio.tipoEpisodio ? (estudio.tipoEpisodio | toTitleCase) : '-' }}</td>
  
                    <td class="opcional">
                      {{ estudio.especialidadAtencion ? (estudio.especialidadAtencion | toTitleCase) : '-' }}
                    </td>
  
                    <td *ngIf="estudio.observacion" class="opcional">
                      {{ estudio.observacion ? (estudio.observacion | toTitleCase) : '-' }}
                    </td>
                    <td *ngIf="!estudio.observacion" class="opcional">-</td>
  
                    <td class="profesional-historial-compartido">{{ estudio.nombreProfesional ? (estudio.nombreProfesional | toTitleCase) : '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </figure>
          </div>
        </div>
  
        <div class="col" *ngIf="selectedEstudio">
          <!-- <button mat-raised-button color="primary" (click)="refreshHistory()"><mat-icon>arrow_back</mat-icon> Volver al historial</button> -->
          <button
            mat-raised-button
            (click)="refreshHistory()"
            class="volver"
            style="float: left"
          >
            <mat-icon>arrow_back</mat-icon> Volver
          </button><br>
          <h4 style="margin-top: 5vh;">Detalle</h4>        
          
          <div class="row mt-3">
            <div class="col-sm-3">
              <p>
                <b>Fecha:</b> 
                 {{ selectedEstudio.fechaPedida }}</p>
            </div>
            <div class="col-sm-3">
              <p>
                <b>Servicio Efector:</b> 
                {{ selectedEstudio.nombreServicio | toTitleCase }}
              </p>
            </div>
            <div class="col-sm-3">
              <p>
                <b>Medico Efector:</b> 
                {{ selectedEstudio.actuante | toTitleCase }}</p>
            </div>
            <div *ngIf="selectedEstudio.solicitante" class="col-sm-3">
              <p>
                <b>Medico Solicitante:</b> 
                {{ selectedEstudio.solicitante ? (selectedEstudio.solicitante | toTitleCase) : '-' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <h4>Estudio</h4>
            </div>
  
            <table id="tabla-detalle" class="table">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Imagen</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let e of selectedEstudios">
                  <td class="nombre-estudio-historial">
                    {{ e.nombreEstudio ? (e.nombreEstudio | toTitleCase) : '-' }}
                  </td>
                  <td>                                  
                    <a *ngIf="e.estadoImgAurora == 0" target="_blank" href="{{ e.urlAurora }}" mat-raised-button color="primary">Ver imagen</a>
  
                    <!-- <ng-template #imgAngra>
                        <a *ngIf="e.estadoImagen == 1; else noImageTemplate" target="_blank" href="{{ e.urlVisor }}" mat-raised-button color="primary">Ver imagen</a><br>
                    </ng-template>   -->
                                  
                    <ng-template #noImageTemplate>
                      <span class="text-muted">Imagen no disponible</span>
                    </ng-template>  
                  </td>                
                </tr>
              </tbody>
            </table>
            <div class="container-fluid">
              <div *ngIf="!esAndroid">
                <div style="border: 0px solid red;">
                  <embed
                    type="application/pdf"
                    [src]="urlEstudioPdf | safeUrl"
                    width="100%"
                    height="600px"
                    internalinstanceid="5"
                  />
                </div>
                <!-- <div style="border: 0px solid green;">
                  <embed
                    type="application/pdf"
                    [src]="urlEstudioPdfComp | safeUrl"
                    width="100%"
                    height="600px"
                    internalinstanceid="5"
                  />
                </div> -->
              </div>
              
              <div class="row" *ngIf="esAndroid">
                <div class="col">
                  <a mat-raised-button *ngIf="existePDFEstudio" color="primary" href="{{urlEstudioPdf}}">Descargar estudio</a>
                </div> 
                <!-- <div class="col">
                  <a style="margin-top: 2vh;" mat-raised-button *ngIf="existePDFComp" href="{{urlEstudioPdfComp}}">Descargar informe</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>