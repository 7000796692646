import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-areas-internacion-prestaciones-especiales',
  templateUrl: './areas-internacion-prestaciones-especiales.component.html',
  styleUrls: ['./areas-internacion-prestaciones-especiales.component.css']
})
export class AreasInternacionPrestacionesEspecialesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
