<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <mat-card style="text-align: center;">
        <mat-card-content [hidden]="!cargando">
          <mat-spinner></mat-spinner>
        </mat-card-content>
        <mat-card-content [hidden]="cargando">
          <mat-icon class="errorIcon" *ngIf="!resultadoPago"> error_outline </mat-icon>
          <mat-icon class="doneIcon" *ngIf="resultadoPago"> done_outline </mat-icon>
          <p> {{ msjeResultado }} </p>
        </mat-card-content>
        <mat-card-actions [hidden]="cargando">
          <button mat-raised-button [color]="resultadoPago ? 'primary' : 'warn'" (click)="finalizar()"> {{resultadoPago ? 'Finalizar' : 'Cerrar' }} </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</body>

</html>