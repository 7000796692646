<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div id="turnos-body" class="col">
        
      <div *ngIf="loading">
        <h6>Se estan cargando los turnos, aguarde un momento</h6>
      </div>

      <div
                class="d-flex justify-content-center"
                style="margin-top: 10%"
                *ngIf="loading">
                <mat-spinner [diameter]="60"></mat-spinner>
                
      </div>

      <div *ngIf="!profesional && !loading">
        <div class="container-fluid">
          <div class="alert alert-primary">
            <span>
                Si el/la profesional que estás buscando no se encuentra en esta grilla de turnos, 
                comunicate a la Central de Turnos 
                <strong>(0221) 412 9470 de lunes a viernes de 7.15 a 17.00hs y 
                sábados de 7.15 a 14.00hs.</strong>
                Podés conocer el plantel completo de profesionales
                <a 
                    href="https://www.hospitalespanol.org.ar/servicios-y-profesionales/nuestros-profesionales/"
                    target="_blank" rel="noopener noreferrer"
                    class="alert-link">
                    AQUÍ
                </a>
                
            </span>
            
           </div>
          <div class="selectores">
            <!-- <div class="selector">
              <p>Especialidades:
                <select class="custom-select custom-select-sm" [ngModel]="especialidadSeleccionada"
                  (ngModelChange)="onChangeEspecialidad($event)">
                  
                  <option value=" " class="font-weight-bold">
                    <b>Todas</b>
                  </option>
                  <option  [ngValue]="especialidad" *ngFor="let especialidad of especialidades">
                    {{ especialidad.nombre | toTitleCase }}
                  </option>
                </select>
              </p>
            </div> -->
            <div class="selector">
              <mat-form-field appearance="outline" class="latoregular">
                <mat-label class="latoregular">Especialidad</mat-label>
                <mat-select class="latoregular" [ngModel]="especialidadSeleccionada" (ngModelChange)="onChangeEspecialidad($event)">
                  <mat-option value=" " class="font-weight-bold">Todas</mat-option>  
                  <mat-option *ngFor="let especialidad of this.especialidades" [value]="especialidad">
                      {{ especialidad.nombre | toTitleCase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            
            <!-- <div class="selector">
              <p>Establecimiento:
                <select class="custom-select custom-select-sm" [ngModel]="establecimientoSeleccionado"
                  (ngModelChange)="onChangeEstablecimiento($event)">
                  <option value=" " class=" font-weight-bold">
                    <b>Todos</b>
                  </option>
                  <option [ngValue]="estab" *ngFor="let estab of establecimientos" class="">
                    {{ estab.nombreCompleto | toTitleCase }}
                  </option>
                </select>
              </p>
            </div> -->

            <!-- <div class="selector">
            <mat-form-field appearance="outline" class="latoregular">
              <mat-label class="latoregular">Establecimiento</mat-label>
              <mat-select class="latoregular" [ngModel]="establecimientoSeleccionado" (ngModelChange)="onChangeEstablecimiento($event)">
                <mat-option value=" " class="font-weight-bold">Todos</mat-option>  
                <mat-option *ngFor="let estab of this.establecimientos" [value]="estab">
                    {{ estab.nombreCompleto | toTitleCase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </div> -->
            
            <!-- <div class="selector">
              <p>
                Profesionales:
                <select
                  class="custom-select custom-select-sm"
                  [ngModel]="profesionalSeleccionado"
                  (ngModelChange)="filtrarProfesional($event)"
                >
                  <option value=" " class="font-weight-bold">
                    <b>Todos</b>
                  </option>
                  <option
                    [ngValue]="profesional"
                    *ngFor="let profesional of profesionales"
                  >
                    {{ profesional.apellido | toTitleCase }}
                  </option>
                </select>
              </p>
            </div> -->
            <div class="selector">
            <mat-form-field appearance="outline" class="latoregular">
              <mat-label class="latoregular">Profesional</mat-label>
              <mat-select class="latoregular" [ngModel]="profesionalSeleccionado" (ngModelChange)="filtrarProfesional($event)">
                <mat-option value=" " class="font-weight-bold">Todos</mat-option>  
                <mat-option *ngFor="let profesional of this.profesionales" [value]="profesional">
                    {{ profesional.apellido | toTitleCase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </div>
            
          </div>
          <div [hidden]="existenTurnos">
            <h5>No se han encontrado turnos para esta especialidad</h5>
          </div>
          <figure
            class="table-container"
            [hidden]="!existenTurnos"
          >

            <table>
              <thead>
                <tr>
                  <th scope="col">Profesional</th>
                  <!-- <th scope="col">Establecimiento</th> -->
                  <th scope="col">Dias de atención</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let turno of turnos" [hidden]="!turno.tieneHorario">
                  <td class="align-middle">
                    <strong>{{ turno.apellido | toTitleCase }} </strong>
                    <p *ngIf="especialidadSeleccionada === ' '">
                      -
                      <span class="m-0" *ngFor="let especialidad of turno.especialidades">
                        {{ especialidad.nombre | toTitleCase }} -
                      </span>
                    </p>
                  </td>

                  <!-- <td class="align-middle">
                    <p class="m-0" *ngFor="let at of turno.diasAtencion">
                      ACÁ IRÍA EL ESTABLECIMIENTO{{ at.establecimiento }}
                    </p>
                  </td> -->

                  <td class="align-middle w-50">
                    <p
                      class="m-0"
                      *ngFor="
                        let establecimiento of turno.diasAtencion
                          | keyvalue: unsorted
                      "
                    >
                      <strong> {{ establecimiento.key | toTitleCase }}</strong
                      ><br />-
                      <span
                        class="m-0"
                        *ngFor="let dia of establecimiento.value | keyvalue"
                      >
                        {{ dia.value.nombreDia }} de
                        {{ dia.value.str_horaInicioAtencion }} a
                        {{ dia.value.str_horaFinAtencion }} -
                      </span>
                    </p>
                  </td>

                  <td>
                    <button
                      (click)="onChangeProfesional(turno)"
                    >
                      Ver turnos
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <td colspan=4 class="td-foot">Hospital Español - Dedicados a cuidar la salud de las personas</td>   
              </tfoot>
            </table>
          </figure>
          
          <!-- <div class="botones">
            <button
            class="boton"
            style="float: left"
            [hidden]="!logueado()"
            routerLink="/turnos-paciente-historico">
            Mis turnos
            </button>
          </div> -->
          
        </div>
      </div>

      <div *ngIf="profesional && cargado">
        <div>          
          
          <button mat-raised-button class="volver" (click)="resetList()">
            <mat-icon>arrow_back</mat-icon>Volver
          </button>
        </div>           
        <div class="contenedor_inicial">
          <div class="profesional-especialidad">            
            <b>
              Profesional:
            </b>
            {{ profesional.apellido | toTitleCase }}
          </div>

          <div class="profesional-especialidad">            
            <b>
              Especialidad:
            </b>
            {{ profesional.especialidades[0].nombre | toTitleCase }}
          </div>          
        </div>
              
            <div
              id="horarios-body"
              style="width: 100%"
              class="container-fluid px-0 mx-0"
            >
              <!-- <h5 *ngIf="profesionalNoDisponeTurno">
                No hay turnos disponibles para este profesional
              </h5> 
            
              <mat-accordion>
              <mat-expansion-panel *ngFor="let dia of horariosProfesional"> -->
              <!-- <h5 *ngIf="cargado">
              {{ horariosProfesional }}
              <p *ngFor="let establecimiento of horariosProfesional | keyvalue">
                {{ establecimiento.key }}
                <span *ngFor="let dia of establecimiento.value | keyvalue">
                  {{ dia.value.establecimiento }}
                </span>
              </p>
            </h5> -->
              <h5 class="my-4 text-center" *ngIf="hayTurnos(); else elseBlock">
                El profesional no dispone de horarios de atención
              </h5>
              <ng-template #elseBlock>
                <div
                  *ngFor="
                    let establecimiento of horariosProfesional
                      | keyvalue: unsorted
                  "
                  class="mb-5"
                >
                  <hr />
                  <h5>{{ establecimiento.key | toTitleCase }}</h5>
                  <h6 *ngIf="profesionalNoDisponeTurno[establecimiento.key]">
                    No hay turnos disponibles para este profesional
                  </h6>
                  <mat-accordion class="fondo-blanco">
                    <mat-expansion-panel class="fondo-blanco"
                      *ngFor="
                        let dia of establecimiento.value | keyvalue: unsorted
                      "
                    >
                      <mat-expansion-panel-header
                        (click)="conseguirBloqueTurno(dia.value)"
                        *ngIf="dia.value.disponible"
                      >
                        <mat-panel-title>
                          <p class="letra-negra"
                            style="
                              font-size: 16px;
                              height: 50%;
                              margin-bottom: 0%;
                            "
                          >
                            {{ dia.value.nombreDia }}
                            {{ dia.value.str_diaPrimerTurnoLibre }}
                          </p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <figure class="table-container2">
                        <table>
                          <thead>
                            <tr>
                              <th scope="col">Fecha</th>
                              <th scope="col">Hora</th>
                              <th scope="col">Turno</th>
                              <th scope="col">Opción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let bloque of bloqueTurnos">
                              <td *ngIf="bloque.libre" class="align-middle">
                                {{ bloque.str_fecha }}
                              </td>

                              <td *ngIf="bloque.libre" class="align-middle">
                                {{ bloque.str_hora }}
                              </td>

                              <td *ngIf="bloque.libre" class="align-middle">
                                {{ bloque.numero }}
                              </td>

                              <td *ngIf="bloque.libre" class="align-middle">
                                <button
                                  (click)="tomarTurno(bloque)"
                                  class="btn btn-info"
                                  id="botones"
                                >
                                  <!--
                                  
                                  data-toggle="modal"
                                  data-target="#confirmarTurnoModal"
                                -->
                                  Reservar turno
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          
                        </table>
                      </figure>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </ng-template>
            </div>
        </div>
       
      <!-- <div *ngIf=profesional >
               
               <br>
               <div class="container">
                   <div class="row">
                      <p>{{profesional.apellido}}</p>
                  </div>
                  <div class="row">
                          <table class="table table-bordered" style="text-align: center">
                                  <thead>
                                  <tr>
                                      
                                      <th scope="col">Fecha</th>
                                      <th scope="col">Hora</th>
                                      <th scope="col">Turno</th>
                                      <th scope="col">Opción</th>
                                      
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr *ngFor="let bloque of bloqueTurnos" >
                                      
                                      <td *ngIf=bloque.libre  > {{bloque.str_fecha}} </td>
                                      
                                      <td *ngIf=bloque.libre> {{bloque.str_hora}}</td>
                                      
                                      <td *ngIf=bloque.libre> {{bloque.numero}}</td>
              
                                      <td *ngIf=bloque.libre> <button (click)=tomarTurno(bloque) class="btn-sm btn-info" data-toggle="modal" data-target="#confirmarTurnoModal" >Reservar turnos</button></td>
              
                                  </tbody>
                              </table>
                      </div>
                  </div>         
              </div> -->
    </div>
  </div>
</div>

<!-- Modal login -->
<button
  id="openModalLogin"
  type="button"
  class="btn btn-info btn-lg"
  data-toggle="modal"
  data-target="#modalLogin"
  [hidden]="true"
>
  Open The Modal Box
</button>
<button
  id="closeModalLogin"
  type="button"
  class="btn btn-info btn-lg"
  data-dismiss="modal"
  data-toggle="modal"
  data-target="#modalLogin"
  [hidden]="true"
>
  Open The Modal Box
</button>

<div
  class="modal fade"
  id="modalLogin"
  data-backdrop="false"
  data-dismiss="modal"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  *ngIf="!logueado()"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Debe loguearse para reservar su turno</h5>
      </div>
      <div class="modal-body">
        <mat-card-content>
          <div *ngIf="!mostrarMas; else elseBlock">
            <div class="container align-items-center">
              <form [formGroup]="loginForm" class="text-center">
                <div
                  class="alert alert-danger p-1 m-2"
                  *ngIf="msjeLogin != null"
                >
                  {{ msjeLogin }}
                </div>

                <div>
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="username"
                      [ngClass]="{
                        'is-invalid': submittedLogin && fc.username.errors
                      }"
                      placeholder="Usuario"
                      required
                    />
                  </mat-form-field>

                  <div
                    *ngIf="submittedLogin && fc.username.errors"
                    class="alert alert-danger p-1 m-1"
                  >
                    <div *ngIf="fc.username.errors.required">
                      El usuario es requerido
                    </div>
                  </div>
                </div>
                <div>
                  <mat-form-field>
                    <input
                      matInput
                      type="password"
                      formControlName="password"
                      [ngClass]="{
                        'is-invalid': submittedLogin && fc.password.errors
                      }"
                      placeholder="Contraseña"
                      required
                    />
                  </mat-form-field>
                  <div
                    *ngIf="submittedLogin && fc.password.errors"
                    class="alert alert-danger p-1 m-1"
                  >
                    <div *ngIf="fc.password.errors.required">
                      La contraseña es requerida
                    </div>
                  </div>
                </div>
                <a
                  data-toggle="modal"
                  data-target="#RecuperarUsuarioModal"
                  onclick="$('#modalLogin').modal('hide')"
                  class="btn btn-link m-auto"
                >
                  Olvidé mis datos
                </a>
              </form>
            </div>
          </div>

          <ng-template #elseBlock>
            <div class="container align-items-center">
              <form [formGroup]="crearUsuarioForm" class="text-center">

                <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        formControlName="firstName"
                        [ngClass]="{
                          'is-invalid': submittedCrear && f.firstName.errors
                        }"
                        placeholder="Nombre"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="submittedCrear && f.firstName.errors"
                      class="alert alert-danger"
                    >
                      <div *ngIf="f.firstName.errors.required">
                        Este campo es requerido
                      </div>
                      <div *ngIf="f.firstName.errors.pattern">
                        Debe contener solo letras
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        formControlName="lastName"
                        [ngClass]="{
                          'is-invalid': submittedCrear && f.lastName.errors
                        }"
                        placeholder="Apellido"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="submittedCrear && f.lastName.errors"
                      class="alert alert-danger"
                    >
                      <div *ngIf="f.lastName.errors.required">
                        Este campo es requerido
                      </div>
                      <div *ngIf="f.lastName.errors.pattern">
                        Debe contener solo letras
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <input
                        matInput
                        type="email"
                        formControlName="mail"
                        [ngClass]="{
                          'is-invalid': submittedCrear && f.mail.errors
                        }"
                        placeholder="E-Mail"
                        required
                      />
                    </mat-form-field>

                    <div
                      *ngIf="submittedCrear && f.mail.errors"
                      class="alert alert-danger"
                    >
                      <div *ngIf="f.mail.errors.required">
                        Este campo es requerido
                      </div>
                      <div *ngIf="f.mail.errors.pattern">
                        Debe ser un mail valido
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        formControlName="tel"
                        [ngClass]="{
                          'is-invalid': submittedCrear && f.tel.errors
                        }"
                        placeholder="Telefono"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="submittedCrear && f.tel.errors"
                      class="alert alert-danger"
                    >
                      <div *ngIf="f.tel.errors.required">
                        Este campo es requerido
                      </div>
                      <div *ngIf="f.tel.errors.minlength">
                        Debe tener al menos 7 digitos
                      </div>
                      <div *ngIf="f.tel.errors.maxlength">
                        Debe tener al menos 16 digitos
                      </div>
                      <div *ngIf="f.tel.errors.pattern">
                        Deben ser solo numeros
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        formControlName="dni"
                        [ngClass]="{
                          'is-invalid': submittedCrear && f.dni.errors
                        }"
                        placeholder="Numero de documento"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="submittedCrear && f.dni.errors"
                      class="alert alert-danger"
                    >
                      <div *ngIf="f.dni.errors.required">
                        Este campo es requerido
                      </div>
                      <div *ngIf="f.dni.errors.minlength">
                        Debe tener al menos 6 digitos
                      </div>
                      <div *ngIf="f.dni.errors.maxlength">
                        Debe tener al menos 8 digitos
                      </div>
                      <div *ngIf="f.dni.errors.pattern">
                        Deben ser solo numeros
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <input
                        matInput
                        type="date"
                        formControlName="date"
                        [ngClass]="{
                          'is-invalid': submittedCrear && f.date.errors
                        }"
                        placeholder="Fecha de nacimiento"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="submittedCrear && f.date.errors"
                      class="alert alert-danger"
                    >
                      <div *ngIf="f.date.errors.required">
                        Este campo es requerido
                      </div>
                      <div *ngIf="f.date.errors.maxDate">
                        La fecha no puede ser mayor a la actual
                      </div>
                      <div *ngIf="f.date.errors.minDate">
                        La fecha no puede ser menor a la 1900
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-flex justify-content-center">
                  <mat-radio-group
                    formControlName="gender"
                    [ngClass]="{
                      'is-invalid': submittedCrear && f.gender.errors
                    }"
                  >
                    <label for="gender" class="align-middle pr-1">Sexo: </label>
                    <mat-radio-button class="p-1 m-1" value="M"
                      >Masculino
                    </mat-radio-button>
                    <mat-radio-button class="p-1 m-1" value="F"
                      >Femenino
                    </mat-radio-button>
                    <mat-radio-button class="p-1 m-1" value="O"
                      >Otro
                    </mat-radio-button>
                  </mat-radio-group>
                  <div
                    *ngIf="submittedCrear && f.gender.errors"
                    class="alert alert-danger"
                  >
                    <div *ngIf="f.gender.errors.required">
                      Este campo es requerido
                    </div>
                  </div>
                </div>
                <div
                  class="text-danger"
                  *ngIf="
                    submittedCrear &&
                    (f.tel.errors ||
                      f.gender.errors ||
                      f.date.errors ||
                      f.dni.errors ||
                      f.mail.errors ||
                      f.lastName.errors ||
                      f.firstName.errors)
                  "
                >
                  <h6>
                    <strong>ATENCION:</strong> No fue posible completar el
                    registro, por favor revise los datos ingresados e intente
                    nuevamente
                  </h6>
                </div>
              </form>
            </div>
          </ng-template>
        </mat-card-content>

        <mat-card-actions class="m-0 p-0">
          <div class="w-80 row text-center">
            <div class="col-sm-6" style="color: white">
              <button
                type="button"
                class="btn btn-outline-info"
                (click)="mostrarMas = !mostrarMas; submittedCrear = false"
              >
                <span *ngIf="!mostrarMas; else elseBlock1">Crear usuario</span>
                <ng-template #elseBlock1>Volver al login</ng-template>
              </button>
            </div>
            <div class="col-sm-4">
              <button
                type="button"
                class="btn btn-info"
                (click)="crearUsuario()"
                [disabled]="loadingCrear"
                [hidden]="!mostrarMas"
              >
                Crear usuario
              </button>
              <button
                (click)="iniciarSesion(bloqueElegido)"
                type="button"
                class="btn btn-info"
                [disabled]="loadingLogin"
                [hidden]="mostrarMas"
              >
                Iniciar sesión
              </button>
            </div>
            <div class="col-sm-2">
              <mat-spinner
                *ngIf="loadingLogin || loadingCrear"
                [diameter]="30"
              ></mat-spinner>
            </div>
          </div>
        </mat-card-actions>
       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Recuperar Usuario -->

<button
  id="openRecuperarUsuarioModalButton"
  type="button"
  class="btn btn-info btn-lg"
  data-toggle="modal"
  data-target="#RecuperarUsuarioModal"
  [hidden]="true"
>
  Open The Modal Box
</button>

<div
  class="modal fade"
  id="RecuperarUsuarioModal"
  data-backdrop="false"
  data-dismiss="modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="RecuperarUsuarioModalLabel"
  aria-hidden="true"
  *ngIf="!logueado()"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="RecuperarUsuarioModalLabel">
          Recuperación de datos
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          onclick="$('#modalLogin').modal('show')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          *ngIf="!recoverUsernameFormReady"
          [formGroup]="recoverUsernameForm"
          (ngSubmit)="recoverUsername()"
        >
          <div class="form-group">
            <label for="mail">Ingrese su email</label>
            <input
              type="text"
              formControlName="mail"
              class="form-control"
              [ngClass]="{
                'is-invalid': recoveredUsersFormSubmitted && ru.mail.errors
              }"
            />
            <div
              *ngIf="recoveredUsersFormSubmitted && ru.mail.errors"
              class="invalid-feedback"
            >
              <div *ngIf="ru.mail.errors.required">Este campo es requerido</div>
              <div *ngIf="ru.mail.errors.pattern">Debe ser un mail valido</div>
              <div *ngIf="ru.mail.errors.incorrect">
                Este mail no esta registrado
              </div>
            </div>
          </div>
          <button style="float: right" type="submit" class="btn btn-info">
            Enviar
          </button>
        </form>
        <div *ngIf="recoverUsernameFormReady">
          <h6>Se encontraron estos usuarios, elija cual quiere recuperar</h6>
          <form
            [formGroup]="recoveredUsersForm"
            (ngSubmit)="onSubmitRecoveredUsersForm()"
          >
            <div class="form-group">
              <div *ngFor="let user of recoveredUsers">
                <input
                  style="width: 1em; height: 1em; display: initial"
                  ng-model="user"
                  type="radio"
                  formControlName="user"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': recoveredUsersFormSubmitted && rec.user.errors
                  }"
                  [value]="user"
                />
                <span style="margin-left: 1%; margin-right: 5%"
                  >{{ user.fullName | toTitleCase }} -
                  {{ user.str_fechaNacimiento | censoredBirthDate }}</span
                >
              </div>
              <div
                *ngIf="recoveredUsersFormSubmitted && rec.user.errors"
                class="invalid-feedback"
              >
                <div *ngIf="rec.user.errors.required">
                  Este campo es requerido
                </div>
              </div>
            </div>
            <button
              style="float: right"
              (click)="closeRecoveredUsersFormModal()"
              class="btn btn-secondary"
            >
              Cancelar
            </button>
            <button
              style="float: right; margin-right: 4px"
              type="submit"
              class="btn btn-info"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
