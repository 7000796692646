<h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%;">Campaña de actualización</h1>
<div mat-dialog-content>
    
        <div>

            <div class="modal-body">
                <div class="form-group">
                    <img src="../../assets/images/popupcampania.png" 
                    width="400px"
                    class="center"
                    alt="">
                </div>
                
                <div class="d-flex justify-content-center form-group">
                    <button class="boton omitir mr-2" (click)="cerrarDialogo()" data-dismiss="modal">Omitir</button>
                    <button class="boton" routerLink="/editar-datos" (click)="actualizar()" data-dismiss="modal">Actualizar</button>
                </div>
            </div>
        </div>
    
</div>
