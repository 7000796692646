<html>
    <head>
        <meta name="robots" content="noindex">
    </head>

    <body>
        <div>
            <h3 class="subtitulo">Admisión de Internación</h3>
            <b class="subtitulo size-reducido">Contacto</b> <br> <br> <br>
            <div class="texto">
                Teléfono: (0221) 412 9400, interno 1528 | 1529 <br>
                Mail: admisiones@hospitalespanol.org.ar <br>        
            </div> <br>
        
            <div class="texto">
                Una vez realizado el trámite en el puesto de Atención de Pacientes
                Quirúrgicos deberás acercarte al área de Admisión de Internación, ubicada
                en planta baja en el hall de ingreso al Hospital; allí deberás
                presentar: <br>
                <ul class="lista">
                    <li>Orden de internación</li>
                    <li>DNI</li>
                    <li>Carnet de obra social</li>
                    <li>Certificación afiliatoria (opcional)</li>
                    <li>Estudios previos (consultar)</li>
                    <li>Pre quirúrgicos</li>
                    <li>Consentimiento Informado específico de la cirugía a realizarse</li>   	
                </ul>
            </div> <br>
        
            <div class="texto">
                Al momento de la internación se solicita venir acompañado/a de un/a familiar (sin excepción) y se entregará la siguiente documentación: <br>
                <ul class="lista">
                    <li>Normas de funcionamiento interno</li>
                    <li>Reglamento</li>
                </ul>
            </div> <br>
        
            <div class="texto centrado cursiva">
                Si para tu cirugía necesitas algún tipo de prótesis, por favor comunícate con una antelación de 48 horas con tu médico/a para asegurarte que el pedido se haya realizado y confirmado la provisión.
                <br>
                Si tenés prescripta alguna medicación deberás informarle a el/la médico/a y traerla desde tu casa. Todos los elementos de higiene personal corren por cuenta de el/ la paciente.
                <br>
                Recordá que el Hospital cuenta con habitaciones compartidas e individuales y con distintos tipos de confort. Podrás disponer de la que esté incluida dentro de la cobertura de tu obra social <b>sujeto a la disponibilidad del momento</b>.                
            </div>
            <br>
            <div class="texto">
                En caso de Internación de Urgencia, se deberá presentar sin excepción DNI y Carnet afiliatorio de la Obra Social
            </div>

            <b class="subtitulo size-reducido">Egreso</b> <br> <br> <br>
            <div class="texto">
                Para retirarse de la Institución, se deberá contar con el alta médica firmada sin excepción.
                <br>
                Si el/la paciente tiene que saldar algún importe deberá acercarse al sector de Admisión de 
                Internación, ubicada en planta baja. En el caso de internaciones particulares o de asociados/as,
                el/ la paciente o su familiar deberán pasar por La Asociación de Profesionales (APHE), ubicada en 
                planta baja frente a la Central de Turnos.

            </div>
        
            <div class="subtitulo size-reducido">Horarios de Visitas</div> <br> <br> 
            <div class="texto">
                Internación General (pisos): 9.00 a 11.00hs y  de 16.00 a 19.00hs. <br>
                Unidad Coronaria: 12.30 a 13.00hs  <br>
                Terapia Intensiva: 11.30 a 12.00hs<br>
            </div>
        </div>
    </body>

</html>