<div class="modal-dialog" role="document">
    <div class="modal-content">
        
        <div class="modal-header">
            
            <h5 class="modal-title" id="SoporteTecnicoModalLabel">
                Contáctese con soporte técnico
            </h5>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>

        <!-- alerta -->
        <div class="row" style="margin-top: 2px;">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p [ngClass]="alerta.cssClass">{{alerta.mensaje}}</p>
            </div>
        </div>

        <div class="modal-body">
            <form [formGroup]="supportMessageForm">
                <div class="form-group">
                    <mat-label style="margin-bottom: 6px;">Seleccione el motivo del mensaje</mat-label>
                    <br>
                    <!-- motivo de contacto -->
                    <mat-radio-group name="motivo" formControlName="motivo" required (change)="onMotivoChange($event)">
                        <mat-radio-button value="login" style="margin-right: 10px;">No puedo iniciar sesión</mat-radio-button>
                        <mat-radio-button value="otro">Otro</mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="supportMessageSubmitted && sp.motivo.errors" class="alert alert-danger" >
                        <div *ngIf="sp.motivo.errors.required">
                            Este campo es requerido
                        </div>
                    </div>
                </div>
               
                <div *ngIf="motivo === 'otro' || motivo === 'login'">
                    <!-- nombre y apellido -->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Nombre y apellido</mat-label>
                            <input matInput type="text" formControlName="nombreApellido" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.nombreApellido.errors" class="alert alert-danger" >
                            <div *ngIf="sp.nombreApellido.errors.required">
                                Este campo es requerido
                            </div>
                            <div *ngIf="sp.nombreApellido.errors.pattern" style="width: 19vw;">Ingresá sólo letras. Verificá que no finalice con un espacio en blanco.</div>
                        </div>
                    </div>  
                    
                    <!-- email -->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Email</mat-label>
                            <input matInput type="text" placeholder="ejemplo@gmail.com" formControlName="email" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.email.errors" class="alert alert-danger">
                            <div *ngIf="sp.email.errors.required">
                                Este campo es requerido
                            </div>
                            <div *ngIf="sp.email.errors.pattern || sp.email.errors.email" style="width: 19vw;">
                                Ingresá un mail válido (ejemplo@gmail.com). Verificá que no finalice con un espacio en blanco.
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="motivo === 'otro'" >
                    <!-- asunto -->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Asunto</mat-label>
                            <input matInput type="text" formControlName="asunto" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.asunto.errors" class="alert alert-danger">
                            <div *ngIf="sp.asunto.errors.required">
                                Este campo es requerido
                            </div>
                        </div>
                    </div>

                    <!-- mensaje -->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Mensaje</mat-label>
                            <input matInput type="text" formControlName="mensaje" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.mensaje.errors" class="alert alert-danger">
                            <div *ngIf="sp.mensaje.errors.required">
                                Este campo es requerido
                            </div>
                        </div>
                    </div>               
                </div>

                <div *ngIf="motivo === 'login'" >
                    <!-- tipo de documento -->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                          <mat-label>Tipo de documento</mat-label>
                          <mat-select name="tipoDocumento" formControlName="tipoDocumento" required>
                            <mat-option *ngFor="let td of tiposDocumentos" value="{{td.desc}}">
                              {{td.desc}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="supportMessageSubmitted && sp.tipoDocumento.errors" class="alert alert-danger">
                        <div *ngIf="sp.tipoDocumento.errors.required">
                            Este campo es requerido
                        </div>
                    </div>

                    <!-- numero de documento-->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Número de documento</mat-label>
                            <input matInput type="number" formControlName="numeroDocumento" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.numeroDocumento.errors" class="alert alert-danger" >
                            <div *ngIf="sp.numeroDocumento.errors.required">
                                Este campo es requerido
                            </div>
                        </div>
                    </div>

                    <!-- nombre de usuario -->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Nombre de usuario</mat-label>
                            <input matInput type="text" formControlName="nombreUsuario" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.nombreUsuario.errors" class="alert alert-danger" >
                            <div *ngIf="sp.nombreUsuario.errors.required">
                                Este campo es requerido
                            </div>
                        </div>
                    </div>

                    <!-- mensaje del error-->
                    <div class="form-group">
                        <mat-form-field class="mat-form-field">
                            <mat-label>Mensaje del error</mat-label>
                            <input matInput type="text" formControlName="mensaje" required>                            
                        </mat-form-field>
                        <div *ngIf="supportMessageSubmitted && sp.mensaje.errors" class="alert alert-danger" >
                            <div *ngIf="sp.mensaje.errors.required">
                                Este campo es requerido
                            </div>
                        </div>
                    </div> 
                </div>
                
            </form>
            <button mat-raised-button class="btn btn-info ml-2" (click)="enviar()" [class.spinner]="supportMessageLoading" [disabled]="supportMessageLoading"
             [hidden]="supportMessageSent" style="float: right;">
                Enviar
            </button>
            <img *ngIf="supportMessageLoading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
            <button mat-dialog-close [disabled]="supportMessageLoading" [hidden]="supportMessageSent" class="btn btn-secondary">
                Cancelar
            </button>
        </div>
    </div>
</div>