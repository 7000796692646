import { Component, OnInit } from '@angular/core';
import { TurnosService } from '../_services/turnos.service';
import { UserService } from '../_services/user.service';
import { AuthenticationService } from '../_services/authentication.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { animate, style, state, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import { MercadoPagoService } from '../_services/mercado-pago.service';
import { Cobrar } from '../pago/pago.component';
import { MercadoPago } from '../autorizar-cobertura/autorizar-cobertura.component';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogReprogramarTurnoComponent } from '../dialog-reprogramar-turno/dialog-reprogramar-turno.component';
import * as moment from 'moment';
import { MediaMatcher } from '@angular/cdk/layout';


export interface Turno {
  str_fecha: string;
  str_horaDesde: string;
  especialidad: string;
  profesional: string;
  estado: string;
  categoria: string;
  turnoEnAtencion: number;
  fechaDiaMes: String;
}

export interface TurnoEnAtencion {
  orden: number;
  atencion: string;
  ok: boolean;
  mensaje: string;
}

@Component({
  selector: 'app-mis-reservas',
  templateUrl: './mis-reservas.component.html',
  styleUrls: ['./mis-reservas.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class MisReservasComponent implements OnInit {

  columnsToDisplay = [
    'str_fecha',
    'str_horaDesde',
    'especialidad',
    'profesional',
    'estado',
    'star',
  ];
  
  expandedElement: Turno | null;
  fechaDelTurno: string;
  fechaDeHoySistema: String;
  fechaArray: any[];
  horasMinutosTurno: any[];
  fechaArrayDos: string[];
  turnoEnAtencion: number;
  existenTurnos: boolean;
  deuda: number;
  habilitarCheckIn: boolean;
  miTurno: Turno;
  espe: string;
  turnosReservados: any = [];
  diaHoraTurno: Date;
  horaAntes: Date;
  fechaHoraSistema: Date;
  cobrar = {} as Cobrar;
  mercadoPago = {} as MercadoPago;
  urlPagar: string = 'null';
  tiempoEnSegundos;
  tiempoEnMinutos;
  currentUser;
  msjeEstad: string;
  funEstad: string;
  urlPagoMP: string;
  isIE: boolean;
  newDate: string;
  loadingTurnos: boolean = true;
  fechaHoraActual = moment();
  admisionDigital: boolean = true;
  mobileQuery: MediaQueryList;

  constructor(
    private turnosService: TurnosService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private urlResolverService: UrlResolverService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private mercadoPagoService: MercadoPagoService,
    private dialog:MatDialog,
    private mediaMatcher: MediaMatcher
  ) {
    iconRegistry.addSvgIcon('keyboard_arrow_down',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/mis-reservas/keyboard_arrow_down-24px.svg'
      )
    );
    iconRegistry.addSvgIcon('keyboard_arrow_up',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/mis-reservas/keyboard_arrow_up-24px.svg'
      )
    );
    this.mobileQuery = this.mediaMatcher.matchMedia('(max-width: 1290px)');
  }

  ngOnInit() {

    this.getNavigator();

    this.getUrlPago();

    this.obtenerTiempoWC();

    this.obtenerDatosUsuario();

    this.obtenerTurnosReservadosPaciente();

    this.obtenerDatosUsuario();

    this.verificarAdmision();
  }

  getNavigator() {
    this.isIE = navigator.userAgent.toLowerCase().indexOf('trident') != -1;
  }

  getUrlPago() {
    this.urlResolverService.getUrlPago().toPromise().then((respGetUrlPago) => {
      // console.log(respGetUrlPago)
      if (respGetUrlPago.ok) {
        this.urlPagoMP = respGetUrlPago.mensaje;
      }
    }, (error) => {
      console.log(error);
    });
  }

  async obtenerTiempoWC() {
    await this.urlResolverService.getTiempoWC().toPromise().then((respTiempoWC) => {
      // console.log(respTiempoWC);
      this.tiempoEnMinutos = respTiempoWC.mensaje;
    }, (error) => {
      console.log(error);
    });
  }

  obtenerDatosUsuario() {
    this.userService.getUserData().subscribe((response) => {
      this.currentUser = response.usuario;
    }, (error) => {
      console.log(error);
    });
  }

  async verificarPago(turno) {
    this.cobrar.nroBoleta = turno.nroBoleta;
    turno.yaPago = false;
    await this.mercadoPagoService.obtenerComprobanteCaja(this.cobrar).toPromise().then(async rpGetCompCaja => {
      // console.log(rpGetCompCaja)
      if (rpGetCompCaja.ok) {
        if (rpGetCompCaja.estado == 'ESP' || rpGetCompCaja.estado == 'ENA') {
          this.cobrar.importeTotal = rpGetCompCaja.importe;
          await this.mercadoPagoService.obtenerLinkPago(this.cobrar).toPromise().then((mercadoPagoResponse) => {
            // console.log(mercadoPagoResponse);
            if (mercadoPagoResponse && mercadoPagoResponse.id) {
              turno.urlPagoMp = this.urlPagoMP + mercadoPagoResponse.id;
              turno.debePagar = true;
            } else {
              turno.debePagar = false;
            }
          }, error => {
            console.log(error);
            turno.debePagar = false;
          })
        } else if (rpGetCompCaja.estado == 'COB') {
          await this.turnosService.getTurnosAtencion(turno).toPromise().then((rpTurnoEnAtencion) => {
            // console.log(rpTurnoEnAtencion);
            turno.turnoEnAtencion = rpTurnoEnAtencion.orden;
          }, (error) => {
            console.log(error);
          });

          turno.debePagar = false;
          turno.yaPago = true;
        } else {
          turno.debePagar = false;
        }
      } else if (turno.estadoReserva && turno.estadoReserva == 'ESP') {
        await this.turnosService.getTurnosAtencion(turno).toPromise().then((rpTurnoEnAtencion) => {
          // console.log(rpTurnoEnAtencion);
          turno.turnoEnAtencion = rpTurnoEnAtencion.orden;
        }, (error) => {
          console.log(error);
        });

        turno.debePagar = false;
        turno.yaPago = true;
      } else {
        turno.debePagar = false;
      }
    }, error => {
      console.log(error)
      turno.debePagar = false;
    })
  }

  async verificarAdmision(){
    await this.authenticationService.verificarParametroAdmisionDigital().toPromise().then((parametro) => {
        if(parametro){
          this.admisionDigital= true
        } else {
          this.admisionDigital = false
        }
    })
  }

  async verificarAdmisionDisponible(turno) {
    if (this.admisionDigital){
      await this.turnosService.habilitarWebcheckin(turno).toPromise().then((responseHabWC) => {
        // console.log(responseHabWC);
        
        turno.habilitarCheckIn = responseHabWC.ok;
        if (!responseHabWC.ok) {
          turno.msjeHabilitacionWC = responseHabWC.mensaje;
        }
      }, (error) => {
        console.log(error);
      });
    }
  }

  async analizarTurnos() {
       if (this.turnosReservados.length > 0) {

      for await (const turno of this.turnosReservados) {
        // console.log(turno)
        turno.habilitarCheckIn = false;
        turno.cargandoLink = true;
        turno.pago = true;
        turno.fechaDiaMes = moment(turno.str_fecha, 'DD/MM/YYYY').format('DD/MM');
        turno.fechaHoraTurno = moment(turno.str_fecha+' '+turno.str_hora, 'DD/MM/YYYY HH:mm');
        turno.fechaHoraTurnoDisponible = moment(turno.str_fecha+' '+turno.str_hora, 'DD/MM/YYYY HH:mm').subtract(this.tiempoEnMinutos, 'minutes');

        let fechaAsignacion = moment(turno.str_fecha_asignacion, 'DD/MM/YYYY');
        let fechaTurno = moment(turno.str_fecha, 'DD/MM/YYYY');
        
        // ME FIJO SI EL TURNO ESTA DENTRO DEL HORARIO PERMITIDO
      if (this.fechaHoraActual > turno.fechaHoraTurnoDisponible 
        && this.fechaHoraActual < turno.fechaHoraTurno) {

        // SI TIENE BOLETA YA HIZO LA ADMISION DIGITAL, PUEDE TENER QUE PAGAR O NO.        
        this.verificarBoleta(turno);
        
      } else if (this.fechaHoraActual > turno.fechaHoraTurno 
        && !fechaAsignacion.isSame(fechaTurno, 'day')) { 
        // si la hora actual supera la hora del turno y ademas no es un turno espontaneo (dado el mismo dia)
        turno.msjeHabilitacionWC = 'Ya no puede realizar la Admision Web debido a que supero el horario del turno.';
      } else if (fechaAsignacion.isSame(fechaTurno, 'day')) {
          // Si la fecha de asignación es la misma que la fecha del turno (turno espontaneo)
          // Es como si estuviese DENTRO DEL HORARIO PERMITIDO
          
          // SI TIENE BOLETA YA HIZO LA ADMISION DIGITAL, PUEDE TENER QUE PAGAR O NO.
          this.verificarBoleta(turno);
          // turno.habilitarCheckIn = true;

      } else {
          turno.msjeHabilitacionWC = 'Podrá realizar la Admision Web 2 horas antes de la hora del turno.';
      }
     
      // console.log((fechaAsignacion.isSame(fechaTurno, 'day')));
        
      };
      this.existenTurnos = true;
    } else {
      this.existenTurnos = false;
    }
    this.loadingTurnos = false;
  }

  async verificarBoleta(turno: any) {
    if (Number.parseInt(turno.nroBoleta) && Number.parseInt(turno.nroBoleta) != 0) {
      turno.yaRealizoWebCheckIn = true;
      await this.verificarPago(turno);
    } else {
      // SI NO TIENE BOLETA VERIFICO SI PUEDE HACER LA ADMISION
      turno.yaRealizoWebCheckIn = false;
      await this.verificarAdmisionDisponible(turno);
    }
  }

  async obtenerTurnosReservadosPaciente() {
    await this.turnosService.getTurnosReservadosPaciente().toPromise().then((response) => {
      // console.log(response.turnos);
      this.turnosReservados = response.turnos;
      this.analizarTurnos();
    }, (error) => {
      console.log(error);
      this.existenTurnos = false;
      this.loadingTurnos = false;
    });
  }

  verificarEspecialidad(especialidad){
    return especialidad != "HEMATOLOGIA";
  }

  // goToTurnos(){
  //   window.location.href='/turnos';
  // }
  
  guardarEstadistica(mensaje: String, funcion: String) {
    this.userService.guardarEstadistica(mensaje, funcion).toPromise().then((respGuardarEstad) => {
      // console.log(respGuardarEstad);
    }, (error) => {
      console.log(error);
    });
  }
  
  async realizarCheckIn(turno) {
    this.loadingTurnos = true;
    this.guardarEstadistica('El usuario ' + this.currentUser + ' intento realizar la admisión digital.', 'FX_SolicitudAdmisionWeb');
    

    await this.userService.getWebCheckIn().toPromise().then((respuesta) => {
      // console.log(respuesta);
      turno.realizoWebCheckIn = respuesta.realizoWebCheckIn == 'N' ? false : true;
      if (respuesta.realizoWebCheckIn == 'N') {
        this.router.navigate(['web-checkin']);
        // window.location.href='/web-checkin';
      } else {
        this.router.navigate(['autorizar-cobertura']);
        // window.location.href='/autorizar-cobertura';
      }
    }, (error) => {
      console.log(error);
    });

    this.turnosService.setTurnoAdmision(turno);
  }

  pagar(turno) {
    this.guardarEstadistica('El usuario ' + this.currentUser + ' intenta pagar desde Reservados.', 'FX_PagoReservados');
    window.location.href = turno.urlPagoMp;
  }

  abrirDialogMensaje(resp) {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      height: this.mobileQuery.matches ? '150px' : 'auto',
      data: resp,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe((confirmado: boolean) => {
      
      // if (confirmado) {
        this.dialog.closeAll();
        this.loadingTurnos = true;
        this.obtenerTurnosReservadosPaciente();
      // }
  
    });
  }

  cancelarTurno(turno) {
    this.turnosService.cancelarTurno(turno).subscribe((data) => {
      if (data.ok) {
        this.abrirDialogMensaje(data);
      }
    });
  }


  abrirDialogConfirmacion(turno) {
    
    const config = {
      mensaje:'cancelar tu turno con:',
      turno:{
        fecha: turno.fechaDiaMes,
        hora: turno.str_hora,
        profesional: turno.profesional,
      },
      titulo: 'Confirmar cancelar turno',
      cerrar: {
        color:'warn',
        texto:'No'
      },
      confirmar: {
        color:'primary',
        texto:'Si'
      },
      estasPor: true
    }

    this.dialog.open( DialogConfirmacionComponent, {
      data: config,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.cancelarTurno(turno);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  abrirDialogMensajeReprogramar(){
    this.dialog.open(DialogReprogramarTurnoComponent, {
      width: '500px',
    });
  }
}
