import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { userInfo } from 'os';

export interface DialogData {
  hashValido: boolean;
  url: string;
}

@Component({
  selector: 'app-dialog-compartir-estudio',
  templateUrl: './dialog-compartir.component.html',
  styleUrls: ['./dialog-compartir.component.css']
})
export class DialogCompartirComponent implements OnInit {
  copiado: boolean = false;

  constructor( 
    public dialogRef: MatDialogRef<DialogCompartirComponent>,   
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    
  }
 
  copyInputMessage(userinput){
    userinput.select();
    userinput.setSelectionRange(0,99999);
    document.execCommand('copy');
    this.copiado = true;  
  }

  cerrar(){
    this.dialogRef.close();
  }
}
