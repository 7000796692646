import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SplitStringPipe } from '../_pipes/split-string.pipe';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { DialogMensajeComponent,Respuesta } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
@Component({
  selector: 'app-validar-usuario',
  templateUrl: './validar-usuario.component.html',
  styleUrls: ['./validar-usuario.component.css'],
  providers: [SplitStringPipe],
})
export class ValidarUsuarioComponent implements OnInit {
  validarForm: FormGroup;
  loadingValidar = false;
  submittedValidar = false;
  currentUser;
  userData;
  estadoValidacion$: Observable<string>;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  cargando= false;
  estadoValidacion: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private splitString: SplitStringPipe,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    
    this.userService.userData.subscribe(
      (userData) => {
        if (userData.tipoDocumento !== '9') {
          // this.router.navigate(['/home']);
          window.location.href='/home';
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.obtenerDatosUsuario();
  }
  buildFormValidar() {
    this.validarForm = this.formBuilder.group({
      fotoFrenteDNI: ['', Validators.required],
      fotoPaciente: ['', Validators.required],
      dni: ['', Validators.required],
      tipoDni: [this.currentUser.tipoDocumento, Validators.required],
      dniTemporal: [this.currentUser.nroDocumento, Validators.required],
      usuario: [this.currentUser.usuario, Validators.required],
      idFinanciador: [65, Validators.required],                            // SIN FINANCIADOR
      nroCarnet: [''],
      idTipoValidacion: [1, Validators.required]                           // COBERTURA
    });
  }

  get fvalidar() {
    return this.validarForm.controls;
  }
  obtenerDatosUsuario() {
    this.userService.getUserData().subscribe(
      (response) => {
        this.currentUser = response;
        this.buildFormValidar();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onSubmitValidar() {
    this.submittedValidar = true;
    if (this.validarForm.invalid) {
      return;
    }
   // console.log(this.validarForm.value)
    this.loadingValidar = true;
    this.cargando=true;
    this.userService
      .validar(this.validarForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.dialog.open
          this.submittedValidar = false;
          this.loadingValidar = false;
          
          if (data.ok) {
            this.cargando = false;
            //this.openDialog(data)
            // this.dialog.open(DialogMensajeComponent, {
            //   width: '500px',
            //   data: {
            //     ok: true,
            //     mensaje: 'Peticion de validacion generada correctamente',
            //   }
            // })
            document.getElementById('openModalValidacionCorrecta').click();
          } else {
            document.getElementById('openModalValidacionIncorrecta').click();
          }
        },
        (error) => {
          this.loadingValidar = false;
          this.submittedValidar = false;
          document.getElementById('openModalValidacionIncorrecta').click();
        }
      );
  }
  onFileChange(event) {
    const reader = new FileReader();
    const maxSize = 25*1024*1024;
    let validado = false;
    //console.log(event.target.files[0].size)
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      new String(event.target.files[0].type).match(/image.*/)
      
    
    ) {

      if(event.target.files[0].size > maxSize){
         this.dialog.open(DialogMensajeComponent, {
              width: '500px',
              data: {
                ok: false,
                mensaje: 'El archivo es demasiado grande',
              },
              panelClass: 'dialog-sin-overflow'
            })
          event.target.value = null;
        }
        else{
        validado = true;
        }
     if(validado){
      this.validarForm
        .get(event.target.getAttribute('id'))
        .setErrors({ archivoInvalido: false });
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.validarForm.get(event.target.getAttribute('id')).setValue({
          // nombre: new String(file.name).replace(/([^0-9a-zA-Z]+)/, ''),
          nombre: new String(file.name).replace(/[^a-zA-Z0-9.]+/g, ''),
          tipo: '.' + this.splitString.transform(file.name),
          valor: (reader.result as string).split(',')[1],
        });
      };
     }
    } else {
      this.validarForm
        .get(event.target.getAttribute('id'))
        .setErrors({ archivoInvalido: true });
      // this.validarForm.get(event.target.getAttribute('id')).setValue(null);
      // event.target.value = '';
    }
  }
  irATurnos() {
    // this.router.navigate(['/reservar-turno-medico']);
    window.location.href='/reservar-turno-medico';
  }
  openDialog(res: Respuesta): void {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: res,
      panelClass: 'dialog-sin-overflow'
    }).
    afterClosed().subscribe(() => {
       if (res.ok) {        
        //this.router.navigate(['/turnos']);  
       }
     });
  }
  


  
}
