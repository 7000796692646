import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resonancia-magnetica',
  templateUrl: './resonancia-magnetica.component.html',
  styleUrls: ['./resonancia-magnetica.component.css']
})
export class ResonanciaMagneticaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
