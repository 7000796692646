<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" *ngIf="noticiasCargadas || hayNoticias">
  
  <!-- INDICADOR DE SLIDES -->
  <ol class="carousel-indicators" >
    <li data-target="#carouselExampleIndicators" *ngFor="let li of noticias; let i = index" [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}"></li>
  </ol>
  
  <!-- FOTOS -->
  <div class="carousel-inner">
    <div class="carousel-item" *ngFor="let item of noticias; let i = index" [ngClass]="{'active' : i == 0}">
      <img class="d-block" [src]="files + item.nombreImagen" [alt]="item.titulo" (click)="verImagen()">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>

</div>