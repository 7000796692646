import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-cancelar-turno',
  templateUrl: './cancelar-turno.component.html',
  styleUrls: ['./cancelar-turno.component.css']
})
export class CancelarTurnoComponent implements OnInit {

  cargando: boolean = true;
  turnoEncontrado: boolean;
  turno: any = {};
  claveTurno: any = {};
  alerta: any = {};
  canceloCorrecto: any;

  constructor(
    private route: ActivatedRoute,
    private turnoService: TurnosService
  ) { }

  ngOnInit() {
    this.getParametros();
  }

  async getParametros() {
    this.claveTurno.matricula = this.route.snapshot.params['nroMatricula'];
    this.claveTurno.orden = this.route.snapshot.params['orden'];
    this.claveTurno.fecha = this.route.snapshot.params['fecha'];
    this.claveTurno.desdeHora = this.route.snapshot.params['desdeHora'];

    // console.log(this.claveTurno);

    await this.turnoService.getTurnoUnico(this.claveTurno).toPromise().then(respGetTurno => {
      // console.log(respGetTurno)
      if (respGetTurno.ok) {
        this.turno = respGetTurno.turnoReservado;
        this.turnoEncontrado = true;
        this.cargando = false;
      } else {
        this.turnoEncontrado = false;
        this.cargando = false;
      }
    }, error => {
      console.log(error)
      this.turnoEncontrado = false;
      this.cargando = false;
    })
  }

  async cancelar() {
    this.cargando = true;
    this.turno.usuario = 'API_PACIENTES';
    this.turno.codEstablecimiento = 1;
    await this.turnoService.cancelarTurnoExt(this.turno).toPromise().then((respCancelarTurno) => {
      // console.log(respCancelarTurno);
      if (respCancelarTurno.ok) {
        this.alerta.cssClass = 'alert alert-success';
        this.alerta.mensaje = respCancelarTurno.mensaje;
      } else {
        this.alerta.cssClass = 'alert alert-danger';
        this.alerta.mensaje = respCancelarTurno.mensaje;
      }
      this.canceloCorrecto = respCancelarTurno.ok;
      this.cargando = false;
    }, error => {
      console.log(error)
      this.turnoEncontrado = false;
      this.cargando = false;
    });
  }

  salir() {
    this.cargando = true;
    window.location.assign("https://pacientes.hospitalespanol.org.ar")
  }

}
