
<h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%;">Reprogramar turno</h1>
<mat-divider class="divisor"></mat-divider>
<div mat-dialog-content>     
      
        
    <div class="modal-body">
        Si querés reprogramar el turno, comunicate con el servicio
    </div>

    <button
        style="float: right;"
        mat-flat-button color="primary"
        (click)="irADiagnosticoPorImagenes()"
        class="boton"
    >
        <span class="texto-boton">
            Servicio
        </span>
    </button>
      
</div>