<html>

<head>
    <meta name="robots" content="noindex">
</head>

<body>
    <div class="container-fluid">
        <div class="row d-flex justify-content-center mt-1">
            <div class="col-md">

                <div class="container">
                    <h3>Preparación</h3>
                    <ol class="mt-4">
                        <li>
                            <p>Presentarse en ayunas de 12 horas.</p>
                        </li>
                        <li>
                            <p>Bañarse dentro de las dos horas previas a la cirugía y previamente cortar las uñas al
                                ras,
                                cuidando de no lesionar la piel. Si están pintadas quitar todo el esmalte. En caso de
                                cirugía con
                                colocación de implante, bañar con jabón antiséptico también los dos días previos y dos
                                horas antes
                                de la cirugía.</p>
                            <p><strong>
                                    <i>¿Cómo realizar el baño? Humedecer el cuerpo con abundante agua. Salir de la misma
                                        y lavar
                                        con jabón antiséptico o jabón neutro nuevo. Hacer hincapié en la cabeza
                                        (cabello, cuello, boca), axilas, ombligo, uñas de pies y manos, ingle y zona
                                        perineal.
                                        Enjuagar y secar con mayor meticulosidad que lo habitual. No utilizar perfume,
                                        desodorante, talco, maquillaje u otros cosméticos. Colocar ropa limpia. </i>
                                </strong></p>
                        </li>
                        <li>
                            <p>Antes de ir al quirófano, Enfermería brindará el equipo de ropa quirúrgica
                                (botas, bata, gorro, tapaboca) que deberá utilizar quitándose todas las prendas de
                                vestir,
                                incluyendo la ropa interior.</p>
                        </li>
                        <li>
                            <p>Si tiene prótesis dentaria -parcial o total-, ocular y/o auditiva, se solicitará dejarlas
                                con un
                                familiar o acompañante antes de ser trasladado al quirófano.</p>
                        </li>
                        <li>
                            <p>Se sugiere presentarse en el Hospital sin accesorios y elementos personales como alianzas
                                o bijouterie.</p>
                        </li>
                        <li>
                            <p>Importante: todo paciente que requiera internación para cirugía necesitará <b>dadores de
                                    sangre</b>
                                (entre 2 y 12 según el procedimiento).</p>
                            <p>Cirugía General (2) - Cardiotorácica (4) - Reparación de Aneurisma (6) - By Pass
                                Coronario (4) - Cirugía Cardiovascular (12) -
                                Ortopedia (4) - Reemplazo total de Cadera (4) - Reemplazo total de Rodilla (4) -
                                Obstetricia/ Ginecología (4) -
                                Histerectomía Radical (4) - Placenta Acreta (6) - Urología (2) - Nefrectomía Radical (3)
                                - Trasplante Renal (2)</p>
                        </li>
                        <li>
                            <p>No suspender los medicamentos que toma excepto por indicación médica.</p>
                        </li>
                        <li>
                            <p>Dependiendo del tipo de cirugía y si está indicado por el médico, se procederá al
                                rasurado correspondiente.</p>
                        </li>
                    </ol>
                    <p><b>Más información: (+54 0221) 412 9400, interno 1506</b></p>
                </div>
            </div>
        </div>
    </div>
</body>

</html>