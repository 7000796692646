<div>
    <h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%;">
        Se ha encontrado un usuario existente.
    </h1>
    <mat-divider class="divisor"></mat-divider>
</div>

<div mat-dialog-content>

    <div class="alert p-1 m-1">
        El número de documento asociado ya dispone de un usuario activo: <br>
        <ul>
            <li><b>Nombre de usuario:</b> {{ data.nombreUsuario }}</li>
            <li><b>email:</b> {{ data.email }}</li>
        </ul>
    </div>
    <div class="alert alert-danger p-1 m-1 align-middle text-center">
        En caso de validar el registro se inactivarán el resto de usuarios asociados con el mismo documento.
    </div>

    
</div>

<div class="d-flex justify-content-center">
    <button class="boton boton-cerrar margen-left-vw-1" 
        (click)="selectOption(null)"
        > Cerrar
    </button>

    <button class="boton recuperar-usuario margen-left-vw-1"
        (click)="selectOption(1)"
        > Recuperar usuario
    </button>

    <button class="boton boton-confirmar margen-left-vw-1"
        (click)="selectOption(2)"> Crear usuario nuevo
    </button>
</div>  