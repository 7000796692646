import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hospital-del-dia',
  templateUrl: './hospital-del-dia.component.html',
  styleUrls: ['./hospital-del-dia.component.css']
})
export class HospitalDelDiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
