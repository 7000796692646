<div *ngIf="(visitante() && userData.fotoPerfil), else mostrarIcono" class="foto-perfil">
    <img [src]="fotoPaciente" >
</div>
<ng-template #mostrarIcono>
   <img src="../../assets/images/header/icon-user.png" >
</ng-template>

<mat-toolbar *ngIf="userData && currentUser" class="custom-toolbar">
    <!-- <div class="seccion-menu">
        <button mat-icon-button 
                (click)="toggleNavBar()" >
                <mat-icon>menu</mat-icon>
                <div class="texto-menu">MENÚ</div>            
        </button>        
    </div> -->

    <!-- <div class="seccion-shape">
        <img (click)="goTurnos()" src="../../assets/images/login/logo HE.png" />
    </div> -->
   
    <div class="seccion-usuario">
        
            <span class="texto-usuario" *ngIf="!visitante()">{{ userData.apellido | toTitleCase }} </span>
            <span class="texto-usuario" *ngIf="visitante()">Hola, {{ userData.apellido | toTitleCase }} </span>
            
            <span *ngIf="this.temporal && visitante()">
                <span *ngIf="validacionEnProceso(); else elseBlock">
                    <button
                    type="button"
                    class="btn btn-outline-danger"
                    routerLink="/validarUsuario"
                    >
                    <small class="align-middle">Usuario no validado </small>
                    </button>
                </span>
                <ng-template #elseBlock>
                    <button type="button" class="btn btn-outline-warning" (click)="openValidacionPendiente()">
                        <small class="align-middle">Validacion pendiente</small>
                    </button>
                </ng-template>
            </span>
            
            
            
            
           
            <mat-menu #belowMenu="matMenu" yPosition="below">
                <!-- <div *ngIf="visitante()">
                    <button mat-menu-item (click)="abrirDialogActualizarContrasena(true)">
                        Actualizar contraseña
                    </button>
                </div>
                <div *ngIf="visitante()">
                    <button mat-menu-item routerLink="/actualizar-datos">Mis datos</button>
                </div> -->
                <button mat-menu-item (click)="logout()">Cerrar sesión</button>
            </mat-menu>
    </div>
    

</mat-toolbar>
