import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { Observable} from 'rxjs';
import { NavBarServiceService } from '../_services/nav-bar-service.service';
import { DialogMensajeComponent} from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogActualizarContrasenaComponent} from '../dialog-actualizar-contrasena/dialog-actualizar-contrasena.component';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

export interface UserData {
  apellido: string;
  campania_actualizacion: string;
  codigoPostal: string;
  domicilio: string;
  email: string;
  estadoValidacion?: string;
  fechaNacimiento: Date;
  localidad: string;
  masculino: boolean;
  nroDocumento: string;
  permisos?: string;
  socioNro?: string;
  telefonoParticular: string;
  tipoDocumento: string;
  usuario: string;
  fotoPerfil: String;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  // CREO EL OBSERVABLE QUE VA A CONSUMIR LOS CAMBIOS DE ESTADO
  estadoValidacion$: Observable<string>;

  estadoValidacion: string;
  currentUser: User;
  userData: UserData;
  fotoPaciente: any;
  submitted = false;
  temporal = false;

  success;
  mensaje;

  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private navBarService: NavBarServiceService,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer

  ) {
     
  }

  ngOnInit() {
    this.cargarUsuario();
    this.estadoValidacion$.subscribe(
      (estadoValidacion) => {
        this.estadoValidacion = estadoValidacion;
      },
      (error) => {
        console.log(error);
      }
    );

    this.temporal = false;
    this.success = false;
    
  }

  validacionEnProceso() {
    return this.estadoValidacion !== 'PENDIENTE';
  }
  toggleNavBar() {
    this.navBarService.toggle();
  }
  closeNavBar() {
    this.navBarService.close();
  }
  visitante() {
    return localStorage.getItem('username') !== 'visitante';
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  goTurnos() {
    if (this.currentUser) {
      //this.router.navigate(['/turnos']);
    }
  }

  openValidacionPendiente(){
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        ok: false,
        mensaje: 'Debe esperar a que su cuenta sea validada.'
      },
      panelClass: 'dialog-sin-overflow'
    });

  }

  abrirDialogActualizarContrasena(mostrarCancel) {
    this.dialog
    .open(DialogActualizarContrasenaComponent, {
      width: '500px',
      disableClose: true,
      data:{mostrarCancelar:mostrarCancel},
      panelClass: 'dialog-sin-overflow'

    }).afterClosed()
    .subscribe(
      (cambiado: boolean) => {
        if (cambiado) {
          this.goTurnos();
        } 
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cargarUsuario(){
    this.authenticationService.currentUser.subscribe(async (x) => {
      this.currentUser = x;
      this.temporal = false;

      if (localStorage.getItem('currentUser') != null) {
        this.userService.getUserData().subscribe(async (x) => {
          this.userData = x;
          
          this.fotoPaciente = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
                 + this.userData.fotoPerfil);
          if (this.userData.tipoDocumento === '9') {
            this.temporal = true;
          }
        }
        , error => {
          console.log(error);
        });
      }

    }, error => {
      console.log(error);
    });
    console.log(this.userData);
  }
}
