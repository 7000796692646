<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid contenedor">
    <div class="row d-flex justify-content-center mt-1 tabla-reservas">
      <div class="d-flex justify-content-center" *ngIf="loadingTurnos">
        <mat-spinner></mat-spinner>
      </div>
  
      <div class="col" *ngIf="!loadingTurnos">
        
        <div [hidden]="existenTurnos" class="alert alert-danger" style="width: fit-content;">
          <h6>Todavía <b>no reservaste</b> ningún turno.</h6>
        </div>

        <div [hidden]="admisionDigital" class="alert alert-warning" style="width: fit-content; margin: auto;">
          <h6><b>La admisión digital no se encuentra disponible</b></h6>
        </div>
        
          

        <div class="contenedor-cards-simil-tabla">
          <div *ngFor="let turno of turnosReservados" class="card-simil-tabla">
            
            <div *ngIf="verificarEspecialidad(turno.especialidad); else horaLlegada" class="fecha-hora">
              <b>{{ turno.fechaDiaMes }} {{ turno.str_hora }}hs.</b>
            </div>
            <ng-template #horaLlegada>
              <b>{{ turno.fechaDiaMes }}</b>
            </ng-template>
          
        
            <div>
              Dr/a <b>{{ turno.profesional }} </b> ({{ turno.especialidad }})
            </div>
        
            
            
            
        
            
            
        
            <div class="contenedor-botones">

              <div [hidden]="turno.bono" *ngIf="(turno.sector_turno_id == 14 || turno.nroServicio == 1); else puedeSerEstudio">
                <button *ngIf="turno.nroBoleta && turno.nroBoleta == 0" mat-flat-button color="warn" (click)="abrirDialogConfirmacion(turno)" class="boton">
                  Cancelar Turno
                </button>
              </div>
              <ng-template #puedeSerEstudio>
                <div *ngIf="turno.nroPedido != null; else elseTemplate" class="estudio">
                  Estudio
                </div>
                <div [hidden]="!turno.descPedido" class="desc-pedido">{{ turno.descPedido }}</div>
              </ng-template>


              <div *ngIf="turno.nroServicio == 1 && !turno.yaRealizoWebCheckIn" style="display: flex;gap: 5px;align-items: center;">
                
                <button [disabled]="!turno.habilitarCheckIn" mat-flat-button color="primary" (click)="realizarCheckIn(turno)" id="boton-derecho" class="boton">
                    Admisi&oacute;n Digital                  
                </button>
                <mat-icon 
                  *ngIf="!turno.habilitarCheckIn && admisionDigital" 
                  #tooltip="matTooltip" 
                  (click)="tooltip.toggle()" 
                  matTooltip="{{ turno.msjeHabilitacionWC }}" 
                  matTooltipPosition="right"
                  class="icono"> help_outline 
                </mat-icon>
              </div>


              <button *ngIf="turno.debePagar" mat-flat-button color="primary" type="button" (click)="pagar(turno)">
                Pagar
              </button>
            </div>
        
            <div class="columna">
              <div *ngIf="turno.yaPago">
                <p *ngIf="turno.bono">
                  <b>Nro. de Autorizaci&oacute;n:</b> {{ turno.bono }}
                </p>
                <p *ngIf="turno.servicio == 'Consultorio'">
                  <b>Consultorio:</b> {{ turno.descripConsultorio }}
                </p>
              </div>
            </div>
        
            <div class="columna">
              <div *ngIf="turno.yaPago">
                <!-- <p *ngIf="turno.turnoEnAtencion == 0" class="turno-info">
                  <b>Turno {{ turno.numero }}</b><br>(El profesional no comenz&oacute; la atenci&oacute;n)
                </p> -->
                <!-- <p *ngIf="turno.turnoEnAtencion != 0" class="turno-info">
                  <b>Turno {{ turno.numero }}</b> <br>(El profesional est&aacute; atendiendo el turno {{ turno.turnoEnAtencion }})
                </p> -->
              </div>
            </div>
          </div>
        </div>
            
      </div>
    </div>
  </div>
</body>

</html>