import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { UrlResolverService } from '../_services/urlResolver.service';
import { DialogValoracionUsuarioComponent } from '../dialog-valoracion-usuario/dialog-valoracion-usuario.component';

export interface UserData {
  apellido: string;
  campania_actualizacion: string;
  codigoPostal: string;
  domicilio: string;
  email: string;
  estadoValidacion?: string;
  fechaNacimiento: Date;
  localidad: string;
  masculino: boolean;
  nroDocumento: string;
  permisos?: string;
  socioNro?: string;
  telefonoParticular: string;
  tipoDocumento: string;
  usuario: string;
  fotoPerfil: String;
  nombres: string;
  realizoValoracion: string;
}

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {
  currentUser: User;
  userData: UserData;
  entradas: any[];
  cargando: boolean = true;
  entrada_seleccionada: any;
  subentrada_seleccionada: any;
  fotoPaciente: any;
  userData_recuperado: boolean;
  es_visitante: boolean;
  paramPeticionValoracion: boolean;

  constructor(
    private userService: UserService,
    public dialog: MatDialog, 
    public authenticationService: AuthenticationService,
    public urlResolverService: UrlResolverService,
    public router: Router,
    private _sanitizer: DomSanitizer
  ) {
    this.userData_recuperado = false;
  }

  ngOnInit(): void {
    this.getUserData();
    this.getParamPeticionValoracion();
    if (!this.es_visitante) {
      this.cargarEntradas();
      this.cargarEntradasSeleccionadas();
      this.vincular_path_con_menu();
      this.cargando = false;
    }
  }
  
  getParamPeticionValoracion() {
    this.urlResolverService.getParamPeticionValoracion().toPromise().then(rpGetParamValoracion => {
      // console.log(rpGetParamValoracion)
      this.paramPeticionValoracion = (rpGetParamValoracion.mensaje === '1' ? true : false);
    }).catch(error => {
      console.error(error)
    })
  }

  getUserData() {
    this.userService.getUserData().subscribe(usuario => {
      // console.log(usuario);
      
      this.userData = usuario;        
      this.userData_recuperado = true;
      this.authenticationService.fotoActual.subscribe(fotoPaciente => this.fotoPaciente = fotoPaciente);
      //this.fotoPaciente = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
      //+ this.userData.fotoPerfil);
      
      if (this.userData.tipoDocumento === '9') {
        // this.temporal = true;
      }    
      this.es_visitante = this.userData.usuario === "visitante" ? true : false;    
    }, error => {
      console.log('ERROR: '+error);
      this.es_visitante = true;
    });    
  }

  async cargarEntradas(){
    await this.userService.obtenerEntradasDeMenu().toPromise().then(
      (data) => {
        if (data.ok) {
         this.ordenarVectorEntradas( data.elementos);           
        }
      }
    ); 
  }

  async ordenarVectorEntradas(vector) {
    let arreglodeItems = {};
    await vector.forEach((item) => {    
      if (!arreglodeItems.hasOwnProperty(item.nombre_menu)) {
        arreglodeItems[item.nombre_menu] = [];
      }
      arreglodeItems[item.nombre_menu].push(item);
    });
    
  //this.itemsMenu = arreglodeItems;
  this.entradas= this.generarEntradasOrdenadas(arreglodeItems); 
  }

generarEntradasOrdenadas(arreglo){
  let arregloJson= JSON.stringify(arreglo);
  let objeto=JSON.parse(arregloJson);
  let arreglito=[];
  for (const key in objeto) {
    arreglito.push(objeto[key]);
  }

  return arreglito;
}

selecciona_entrada(entrada){
  this.entrada_seleccionada = entrada.nombre_menu;
  this.subentrada_seleccionada = entrada.nombre_submenu;
  //guardo en localStorage la entrada seleccionada y la subentrada por defecto
  sessionStorage.setItem('entrada_seleccionada', entrada.nombre_menu);
  sessionStorage.setItem('subentrada_seleccionada', entrada.nombre_submenu);
}

selecciona_subentrada(subentrada){
  
  if(subentrada.nombre_submenu == 'Nefrología y diálisis'){
    window.open('https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-11', '_blank');      
  } 
  else {
    if(subentrada.nombre_submenu == 'Terapia Intensiva'){
      window.open('https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-no-ambulatorios/#seccion-5', '_blank');
    }else{        
      this.router.navigate([subentrada.url]);
      this.subentrada_seleccionada = subentrada.nombre_submenu;
      //guardo en localStorage la subentrada seleccionada
      sessionStorage.setItem('subentrada_seleccionada', subentrada.nombre_submenu);
    }
  }    
}


vincular_path_con_menu(){
  switch (window.location.pathname) {
    case '/turnos':{
      this.entrada_seleccionada = 'Turnos';
      this.subentrada_seleccionada = 'Reservar turno de consulta médica';      
      break;
    }
    case '/turnos-estudios':{
      this.entrada_seleccionada = 'Turnos';
      this.subentrada_seleccionada = 'Reservar turno para un estudio';
      break;
    }
    case '/turnos-reservados':{
      this.entrada_seleccionada = 'Turnos';
      this.subentrada_seleccionada = 'Reservados';
      break;
    }
    case '/mis-coberturas':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Coberturas';
      break;
    }
    case '/mis-ordenes':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Órdenes';
      break;
    }
    case '/facturas':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Facturas';
      break;
    }
    case '/editar-datos':{
      this.entrada_seleccionada = 'Paciente';
      this.subentrada_seleccionada = 'Editar Datos';
      break;
    }
    case '/historial-clinico':{
      this.entrada_seleccionada = 'Historial clínico';
      break;
    }
    case '/tramites-de-cirugías':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Trámites de cirugías';
      break;
    }
    case '/prequirurgicos-preparacion':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Prequirúrgicos y preparación';
      break;
    }
    case '/admision':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Admisión';
      break;
    }
    case '/derechos-normas':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Derechos del paciente y normas de convivencia';
      break;
    }
    case '/visitas-egresos':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Visitas y egresos';
      break;
    }
    case '/nefrologia-dialisis':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Nefrología y diálisis';
      break;
    }
    case '/terapia-intensiva':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Terapia Intensiva';
      break;
    }
    case '/hospital-de-dia':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Hospital de día';
      break;
    }
    case '/egresos':{
      this.entrada_seleccionada = 'Información útil';
      this.subentrada_seleccionada = 'Egresos';
      break;
    }
    case '/register':{
      this.entrada_seleccionada = 'validar';
      this.subentrada_seleccionada = '';
      break;
    }
    default:{
      this.entrada_seleccionada = '';
      this.subentrada_seleccionada = '';
      break;
    }
  }         
}

cerrar_menus(){
  this.entrada_seleccionada = '';
  this.subentrada_seleccionada = '';
}

click_foto(){
  this.entrada_seleccionada = 'Paciente';
  this.subentrada_seleccionada = 'Editar Datos';
}

verificarNombreMenu(nombre_menu){
   if( (nombre_menu == "Paciente") ){
    return true;
   } else {
    return false;
   }
}

noEsCerrarSesion(nombre_submenu){
  return (nombre_submenu != 'Cerrar sesión') ? true : false;
}

logout() {
  if (this.userData.realizoValoracion === 'N' && this.paramPeticionValoracion) {
    this.dialog.open(DialogValoracionUsuarioComponent, {
      width: 'auto',
      data: null,
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe(() => {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      sessionStorage.clear();
    })
  } else {
    this.dialog.open(DialogConfirmacionComponent, {
      //width: '50%',
      data: {
        titulo: 'Confirmar cerrar sesión',
        mensaje: 'cerrar tu sesión',
        estasPor: true      
      },
      hasBackdrop: true,
      enterAnimationDuration: '.5s',
      panelClass: 'dialog-sin-overflow'    
    }).afterClosed().subscribe((confirma: boolean) => {
      if (confirma) {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    });
  }
}

login(){
  this.router.navigate(['/login']);
  sessionStorage.clear();
}

verificar_menu_visitante(nombre_menu){
  if( (nombre_menu == 'Turnos') || (nombre_menu == 'Información útil') ){
    return true;
  } else {
    return false;
  }
}  
cargarEntradasSeleccionadas(){
  this.entrada_seleccionada = sessionStorage.getItem('entrada_seleccionada');
  this.subentrada_seleccionada = sessionStorage.getItem('subentrada_seleccionada');
  //console.log('entrada seleccionada: '+this.entrada_seleccionada);
  //console.log('subentrada_seleccionada: '+this.subentrada_seleccionada);
}
noEsLaultimaSubEntrada(entrada,subentrada){
  let indice = entrada.indexOf(subentrada);
  let tam = entrada.length - 1;  
  return (indice == tam) ? false : true;
}

tieneNombre(){
  let tiene_nombre = true;
  if(this.userData.nombres==null || this.userData.nombres==''){
    tiene_nombre = false;
  }
 
  return tiene_nombre;
}

validacionPendiente() {    
  return (this.userData?.tipoDocumento == '9' && (this.userData.estadoValidacion=='PENDIENTE' || this.userData.estadoValidacion=='CARGANDO')) ;
}
  
validacionSinValidar() {
  return (this.userData?.tipoDocumento == '9' && (this.userData.estadoValidacion!='PENDIENTE' && this.userData.estadoValidacion!='CARGANDO'));
}

goToValidar(){
  this.entrada_seleccionada = 'validar';
  sessionStorage.setItem('entrada_seleccionada', 'validar');
  sessionStorage.setItem('voyAValidar', 'si');
  // this.router.navigate(['/register']);
  window.location.href='/register';
}

}
