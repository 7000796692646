import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService} from '../_services/alert.service';
import { FacturasService} from '../_services/facturas.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { Observable } from 'rxjs';
import { UserService } from '../_services/user.service';

interface factura_y_recibo{
  fecha: string,
  letra: any,
  prefijo: any,
  numero: any,
  descripcion_factura: string | undefined,
  descripcion_recibo: string | undefined,
  mutual_desc: string,
};

class Idatos_validacion{
  estadoValidacion: string;
  tipoDocumento: string;
}
@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.css']
})
export class FacturasComponent implements OnInit {
  facturas: factura_y_recibo[];
  recibos: factura_y_recibo[];
  facturas_y_recibos: factura_y_recibo[];
  selected;
  horusRestfullUrl;
  selectedUrl;
  cargando = true;
  ordenado_desc: boolean;
  datos_validacion: Idatos_validacion = new Idatos_validacion();

  constructor(private facturasService: FacturasService, 
    private urlResolver: UrlResolverService,
    private userService: UserService) { 
    this.ordenado_desc = false;
  }

  ngOnInit() {
    this.userService.getUserData().subscribe(
      x => {
               
        this.datos_validacion.tipoDocumento = x.tipoDocumento;
        this.datos_validacion.estadoValidacion = x.estadoValidacion;
        
        if(this.validacionAprobada()){
          this.getUrl();
          this.recuperar_facturas();   
        }else{
          this.cargando = false;
        }
      }     
    );          
  }

  recuperar_facturas(){
    this.facturasService.getFacturasDeGastos().subscribe(
      data_gastos => {
        this.facturas = data_gastos.facturas;
        this.facturasService.getFacturasDeHonorarios().subscribe( 
          data_honorarios => {
            this.recibos = data_honorarios.recibos;
            this.facturas_y_recibos = this.facturas.concat(this.recibos); // vector que mezcla todas las facturas
            this.facturas_y_recibos = this.facturas_y_recibos.sort(
              (obj1, obj2) => {

                let partes = obj1.fecha.split('/');                    
                let date1 = new Date(parseInt(partes[2]), parseInt(partes[1])-1, parseInt(partes[0]));

                partes = obj2.fecha.split('/');
                let date2 = new Date(parseInt(partes[2]), parseInt(partes[1])-1, parseInt(partes[0]));
                if(this.ordenado_desc){
                  return date1.getTime() - date2.getTime(); // orden ascendente
                } else {
                  return date2.getTime() - date1.getTime(); // orden descendente
                }                    
              }
            ); 
            this.cargando = false;    
          }          

        );     
      }         
    );
  }

  cantidad_facturas(){
    if(this.facturas_y_recibos != null){
      return this.facturas_y_recibos.length;
    } else{
      return 0;
    }
  }

  getUrl(){
    this.urlResolver.getHorusRestfullUrl().subscribe(x => this.horusRestfullUrl = x.mensaje)
    // this.horusRestfullUrl = 'http://localhost/horus_restfull/'
  }

  crearPrefijoLink(prefijo){
    let str = "0000";
    return ((str + prefijo.toString()).substr(prefijo.toString().length));
  }

  crearNumeroLink(numero){
    let str = "00000000";
    return ((str + numero.toString()).substr(numero.toString().length));
  }


  setFactura_de_gastos(factura){
    this.selectedUrl = this.horusRestfullUrl + 'api/facturacionPaciente/imprimirPorPaciente/pdf/FAE' + factura.letra + this.crearPrefijoLink(factura.prefijo) + this.crearNumeroLink(factura.numero) + '.pdf' ;

    if (navigator.userAgent.indexOf("Android") > -1) {
      this.downloadPDF(this.selectedUrl);

    }else{
      this.selected=factura;
   }
  }

  setFactura_de_honorarios(recibo){
    this.selected=recibo;    
    this.selectedUrl = this.horusRestfullUrl + 'api/facturacionPaciente/imprimirPorPaciente/pdf/RAE' + recibo.letra + this.crearPrefijoLink(recibo.prefijo) + this.crearNumeroLink(recibo.numero) + '.pdf' ;
  }

  resetList(){
    this.selected=null;
  }

  downloadPDF(urlPdf) {
    
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute('href', urlPdf);
    downloadLink.setAttribute('download', "factura_gasto");
    downloadLink.setAttribute('target', '_blank');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
   
  }

  cambiar_orden(){
    this.ordenado_desc = !this.ordenado_desc;
    this.recuperar_facturas()
  }

  validacionAprobada(){      
    return this.datos_validacion.tipoDocumento != '9';
  }


  validacionPendiente(){    
    return (this.datos_validacion.tipoDocumento == '9' && (this.datos_validacion.estadoValidacion=='PENDIENTE' ||
    this.datos_validacion.estadoValidacion=='CARGANDO')) ;
  }

  goToValidar(){
    sessionStorage.setItem('voyAValidar', 'si');
    window.location.href='/register';
  }

}
