import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogRecuperarDatosComponent } from '../dialog-recuperar-datos/dialog-recuperar-datos.component';
import { UserService } from '../_services/user.service';
import { first } from 'rxjs/operators';

export interface Datos {
  email: string
}

@Component({
  selector: 'app-dialog-olvide-mis-datos',
  templateUrl: './dialog-olvide-mis-datos.component.html',
  styleUrls: ['./dialog-olvide-mis-datos.component.css']
})
export class DialogOlvideMisDatosComponent implements OnInit {
  recoveredUsers;

  recoverUsernameForm: FormGroup;
  recoveredUsersForm: FormGroup;

  recoverUsernameFormReady = false;

  recoveredUsersFormSubmitted = false;
  respuesta: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog, 
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public email: Datos
  ) { }

  ngOnInit(): void {
    this.recoverUsernameForm = this.formBuilder.group({
      //  dni: ['', [Validators.required, Validators.maxLength(8), Validators.minLength(7), Validators.pattern('[0-9]*')]],
      mail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
    });

    this.recoveredUsersForm = this.formBuilder.group({
      user: ['', Validators.required],
    });

    this.recoverUsernameForm.setValue({
      mail: this.email.email,
    });
  }

  get ru() {
    return this.recoverUsernameForm.controls;
  }
  get rec() {
    //console.log(this.recoveredUsersForm.controls.user.value);
    
    return this.recoveredUsersForm.controls;
  }

  recoverUsername() {
    this.recoveredUsersFormSubmitted = true;
    if (this.recoverUsernameForm.invalid) {
      return;
    }

    this.respuesta = false;
    this.userService.recoverUsername(this.recoverUsernameForm.value).subscribe(
      (data) => {
        this.recoveredUsers = data;        
        if (this.recoveredUsers.length == 1) {
          this.userService
            .sendMail({ user: this.recoveredUsers[0] })
            .pipe(first())
            .subscribe(
              (data) => {
                this.respuesta = true;
                //alert(data.mensaje);
                let msjDialog = {
                  ok: true,
                  mensaje: data.mensaje,
                }
                this.openDialog(msjDialog);
                this.closeRecoveredUsersFormModal();
                this.recoveredUsersFormSubmitted = false;
              },
              (error) => {
                this.respuesta = true;
              }
            );
        } else {
          this.recoverUsernameFormReady = true;
          this.recoveredUsersFormSubmitted = true;
          this.respuesta = true;
        }
      },
      (error) => {
        // this.recoverUsernameForm.controls['dni'].setErrors({'incorrect': true});

        this.respuesta = true;
        this.recoverUsernameForm.controls['mail'].setErrors({
          incorrect: true,
        });
      }
    );
  }

  onSubmitRecoveredUsersForm() {
        
    this.recoveredUsersFormSubmitted = true;
    this.respuesta = false;
    if (this.recoveredUsersForm.invalid) {
      return;
    }
    // this.supportMessageLoading = true;
    this.userService
      .sendMail(this.recoveredUsersForm.value)
      .pipe(first())
      .subscribe(
        (data) => { 
          this.respuesta = true;
          let msjDialog = {
            ok: true,
            mensaje: data.mensaje,
          }
          this.cerrarDialog();
          this.openDialog(msjDialog);
          //this.closeRecoveredUsersFormModal();
          this.recoveredUsersFormSubmitted = false;
        },
        (error) => {
          let msjDialog = {
            ok: false,
            mensaje: 'Hubo un error enviando el correo. Intente nuevamente más tarde. Si el problema persiste comuníquese con Soporte Técnico.',
          }
          
          this.cerrarDialog();
          this.openDialog(msjDialog);
          //this.respuesta = true;
          this.recoveredUsersFormSubmitted = false;
        }
      );
  }

  closeRecoveredUsersFormModal() {
    document.getElementById('openRecuperarUsuarioModalButton').click();
    this.recoverUsernameForm.reset();
    this.recoveredUsersForm.reset();
    this.recoverUsernameFormReady = false;
    this.recoveredUsersFormSubmitted = false;
  }

  openDialog(respuesta){
    this.dialog.open(DialogRecuperarDatosComponent, {
      width: '580px',
      data: {ok:respuesta.ok, mensaje:respuesta.mensaje},
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe(
      () => {
        window.location.href = '/login';
      }
    )
  }

  cerrarDialog(){    
    this.dialog.closeAll();
  }
}
