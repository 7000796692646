import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../_services/user.service';
import { AppComponent } from '../app.component';
import { TurnoEnAtencion, Turno } from '../mis-reservas/mis-reservas.component';
import { log } from 'console';

@Injectable({ providedIn: 'root' })
export class TurnosService {
  private baseUrl: string;
  userData;
  private sucursal;
  private datosTurno: Turno;
  private especialidad;
  private servicio;
  private consultorio;
  profesional;
  fechaHora;
  private matricula: number;
  private fecha;
  private desdeHora;
  private orden;
  private linkTeleconsulta;
  turnoParaAdmision: any;

  constructor(private http: HttpClient, private userService: UserService) {
    this.baseUrl = AppComponent.testUrl;
    this.sucursal = AppComponent.sucursal;
    this.getUserData();
  }

  setLink(linkTeleconsulta: string) {
    this.linkTeleconsulta = linkTeleconsulta;
  }

  getLink() {
    return localStorage.getItem('linkTeleconsulta_turno');
  }

  setConsultorio(consultorio: string) {
    this.consultorio = consultorio;
  }

  getConsultorio() {
    return localStorage.getItem('consultorio_turno');
  }

  setServicio(servicio: string) {
    this.servicio = servicio;
  }

  getServicio() {
    return localStorage.getItem('servicio_turno');
  }

  setEspecialidad(especialidad: string) {
    this.especialidad = especialidad;
  }

  getEspecialidad() {
    return localStorage.getItem('especialidad_turno');
  }

  setProfesional(profesional: string) {
    this.profesional = profesional;
    }

  getProfesional() {    
    return localStorage.getItem('profesional_turno');
  }

  setFechaHora(fecha: string, hora: string) {
    this.fechaHora = fecha + ' - ' + hora;
  }

  getFecha() {
    return localStorage.getItem('fecha_turno');
  }

  getHora() {
    return localStorage.getItem('hora_turno');
  }

  setMatricula(matricula: number) {
    this.matricula = matricula;
  }

  getMatricula() {
    return localStorage.getItem('matricula_turno');
  }

  setFecha(fecha: string) {
    this.fecha = fecha;
  }

  setDesdeHora(desdeHora: string) {
    this.desdeHora = desdeHora;
  }

  getDesdeHora() {
    return localStorage.getItem('desdeHora_turno');
  }

  setOrden(orden: number) {
    this.orden = orden;
  }

  getOrden() {
    return localStorage.getItem('orden_turno');
  }

  getUserData() {
    this.userService.userData.subscribe(
      (x) => {
        this.userData = x;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getEspecialidades(): Observable<any> {
    this.getUserData();

    return this.http.get<any>(this.baseUrl + '/especialidades');
  }

  getEspecialidadesSinAuth(): Observable<any> {
    this.getUserData();

    return this.http.get<any>(this.baseUrl + '/especialidades-sin-auth');
  }

  getProfesionales(): Observable<any> {
    let body = new FormData();
    body.append('sucursal', this.sucursal);
    return this.http.post<any>(this.baseUrl + '/profesionales', body);
  }

  getProfesionalesPorEspecialidad(codEspe, idCondicion): Observable<any> {
    let params = new HttpParams()
      .set('matricula', null)
      .set('servicio', null)
      .set('especialidad', codEspe)
      .set('condicion', idCondicion)
      .set('estado', 'ON'); //Create new HttpParams
    return this.http.get<any>(this.baseUrl + '/profesionales-filtro', {
      params,
    });
  }

  getTurnosPorEspecialidad(especialidad, matricula) {
    let body = new FormData();
    body.append('codigo', especialidad.codigo);
    body.append('matricula', matricula);

    return this.http.post<any>(this.baseUrl + '/turnos', body);
  }

  getEestablecimientos() {
    return this.http.get<any>(this.baseUrl + '/establecimientos');
  }

  getHorariosProfesional(profesional, sucursal) {
    let body = new FormData();
    body.append('matricula', profesional.nroMatricula);
    body.append('usuario', this.userData.usuario);
    body.append('sucursal', this.sucursal);

    return this.http.post<any>(this.baseUrl + '/horarios-profesional', body);
  }
  /*   getDiasAtencionProfesional(profesional) {
    let body = new FormData();
    body.append('matricula', profesional.nroMatricula);

    return this.http.post<any>(
      this.baseUrl + '/dias-atencion-profesional',
      body
    );
  } */

  getTurnosProfesional(profesional, diaAtencion) {
    let body = new FormData();
    body.append('matricula', profesional.nroMatricula);
    body.append('horaDesde', diaAtencion.str_horaInicioAtencion);
    body.append('fecha', diaAtencion.str_diaPrimerTurnoLibre);
    body.append('nombreDia', diaAtencion.nombreDia);
    body.append('desdeVigencia', diaAtencion.str_vigenciaDesde);
    body.append('serv', 'consultorio'); //HARDCODED --- CAMBIAR DESPUES
    body.append('usuario', this.userData.usuario);

    return this.http.post<any>(this.baseUrl + '/turnos-profesional', body);
  }

  tomarTurno(bloqueTurno) {
    //CONSULTARLE A MACERI SOBRE ESTO DEL USUARIO
    this.getUserData();
    let body = new FormData();
    body.append('matricula', bloqueTurno.matricula);
    body.append('str_horaDesde', bloqueTurno.str_horaDesde);
    body.append('str_fecha', bloqueTurno.str_fecha);
    body.append('nroTurno', bloqueTurno.numero);
    body.append('usuario', localStorage.getItem('username'));
    body.append('desde_vigencia', bloqueTurno.str_desdeVigencia);

    // console.log(body);
    
    return this.http.post<any>(this.baseUrl + '/tomar-turno', body);
  }

  liberarTurno(bloqueTurno) {
    let body = new FormData();
    body.append('matricula', bloqueTurno.matricula);
    body.append('str_horaDesde', bloqueTurno.str_horaDesde);
    body.append('str_fecha', bloqueTurno.str_fecha);
    body.append('nroTurno', bloqueTurno.numero);
    body.append('usuario', this.userData.usuario);

    return this.http.post<any>(this.baseUrl + '/liberar-turno', body);
  }

  confirmarTurno(bloqueTurno, mutualElegida) {
    if (mutualElegida == undefined) {
      mutualElegida = { mutual: 14, nroCarnet: null };
    }
    let body = new FormData();
    body.append('matricula', bloqueTurno.matricula);
    body.append('str_horaDesde', bloqueTurno.str_horaDesde);
    body.append('str_fecha', bloqueTurno.str_fecha);
    body.append('nroTurno', bloqueTurno.numero);
    body.append('usuario', this.userData.usuario);
    // body.append('sucursal', bloqueTurno.codEstablecimiento);
    body.append('sucursal', '1');
    body.append('cobertura', mutualElegida.mutual);
    body.append('nroCarnet', mutualElegida.nroCarnet);

    return this.http.post<any>(this.baseUrl + '/confirmar-turno', body);
  }

  getHistoricoPaciente() {
    let body = new FormData();
    body.append('sucursal', this.sucursal);

    return this.http.post<any>(
      this.baseUrl + '/turnos-paciente-historico',
      body
    );
  }

  getTurnosReservadosPaciente() {
    return this.http.get<any>(this.baseUrl + '/turnos-paciente');
  }

  cancelarTurno(bloqueTurno) {
    let body = new FormData();
    body.append('matricula', bloqueTurno.matricula);
    body.append('str_horaDesde', bloqueTurno.str_horaDesde);
    body.append('str_hora', bloqueTurno.str_hora);
    body.append('str_fecha', bloqueTurno.str_fecha);
    body.append('nroTurno', bloqueTurno.numero);
    body.append('usuario', this.userData.usuario);
    body.append('nombreDia', bloqueTurno.nombre);
    body.append('sucursal', bloqueTurno.codEstablecimiento);

    return this.http.post<any>(this.baseUrl + '/cancelar-turno', body);
  }

  cancelarTurnoExt(turno) {
    let body = new FormData();
    
    body.append('matricula', turno.matricula);
    body.append('str_horaDesde', turno.str_horaDesde);
    body.append('str_hora', turno.str_hora);
    body.append('str_fecha', turno.str_fecha);
    body.append('nroTurno', turno.numero);
    body.append('usuario', turno.usuario);
    body.append('profesional', turno.profesional);
    body.append('especialidad', turno.especialidad);
    body.append('nombreDia', turno.nombre);
    body.append('categoria', turno.categoria);
    body.append('sucursal', turno.codEstablecimiento);

    return this.http.post<any>(this.baseUrl + '/cancelar-turno-externo', body);
  }

  getTurnosAtencion(turno) {
    let body = new FormData();

    body.append('matricula', turno.matricula);
    body.append('fecha', turno.str_fecha);
    body.append('desde_hora', turno.str_horaDesde);
    body.append('desde_vigencia', turno.str_desdeVigencia);

    return this.http.post<TurnoEnAtencion>(this.baseUrl + '/turnos-atencion', body);
  }

  habilitarWebcheckin(turno) {
    let body = new FormData();

    body.append('matricula', turno.matricula);
    body.append('fecha', turno.str_fecha);
    body.append('desde_hora', turno.str_horaDesde);
    body.append('desde_vigencia', turno.str_desdeVigencia);
    body.append('orden', turno.numero);

    return this.http.post<any>(this.baseUrl + '/habilitar-web-checkin', body);
  }

  // SETEA X MUTUAL-CARNET AL TURNO
  setMutualDelTurno(mutualTurno) {
    let body = new FormData();

    body.append('matricula', mutualTurno.matricula);
    body.append('fecha', mutualTurno.fecha);
    body.append('desdeHora', mutualTurno.desdeHora);
    body.append('orden', mutualTurno.orden);
    body.append('idMutual', mutualTurno.idMutual);
    body.append('carnet', mutualTurno.nroCarnet);
        
    return this.http.post<any>(this.baseUrl + '/setMutualDelTurno', body);
  }

  // SETEA MUTUAL PARTICULAR AL TURNO
  setMutualTurnoParticular(mutualTurno) {
    let body = new FormData();

    body.append('matricula', mutualTurno.matricula);
    body.append('fecha', mutualTurno.fecha);
    body.append('desdeHora', mutualTurno.desdeHora);
    body.append('orden', mutualTurno.orden);

    return this.http.post<any>(this.baseUrl + '/setMutualTurnoParticular', body);
  }

  verificarMutualProf(matricula, mutual) {
    let body = new FormData();

    body.append('matricula', matricula);
    body.append('mutual', mutual);

    return this.http.post<any>(this.baseUrl + '/verificarMutualProf', body);
  }

  verifProfConvenio(matricula, financiador) {
    let body = new FormData();

    body.append('matricula', matricula);
    body.append('financiador', financiador);

    return this.http.post<any>(this.baseUrl + '/verificarProfConvenio', body);
  }

  getTurnoUnico(claveTurno) {
    let body = new FormData();

    body.append('matricula', claveTurno.matricula);
    body.append('orden', claveTurno.orden);
    body.append('fecha', claveTurno.fecha);
    body.append('desdeHora', claveTurno.desdeHora);

    return this.http.post<any>(this.baseUrl + '/obtener-unico-turno', body);
  }

  getProfesionalesHorariosWeb(matricula): Observable<any> {
    let params = new HttpParams()
      .set('matricula', matricula)
    return this.http.get<any>(this.baseUrl + '/profesionales-horarios-web', {params});
  }

  getTurnosHorariosFiltros(codigoEsp, matricula, codigoSuc, idFinanciador,desde,cant,filtrarTurnoUno) {
    let params = new HttpParams()
    .set('codigoEsp', codigoEsp)
    .set('matricula', matricula)
    .set('codigoSuc', codigoSuc)
    .set('financiador', idFinanciador)
    .set('desde', (desde/cant)+1)
    .set('cant', cant)
    .set('filtrarTurnoUno',filtrarTurnoUno);
    return this.http.get<any>(this.baseUrl + '/getTurnosHorariosFiltros',{params});
  }

  /* nuevo */ 
  getProfesionalesHorariosFiltros(codigoEsp, matricula, codigoSuc, idFinanciador,filtrarTurnoUno,filtrarTurnosIOMA){
    let params = new HttpParams()
    .set('codigoEsp', codigoEsp)
    .set('matricula', matricula)
    .set('codigoSuc', codigoSuc)
    .set('financiador', idFinanciador)
    .set('filtrarTurnoUno',filtrarTurnoUno)
    .set('filtrarTurnosIOMA',filtrarTurnosIOMA);
    return this.http.get<any>(this.baseUrl + '/getProfesionalesHorariosFiltros', {params});
  }

  // nuevo 
  getProfesionalHorarioTurnos(agendas){
    let params = new HttpParams()
    .set('agendas', JSON.stringify(agendas))
    return this.http.get<any>(this.baseUrl + '/getProfesionalHorarioTurnos', {params});
  }

  getPacienteTurnoUno(username) {
    let params = new HttpParams()
    .set('username', username);
    return this.http.get<any>(this.baseUrl + '/getPacienteTurnoUno',{params});
  }

  setTurnoAdmision(turno) {
    this.turnoParaAdmision = turno;
  }

  getTurnoAdmision() {
    return this.turnoParaAdmision;
  }

  setIdMutual(idMutual){
    localStorage.setItem('idMutual_turno', idMutual)
  }

  setNroCarnet(nroCarnet){
    localStorage.setItem('nroCarnet_turno', nroCarnet)
  }

  getIdMutual(){
    return localStorage.getItem('idMutual_turno')
  }

  getNroCarnet(){
    return localStorage.getItem('nroCarnet_turno')
  }
}
