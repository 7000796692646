import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { UserService } from '../_services/user.service';
import { AuthenticationService } from '../_services/authentication.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-terminos-y-condiciones',
  templateUrl: './dialog-terminos-y-condiciones.component.html',
  styleUrls: ['./dialog-terminos-y-condiciones.component.css']
})
export class DialogTerminosYCondicionesComponent implements OnInit {

  termsForm: FormGroup;
  termsFormSubmitted = false;
  userData;
  currentUser;
  loading = true;
  loadingSubmit=false;
  selectedUrl;

  constructor(  
    private fb: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<DialogTerminosYCondicionesComponent>,
    public dialogs:MatDialog,
    public router: Router
  ) {
   }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.obtenerDatosUsuario();
    this.crearFormulario();
    
  }

  crearFormulario (){
    this.termsForm = this.fb.group({
      check: [false, Validators.required],
    });
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then((response) => {
      this.userData = response;
      this.loading = false;
    }, (error) => {
      console.log(error);
    });
  }

  onSubmit() {
    this.loadingSubmit = true;
    this.userService.aceptarTerminos().subscribe((respuesta) => {
      if (respuesta.ok) {
        this.userService.registrarTerminosYCondiciones(this.userData).subscribe((resp) => {
          if (resp) {
            this.currentUser.terminosYCondiciones = 'ACE';
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            this.cerrarDialogo(true);
          }
        }, (error) => {
          console.log(error);
        });
      }
      this.loadingSubmit = false;
    }, (error) => {
      console.log(error);
      this.loadingSubmit = false;
    });
  }

  cerrarDialogo(aceptado) {
    this.dialogRef.close(aceptado);
  }
  
  cerrarTodosDialogos(){
    this.dialogs.closeAll();
  }

}
