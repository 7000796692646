import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-web-checkin',
  templateUrl: './web-checkin.component.html',
  styleUrls: ['./web-checkin.component.css'],
})

export class WebCheckinComponent implements OnInit {
  disabled: boolean = true;
  datosUsuario: string;
  fechaNacArray: string[];
  fechaNac: string;
  sexo: string;
  guardadoDatosPers: boolean;
  cargando: boolean = true;

  webCheckInForm = new FormGroup({
    paciente: new FormControl(''),
    tipoDni: new FormControl(''),
    nroDni: new FormControl(''),
    telefono: new FormControl(''),
    domicilio: new FormControl(''),
    localidad: new FormControl(''),
  });

  constructor(
    private turnoService: TurnosService,
    private userService: UserService, 
    private router: Router,
    private dialog: MatDialog
  ) {
  
  }

  ngOnInit() {
    this.obtenerDatosUsuario();
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then((respuesta) => {
      // console.log(respuesta);
      this.webCheckInForm = new FormGroup({
        paciente: new FormControl({value: respuesta.apellido, disabled: true}),
        tipoDni: new FormControl({value: respuesta.tipoDocumento, disabled: true}),
        nroDni: new FormControl({value: respuesta.nroDocumento, disabled: true}),
        telefono: new FormControl({value: respuesta.telefonoParticular, disabled: false}),
        domicilio: new FormControl({value: respuesta.domicilio, disabled: false }),
        localidad: new FormControl({value: respuesta.localidad, disabled: false }),
      });
      this.cargando = false;
    }, (error) => {
      console.log(error);
    });
  }

  async guardarFormWebCheckIn() {
    this.cargando = true;

    await this.userService.guardarDatosUsuario(this.webCheckInForm.value).toPromise().then((respuesta) => {
      // console.log(respuesta);
      if (respuesta.ok) {
        this.irAutorizarCobertura();
      } else {
        this.dialog.open(DialogMensajeComponent, {
          data: {
            ok: false,
            mensaje: "Ocurrio un error al guardar sus datos. Intente nuevamente."
          },
          panelClass: 'dialog-sin-overflow'
        })
        this.cargando = false;
      }
    }, (error) => {
      console.log(error);
      this.dialog.open(DialogMensajeComponent, {
        data: {
          ok: false,
          mensaje: "Ocurrio un error al guardar sus datos. Intente nuevamente."
        },
        panelClass: 'dialog-sin-overflow'
      })
      this.cargando = false;
    });
  }

  cancelar() {
    this.turnoService.setTurnoAdmision(null);
    this.router.navigate(['turnos-reservados']);
    // window.location.href='/turnos-reservados';
  }

  irAutorizarCobertura() {
    this.router.navigate(['autorizar-cobertura']);
    // window.location.href='/autorizar-cobertura';
  }
}
