<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Radiografía (Adultos y Pediátricos)</h4>
        <div class="container">
  
          <p>
            Para solicitar turno por orden de llegada de lunes a
            viernes de 8:00 a 20:00hs y sábados de 8:00 a 13:00hs.
          </p>
  
          <p><strong>Equipo Médico: </strong></p>
  
          <p>Pedriátrico: Dra. Paula Delgado | Dra. Maricel De Battista </p>
          <p>Adultos: Dr. Carlos Nass | Dr. Julio Tello | Dr. Francisco Miraglia | Dr. Luis Illanes | Dr. Marcelo Sabbione </p>
  
        </div>
      </div>
    </div>
  </div>
  
</body>

</html>