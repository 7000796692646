
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogMensajeComponent, Respuesta } from '../dialog-mensaje/dialog-mensaje.component';
import { EstudiosService } from '../_services/estudios.service';
import { AuthenticationService } from '../_services/authentication.service';

import { UserService } from '../_services/user.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

@Component({
  selector: 'app-estudios-externos',
  templateUrl: './estudios-externos.component.html',
  styleUrls: ['./estudios-externos.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class EstudiosExternosComponent implements OnInit {
  patient: any;
  currentUser: any;
  fileName = '';
  form: FormGroup;
  maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  loading = false;
  submitted: boolean;
  file: File;
  output: String;

  constructor(
    private userService: UserService,
    private estudioService: EstudiosService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    
  }

  async ngOnInit() {
    await this.getUserData();
    this.currentUser = this.authService.currentUserValue;
    this.initForm();    
  }

  async getUserData() {
    await this.userService.userData.toPromise().then((userData) => {
      this.patient = userData;      
      this.loading = true;      
    },
    (error) => {
      console.log(error);
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      description:['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 .,]*$/)]],
      date: ['', [Validators.required]],
      file: ['', [Validators.required]]
    });
  }


  onFileSelected(event) {    
    this.file = event.target.files[0];    
    if (this.file) {
        this.fileName = this.file.name;        
    }
  }
  
  saveData(){    
    this.submitted = true;    
    if (this.form.dirty) {//SI EL USUARIO MODIFICO, DIRTY = TRUE  
      if (this.form.valid){
      this.output = JSON.stringify(this.buildingJson());  
      // console.log(this.output)    
      
      this.estudioService.guardarRegistroHCEPacientes(this.output, this.file).subscribe((res) => {
              this.openDialog(res)    
              // console.log(res)       
          },
          (error) => {
            console.log('error' + error);
          }
        );
      }
    }
    else
    {
      this.dialog.open(DialogMensajeComponent, {
        width: '500px',
        data: {
          ok: false,
          mensaje: 'Por favor, complete los campos',
        },
        panelClass: 'dialog-sin-overflow'
      })
    }
  } 

  get getForm() {
    return this.form.controls;
  }


  buildingJson(){
    return {
      tipoRegistro: {tipoRegistroId: 43},
      tipoDocumento: parseInt(this.patient.tipoDocumento), 
      numeroDocumento: parseInt(this.patient.nroDocumento),
      descripcion: this.form.value.description,
      fechaRegistro: moment(this.form.value.date).format("DD/MM/YYYY"),
      matriculaAutor: 0,
      tipoEpisodio: 'registro_pacientes',
      UsuarioModifico: this.patient.usuario,
      referenciaDocumento: this.patient.tipoDocumento + '_' + this.patient.nroDocumento + '_' + Math.floor(1000 + Math.random() * 9000)
      // estado: 'ACT',
      // login_estado: this.patient.usuario,
      // fecha_hora_estado: new Date()
    }
  }

  getErrorMessageDescription() {
    return this.form.controls.description.hasError('pattern') ? 'Ingrese solo letras, numeros, puntos y comas' :        
        this.form.controls.description.hasError('required') ? 'La descripcion es obligatoria' : '';
  }

  getErrorMessageDate() {
    return this.form.controls.date.hasError('required') ? 'La fecha es obligatoria': '';
  }

  getErrorMessageFile() {
    return this.form.controls.file.hasError('required') ? 'Debe adjuntarse un archivo .PDF': '';
  }

  openDialog(res: Respuesta): void {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: res,
      panelClass: 'dialog-sin-overflow'
    }).   
   afterClosed().subscribe(() => {
      if (res.ok) {        
        // this.router.navigate(['/historial-clinico']);
        window.location.href='/historial-clinico';
      }
    });
  }

  back(){
    this.dialog.open(DialogConfirmacionComponent, {
      data: {
        titulo: 'Confirmar acción',
        mensaje: 'detener la carga del documento',        
        estasPor: true
      },
      panelClass: 'dialog-sin-overflow'
    })
    .afterClosed()
    .subscribe((confirmado: boolean) => {
      if (confirmado) {
        // this.router.navigate(['/historial-clinico']);        
        window.location.href='/historial-clinico';
      }
    });
  }



}


