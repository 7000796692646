import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { error } from 'protractor';
import { EstudiosService } from '../_services/estudios.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { UserService } from '../_services/user.service';

export interface UserData {
  nroDocumento: string;
  tipoDocumento: string;
  usuario: string;
  sucursal: string;
}

@Component({
  selector: 'app-visualizar-historial',
  templateUrl: './visualizar-historial.component.html',
  styleUrls: ['./visualizar-historial.component.css'],
})
export class VisualizarHistorialComponent implements OnInit {
  hashDecode: string;
  arrayString: string[];
  userData = {} as UserData;
  estudios: any = [];
  horusRestfullUrl: any;
  hashKey: string;
  hashInvalido: boolean = false;
  hashValido: boolean = false;
  cargandoInfo: boolean = true;
  estudiosImagenes;
  random;
  selectedEstudio;
  selectedEstudios;
  urlEstudioPdf;
  // urlEstudioPdfComp: string;
  esAndroid: boolean;
  // existePDFComp: boolean = true;
  existePDFEstudio: boolean = true;
  userDataExt = {};
  verUserData: boolean = false;
  existeImagen: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private estudiosService: EstudiosService,
    private urlResolver: UrlResolverService,
    private userService: UserService
  ) {
    this.getUrl();
    this.getMobile();
  }

  ngOnInit() {}

  getMobile() {
    if (/android/i.test(navigator.userAgent)) {
      this.esAndroid = true;
    } else {
      this.esAndroid = false;
    }
  }

  getUrl() {
    this.urlResolver.getHorusRestfullUrl().subscribe(
      (respGetUrlHR) => {
        if (respGetUrlHR.ok) {
          this.horusRestfullUrl = respGetUrlHR.mensaje;
          this.getHash(this.horusRestfullUrl);
        } else {
          this.cargandoInfo = false;
          this.hashInvalido = true;
        }
      },
      (error) => {
        console.log(error);
        this.cargandoInfo = false;
        this.hashInvalido = true;
      }
    );
    // this.horusRestfullUrl =  'http://localhost/horus_restfull';
  }

  getHash(horusRestfullUrl) {
    this.hashKey = this.route.snapshot.url[1].path;
    this.hashDecode = atob(this.route.snapshot.url[1].path);
    this.arrayString = this.hashDecode.split('-');

    this.userData.usuario = this.arrayString[0];
    this.userData.tipoDocumento = this.arrayString[1];
    this.userData.nroDocumento = this.arrayString[2];
    this.userData.sucursal = this.arrayString[3];

    this.userService.getUserDataExt(this.userData.usuario).subscribe(
      (respGetUserDataExt) => {
        if (respGetUserDataExt) {
          this.userDataExt = respGetUserDataExt;
          this.verUserData = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.estudiosService
      .getHashEstudio(this.hashKey, horusRestfullUrl)
      .subscribe(
        (respGetHash) => {
          if (respGetHash.ok) {
            this.getHistorialClinicoExterno(this.userData, horusRestfullUrl);
          } else {
            this.cargandoInfo = false;
            this.hashInvalido = true;
          }
        },
        (error) => {
          console.log(error);
          this.cargandoInfo = false;
          this.hashInvalido = true;
        }
      );
  }

  getHistorialClinicoExterno(userData, horusRestfullUrl) {
    this.estudiosService
      .getHistorialClinicoExterno(userData, horusRestfullUrl)
      .toPromise()
      .then(
        (data) => {
          if (data.paginador.elementos.length > 0) {
            this.estudios = data.paginador.elementos.sort(
              (a, b) =>
                <any>new Date(b.fecha).getTime() -
                <any>new Date(a.fecha).getTime()
            );
            this.hashValido = true;
          } else if (data.paginador.elementos.length == 0) {
            this.hashInvalido = true;
          }
          this.cargandoInfo = false;
        },
        (error) => {
          console.log(error);
          this.cargandoInfo = false;
          this.hashInvalido = true;
        }
      );
  }

  setSelectedEstudio(estudio) {
    // this.msjeEstad = 'El usuario ' + this.userData.usuario + ' visualizo el estudio numero: ' +estudio.idEpisodio;
    // this.funEstad = 'FX_VisualizarEstudio';

    // this.userService.guardarEstadistica(this.msjeEstad, this.funEstad).subscribe(respGuardarEst => {
    // }, error => {
    //   console.log(error)
    // });

    if (estudio.tipoEpisodio != 'Internacion') {
      
      this.random = Math.floor(Math.random() * 9999999999) + 1000000000;
      this.getEstudio(estudio.idEpisodio).subscribe((x) => {
        this.selectedEstudio = x;
        this.selectedEstudios = x.estudiosPaciente;
        
        this.selectedEstudios.forEach(element => {
          this.getImgAurora(element, this.selectedEstudio);
        });
        
        this.selectedEstudio.idCifrado = this.selectedEstudio.idCifrado.replace(/\//g, '_'); //reemplazo la / de la url 

        this.urlEstudioPdf =
          this.horusRestfullUrl +
          '/api/historiaClinica/pedidos/imprimirparapacientes/pdf/' +
          this.selectedEstudio.idCifrado +
          '/' +
          this.selectedEstudio.idCifrado +
          '/' +
          this.random;
        // this.urlEstudioPdfComp =
        //   this.horusRestfullUrl +
        //   '/api/historiaClinica/pedidos/imprimirInformeComplementarioPaciente/pdf/' +
        //   this.selectedEstudio.idCifrado +
        //   '/' +
        //   this.selectedEstudio.idCifrado +
        //   '/' +
        //   this.random;

        if (this.esAndroid) {
          this.estudiosService.getEstudioPDF(this.urlEstudioPdf).subscribe(
            (resp) => {
              if (resp == null) {
                this.existePDFEstudio = false;
              }
            },
            (error) => {
              console.log(error);
            }
          );
          // this.estudiosService
          //   .getInfoComplPDF(this.urlEstudioPdfComp)
          //   .subscribe(
          //     (respComp) => {
          //       if (respComp == null) {
          //         this.existePDFComp = false;
          //       }
          //     },
          //     (error) => {
          //       console.log(error);
          //     }
          //   );
        }
      });
    } else {
      this.showEpicrisis(estudio);
    }
  }

  async getImgAurora(estudio, pedido) {
    // console.log(estudio)

    estudio.pedido = {
      numero: pedido.numero
    }

    await this.estudiosService.getUrlImgAurora(estudio).toPromise().then(resp => {
      // console.log(resp)
      estudio.estadoImgAurora = resp.error;
      estudio.urlAurora = resp.url;
    }, error => {
      console.log(error)
    })
  }

  getEstudio(estudioId) {
    return this.estudiosService.getEstudio(estudioId);
  }

  getImagenEstudio(estudio) {
    if (estudio.item) {
      return this.estudiosImagenes[estudio.item - 1] != 0;
    }
  }

  showEpicrisis(estudio) {
    this.random = Math.floor(Math.random() * 9999999999999) + 1000000000000;
    var win = window.open(
      this.horusRestfullUrl +
        '/api/internacion/epicrisis/imprimirporpaciente/pdf/' +
        estudio.idEpisodio +
        '/' +
        this.random,
      '_blank'
    );
    win.focus();
  }

  refreshHistory() {
    this.selectedEstudio = null;
    // this.existePDFComp = true;
    this.existePDFEstudio = true;
  }
}
