<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Hemodinamia</h4>
  
        <div class="container">
          <img
            class="img-fluid mx-auto d-block mb-2"
            src="../../assets/images/hemodinamia/angiografo.jpg"
          />
          <h5>Prestaciones</h5>
          <p><strong>Angiografía y angioplastía coronaria</strong></p>
  
          <ul>
            <li>Ecografía vascular endoluminal.</li>
            <li>Angiografías coronarias.</li>
            <li>Rotablator.</li>
            <li>Angioplastías coronarias.</li>
          </ul>
  
          <p><strong>Neurointervencionismo</strong></p>
          <ul>
            <li>Angioplastías de carótidas, vertebrales e intracerebrales.</li>
            <li>
              Embolizaciones (aneurismas cerebrales, malformaciones vasculares,
              tumores).
            </li>
          </ul>
  
          <p><strong>Filtro de vena cava permanente y transitorio </strong></p>
  
          <p>
            <strong
              >Angiografía y angioplastía en el infarto agudo de miocardio</strong
            >
          </p>
  
          <p>
            <strong>Angiografía y angioplastía periférica y esplácnica</strong>
          </p>
  
          <ul>
            <li>
              Angiografía y angioplastía de miembros inferiores y superiores.
            </li>
            <li>Angiografía y angioplastía de troncos supraórticos.</li>
            <li>Angiografía y angioplastía de territorios esplácnicos.</li>
            <li>Embolizaciones y quimioembolizaciones.</li>
            <li>Angiografía y angioplastía renales.</li>
            <li>Dosaje de renina.</li>
          </ul>
  
          <p><strong>Valvuloplastías</strong></p>
          <p><strong>Tratamiento endovascular de aneurisma de aorta</strong></p>
          <p><strong>Electrofisiología</strong></p>
          <ul>
            <li>Estudios diagnósticos.</li>
            <li>Ablación por radiofrecuencia.</li>
            <li>Marcapasos.</li>
            <li>Cardiodesfibriladores.</li>
            <li>Prácticas pediátricas</li>
          </ul>
  
          <p><strong>Angiografía y angioplastía</strong></p>
          <ul>
            <li>Cateterismo cardíaco diagnóstico y terapéutico.</li>
            <li>
              Angioplastía de estenosis de ramas de arteria pulmonar, de
              coartación de aorta y angioplastía periférica.
            </li>
            <li>Valvuloplastía pulmonar, aórtica y mitral.</li>
            <li>
              Embolizaciones, cierre de ductus y cierre de comunicaciones
              interauriculares.
            </li>
          </ul>
  
          <p>
            Profesionales especializados (cardioangiólogos) en intervenciones
            cardiovasculares mínimamente invasivas con tecnología de última
            generación crean un Área preparada para el diagnóstico y tratamiento
            de enfermedades vasculares de todos los territorios del organismo
            (cerebral, abdominal, cardiopulmonar, miembros superiores e
            inferiores, entre otros.)
          </p>
          <p>
            La estructura funcional permite atender pacientes agudos de urgencia
            durante las 24 horas, contando para ello no sólo con los profesionales
            médicos, sino con personal paramédico especializado (enfermeras,
            técnicos): todos los pacientes con cuadro de preinfarto o infarto
            agudo, rotura de aneurismas de aorta, obstrucciones agudas de
            arterias, sangrados intestinales, uterinos, etc., pueden ser tratados
            en la urgencia.
          </p>
          <p>
            Se cuenta además con el apoyo experimentado de la Unidad Coronaria y
            de Terapia General, equipos quirúrgicos cardíaco y vascular general,
            electrofisiólogos y cardioangiólogos pediátricos para el tratamiento
            de las enfermedades congénitas y urgencias neonatales.
          </p>
        </div>
      </div>
    </div>
  </div>
</body>

</html>