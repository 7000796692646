
<div class="d-flex justify-content-center" *ngIf="cargando">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!cargando">
    <!-- HEADER -->
    <div class="modal-header text-center">
        <h5 class="subtitulo" id="exampleModalLabel" [hidden]="!turnoAutorizar.realizoWebCheckIn"> Admisi&oacute;n Digital - Paso 4 </h5>
        <h5 class="subtitulo" id="exampleModalLabel" [hidden]="turnoAutorizar.realizoWebCheckIn"> Admisi&oacute;n Digital - Paso 5 </h5>
    </div>

    <!-- BODY -->
    <div class="modal-body">
        <div>
            <p> Ser&aacute;s llamado/a por el/la profesional en la sala de espera del Consultorio {{ turnoAutorizar.descripConsultorio }}. </p>
        </div>
    </div>

    <!-- FOOTER -->
    <div class="modal-footer">
        <button
            mat-raised-button color="primary"
            type="button"
            (click)="finalizar()"
        >
            Finalizar
        </button>
    </div>
</div>
