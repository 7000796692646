import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-soporte-tecnico',
  templateUrl: './dialog-soporte-tecnico.component.html',
  styleUrls: ['./dialog-soporte-tecnico.component.css']
})
export class DialogSoporteTecnicoComponent implements OnInit {
  supportMessageForm: FormGroup;
  supportMessageSubmitted: boolean;
  supportMessageLoading: boolean;
  alerta: any = {};
  supportMessageSent: boolean;
  motivo: String;
  tiposDocumentos: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    const emailPattern: RegExp = /^[a-zA-Z0-9-_.]([\.]?[a-zA-Z0-9-_.])+@[a-zA-Z0-9]([^@&%$\/\(\)=?¿!\.,:;\s]|\d)+[a-zA-Z0-9]([\.][a-zA-Z]{2,})+$/

    this.supportMessageForm = this.formBuilder.group({
      nombreApellido: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$')],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(emailPattern),
        ],
      ],
      asunto: [''],
      mensaje: ['', Validators.required],
      motivo: [this.data.openedFromAyudaDialog ? 'login':'', Validators.required], // Preseleccionar solo si se abrió desde el diálogo de ayuda
      tipoDocumento: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
      nombreUsuario: ['', Validators.required]
    });
    this.recuperarTiposDeDocumentos();
    if (this.data.openedFromAyudaDialog == true){
      this.motivo= 'login'
    }
  }

  enviar() {
    this.supportMessageSubmitted = true;
    this.alerta = {};
    
    if (this.supportMessageForm.value.motivo === 'login'){
      if (this.supportMessageForm.invalid || this.sp.email.errors?.pattern || this.sp.nombreApellido.errors?.pattern) { //si hay campos incompletos no envio el email
        this.supportMessageLoading = false;
        return;
      }
      //estructura predefinida del email
      this.supportMessageForm.value.asunto ='Problemas para iniciar sesión'
      this.supportMessageForm.value.mensaje = 
      'Hola, mi nombre es '+this.supportMessageForm.value.nombreApellido+', con '+this.supportMessageForm.value.tipoDocumento+': '+this.supportMessageForm.value.numeroDocumento+'. Estoy intentando iniciar sesión con el usuario: '+this.supportMessageForm.value.nombreUsuario+' y me muestra el siguiente error: '+this.supportMessageForm.value.mensaje;
    } else { // si el asunto es otro
        if (this.supportMessageForm.value.asunto === '' || this.supportMessageForm.value.motivo === ''
        || this.supportMessageForm.value.mensaje === '' || this.supportMessageForm.value.email === ''
        || this.supportMessageForm.value.nombreApellido === '' || this.sp.email.errors?.pattern || this.sp.nombreApellido.errors?.pattern) {  
          this.supportMessageLoading = false; 
          return;
        }
    }

    this.supportMessageLoading = true;
    this.supportMessageSent = true;
    this.userService
      .supportMessage(this.supportMessageForm.value)
      .pipe(first())
      .subscribe(
        (data) => {             
          this.alerta.cssClass = 'alert alert-success';
          this.alerta.mensaje = 'El mail se envió con éxito';    
          this.supportMessageLoading = false;      
        },
        (error) => {}
      );
  }

  get sp() {
    return this.supportMessageForm.controls;
  }

  recuperarTiposDeDocumentos() {
    this.userService.recuperarTiposDeDocumentos().subscribe(
      (data) => {
        this.tiposDocumentos = data.elementos;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onMotivoChange(event: any){
    this.motivo = this.supportMessageForm.get('motivo').value;
  }

}
