import { Component, OnInit } from '@angular/core';
import { EstudiosService } from '../_services/estudios.service';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCompartirComponent } from '../dialog-compartir/dialog-compartir.component';
import { log } from 'console';


  
@Component({
  selector: 'app-episodio',
  templateUrl: './episodio.component.html',
  styleUrls: ['./episodio.component.css']
})
export class EpisodioComponent implements OnInit {
  displayedColumns : string[] = ['estudio', 'imagen']
  dataSource: any;
  mobileDevice: boolean;
  pedidoParam: any;
  msjEstado: string;
  funtionEstado: string;
  estudiosImagenes: any;
  random: number;
  selectedEstudio: any;
  selectedEstudios: any;
  urlEstudioPdf: string;
  horusRestfullUrl: string;
  // urlEstudioPdfComp: string;
  existePDFEstudio: boolean = false;
  // existePDFComp: boolean = false;
  cargando: boolean;
  userData: any;
  urlSitioPac: string;
  hashValido: boolean;
  existeImagen: boolean = false;
  esEstudioVIH: boolean;
  informado: boolean;
  

  constructor(
    private estudioService: EstudiosService,
    private userService: UserService,
    private urlResolver: UrlResolverService,
    private router: Router,
    private dialog: MatDialog) 
    {      
    this.pedidoParam = estudioService.localStorageGetEstudio();    
    //console.log(this.pedidoParam);
    
    this.informado = (this.pedidoParam.estado == 'INF' || this.pedidoParam.estado=='Informado' || this.pedidoParam.estado=='INFORMADO' );

    //si es un pdf cargado de un medico externo, lo cargó el paciente
    //si el tipo de episodio es 44 (documento de paciente por medico)
    //le fuerzo el estado de informado
    if(this.pedidoParam.matriculaProfesional == 0 || this.pedidoParam.idTipoEpisodio==44){ 
      this.informado=true;
    }
  }

  async ngOnInit() {
    this.cargando = false;
    await this.getMobile();    
    await this.getHorusUrl();
    await this.setSelectedEstudio(this.pedidoParam);    
  }

  async getImgAurora(estudio, pedido) {
    // console.log(estudio)

    estudio.pedido = {
      numero: pedido.numero
    }

    await this.estudioService.getUrlImgAurora(estudio).toPromise().then(resp => {
      // console.log(resp)
      estudio.estadoImgAurora = resp.error;
      estudio.urlAurora = resp.url;
    }, error => {
      console.log(error)
    })
  }
  

  //Funcion que se utiliza para conocer si el usuario está navegando desde el movil
  async getMobile(){
    this.mobileDevice = false;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobileDevice = true;
     }    
  }

  //Funcion para volver a la vista de historial clínico, ejecutada por el boton "Volver al historial"
  backToHistorial(){
    this.estudioService.localStorageDeleteEstudio();
    // this.router.navigate(['historial-clinico'])
    window.location.href='/historial-clinico';
  }

  //En esta funcion se recibe el estudio por parametro (fué extraido desde el local storage en la primera linea del constructor).
  //Al principio se guarda una estadistica.
  //Si el estudio pertenece a un documento que cargó el paciente, se realiza una accion y se retorna directamente.
  //Si es otra clase de estudio que no corresponda a internacion, se realizan las acciones.
  async setSelectedEstudio(estudio) {

    this.random = Math.floor(Math.random() * 9999999999) + 1000000000;
    
    this.userService.userData.subscribe(
      (userData) => {
        this.userData = userData;
        this.msjEstado = 'El usuario ' + userData.usuario + ' visualizo el estudio numero: ' + estudio.idEpisodio;
        this.funtionEstado = 'FX_VisualizarEstudio';
        this.userService.guardarEstadistica(this.msjEstado, this.funtionEstado).subscribe((respGuardarEst) => {},
        (error) => {
          console.log(error);
        }
      );
    })

    if (estudio.tipoEpisodio == 'Registro paciente' || estudio.tipoEpisodio=='Registros de medicos'){
      this.selectedEstudio = estudio; //Siempre trabajo con la variable selectedEstudio
      this.urlEstudioPdf = this.horusRestfullUrl + '/api/hcdigital/imprimir/pdf/' + this.selectedEstudio.idTipoEpisodio + '/' + this.selectedEstudio.referenciaRegistro + '/' + this.random //Armo la URL para consumir la API "manualmente"
      // this.urlEstudioPdfComp = '';
      this.obtenerPDFs();//Seteo la variable de control para establecer que existe el estudio.
      this.cargando = true; //Seteo la variable de control para establecer que ya cargó todo
      return;
    }

    if (estudio.tipoEpisodio != 'Internacion') {
      // await this.estudioService
      //   .getInfoImagenesEstudio(estudio.idEpisodio)
      //   .toPromise()
      //   .then((x) => (this.estudiosImagenes = x.paginador.elementos)); //Voy a buscar las imagenes del estudio

      await this.estudioService.getEstudio(estudio.idEpisodio)
        .toPromise()
        .then((x) => {
          this.selectedEstudio = x;
          
          if(this.selectedEstudio.estudios.indexOf('HIV') !== -1){
            this.esEstudioVIH = true;
          } 

          // console.log(this.selectedEstudio.estudiosPaciente);
          
          this.selectedEstudio.estudiosPaciente.forEach(element => {
            this.getImgAurora(element, this.selectedEstudio);
          });

          this.dataSource = this.selectedEstudio.estudiosPaciente.sort(
            (a, b) => 0 - (a.numeroItem > b.numeroItem ? -1 : 1)
          );

          
      });
    
      
        // el selectedEstudio.id debe estar encriptado desde el back
        // console.log(this.selectedEstudio);

        this.selectedEstudio.idCifrado = this.selectedEstudio.idCifrado.replace(/\//g, '_'); //reemplazo la / de la url 
      
        this.urlEstudioPdf =
          this.horusRestfullUrl +
          '/api/historiaClinica/pedidos/imprimirparapacientes/pdf/' +
          this.selectedEstudio.idCifrado +
          '/' +
          this.selectedEstudio.idCifrado +
          '/' +
          this.random; //Armo la URL para consumir la API "manualmente"      

        // this.urlEstudioPdfComp =
        //   this.horusRestfullUrl +
        //   '/api/historiaClinica/pedidos/imprimirInformeComplementarioPaciente/pdf/' +
        //   this.selectedEstudio.idCifrado +
        //   '/' +
        //   this.selectedEstudio.idCifrado +
        //   '/' +
        //   this.random; //Armo la URL para consumir la API "manualmente"

      await this.obtenerPDFs();

      // if (this.dataSource.length > 0 ){
      //   this.existeImagen = true;
      //   for (let index = 0; index < this.dataSource.length; index++) {
      //     this.dataSource[index].estadoImagen =
      //       this.estudiosImagenes[index];
      //   }
      // }
    } else {
      this.showEpicrisis(estudio);
    }
    this.cargando = true;
  }

  async obtenerPDFs(){
    //Obtengo el pdf del Estudio
    await this.estudioService.getEstudioPDF(this.urlEstudioPdf).toPromise().then(
      (resp: any) => {
         if (resp.type == 'application/pdf') {
          this.existePDFEstudio = true;
         }
      },
      (error) => {
        console.log(error);
      }
    );
    //Obtengo el pdf del Estudio Complementario
    // await this.estudioService
    //   .getInfoComplPDF(this.urlEstudioPdfComp)
    //   .toPromise().then(
    //     (respComp: any) => {       
    //        if (respComp.type == 'application/pdf') {
    //          this.existePDFComp = true;
    //        }
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  }


  showEpicrisis(estudio) {
       
    this.selectedEstudio = estudio;

    this.random = Math.floor(Math.random() * 9999999999999) + 1000000000000;
        
    this.urlEstudioPdf = this.horusRestfullUrl + '/api/internacion/epicrisis/imprimirporpaciente/pdf/' + estudio.idEpisodio + '/' + this.random; //Armo la URL para consumir la API "manualmente"
    
    this.obtenerPDFs(); 
    this.informado = true; //fuerzo el estado de informado
    this.cargando = true; //Seteo la variable de control para establecer que ya cargó todo
  }

  async getHorusUrl() {    
    let response = await this.urlResolver.getHorusRestfullUrl().toPromise();
    if (response){
      this.horusRestfullUrl = response.mensaje
    } 
    // this.horusRestfullUrl =  'http://localhost/horus_restfull'  
  }
  
  async setHashEstudio() {
    await this.getUrlPacientes();
    let urlVerEstudio = '';
    this.estudioService.setHashEstudio(this.userData.usuario, this.selectedEstudio.numero).subscribe(
        (respSetHash) => {          
          if (respSetHash.ok) {
            urlVerEstudio = this.urlSitioPac + '/ver-estudio/' + respSetHash.hash;
            this.hashValido = true;            
          } else {
            this.hashValido = false;
          }
          this.dialog.open(DialogCompartirComponent, {
            width: '550px',            
            data: {hashValido: this.hashValido, url: urlVerEstudio},
            panelClass: 'dialog-sin-overflow'
          })
        },
        (error) => {
          console.log(error);
        }
      );    
  }

  async getUrlPacientes() {
    this.urlResolver.getUrlPacientes().subscribe(
      (respGetUrlPac) => {
        this.urlSitioPac = respGetUrlPac.mensaje;
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
