<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <div class="container">
        <p>
          <strong
            >El acompañamiento y las visitas proporcionan un gran beneficio a
            los pacientes.</strong
          >
        </p>

        <p>
          Cuando el paciente tenga firmada el alta médica,
          <strong>Enfermería</strong> ordenará la documentación de la Historia
          Clínica y entregará los estudios que pudiera tener hechos el paciente,
          para que un familiar gestione el alta administrativa en el
          <strong>Área de Facturación</strong> y también en la
          <strong
            >Asociación de Profesionales del Hospital Español (APHE)</strong
          >, ambas en planta baja.
        </p>
        <p>
          Cumplidos estos trámites se le extenderá por escrito el alta
          administrativa que deberá entregar al Coordinador responsable del piso
          antes de movilizar al paciente.
        </p>
      </div>
    </div>
  </div>
</div>
