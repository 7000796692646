import { Component, OnInit } from '@angular/core';
import { UrlResolverService } from '../_services/urlResolver.service';

@Component({
  selector: 'app-turnos-dxi',
  templateUrl: './turnos-dxi.component.html',
  styleUrls: ['./turnos-dxi.component.css'],
})
export class TurnosDxiComponent implements OnInit {
  linkWhatsapp: string;
  hayMsje: boolean = false;

  constructor(private urlService: UrlResolverService) {}

  ngOnInit() {
    this.obtenerUrlWhatsappDXI();
  }

  obtenerUrlWhatsappDXI() {
    this.urlService.getUrlWhatsappDxi().subscribe(
      (respuesta) => {
        if (respuesta.ok) {
          this.linkWhatsapp = respuesta.mensaje;
          this.hayMsje = true;
        } else {
          this.hayMsje = false;
        }
      },
      (error) => {
        console.log(error);
        this.hayMsje = false;
      }
    );
  }
}
