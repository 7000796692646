import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { UrlResolverService } from '../_services/urlResolver.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ComunicacionService {
  baseUrl: string;
  comunicacionUrl: string;
  noticias;
  idPortal;
  desde: any = 0;
  cuantas: any = 10;

  constructor(private http: HttpClient,
    private urlResolver: UrlResolverService
    ) {
    this.idPortal = 32;
    this.baseUrl = AppComponent.testUrl;
    this.getUrlComunicacion();
  }
    //this.currentUser = this.currentUserSubject.asObservable();

  getUrlComunicacion() {
    // this.urlResolver.getComunicacionUrl().subscribe(x => {
    //     console.log(x);
    //     this.comunicacionUrl = x.mensaje;
    //     this.comunicacionUrl = '/home/Repositorio_Digital/comunicacion/imagenes_noticias/desarrollo/';
    // });

    return this.baseUrl + '/restricted/getImage?pathString=';
  }

  getUrlFiles() {
    return this.baseUrl + '/restricted/getImage?pathString=' + this.comunicacionUrl;
  }

  getNoticiasFraccionadas(
      aplicacion,
      desde,
      cuantas
    ) {
      let body = new FormData();

      body.append("IDAPLICACION", aplicacion);
      body.append("desde", desde);
      body.append("cant", cuantas);

      return this.http.post<any>(this.baseUrl + "/noticias", body);;
    }

  getNoticiasFraccionadasHomePortal() {
    let body = new FormData();

    body.append("IDAPLICACION", this.idPortal);
    body.append("desde", this.desde);
    body.append("cant", this.cuantas);
    
    return this.http.post<any>(this.baseUrl + "/noticias", body);
  }
      
}
