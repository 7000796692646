<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Mamografías</h4>
        <br />
        <div class="container">
          <p>
            Para solicitar turno comunicarse al:
          </p>
          <p>
            Teléfono: <strong>(0221) 412-9400, interno 1319</strong> de lunes a
            viernes de 7:15 a 17:00hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            <img
              src="../../assets/images/ecocardiogramas/icono_whatsapp.png"
            />
            WhatsApp: <strong> +54 9 221
            476-9962 </strong> de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Turno presencial: Central de Turnos, Planta Baja de lunes a
            viernes de 7:00 a 21:30hs y sábados de 7:00 a 14:30hs.
          </p>
  
          <p><strong>Equipo Médico: </strong></p>
  
          <p>Especialista en Mamas: Dra. Carolina Llarull | Dra. Yamila Luna </p>
  
          <p><strong>Estudios:</strong></p>
          <ul>
            <li>Ecografía ginecológica | Ecografía ginecológica con transductor endovaginal</li>
            <li>Ecografía mamaria bilateral | Mamografía bialteral con proyección axilar</li>
            <li>Magnificación | Compresion focalizada</li>
            <li>Marcación radioquirúrgica de lesión mamaría no palpable (con arpón o carbón)</li>
            <li>Punción aspiración con aguja fina guiada por ecografía o por mamografía</li>
            <li>Punción biopsia con aguja gruesa guiada por ecografía o por mamografía</li>
          </ul>
  
          <p>
            Para todas las prácticas, se recomienda no colocar desodorantes, ni
            talco, ni cremas en mamas y axilas. Es necesario traer todos los
            estudios previos.
          </p>
        </div>
      </div>
    </div>
  </div>
</body>

</html>