import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ingreso-egreso',
  templateUrl: './ingreso-egreso.component.html',
  styleUrls: ['./ingreso-egreso.component.css']
})
export class IngresoEgresoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
