<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Hematología</h4>
  
        <div class="container">
          <p>
            Adultos, pediátricos y neonatales. Consulta con especialistas al
            <strong>412-9400, interno 1715.</strong>
          </p>
          <p>Por horarios de atención <a routerLink="#">haga click aquí</a></p>
          <ul>
            <li>Estudios hematológicos en sangre periférica.</li>
            <li>Estudios de hemostasia y trombosis.</li>
            <li>Anticuagulados.</li>
            <li>Punciones ganglionares.</li>
            <li>Punciones de médula ósea.</li>
            <li>Biopsias de médula ósea.</li>
            <li>Tratamientos quimioterápicos.</li>
            <li>Grupo y Factor Rh. Genotipo Rh.</li>
            <li>Identificación de anticuerpos irregulares.</li>
            <li>Titulación de anticuerpos</li>
            <li>Pruebas de Cooms directa e indirecta.</li>
            <li>Inmunohematología obstétrica y perinatal</li>
            <li>
              Asesoramiento inmunohematológico al paciente y al médico tratante.
            </li>
            <li>
              Transfusiones de hemocomponentes (sangre, plasma, plaquetas,
              crioprecipitados) en ambulatorio (según normas de procedimiento del
              servicio) e internados.
            </li>
          </ul>
  
          <p>
            <strong>Horario de extracciones:</strong> de 7.30 a 10 con ayuno de
            horas de grasas
          </p>
          <p>Puede tomar té, café o mate sin azúcar</p>
          <p>
            <strong>Entrega de resultados de rutina:</strong> al otro día, con
            excepciones.
          </p>
          <p><strong>Estudios Especializados:</strong>72 horas o más</p>
          <p>
            <strong>Sugerencia:</strong> Respetar los tiempos que informa el
            servicio, en virtud que a veces deben repetirse procedimientos
            analíticos para exactitud de las pruebas.
          </p>
          <p><strong>Equipamiento de ultima generacion</strong></p>
          <p><strong>Jefe:</strong> Dr. Luis Fabián Pintos.</p>
          <p>Dra. Julieta Gil (encargada del área de Hemoterapia)</p>
          <p>Dra. Mercedes Bolognani</p>
          <p>Dr. Miguel De Luca</p>
  
          <p>
            <strong>Hemoterapia:</strong> Unidad de Transfusión, dependiente del
            Banco de Sangre Regional: Instituto de Hemoterapia de la Provincia de
            Bs. As. (Ley Provincial de Sangre 11.725). Provee todos los
            hemocomponentes o hemoderivados que se solicitan para la asistencia de
            los pacientes. Normas ISO 9001.2000.
          </p>
          <p>
            Los dadores de sangre que se solicitan, deben concurrir al Instituto
            de Hemoterapia, calle 15 esquina 66, detrás del Hospital de Niños de
            la Plata de lunes a viernes de 7 a 15 y los sábados 7 a 13.
          </p>
          <p>
            <strong>Condiciones:</strong> 18 a 65 años. Ayuno de 6 horas para
            alimentos sólidos. Puede ingerir agua, té, café, mate, gaseosas, jugos
            azucarados. No ingerir leche. Peso mínimo 50 kg.
          </p>
          <p>Se aconseja preguntar por el Seguro de Sangre del Instituto.</p>
        </div>
      </div>
    </div>
  </div>
  
</body>

</html>