<div class="barra-inicial barra-inicial-celeste">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrar()">
</div>
<h1 mat-dialog-title>Compartir enlace</h1>
<div mat-dialog-content *ngIf="data.hashValido; else hashNoValidoTemplate">
    <p>Por favor, copiá el siguiente enlace para poder compartirlo.</p>
    <mat-form-field>
        <input matInput type="text" value="{{data.url}}" #userinput />
        <mat-hint class="color-success" *ngIf="copiado">Enlace copiado correctamente!</mat-hint>
    </mat-form-field>
    <button  mat-mini-fab class="boton-mat-icon ml-2" matTooltip="Copiar" (click)="copyInputMessage(userinput)">
        <mat-icon>content_copy</mat-icon>    
    </button>
  <p style="font-style: italic;color: red;font-size: 14px;">El mismo expirará luego de 24hs.</p>
</div>

<ng-template #hashNoValidoTemplate>
    <div mat-dialog-content>
        <div class="alert alert-danger text-center" role="alert">
            No se pudo crear el enlace correctamente
        </div>
    </div>
</ng-template>

<mat-dialog-actions align="end">
    <!-- <button mat-raised-button mat-dialog-close color="warn"> Cancelar </button> -->
    <div class="boton-dialog boton-cerrar" (click)="cerrar()">Cancelar</div>
</mat-dialog-actions>