<div class="d-flex justify-content-center" *ngIf="cargando">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!cargando">
    <div class="modal-header text-center" style="display: inline-block">
        <h6 class="subtitulo" id="exampleModalLabel" [hidden]="!turnoAutorizar.realizoWebCheckIn"> Admisi&oacute;n Digital - Paso 2 </h6>
        <h6 class="subtitulo" id="exampleModalLabel" [hidden]="turnoAutorizar.realizoWebCheckIn"> Admisi&oacute;n Digital - Paso 3 </h6>
        <div style="border: 0px solid green">
            <p [hidden]="!turnoAutorizar.realizoWebCheckIn">
                El segundo paso es solicitar la autorizaci&oacute;n a tu obra social, esto puede tardar unos minutos
            </p>
            <p [hidden]="turnoAutorizar.realizoWebCheckIn">
                El tercer paso es solicitar la autorizaci&oacute;n a tu obra social, esto puede tardar unos minutos
            </p>
        </div>
    </div>

    <div class="modal-body">
        <div style="margin-left: 2%">
            <p><b>Tipo de atenci&oacute;n: </b>Consulta</p>
            <p><b>Profesional: </b>{{ turnoAutorizar.profesional }}</p>
            <p><b>Fecha y Hora: </b>{{ turnoAutorizar.str_fecha }} {{ turnoAutorizar.str_hora }}</p>
            <p><b>Paciente: </b>{{ turnoAutorizar.paciente.apellido }}</p>
            <p><b>Obra Social: </b>{{ turnoAutorizar.mutualElegida.nomFin }}</p>
            <p><b>Nro. de Carnet: </b>{{ turnoAutorizar.nroCarnet }}</p>
            <p style="padding-top: 1%;">Tené a mano tu tarjeta por si tu obra social requiere un copago o un diferenciado.</p>
        </div>
    </div>

    <div class="modal-footer">
        <button mat-flat-button color="warn" class="boton" style="margin-right: 3%" data-dismiss="modal" (click)="cerrarDialog()"> Cancelar </button>
        <button mat-flat-button color="primary" class="boton" data-dismiss="modal" (click)="confirmarAutorizar()"> Continuar </button>
    </div>
</div>
