<div *ngIf="cargando; else loadingTemplate" class="container">
    <button mat-raised-button class="volver" (click)="backToHistorial()"><mat-icon>arrow_back</mat-icon>Volver al historial</button>
    <button mat-raised-button class="espaciado" *ngIf="this.selectedEstudio.idTipoEpisodio != 43 && this.selectedEstudio.idTipoEpisodio!=44 && informado" (click)="setHashEstudio()" color="primary"><mat-icon>share</mat-icon>Compartir estudio</button>


    <h4>Detalle</h4>
    <div class="detail" *ngIf="this.selectedEstudio.idTipoEpisodio != 43 && this.selectedEstudio.idTipoEpisodio!=44 else docPaciente">
        <span><b>Fecha:</b> {{ pedidoParam.fechast}}</span>
        <span><b>Servicio efector:</b> {{ selectedEstudio.nombreServicio ? (selectedEstudio.nombreServicio | toTitleCase) : '-' }}</span>
        <span><b>Médico efector:</b> {{ selectedEstudio.actuante ? (selectedEstudio.actuante | toTitleCase) : '-' }}</span>
        <span><b>Médico solicitante:</b> {{ selectedEstudio.solicitante ? (selectedEstudio.solicitante | toTitleCase) : '-' }}</span>
    </div>

    <ng-template #docPaciente>
        <div class="detail" *ngIf="this.selectedEstudio.idTipoEpisodio==44 else docPropioPaciente">
            <span *ngIf="selectedEstudio.fechast"><b>Fecha:</b> {{selectedEstudio.fechast }}</span>
            <span *ngIf="selectedEstudio.observacion"><b>Descripción:</b> {{selectedEstudio.observacion}}</span>
            <span *ngIf="selectedEstudio.nombreProfesional"><b>Registro del Profesional:</b> {{selectedEstudio.nombreProfesional}} (Mat. {{selectedEstudio.matriculaProfesional}})</span>
        </div>
        <ng-template #docPropioPaciente>
            <div class="detail">
                <span><b>Fecha:</b> {{selectedEstudio.fechast }}</span>
                <span><b>Descripción:</b> {{selectedEstudio.observacion}}</span>
            </div>
        </ng-template>
    </ng-template>

    <!-- <div *ngIf="existeImagen"> -->
        
        <div *ngIf="selectedEstudio.nombreServicio != 'LABORATORIO'; else vihTemplate" class="contenedor-cards-simil-tabla" style="margin-bottom: 10px;">
            <div *ngFor="let estudio of dataSource" class="card-simil-tabla">
                <div class="estudio">
                    <div>
                        <b>Estudio</b>
                    </div>
                    <div class="estudio-content">
                        {{ estudio.nombreEstudio | toTitleCase }}
                    </div>
                </div>
        
                <div class="imagen" style="display: flex;flex-direction: row;">
                    
                    <div class="imagen-content">
                        <ng-container *ngIf="estudio.estadoImgAurora == 0; else imgAngra">
                            <a target="_blank" href="{{ estudio.urlAurora }}" mat-raised-button color="primary">Ver imagen</a>
                        </ng-container>
        
                        <ng-template #imgAngra>
                            <ng-container *ngIf="estudio.estadoImagen == 1; else noImageTemplate">
                                <a target="_blank" href="{{ estudio.urlVisor }}" mat-raised-button color="primary">Ver imagen</a>
                            </ng-container>
                            <ng-template #noImageTemplate>
                                <span class="text-muted">Imagen no disponible</span>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        
    <!-- </div> -->


    <div *ngIf="!mobileDevice; else mobileTemplate">
        <div class="row">
            <div class="col" *ngIf="existePDFEstudio && ((informado || selectedEstudio.estado=='Informado') || selectedEstudio.codigoServicio==22)"><iframe [src]="urlEstudioPdf | safeUrl" type="application/pdf" width="100%" height="800px"></iframe></div>
            <!-- <div class="col" *ngIf="existePDFComp && informado"><iframe type="application/pdf" [src]="urlEstudioPdfComp | safeUrl" width="100%" height="600px"></iframe></div> -->
        </div>
        <!-- <div class="alert-info" *ngIf="(existePDFEstudio || existePDFComp) && !informado" style="text-align: center;"> -->
        <div class="alert-info" *ngIf="(existePDFEstudio) && !(informado || selectedEstudio.estado=='Informado') && selectedEstudio.codigoServicio!=22" style="text-align: center;">
            El informe aún no se encuentra disponible.
        </div>
    </div>

    <ng-template #mobileTemplate>
        <div class="row">
            <div class="col d-flex flex-column justify-content-around">
                <a mat-raised-button *ngIf="existePDFEstudio && ((informado || selectedEstudio.estado=='Informado')  || selectedEstudio.codigoServicio==22)" class="mx-auto" color="primary" href="{{ urlEstudioPdf }}">Descargar informe</a>
                <!-- <a mat-raised-button class="mt-2 mx-auto" *ngIf="existePDFComp  && informado" color="primary" href="{{ urlEstudioPdfComp }}">Descargar informe</a> -->

            </div>
        </div>
        <!-- <div class="alert-info" *ngIf="(existePDFEstudio || existePDFComp) && !informado" style="text-align: center;"> -->
        <div class="alert-info" *ngIf="(existePDFEstudio) && !(informado || selectedEstudio.estado=='Informado') && selectedEstudio.codigoServicio!=22" style="text-align: center;">
            El informe aún no se encuentra disponible.
        </div>
    </ng-template>

    <ng-template #vihTemplate>
        <div class="alert alert-info w-75 mx-auto my-4" role="alert" *ngIf="esEstudioVIH">
            <b>INFORMACION IMPORTANTE! </b>El resultado es entregado en sobre cerrado y bajo recibo al médico tratante (art. 8- Ley 23.798 y Decreto Reglamentario 1.244/90). En caso de resultado no reactivo, el Laboratorio podrá entregar el informe al
            paciente o representante legal, previa acreditación de identidad y vínculo (Ley 26.529, modificatorias y Decreto 1.089/12).
        </div>

        <!-- <ng-template #noVihTemplate>
            <div class="alert alert-info text-center w-75 mx-auto my-4" role="alert">
                Este tipo de estudios <b>no se ven por sistema</b>
            </div>
        </ng-template> -->
    </ng-template>

</div>

<ng-template #loadingTemplate>
    <h6>Aguarde un momento por favor.</h6>
    <mat-progress-bar class="barra-progreso-celeste container" mode="indeterminate"></mat-progress-bar>
</ng-template>