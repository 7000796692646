<div class="barra-inicial barra-inicial-celeste">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo(false)">
</div>
<div mat-dialog-content>
    <div *ngIf="loading" class="d-flex justify-content-center">
        <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    
    <div *ngIf="!loading">
        <div>
            Estás reservando un turno con: <br>
            <ul>
                <li><b>Profesional: </b>{{ data.bloqueElegido.profesional | toTitleCase }} </li>
                <li><b>Fecha: </b>{{data.bloqueElegido.nombreDia | lowercase}} {{nro_dia}} de {{nombre_mes}} del {{nro_ano}}  </li>
                <li><b>Hora: </b> {{ data.bloqueElegido.str_hora }}hs.</li>
                <li><b>Cobertura: </b>{{mutualElegida.nombreMutual | toTitleCase}}</li>
            </ul>             
            <!-- <p><b>Fecha:</b> {{ data.bloqueElegido.str_fecha }}</p> -->           
            <!-- en  {{ data.bloqueElegido.establecimiento }}. -->
            <!-- <p id="cobertura">Tu cobertura es: {{mutualElegida.nombreMutual | toTitleCase}}</p> -->
        </div>

        <mat-divider class="divisor"></mat-divider>

        <!-- Select cobertura -->
        <div *ngIf="this.mutuales.length > 0; else elseBlockMutuales">
            <mat-form-field  appearance="outline">
                <mat-label>Seleccionar cobertura - nro afiliado</mat-label>
                <mat-select [(ngModel)]="mutualElegida" (selectionChange)="cambioMutual()" required>
                    <mat-option *ngFor="let mutual of mutuales" [value]="mutual">
                        {{ mutual.nomFin }} -
                        {{ mutual.nroCarnet }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <ng-template #elseBlockMutuales> Usted no tiene mutuales asociadas </ng-template>

        <p [hidden]="ocultarMsjeMutualProf" id="msjeMutualProf"> {{ msjeMutualProf }} </p>

        <!-- <div id="pregunta">¿Querés reservarlo?</div> -->
    </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-center" *ngIf="!loading">
    <!-- <button mat-raised-button 
        color="warn" 
        (click)="cerrarDialogo()"
        > Cancelar
    </button>

    <button mat-raised-button 
        color="primary" 
        (click)="onSubmit()"
        [disabled]="loadingSubmit"
        [class.spinnerButton]="loadingSubmit"> Reservar
    </button> -->
    <div 
        class="boton-dialog boton-cerrar"
        color="warn" 
        (click)="cerrarDialogo()"
        > Cancelar
    </div>

    <div 
        class="boton-dialog boton-confirmar"
        color="primary" 
        (click)="onSubmit()"
        [disabled]="loadingSubmit"
        [class.spinnerButton]="loadingSubmit"> Confirmar
    </div>
</div>   
