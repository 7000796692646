import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, delay } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService} from '../_services/alert.service';
import { FacturasService} from '../_services/facturas.service';
import { Observable } from 'rxjs';
import { UrlResolverService } from '../_services/urlResolver.service';


@Component({
  selector: 'app-facturas-de-honorarios',
  templateUrl: './facturas-de-honorarios.component.html',
  styleUrls: ['./facturas-de-honorarios.component.css']
})
export class FacturasDeHonorariosComponent implements OnInit {

    facturas: any = [];
    loading: Boolean;
    selected;
    horusRestfullUrl;
    selectedUrl;

  constructor(private facturasService: FacturasService, private urlResolver: UrlResolverService) { }

  ngOnInit() {
        this.getUrl()
        this.loading = true;
        delay(99999999999999999999999999999999);
        this.facturasService.getFacturasDeHonorarios().subscribe( data => this.facturas = data.recibos);
        
        this.loading = false;
  }

  getUrl(){
    this.urlResolver.getHorusRestfullUrl().subscribe(x => this.horusRestfullUrl = x.mensaje)
  }

  crearPrefijoLink(prefijo){
    let str = "0000";
    return ((str + prefijo.toString()).substr(prefijo.toString().length));
  }

  crearNumeroLink(numero){
    let str = "00000000";
    return ((str + numero.toString()).substr(numero.toString().length));
  }


  setFactura(factura){
    this.selected=factura;
    this.selectedUrl = this.horusRestfullUrl + 'api/facturacionPaciente/imprimirPorPaciente/pdf/RAE' + factura.letra + this.crearPrefijoLink(factura.prefijo) + this.crearNumeroLink(factura.numero) + '.pdf' ;
  }

  resetList(){
    this.selected=null;
  }
}
