<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div *ngIf="!cargando;else cargandoBlock" class="container-fluid">
    <div *ngIf="validacionAprobada();else noValidadoBlock" class="row d-flex justify-content-center mt-1 m-0 p-0">
      <ng-template #NoFacturasTemplate>
        <div class="alert alert-danger">
          <h6>No se encontraron recibos o facturas.</h6>
        </div>
      </ng-template>
      <div class="w-100" *ngIf="!selected">

        <div *ngIf="cantidad_facturas() > 0; else NoFacturasTemplate">
          <b>Ordenar por fecha</b>

          <button id="" mat-mini-fab (click)="cambiar_orden()" style="margin-left: 5px; margin-bottom: 5px;">            
            <mat-icon *ngIf="ordenado_desc">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!ordenado_desc">keyboard_arrow_up</mat-icon>
          </button>

          <div class="contenedor-cards-simil-tabla">
                <div class="card-simil-tabla" *ngFor="let factura of facturas_y_recibos">
                  <div class="titulo_tarjeta D-DINCondensed-Bold">
                    {{factura.fecha}}
                  </div>
                  <div>
                    <strong>{{factura.letra}} - {{factura.prefijo}} - {{factura.numero}}</strong>
                  </div>
                  <div class="opcional" *ngIf="factura.descripcion_factura !== undefined">
                    {{factura.descripcion_factura | toTitleCase}} (FACTURA)
                  </div>
                  <div class="opcional" *ngIf="factura.descripcion_recibo !== undefined">
                    {{factura.descripcion_recibo | toTitleCase}} (RECIBO)
                  </div>
                  <div>
                    Mutual: {{factura.mutual_desc}}
                  </div>
                  <button  
                    *ngIf="factura.descripcion_factura !== undefined" 
                    class="boton boton-card mat-focus-indicator mat-flat-button mat-button-base ng-tns-c186-6 ng-star-inserted"             
                    id="ver-pdf" 
                    (click)="setFactura_de_gastos(factura)">
                    <div class="contenido-boton"><i class="material-icons"> picture_as_pdf</i> VER FACTURA</div>
                  </button>

                  <button 
                    *ngIf="factura.descripcion_recibo !== undefined" 
                    class="boton boton-card mat-focus-indicator mat-flat-button mat-button-base ng-tns-c186-6 ng-star-inserted"                    
                    id="ver-pdf" 
                    (click)="setFactura_de_honorarios(factura)">
                    <div class="contenido-boton"><i class="material-icons"> picture_as_pdf</i> VER RECIBO</div>
                  </button>

                </div>
              </div>
        </div>


      </div>
      <div class="col" *ngIf="selected">

        <button mat-raised-button (click)="resetList()" class="volver" style="float: left">
          <mat-icon>arrow_back</mat-icon> Volver
        </button>

        <br />
        <div class="container">
          <embed type="application/pdf" [src]="selectedUrl | safeUrl" width="103.5%" height="100%"
            internalinstanceid="5" />
        </div>
      </div>
    </div>
    <ng-template #noValidadoBlock>
      <div *ngIf="validacionPendiente();else sinValidarBlock">
        Tu usuario aún se encuentra en <b>proceso de VALIDACIÓN.</b> <br><br>

        Vas a poder acceder a tus Facturas y realizar otras gestiones desde el Portal, una vez que se haya completado el
        proceso de validación. <br>

        *tiempo estimado de validación de usuarios: 72hs
      </div>
      <ng-template #sinValidarBlock>
        <div style="margin-top: 3vh;">
          Te recordamos que para poder acceder a tus Facturas y realizar otras gestiones desde el Portal, es necesario
          que se validen tus datos. <br>
          *para completar los datos personales sugerimos tener cerca el DNI.</div>
        <button class="btn boton_validar" (click)="goToValidar()" style="margin-top: 3vh;"> Validá tu identidad</button>
      </ng-template>
    </ng-template>
  </div>
  <ng-template #cargandoBlock>
    <div>
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</body>

</html>