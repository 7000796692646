<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h3>Recomendaciones</h3>
        <div class="container">
          <p>
            Se aconseja no portar joyas, dinero u otros objetos de valor: la
            institución no se hace responsable de su deterioro o pérdida. Por
            razones de higiene no guarde alimentos en las habitaciones. Respete
            las instalaciones y utilice el televisor o la radio con volumen
            moderado.
          </p>

          <h6><strong>Medicamentos</strong></h6>
          <p>
            Si tiene prescripta alguna medicación crónica deberá traerla desde su
            casa e informar a la enfermera. El personal sólo está autorizado a
            administrar la medicación prescripta en la hoja de indicaciones
            médicas y las camareras sólo pueden servir la dieta señalada por el
            profesional. El médico interno de Guardia podrá solucionar cualquier
            problema de urgencia.
          </p>

          <h6><strong>Responsabilidades</strong></h6>
          <ul>
            <li>
              Dar información completa y correcta a su equipo médico sobre su
              salud, historia médica, mutual, obra social, prepago y formas de
              financiamiento y otros asuntos pertinentes.
            </li>
            <li>Seguir su plan de cuidado médico.</li>
            <li>Prestar atención al cuidado de salud que recibe.</li>
            <li>
              Comunicar a los responsables de su cuidado todo cambio en su estado
              de salud cuando esté en el Hospital, en el consultorio médico o una
              vez que le den el alta.
            </li>
            <li>Expresar libremente si no entiende.</li>
          </ul>

          <h6><strong>Artículos de uso personal</strong></h6>
          <p>
            La enfermera le informará sobre los artículos de uso personal
            necesarios durante la internación. Le rogamos se responsabilice de las
            prótesis dentales y objetos de valor similares.
          </p>

          <h6><strong>Dudas</strong></h6>
          <p>
            Existe un coordinador de Enfermería –fácilmente distinguible por estar
            vestido con pantalón bordó y chaqueta blanca con vivos bordó- que
            tiene como misión optimizar el funcionamiento del servicio y que
            responderá a cualquier inquietud que se le presente a usted y a sus
            familiares.
          </p>
          <h6><strong>Informes</strong></h6>
          <p>
            Internaciones. Planta Baja.
            <strong>(0221) 427 0191/99, interno 1506 o 1202</strong>
          </p>
        </div>
      </div>
    </div>
  </div>

</body>

</html>