<div class="d-flex justify-content-center" *ngIf="cargando">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!cargando">
    <!-- HEADER -->
    <div class="modal-header text-center" style="display: inline-block">
        <h6 class="subtitulo" id="exampleModalLabel" [hidden]="!turnoAutorizar.realizoWebCheckIn"> Admisi&oacute;n Digital - Paso 3 </h6>
        <h6 class="subtitulo" id="exampleModalLabel" [hidden]="turnoAutorizar.realizoWebCheckIn"> Admisi&oacute;n Digital - Paso 4 </h6>
    </div>

    <!-- BODY -->
    <div class="modal-body">
        <div style="text-align: center">
            <p>{{ mensajeAutorizar }}</p>
            <p style="padding-top: 5%;" *ngIf="autorizacion && importeTotal > 0">Tenga a mano su tarjeta por si su obra social requiere un copago o un diferenciado.</p>
        </div>
    </div>

    <!-- FOOTER SI AUTORIZA OK -->
    <div class="modal-footer" [hidden]="!autorizoOK">
        <button
            mat-raised-button color="warn" style="margin-right: 2%;"
            type="button"
            (click)="cancelar()"
        > Cerrar
        </button>
        <button
            mat-raised-button color="accent"
            type="button"
            (click)="pagar()"
            [hidden]="!existePago || existeError"
        > Pagar
        </button>
        <button
            mat-raised-button color="accent"
            type="button"
            (click)="registrarLlegada()"
            [hidden]="existePago || existeError"
        > Siguiente
        </button>
    </div>
    <!-- FOOTER SI NO AUTORIZA OK -->
    <div class="modal-footer" [hidden]="autorizoOK">
        <button
            mat-raised-button color="warn" style="margin-right: 2%;"
            type="button"
            (click)="cancelar()"
        > Cancelar
        </button>
        <button
            mat-raised-button color="primary" style="margin-right: 2%;"
            type="button"
            *ngIf="!pagaParticular"
            (click)="setMutualTurnoAutorizarConsulta()"
        > Reintentar
        </button>
        <button
            mat-raised-button color="accent"
            type="button"
            *ngIf="valorizoParticular"
            (click)="pagarParticular()"
        > Pagar Particular
        </button>
    </div>
</div>
