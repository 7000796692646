<!-- <div class="contenedor" *ngIf="!cargando; else cargandoBlock">
  <div class="container-fluid">
    <div class="wrapper">
      <div class="texto-saludo">
        <div>
        <div class="titulo">
          <div *ngIf="tieneNombre();else noTieneNombreBlock">
              ¡Hola {{userData.nombres | toTitleCase}}!
          </div>
          <ng-template #noTieneNombreBlock>
              ¡Hola {{userData.apellido | toTitleCase}}!
          </ng-template>        
        </div>
        <div class="subtitulo">
          Te damos la bienvenida a tu portal
        </div>
        <div class="cuerpo_mensaje">
          <p>Podés buscar, reservar y cancelar turnos de consulta médica.</p>
          
          <div *ngIf="validacionPendiente();else validadoBlock">
              <p>Y una vez que tu usuario se haya validado, también vas a poder: </p>
              <ul>
                  <li>Gestionar el presente de tus turnos (sin tener que pasar por las cajas)</li>
                  <li>Acceder a tu Historial Clínico completo</li>
                  <li>Adjuntar todos tus estudios en un mismo lugar y compartirlos con los y las profesionales de la salud que necesites</li>
              </ul>
          </div>
    
          <ng-template #validadoBlock>
              <p>El día del turno te das el presente desde acá.</p>
              
              <p>Accedés a tu historial clínico completo.</p>
              
              <p>Tenés la posibilidad de adjuntar todos tus estudios en un mismo lugar
                  y compartirlos con el/la profesional de la salud que necesites.</p>
          </ng-template>
        </div>
      </div>
      </div>

      <div class="carousel" id="claseCarousel" *ngIf="hayNoticias">
        <div class="subtitulo" id="subtitulo-noticias">
          Noticias
        </div>
        <div class="cuerpo_mensaje" id="cuerpo-mensaje-noticias">
          ¡Deslizá para enterarte de las últimas novedades!
        </div>
        <app-carousel></app-carousel>
      </div>

    </div>
  </div>
</div>  
  

<ng-template #cargandoBlock>
  <div style="display: grid;">
      <div class="col">Cargando, aguarde un momento.</div>
      <mat-spinner [diameter]="40" class="float-right align-middle ml-1" style="margin-top: 5vh;"></mat-spinner>
  </div>
</ng-template> -->

<!DOCTYPE html>
<html lang="es">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body>
  <div *ngIf="validacionDenegada" class="alert alert-danger denegada">
      Lo sentimos, la validación de tu usuario ha sido rechazada debido al siguiente motivo: 
      <br>
      {{userData.motivo}}.
      <br>
      Deberás hacerla nuevamente teniendo esto en cuenta.
  </div>
  <main>
  <div class="grid-container">
    <!-- BIENVENIDA -->
    <div class="grid-item">
      <div class="tarjeta">
        <div class="titulo_tarjeta D-DINCondensed-Bold">
          TE DAMOS LA BIENVENIDA
        </div>

        <div class="subtitulo_tarjeta">
          <div *ngIf="validacionPendiente_bool;else validadoBlock1">
            Desde el portal vas a poder:
          </div>
          <ng-template #validadoBlock1>
            Desde el portal podés:
          </ng-template>
        </div>
        <div class="cuerpo_mensaje" id="bienvenida">
          <ul> 
            <div *ngIf="validacionPendiente_bool;else validadoBlock2">

              <li>Reservar | Reprogramar | Cancelar turnos de consulta médica</li>
              <li>Realizar la Admisión online el día del turno</li>
              <li>Acceder a tu Historial Clínico completo</li>
              <li>Adjuntar todos tus estudios en un mismo lugar y compartirlos con el/la profesionales de la salud que
                necesites</li>

            </div>

            <ng-template #validadoBlock2>
              <li>Dar el presente el día del turno</li>
              <li>Acceder a tu Historial Clínico completo</li>

              <li>Adjuntar todos tus estudios en un mismo lugar y compartirlos con el/la profesional de la salud que
                necesites.</li>
            </ng-template>
          </ul>
        </div>

      </div>
    </div>









    
    <!-- NOVEDADES -->
    <div class="grid-item">
      <div class="tarjeta">
        <div class="titulo_tarjeta D-DINCondensed-Bold">
          NOVEDADES
        </div>
        <div class="centrar_carousel">
          <app-carousel></app-carousel>
        </div>
      </div>
    </div>









    
    <!-- TURNOS DEL DIA -->
    <div class="grid-item">

      <div class="tarjeta">
        <div class="titulo_tarjeta D-DINCondensed-Bold" id="turnos_del_dia">
          TURNOS DEL DÍA
        </div>
        <div class="cuerpo_mensaje">
          <div class="d-flex justify-content-center" *ngIf="loadingTurnos">
            <mat-spinner></mat-spinner>
          </div>

          <div class="estudios_y_turnos" id="turnos" *ngIf="existenTurnos && !loadingTurnos">
            <ul>
              <div *ngFor="let turno of turnosReservados">
                <li><p><b>{{turno.str_hora}}</b> {{turno.profesional | toTitleCase }} ({{turno.servicio}})</p></li>
              </div>
            </ul>
          </div>

          <div *ngIf="!existenTurnos && !loadingTurnos">
            <div class="subtitulo_tarjeta">
              No tenés turnos para el día de hoy
            </div>
          </div>

        </div>

        <div class="pie_tarjeta" *ngIf="!loadingTurnos">
          Podés ver todos tus turnos en&nbsp;<a class="link_amarillo" href="/turnos-reservados">Turnos Reservados</a>
        </div>
      </div>
    </div>










    <!-- ESTUDIOS DE LA SEMANA -->
    <div class="grid-item" *ngIf="!validacionPendiente_bool">
      <div class="tarjeta">
        <div class="titulo_tarjeta D-DINCondensed-Bold">
          ESTUDIOS DE LA SEMANA
        </div>
        <div class="cuerpo_mensaje">
          <div class="d-flex justify-content-center" *ngIf="loadingEstudios">
            <mat-spinner></mat-spinner>
          </div>

          <div class="estudios_y_turnos" id="estudios" *ngIf="existenEstudios && !loadingEstudios">
            <div *ngFor="let e of estudios">
            <ul>
              <div class="contenido-en-linea">
                <!-- <div class="oscuro">{{ e.fechast | date:'MM/dd' }}&nbsp;</div> -->
                <li>
                <div (click)="e.tipoEpisodio == 'Estudio' || e.tipoEpisodio == 'Registro paciente' || (e.tipoEpisodio == 'Internacion' && e.tiene)
                    ? selectEstudio(e)
                    : false" [className]="e.tipoEpisodio == 'Estudio' || e.tipoEpisodio == 'Registro paciente' || (e.tipoEpisodio == 'Internacion' && e.tiene)
                    ? 'link'
                    : null">
                  {{ e.especialidadAtencion | toTitleCase }}
                </div>
                &nbsp;{{ e.observacion }}
                </li>
              </div>              
            </ul>

            </div>
          </div>

          <div *ngIf="!existenEstudios && !loadingEstudios">
            <div class="subtitulo_tarjeta">
              No tenés resultados de estudios esta semana
            </div>
          </div>

        </div>

        <div class="pie_tarjeta" *ngIf="!loadingEstudios">
          Podés ver todos tus estudios en tu&nbsp;<a class="link_amarillo"
            href="/historial-clinico">Historial Clínico</a>
        </div>
      </div>
    </div>
  </div>
  </main>

  <footer>
    <div class="footer">
      <img src="../../assets/images/login/logo HE.png" id="icono-portal-footer">

      <div class="texto-footer">
      Hospital Espanol | <div class="letra-blanca">Dedicados a cuidar la salud de las personas</div> |
      Calle 9 Nro. 175 e/35 y 36 
      </div>

      <div class="iconos-footer">
        <a href="https://www.facebook.com/hospitalespanol.oficial/" target="_blank">
          <img class="icono-footer" src="../../assets/images/redes-sociales/facebook.png" alt="Redirigir a Facebook">
        </a>

        <a href="https://instagram.com/espanoldelaplataoficial" target="_blank">
          <img class="icono-footer" src="../../assets/images/redes-sociales/instagram.png" alt="Redirigir a Instagram">
        </a>

        <a href="https://ar.linkedin.com/company/hospitalespanoldelaplata" target="_blank">
          <img class="icono-footer" src="../../assets/images/redes-sociales/linkedin.png" alt="Redirigir a Linkedin">
        </a>

        <a href="https://www.youtube.com/@HospitalEspanolLP.oficial" target="_blank">
          <img class="icono-footer" src="../../assets/images/redes-sociales/youtube.png" alt="Redirigir a Youtube">
        </a>
      </div>
      
    </div>
  </footer>
</body>

</html>