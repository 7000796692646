import { Component, ElementRef, OnInit, HostListener } from "@angular/core";
import { TurnosService } from "../_services/turnos.service";
import { FormGroup, FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { NavigationStart, Router } from "@angular/router";
import { FinanciadoresService } from "../_services/financiadores.service";
import { UrlResolverService } from "../_services/urlResolver.service";
import { DialogConfirmarTurnoComponent } from "../dialog-confirmar-turno/dialog-confirmar-turno.component";
import { AuthenticationService } from "../_services/authentication.service";
import { DialogConfirmacionComponent } from "../dialog-confirmacion/dialog-confirmacion.component";
import { Turno } from "../mis-reservas/mis-reservas.component";
import { UserService } from "../_services/user.service";
import { DialogValoracionUsuarioComponent } from "../dialog-valoracion-usuario/dialog-valoracion-usuario.component";
import { DialogMensajeComponent } from "../dialog-mensaje/dialog-mensaje.component";
import { UserData } from "../menu-lateral/menu-lateral.component";

@Component({
  selector: "app-reservar-turno-medico",
  templateUrl: "./reservar-turno-medico.component.html",
  styleUrls: ["./reservar-turno-medico.component.css"],
})

export class ReservarTurnoMedicoComponent implements OnInit {
  listaEspecialidades: any[];
  especialidadSeleccionada: any = { codigo: null };
  listaEstablecimientos: any[];
  establecimientoSeleccionado: any = { codigo: null };
  loading = false;
  loadingFiltros = true;
  existenTurnos: boolean = false;
  formVacio = true;
  paramPeticionValoracion: boolean;
  userData: UserData;

  /* Variables para los mat-select */
  filtroTurnosForm: FormGroup = new FormGroup({
    especialidad: new FormControl(""),
    establecimiento: new FormControl(""),
    profesional: new FormControl(""),
    financiador: new FormControl(""),
  });

  especialidadesFiltradas: any[];
  establecimientosFiltrados: any[];
  profesionalesFiltrados: any[];
  listaProfesionales: any[];
  listaSucursales: any[];
  listaFinanciadores: any[] = [];

  tresCaracteres = false;
  hayEspeOProf = false;

  /********************************** */
  diasSemana: any[];
  pxCapturados = false;

  /********************************** */
  // listaTurnosTotal: any[];
  // listaTurnosActual: any[];
  desde: number;
  fin = false;
  errorAlCargarTurnos = false;
  filtrarTurnoUno = "S";
  /*showGoUpButton: boolean;
  private showScrollHeight: number;
  private hideScrollHeight: number;*/

  /********************************** */

  protected onDestroyProperty = new Subject<void>();
  bloqueElegido: any;
  mensajeTurnoError: any;
  tieneWebCheckIn: boolean;
  habilitarMsjeMutualProf: any;
  puedeTomarTurno: any;
  mutualElegida: any;
  fecha_seleccionado: any;
  nro_seleccionado: any;
  prof_seleccionado: any;
  primer_turno_libre: Turno | undefined;
  profesionalesFiltrosActuales: any;
  profesionalConsultado = null;
  loadingConsultaPorDia: boolean;
  diaSeleccionado: any;
  devolucionDiaSeleccionado: any;
  boxes_diaAtencion: any; 
  hayTurnos: boolean = false;
  card_prof_visible = false;
  currentUser: any;
  filtrarTurnosIOMA = 'N';
  

  constructor(
    private turnosService: TurnosService,
    private router: Router,
    public finciadoresService: FinanciadoresService,
    public urlResolver: UrlResolverService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private elementRef: ElementRef,
    private userService: UserService
  ) {
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === "popstate") {
        history.pushState(null, null, location.href);
      }
    });
  }

  async ngOnInit() {
    if ( localStorage.getItem("currentUser") !== null && localStorage.getItem("username") !== "visitante" ) {
      try {
        await this.recuperarMutuales();
        await this.recuperarPacienteTurnoUno();
        this.iniciarCarga();
      } catch (error) {
        console.error('Error al recuperar los datos.', error);        
      }    
    } else {
      this.authenticationService.login("visitante", "v1s1t4nt3").subscribe(
        (data: any) => {         
          if (data.token) {
            this.filtrarTurnosIOMA = 'S';
            this.iniciarCarga();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }

    this.getParamPeticionValoracion();
    this.filtroChanges();
    this.onChangeFiltros();
    this.calcularDiasSemana();
    this.recuperarUsuario();   
  }

  async recuperarMutuales() {
    this.userService.obtenerCoberturasPaciente().toPromise()
    .then(
      (mutuales) => {
        const tieneUnidadNegocio4 = mutuales[0].unidadNegocio === 4; // la ultima usada es ioma

        const tieneUnidadNegocioDistintaDe2 = mutuales[0].unidadNegocio !== 2; // la ultima usada es una distinta a particular

        if (tieneUnidadNegocio4 || !tieneUnidadNegocioDistintaDe2) {  //si tiene IOMA o solo tiene particular
            this.filtrarTurnosIOMA = 'S';
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  async recuperarPacienteTurnoUno() {
    await this.turnosService.getPacienteTurnoUno(localStorage.getItem("username")).toPromise()
      .then(
        (data) => {
          if (data.ok) {
            this.filtrarTurnoUno = "N";
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getParamPeticionValoracion() {
    this.urlResolver.getParamPeticionValoracion().toPromise().then(rpGetParamValoracion => {
      // console.log(rpGetParamValoracion)
      this.paramPeticionValoracion = (rpGetParamValoracion.mensaje === '1' ? true : false);
    }).catch(error => {
      console.error(error)
    })
  }

  recuperarUsuario() {
    this.userService.getUserData().subscribe(x => {
      // console.log(x)
      this.userData = x;
      this.currentUser = x.usuario;
    }, error => {
      console.error(error)
    })
  }


  iniciarCarga() {
    this.cargarEspecialidades();
    this.cargarEstablecimientos();
    this.cargarProfesionales();
    this.cargarFinanciadores();
  }

  desplegarCalendario(profesional) {
    let calendario = document.getElementById(
      profesional.nroMatricula
    ) as HTMLElement;
    calendario.style.transition = "all 200ms";
    calendario.style.height = profesional.heightCalendario + "px";
    profesional.open = true;
  }

  plegarCalendario(profesional) {
    let calendario = document.getElementById(
      profesional.nroMatricula
    ) as HTMLElement;
    calendario.style.height = 250 + "px";
    profesional.open = false;
  }

  // ngAfterViewChecked() {
  //   if (!this.pxCapturados) {
  //     this.guardarPxMaximoCalendario(this.listaTurnosActual);
  //   }
  // }

  /*Si esto lo tenemos en cuenta en el sp hay que sacarlo*/
  calcularFormVacio() {
    if (
      this.filtroTurnosForm.value.especialidad == "" &&
      this.filtroTurnosForm.value.establecimiento == "" &&
      typeof this.filtroTurnosForm.value.profesional == "string" &&
      this.filtroTurnosForm.value.financiador == ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  protected onChangeFiltros() {
    this.filtroTurnosForm
      .get("especialidad")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.hayEspeOProf = true;
        this.buscarTurnos();
      });

    this.filtroTurnosForm
      .get("financiador")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.buscarTurnos();
      });

    this.filtroTurnosForm
      .get("establecimiento")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.buscarTurnos();
      });

    this.filtroTurnosForm
      .get("profesional")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe((value) => {
        
        if (typeof value !== "string") {
          this.buscarTurnos();
        } else {
        }
      });
  }

  protected filtroChanges() {
    this.filtroTurnosForm
      .get("profesional")
      .valueChanges.pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtroProfesionales();
      });
  }

  protected filtroProfesionales() {
    if (!this.listaProfesionales) {
      return;
    }
    let search;

    if (typeof this.filtroTurnosForm.get("profesional").value === "string") {
      search = this.filtroTurnosForm.get("profesional").value;
    } else {
      search = this.filtroTurnosForm.get("profesional").value.apellido;
    }
    if (!search) {
      this.profesionalesFiltrados = this.listaProfesionales;
      return;
    } else {
      search = search.toLowerCase();
    }
    this.profesionalesFiltrados = this.listaProfesionales.filter(
      (profesional) => profesional.apellido.toLowerCase().indexOf(search) > -1
    );
  }

  onKeyUp() {
    if (this.filtroTurnosForm.get("profesional").value.length >= 3) {
      this.tresCaracteres = true;
    } else {
      this.tresCaracteres = false;
    }
  }

  displayFn(profesional: any): string | undefined {
    return profesional
      ? profesional.apellido.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
      : undefined;
  }

  /* protected filterBanksMultiEspecialidades() {
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.especialidadesFiltradas=this.listaEspecialidades;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro especialidades
    this.especialidadesFiltradas=
      this.listaEspecialidades.filter(
        (especialidad) => especialidad.nombre.toLowerCase().indexOf(search) > -1
      );
  }

  protected filterBanksMultiEstablecimientos() {

    let search;
    search = this.filtroTurnosForm.get("establecimiento").value;
    if (!search) {
      this.profesionalesFiltrados = this.listaProfesionales;
      this.establecimientosFiltrados=this.listaEstablecimientos;
      return;
    } else {
      search = search.toLowerCase();
    }
     //filtro establecimientos
      this.establecimientosFiltrados=
      this.listaEstablecimientos.filter(
        (establecimiento) => establecimiento.nombreCompleto.toLowerCase().indexOf(search) > -1
      );
  }

  */
  async cargarEspecialidades() {
    await this.turnosService
      .getEspecialidades()
      .toPromise()
      .then(
        (data) => {
          if (data.cantidadTotal > 0) {
            this.listaEspecialidades = data.especialidades;
            this.especialidadesFiltradas = data.especialidades;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async cargarEstablecimientos() {
    await this.turnosService
      .getEestablecimientos()
      .toPromise()
      .then(
        (data) => {
          if (data) {
            this.listaEstablecimientos = data;
            this.establecimientosFiltrados = data;            
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async cargarProfesionales() {
    let matricula = "null";
    await this.turnosService
      .getProfesionalesHorariosWeb(matricula)
      .toPromise()
      .then(
        (data) => {
          if (data) {
            this.profesionalesFiltrados = data;
            this.listaProfesionales = data;
            this.loadingFiltros = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async cargarFinanciadores() {
    await this.urlResolver
      .getHorusRestfullUrl()
      .toPromise()
      .then((data) => {
        if (data.ok) {
          this.finciadoresService
            .getFinanciadores(data.mensaje)
            .toPromise()
            .then(
              (resp) => {
                if (resp.ok) {
                  this.listaFinanciadores = resp.paginador.elementos.sort(
                    (a, b) => {
                      if (a.nombre < b.nombre) {
                        return -1;
                      }
                      if (a.nombre > b.nombre) {
                        return 1;
                      }
                    }
                  );
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      });
  }

  async buscarTurnosHorariosWeb() {
    let especialidad = this.filtroTurnosForm.get("especialidad").value.codigo;
    let profesional =
      this.filtroTurnosForm.get("profesional").value.nroMatricula;
    let establecimiento =
      this.filtroTurnosForm.get("establecimiento").value.codigo;
    let financiador =
      this.filtroTurnosForm.get("financiador").value.financiadorId;

    // console.log("especialidad: "+especialidad);
    // console.log("profesional: "+profesional);
    // console.log("establecimiento: "+establecimiento);
    // console.log("financiador: "+financiador);
    // console.log("filtrarTurnoUno: "+this.filtrarTurnoUno);

    await this.turnosService
      .getProfesionalesHorariosFiltros(
        especialidad,
        profesional,
        establecimiento,
        financiador,
        this.filtrarTurnoUno,
        this.filtrarTurnosIOMA
      )
      .toPromise()
      .then(
        (data) => {
          // console.log(data);

          if (
            this.comprobarFiltrosActuales(
              especialidad,
              profesional,
              establecimiento,
              financiador
            )
          ) {
            if (data.ok) {
              if (data.data.length > 0) {
                this.existenTurnos = true;
                this.profesionalesFiltrosActuales = this.agrupadoPorDiaYServicio(data.data)
              } else {
                this.existenTurnos = false;
              }
            } else {
              this.fin = true;
            }
            this.errorAlCargarTurnos = !data.ok;
            this.loading = false;
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  comprobarFiltrosActuales(
    especialidad,
    profesional,
    establecimiento,
    financiador
  ) {
    if (
      especialidad == this.filtroTurnosForm.get("especialidad").value.codigo &&
      profesional ==
        this.filtroTurnosForm.get("profesional").value.nroMatricula &&
      establecimiento ==
        this.filtroTurnosForm.get("establecimiento").value.codigo &&
      financiador ==
        this.filtroTurnosForm.get("financiador").value.financiadorId
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Función para convertir "DD/MM/YYYY" a una fecha válida
  parseDate(fechaStr: string): Date {
    const [day, month, year] = fechaStr.split('/');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  }

  async consultaPorDia(profesional, agendas) {
    this.profesionalConsultado = profesional.nroMatricula;
    this.loadingConsultaPorDia = true;
    this.diaSeleccionado = agendas;
    this.deshabilitar(this.boxes_diaAtencion, true);
    await this.turnosService
      .getProfesionalHorarioTurnos(agendas)
      .toPromise()
      .then(
        (data) => {
          //mergeo las fechas para que no haya columnas de dias repetidos
          this.devolucionDiaSeleccionado = data.data.reduce((resultado, elemento) => {
            const fechaExistente = resultado.find((item) => item[0].str_fecha === elemento.str_fecha);
            if (fechaExistente) {
              // Si la fecha ya existe, fusionar listadoTurnos
              fechaExistente.push(elemento)
              //fechaExistente.listadoTurnos = fechaExistente.listadoTurnos.concat(elemento.listadoTurnos);
            } else {
              // Si la fecha no existe, agregar el elemento completo
              resultado.push([elemento]);
            }
            return resultado;
          }, []);

          this.hayTurnos = this.devolucionDiaSeleccionado.some(agenda => agenda.length > 0);
                    
          // Ordenar las fechas
          this.devolucionDiaSeleccionado.sort((a, b) => {
            const fechaA = this.parseDate(a[0].str_fecha);
            const fechaB = this.parseDate(b[0].str_fecha);
            return fechaA.getTime() - fechaB.getTime();          
          });

          if (this.hayTurnos && this.fechaActual()) {
            let fechaActual = new Date();
            let horaActual = fechaActual.getHours();
            let minutoActual = fechaActual.getMinutes();
            let horariosAux = [];
            this.devolucionDiaSeleccionado[0][0].listadoTurnos.forEach(horario => {
              const [hora, minuto] = horario.str_hora.split(':');
              if (parseInt(hora) > horaActual || (parseInt(hora) === horaActual && parseInt(minuto) > minutoActual))
                horariosAux.push(horario);
            });
            if (horariosAux.length != 0) {
              this.devolucionDiaSeleccionado[0][0].listadoTurnos = horariosAux;
            } else {
              this.devolucionDiaSeleccionado.splice(0, 1);
              if (this.devolucionDiaSeleccionado.length === 0)
                this.hayTurnos = false;
            }       
          }
        },
        (error) => {
          console.log(error);
        }
      );


      
        
    this.loadingConsultaPorDia = false;

    this.deshabilitar(this.boxes_diaAtencion, false);
  }

  fechaActual() {
    let fechaActual = new Date();
    let diaActual = fechaActual.getDate();
    let mesActual = fechaActual.getMonth() + 1; // Se agrega 1 porque los meses se indexan desde 0
    let anioActual = fechaActual.getFullYear();

    let mesActualFormateado = mesActual.toString().padStart(2, '0')
    let diaActualFormateado = diaActual.toString().padStart(2, '0');

    let fechaActualFormateada = `${diaActualFormateado}/${mesActualFormateado}/${anioActual}`;
    
    return (this.devolucionDiaSeleccionado[0][0].str_fecha === fechaActualFormateada);
  }

  volverFn() {
    this.profesionalConsultado = null;
  }

  buscarTurnos() {
    this.loading = true;
    // this.listaTurnosActual = new Array();
    // this.listaTurnosTotal = new Array();
    this.desde = 0;
    this.fin = false;
    this.buscarTurnosHorariosWeb();
    this.profesionalConsultado = null;

    this.boxes_diaAtencion = document.getElementsByClassName("diaAtencionBox");
  }

  deshabilitar(boxes, value) {
    for (let i = 0; i < boxes.length; i++) {
      let element = boxes[i] as HTMLInputElement | HTMLButtonElement;
      element.disabled = value;
    }
  }

  mostrarDiasAtencion(turno: any) {
    sessionStorage.setItem(
      "matriculaProfesional",
      JSON.stringify(turno.nroMatricula)
    );
    sessionStorage.setItem(
      "establecimiento",
      JSON.stringify(turno.diasAtencion[0].numero)
    );
    // this.router.navigate(['/diasAtencion']);
    window.location.href = "/diasAtencion";
  }

  removeFiltro(filtro) {
    this.filtroTurnosForm.get(filtro.key).setValue("");
    if (this.tresCaracteres) {
      this.tresCaracteres = !this.tresCaracteres;
    }
    if (filtro.key === "especialidad") {
      this.hayEspeOProf = false;
    }
    this.buscarTurnos();
  }

  getNombreFiltro(filtro) {
    return filtro.nombreCompleto
      ? filtro.nombreCompleto
      : filtro.nombre
      ? filtro.nombre
      : filtro.apellido
      ? filtro.apellido
      : "";
  }

  getOcultarChip(filtro) {
    return typeof filtro.value === "string";
  }

  /***************************************************************************************/

  calcularDiasSemana() {
    this.diasSemana = this.getWeekDays("es-Es");
    //this.diasSemana = this.diasSemana.concat(this.diasSemana);
    /*this.diasSemana[0]={ 'nombreDia': ' Hoy ',
                        'fecha':  this.diasSemana[0].fecha};
    this.diasSemana[1]={ 'nombreDia': 'Mañana',
                        'fecha':  this.diasSemana[1].fecha};*/
  }

  getWeekDays(locale) {
    let baseDate = new Date();
    let weekDays = [];
    for (var i = 0; i < 31; i++) {
      weekDays.push({
        nombreDia: this.toTitleCase(
          baseDate.toLocaleDateString(locale, { weekday: "long" })
        ),
        fecha:
          (baseDate.getDate() > 9
            ? baseDate.getDate()
            : "0" + baseDate.getDate()) +
          "/" +
          (baseDate.getMonth() + 1 > 9
            ? baseDate.getMonth() + 1
            : "0" + (baseDate.getMonth() + 1)),
      });
      baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  acomodarAgendas(agendas) {
    agendas.forEach((profesional) => {
      //profesional.diasSemana = this.calcularIntervalosHoras(profesional.diasAtencion);
      profesional.infoTurnos = this.calcularIntervalosHoras(
        profesional,
        profesional.diasAtencion
      );
    });
  }

  clickPrimerTurnoLibre(profesional) {
    // this.tomarTurno(profesional.primer_turno_libre, profesional, profesional.nombreDia_primer_turno_libre);
    this.desplegarCalendario(profesional);
    let fecha_completa = profesional.primer_turno_libre.str_fecha;
    let cadena_fecha = fecha_completa.split("/");
    let id =
      "#" +
      profesional.nroMatricula +
      profesional.nombreDia_primer_turno_libre +
      cadena_fecha[0] +
      "/" +
      cadena_fecha[1];
    window.location.hash = id;
  }

  verificarPrimerTurnoLibre(primer_turno_libre, listadoTurnos) {
    for (let turno of listadoTurnos) {
      if (primer_turno_libre === undefined && turno.libre) {
        return turno;
      }
    }
  }
  /*Agrega a cada día, el arreglo con los turnos libres y reservados*/
  calcularIntervalosHoras(profesional, agenda) {
    // console.log(agenda);
    let turnosLibres = 0;
    const diasProfesional = JSON.parse(JSON.stringify(this.diasSemana));
    diasProfesional.forEach((dia) => {
      // console.log(dia.fecha);
      let i = 0;
      let seguir = true;
      while (i < agenda.length && seguir) {
        if (dia.fecha === agenda[i].str_fecha.substring(0, 5)) {
          turnosLibres =
            turnosLibres +
            agenda[i].listadoTurnos.reduce((n, turno) => n + turno.libre, 0);
          if (!dia.turnos) {
            dia.turnos = agenda[i].listadoTurnos;
          } else {
            dia.turnos = dia.turnos.concat(agenda[i].listadoTurnos);
          }

          if (profesional.primer_turno_libre === undefined) {
            // console.log(profesional);
            profesional.nombreDia_primer_turno_libre = dia.nombreDia;
            profesional.primer_turno_libre = this.verificarPrimerTurnoLibre(
              profesional.primer_turno_libre,
              agenda[i].listadoTurnos
            );
          }
        }
        /****** ESTO ES PARA NO RECORRER TODO EL ARREGLO; PARA USAR DEBE TRAER ORDENADO POR FECHA****/
        /*else {
          let baseDate = new Date();
          baseDate = new Date(baseDate.getFullYear(),dia.fecha.substring(3,5)-1,dia.fecha.substring(0,2));
          let dateArray = new Date (agenda[i].str_diaPrimerTurnoLibre.substring(6,11),
                    agenda[i].str_diaPrimerTurnoLibre.substring(3,5)-1,
                    agenda[i].str_diaPrimerTurnoLibre.substring(0,2));
          console.log(baseDate);
          console.log(dateArray);
          console.log(baseDate.getTime() , dateArray.getTime());
          if(baseDate.getTime() < dateArray.getTime()){
            console.log('entro al if para cortar el while');
            seguir=false;
          }
        }*/
        i++;
      }
    });

    return { diasSemana: diasProfesional, cantTurnosLibres: turnosLibres };
  }

  guardarPxMaximoCalendario(agendas) {
    if (agendas) {
      agendas.forEach((profesional) => {
        if (!profesional.heightCalendario) {
          let calendProf = document.getElementById(
            profesional.nroMatricula
          ) as HTMLElement;
          profesional.heightCalendario = calendProf.clientHeight;
          //console.log('el calculo dio: ', profesional.heightCalendario);
          if (profesional.heightCalendario <= 250) {
            let verMasProf = document.getElementById(
              profesional.nroMatricula + "verMas"
            ) as HTMLElement;
            verMasProf.hidden = true;
          } else {
            this.plegarCalendario(profesional);
          }
        }
      });
      this.pxCapturados = true;
    }
  }
  /************************** Rerserva de Turnos ****************************/
  logueado() {
    return localStorage.getItem("username") !== "visitante";
  }

  quiereTurno(str_hora, str_fecha, numero, profesional, str_horaInicioAtencion, str_vigenciaDesde) {
    let bloqueTurno = {
      matricula: profesional.nroMatricula,
      profesional: profesional.apellido,
      str_hora: str_hora,
      str_horaDesde: str_horaInicioAtencion,
      str_fecha: str_fecha,
      numero: numero,
      nombreDia: this.diaSeleccionado[0].nombreDia,
      str_desdeVigencia: str_vigenciaDesde,
    };

    this.tomarTurno(bloqueTurno);
  }

  tomarTurno(bloqueTurno) {
    if (!this.logueado()) {
      this.abrirDialogMensajeLogin();
    } else {
      // bloqueTurno.profesional=profesional.apellido;
      // bloqueTurno.nombreDia = nombreDia;
      this.reservarTurno(bloqueTurno);
    }
  }

  reservarTurno(bloqueTurno) {
    this.fecha_seleccionado = bloqueTurno.str_fecha;
    this.nro_seleccionado = bloqueTurno.numero;
    this.prof_seleccionado = bloqueTurno.profesional;

    if (
      sessionStorage.getItem("bloqueElegido") != "undefined" &&
      JSON.parse(sessionStorage.getItem("bloqueElegido")) != null
    ) {
      this.liberarTurno(JSON.parse(sessionStorage.getItem("bloqueElegido")));
    }
    sessionStorage.setItem("bloqueElegido", JSON.stringify(bloqueTurno));
    this.bloqueElegido = bloqueTurno;

    // console.log(bloqueTurno);

    this.turnosService.tomarTurno(bloqueTurno).subscribe(
      async (x) => {
        // console.log(x);

        this.mensajeTurnoError = x.mensaje;
        this.puedeTomarTurno = x.ok;

        if (this.puedeTomarTurno) {
          this.abrirDialogConfirmarTurno();
        } else {
          this.abrirDialogMensajeTurnoDenegado(this.mensajeTurnoError);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  liberarTurno(bloqueTurno) {
    this.turnosService.liberarTurno(bloqueTurno).subscribe((x) => {
      //window.location.reload();
    });
    this.bloqueElegido = null;
    sessionStorage.setItem("bloqueElegido", null);
  }

  irTurnosHistorial() {
    // this.router.navigate(['/turnos-reservados']);
    window.location.href = "/turnos-reservados";
  }

  irLogin() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  openDialogValoracionUsuario() {
    this.dialog.open(DialogValoracionUsuarioComponent, {
      width: 'auto',
      data: null,
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe(() => {
      this.irTurnosHistorial();
    })
  }

  abrirDialogConfirmarTurno() {
    this.dialog.open(DialogConfirmarTurnoComponent, {
      width: "500px",
      disableClose: true,
      data: { bloqueElegido: this.bloqueElegido },
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        if (this.userData.realizoValoracion === 'N' && this.paramPeticionValoracion) {
          this.openDialogValoracionUsuario();
        } else {
          this.irTurnosHistorial();
        }
      }

      this.fecha_seleccionado = "";
      this.nro_seleccionado = "";
      this.prof_seleccionado = "";
    }, (error) => {
      console.log(error);
    });
  }

  abrirDialogMensajeLogin() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        width: "500px",
        disableClose: true,
        data: {
          titulo: "Debe estar logeado para reservar un turno",
          mensaje: "¿Desea logearse ahora?",
          cerrar: { color: "warn", texto: "No" },
          confirmar: { color: "primary", texto: "Si" },
          estasPor: false
        },
        panelClass: 'dialog-sin-overflow',
        
      })
      .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.irLogin();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  abrirDialogMensajeTurnoDenegado(mensaje) {
    // this.dialog.open(DialogConfirmacionComponent, {
    //   disableClose: true,
    //   data: {
    //     titulo: "Turno denegado",
    //     mensaje: mensaje,
    //     estasPor: false
    //   },
    //   panelClass: 'dialog-sin-overflow'
    // });
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        ok: false,
        titulo: "Turno denegado",
        mensaje: mensaje,
        estasPor: false
      },
      panelClass: 'dialog-sin-overflow'
    });
    this.fecha_seleccionado = "";
    this.nro_seleccionado = "";
    this.prof_seleccionado = "";
  }

  calcularColorTurno(codEstablecimiento): object {
    let cod: number = +codEstablecimiento;
    // console.log(cod,':',(50*(cod-1)));
    return {
      "background-color":
        "rgb(" + 100 * (cod - 1) + "," + (160 + 50 * (cod - 1)) + ",255)",
    };
  }

  /************************** Scroll Inifnito *********************************/
  // mostrarMas() {
  //   this.listaTurnosActual.forEach((elemento) => {
  //     this.listaTurnosTotal.push(elemento);
  //   });
  //   this.desde = this.desde + this.listaTurnosActual.length;
  // }

  // onScroll() {
  //   if (!this.loading) {
  //     //console.log('listaTunosTotal.lenght: ',this.listaTurnosTotal.length);
  //     // console.log('entro al onScroll, fin: ',this.fin);
  //     if (!this.fin) {
  //       this.loading = true;
  //       this.buscarTurnosHorariosWeb();
  //     }
  //   }
  // }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  verificarOcultamientoSpinner(turno) {
    if (
      turno.str_fecha != this.fecha_seleccionado ||
      turno.numero != this.nro_seleccionado ||
      turno.profesional != this.prof_seleccionado
    ) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(dateString: string): string {
    const parts = dateString.split("/");
    if (parts.length !== 3) {
      return ""; // La fecha no tiene el formato esperado, devuelve una cadena vacía o maneja el error según tus necesidades.
    }
    const day = parts[0];
    const month = parts[1];
    return `${day}/${month}`;
  }

  chequearSucursal(sucursal){
    if(sucursal != 'Hospital Español'){
      return ', '+sucursal;
    }
  }

  toggleCard() {
    Object.keys(this.filtroTurnosForm.controls).forEach(key => {
      this.filtroTurnosForm.get(key).setValue('');
    });

    this.card_prof_visible = !this.card_prof_visible;
  } 

  goToprofesionales() {
    // Guardo la alerta de la redireccion
    this.userService.guardarAlertaRedireccion('/profesionales', this.currentUser).subscribe(
      respuestaGuardar=>{
        // console.log(respuestaGuardar); 
        window.location.href = '/profesionales';
      },
      error => {
        console.log(error);            
      }
    ); 
  }

  goToTurnos() {
    // Guardo la alerta de la redireccion
    this.userService.guardarAlertaRedireccion('/turnos', this.currentUser).subscribe(
      respuestaGuardar=>{
        // console.log(respuestaGuardar); 
        window.location.href = '/turnos';           
      },
      error => {
        console.log(error);            
      }
    );  

  }

  // Función para combinar agendas con el mismo dia
  agrupadoPorDiaYServicio(arr) {
    for (const prof of arr) {
      let res= [];
      prof.diasAtencion.forEach((element) => {
        let a = {
          desdeHora: element.desdeHora,
          dia: element.dia,
          nroMatricula: prof.nroMatricula,
          desdeVigencia: element.desdeVigencia,
          hastaHora: element.hastaHora,
          servicio: element.servicio,
          nombreDia: element.nombreDia,
          sucursal: element.sucursal,
          especialidad: element.especialidad,
          filtrarTurnoUno: this.filtrarTurnoUno,
        }
        const i = res.findIndex(e => (e[0].dia === element.dia && e[0].servicio === element.servicio && e[0].sucursal === element.sucursal && e[0].especialidad[0].codigo === element.especialidad[0].codigo));
        if (i > -1) { // se repite el dia
          res[i].push(a)
        } else {
          res.push([a])
        }
      });
      prof.diasAtencion= res
    }
    return arr;
  }


  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.card_prof_visible = false;
    }
  }
  
}
