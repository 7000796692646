import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAgregarCoberturaComponent } from '../dialog-agregar-cobertura/dialog-agregar-cobertura.component';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { Platform } from '@angular/cdk/platform';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mis-coberturas',
  templateUrl: './mis-coberturas.component.html',
  styleUrls: ['./mis-coberturas.component.css']
})

export class MisCoberturasComponent implements OnInit {

  mutualesPaciente: any [] = [];
  listaFinanciadoresCargados: Number [] = [];
  cargando: boolean = true;
  currentUser;
  esTemporal: boolean;
  estadoValidacion: string;
  filtrosValidaciones: any = {};
  version_mobile: boolean;
  

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public platform: Platform,
    public router: Router,
  ) { 
    // Establecer si es version mobile
    (platform.ANDROID || platform.IOS) ? this.version_mobile=true : this.version_mobile=false;

    // para las pruebas
    this.version_mobile=false;
  }

  ngOnInit() {    
    this.obtenerDatosUsuario();
  }

  obtenerDatosUsuario() {
    this.userService.getUserData().toPromise().then((response) => {
      //console.log(response);
      this.currentUser = response;     
      
      if(this.validacionAprobada()){        
        this.getCoberturasPacientes();
      }else{
        this.cargando = false;
      }
      this.filtrosValidaciones.estado = 'PENDIENTE';
      this.filtrosValidaciones.solicitudId = this.currentUser.nroDocumento;
    }, (error) => {
      console.log(error);
      this.cargando = false;
    });
  }

  getCoberturasPacientes() {
    this.userService.obtenerCoberturasPaciente().toPromise().then(resp => {
      // console.log(resp)
      if (resp) {
        this.mutualesPaciente = resp;
      }  
      this.cargando = false;    
    }, error => {
      console.log(error);
      this.cargando = false;
    });    
  }

  async actualizarCobertura(mutual) {
    this.userService.actualizarCobertura(mutual).toPromise().then(resp => {
      // console.log(resp)
      if (mutual.mutual === 14) { // SI ES PARTICULAR NO SE VA A ELIMINAR, MUESTRO MSJE
        this.dialog.open(DialogMensajeComponent, {
          data: resp,
          panelClass: 'dialog-sin-overflow'
        })
      }
    }, error => {
      console.log(error)
    })
  }

  async openDialogAgregarCobertura() {
    // console.log(this.listaFinanciadoresCargados);
    this.dialog.open(DialogAgregarCoberturaComponent, {
      //width: '50%'
      data: {
        listaFinanciadores: this.listaFinanciadoresCargados
      }
    }).afterClosed().subscribe(close => {
      this.cargando = true;
      this.ngOnInit();
    })
  }

  async openDialogConfirmacion(mutual) {
    
    this.dialog.open(DialogConfirmacionComponent, {
      //width: '50%',
      data: {
        titulo: 'Confirmar borrado',
        mensaje: "eliminar tu cobertura con los siguientes datos:",
        nombreMutual: mutual.nomFin,
        nroCarnet: mutual.nroCarnet,
        estasPor: true
      },
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe((confirma: boolean) => {
      if (confirma) {
        this.listaFinanciadoresCargados = [];
        this.cargando = true;
        mutual.usuario = this.currentUser.usuario;
        mutual.estado = 'INA';
        this.actualizarCobertura(mutual);
        this.ngOnInit();
      }
    });
  }


  validacionAprobada(){      
    return this.currentUser.tipoDocumento != 9;
  }


  validacionPendiente(){    
    return (this.currentUser.tipoDocumento == '9' && (this.currentUser.estadoValidacion=='PENDIENTE' ||
    this.currentUser.estadoValidacion=='CARGANDO')) ;
  }

// denegada sigue 9
// pendiente sigue 9
// aprobada distinto 9

  goToValidar(){
    sessionStorage.setItem('voyAValidar', 'si');
    // this.router.navigate(['/register']);
    window.location.href='/register';
  }

}
