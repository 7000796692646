<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <div class="container">
        <img
          class="img-fluid mx-auto d-block mb-2"
          src="../../assets/images/hospital-del-dia/hospitaldedia.jpg"
        />
        <h6>Practicas:</h6>
        <p>
          Tratamientos de quimioterapia, inmunoterapia, planes de hidratación,
          infusión de drogas (vía subcutánea y endovenosa), transfusiones de
          sedimento globular y plaquetas, tratamientos inmunosupresores para
          pacientes trasplantados, tratamientos antibióticos y recuperación post
          anestésica de cirugías menores.
        </p>

        <br />

        <h6>Requisitos básicos:</h6>
        <ul>
          <li>Contar con un médico del staff de profesional del Hospital.</li>
          <li>Orden de internación e indicaciones médicas.</li>
          <li>Documento Nacional de Identidad (DNI).</li>
          <li>Carnet de la obra social.</li>
          <li>
            Último recibo de sueldo y constancia del Plan Meppes (afiliados a
            Ioma).
          </li>
          <li>
            Último análisis de Laboratorio para el caso de transfusiones e
            infusiones de hierro.
          </li>
        </ul>

        <br />

        <h6>Contacto:</h6>
        <img
          src="../../assets/images/hospital-del-dia/icono_whatsapp.png"
        /><strong>+54 9 221 504-3636</strong>
        <br />
        <strong>De lunes a viernes de 7 a 14:30.</strong>
        <br />
        <strong>Teléfono:</strong> (0221) 412-9400, interno 1381.
        <br />
        <strong>Ubicacion:</strong> Calle 9 N 175 entre 35 y 36, Segundo Piso
        <br />
        <strong>Mail:</strong> hospitaldedia@hospitalespanol.org.ar
        <br />
        <br />

        <p>
          El Hospital de Día es un sector destinado para el desarrollo de
          prácticas ambulatorias y de rápido recambio. Consta de tres salas con
          diez lugares equipados con bomba de infusión, distribuidos en ocho
          sillones y dos camas.
        </p>
        <p>
          Posee personal exclusivo: administrativo (para gestión de turnos,
          admisión y facturación); de enfermería (para la administración de los
          tratamientos y seguimiento de cada caso de forma permanente); y de
          Farmacia (para el almacenamiento, preparación y control de la
          medicación).
        </p>
        <p>
          La preparación de la medicación se realiza en una cabina con sistema
          de aislamiento bajo estrictas normas de bioseguridad donde también se
          aloja un banco de drogas a fin de que el proceso de manipulación y
          control sea más ágil a la hora de atender turnos de demanda
          espontánea.
        </p>
      </div>
    </div>
  </div>
</div>
