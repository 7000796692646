<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <!-- DERECHOS DE EL/LA RECIÉN NACIDO/A -->
    <div class="subtitulo titulo-recien-nacido" style="margin-top: -2vh;margin-bottom: 2vh;">
      Derechos del paciente
    </div>

    <div class="wrapper">

      <!-- FILA -->
      <div class="texto_recuadrado texto_recuadrado_1">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/17.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A recibir una atención médica</b> oportuna, segura y de calidad.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_2">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/16.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A obtener una segunda opinión</b> sobre el diagnóstico, pronóstico o tratamiento relacionado con su estado
          de salud;
          y/o ser derivado/a con otro/a profesional para la continuidad del tratamiento.
        </div>
      </div>


      <!-- FILA -->
      <div class="texto_recuadrado texto_recuadrado_3">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/18.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A que durante la elaboración o manipulación de la documentación clínica</b> se guarde la debida reserva.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_4">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/19.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A acceder a su historial clínico actualizado,</b> en cualquier momento y sin condicionamientos.
        </div>
      </div>


      <!-- FILA -->
      <div class="texto_recuadrado texto_recuadrado_5">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/20.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A obtener el alta médica en cuanto sea posible, </b> y recibir instrucciones de cómo cuidarse en el hogar.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_6">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/22.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A retirarse voluntariamente</b> de la Institución interrumpiendo la hospitalización o atención por Guardia.
        </div>
      </div>

      <!-- FILA -->
      <div class="texto_recuadrado texto_recuadrado_7">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/23.png">
        </div>
        <div class="texto-derecho-norma" style="padding-bottom: 10vh;">
          <b>A ser atendido/a con respeto y dignidad,</b> sin menoscabo y distinción alguna producto de sus ideas,
          creencias religiosas,
          políticas, condición socio económica, raza, género, orientación sexual o cualquier otra condición de su
          personalidad.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_8">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/24.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A que sea respetada su privacidad e intimidad personal.</b>
        </div>
      </div>

      <!-- FILA -->
      <div class="texto_recuadrado texto_recuadrado_9">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/25.png">
        </div>
        <div class="texto-derecho-norma">
          <b> A recibir apoyo emocional y asistencia espiritual y moral</b> de un/a representante de su religión.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_10">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/1.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A recibir adecuación del esfuerzo terapéutico,</b> cuidados paliativos y acompañamiento en los últimos
          momentos de vida.
        </div>
      </div>

      <!-- FILA -->
      <div class="texto_recuadrado texto_recuadrado_11">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/2.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A prestar su consentimiento informado</b> de aceptar y/o rechazar terapias y/o procedimientos médicos o
          biológicos.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_12">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/3.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A recibir evaluación completa</b> del dolor y rápido tratamiento del mismo.
        </div>
      </div>

      <!--  FILA -->
      <div class="texto_recuadrado texto_recuadrado_13">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/4.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A recibir información oportuna</b> vinculada a su estado de salud; opciones terapéuticas disponibles,
          riesgos y/o eventos adversos y alternativas posibles.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_14">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/5.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A ser informado/a a través</b> de los medios técnicos que se adecuen a sus requerimientos (lenguaje de
          señas, braile).
        </div>
      </div>

      <!--  FILA -->
      <div class="texto_recuadrado texto_recuadrado_15">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/21.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A que sea reconocida su identidad de género autopercibida.</b>
        </div>
      </div>

      <!-- DERECHOS DE PERSONAS GESTANTES O CON CAPACIDAD DE GESTAR -->
      <!--  FILA -->
      <div class="subtitulo titulo-gestantes">
        Derechos de personas gestantes o con capacidad de gestar
      </div>

      <div class="texto_recuadrado texto_recuadrado_16">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/13.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A que sean respetados sus derechos sexuales y reproductivos.</b>
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_17">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/6.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A ser informada desde el embarazo </b> sobre los beneficios de la lactancia materna y recibir apoyo para
          amamantar.
        </div>
      </div>

      <!--  FILA -->
      <div class="texto_recuadrado texto_recuadrado_18">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/7.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A ser considerada en su situación</b> respecto del proceso de nacimiento como persona sana.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_19">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/8.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A tener un parto natural, respetuoso</b> de los tiempos biológicos y psicológicos,
          evitando prácticas invasivas y suministro de medicación que no estén justificados.
        </div>
      </div>

      <!--  FILA -->
      <div class="texto_recuadrado texto_recuadrado_20">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/9.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A recibir información comprensible, una atención segura y ser derivada inmediatamente para el acceso
            a la interrupción voluntaria/legal del embarazo (IVE / ILE)</b> y la atención post-aborto.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_21">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/10.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A ser informada acerca de los distintos métodos anticonceptivos</b> eficaces para prevenir embarazos no
          deseados.
        </div>
      </div>


      <!--  FILA -->
      <div class="texto_recuadrado texto_recuadrado_22">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/11.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A estar acompañada por una persona de su confianza y elección </b> durante todo el proceso de embarazo,
          parto y puerperio.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_23">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/12.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A tener cerca a tu hijo/a durante la permanencia en el Hospital,</b> siempre y cuando el/la recién nacido/a
          no requiera de cuidados especiales.
        </div>
      </div>


      <!-- DERECHOS DE EL/LA RECIÉN NACIDO/A -->
      <div class="subtitulo titulo-recien-nacido">
        Derechos de el/la recién nacido/a
      </div>

      <!--  FILA -->
      <div class="texto_recuadrado texto_recuadrado_24">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/14.png">
        </div>
        <div class="texto-derecho-norma">
          <b>A ser internado/a junto a su madre</b> y que la misma sea lo más breve posible, considerando el estado de
          salud de ambos/as.
        </div>
      </div>

      <div class="texto_recuadrado texto_recuadrado_25">
        <div class="icono icono-derecho-norma">
          <img class="iconos-derechos-normas" src="../../assets/images/iconos-derechos-normas/15.png">
        </div>
        <div class="texto-derecho-norma">
          <b> A ser identificado/a de manera inequívoca</b> al momento del nacimiento y egreso/salida del Hospital.
        </div>
      </div>

      <!-- NORMAS DE CONVIVENCIA -->
      <div class="subtitulo titulo-normas">
        Normas de convivencia
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-1">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/acompanante.png">
        </div>
        <div class="texto-derecho-norma">
          Un/a acompañante por cama.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-2">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/retiro.png">
        </div>
        <div class="texto-derecho-norma">
          Retirarse de la habitación cuando: el/la paciente es atendido/a; ingresa el/la médico/a; o al momento de la
          limpieza.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-3">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/sin-ninos.png">
        </div>
        <div class="texto-derecho-norma">
          Se recomienda no asistir a la visita con niños/as.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-4">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/sin-ruidos.png">
        </div>
        <div class="texto-derecho-norma">
          No perturbar con ruidos fuertes.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-5">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/higiene-manos.png">
        </div>
        <div class="texto-derecho-norma">
          Higiene de manos al ingresar a la habitación y en el contacto con el/ la paciente.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-6">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/cuidar-instalaciones.png">
        </div>
        <div class="texto-derecho-norma">
          Cuidar las instalaciones (mobiliario y equipos médicos).
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-7">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/prohibido-fumar.png">
        </div>
        <div class="texto-derecho-norma">
          Prohibido fumar
          dentro de la Institución.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-8">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/prohibido-tomar-mate.png">
        </div>
        <div class="texto-derecho-norma">
          Prohibido tomar mate
          dentro de la habitación.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-9">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/prohibido-sentarse.png">
        </div>
        <div class="texto-derecho-norma">
          Prohibido ocupar y/o sentarse en la cama vacía.
        </div>
      </div>

      <div class="texto_recuadrado texto-recuadrado-norma-10">
        <div class="icono icono-derecho-norma">
          <img class="iconos-normas" src="../../assets/images/normas-de-convivencia/prohibido-sacar-fotos.png">
        </div>
        <div class="texto-derecho-norma">
          Prohibido sacar fotos y/o filmar dentro del Hospital.
        </div>
      </div>
    </div>
  </div>
</body>

</html>