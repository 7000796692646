<html>
  <head>
    <meta name="robots" content="noindex">
  </head>
  <body>
    <div class="container-fluid">
      <div class="row d-flex justify-content-center mt-1">
        <div class="col-md">
          <h4>Obras sociales y prepagas</h4>
          <div class="container">
            <p>
              Para consultar sobre los planes adheridos comuniquese con su obra
              social o prepaga.
            </p>
            <ul>
              <li>Amemop</li>
              <li>Amffa</li>
              <li>Asome</li>
              <li>Aca Salud</li>
              <li>Agua y Energ&iacute;a Capital Federal</li>
              <li>Apres SA.</li>
              <li>Apsot</li>
              <li>Asociaci&oacute;n Esclesi&aacute;stica San Pedro</li>
              <li>Austral Omi</li>
              <li>Axa Assistance SA.</li>
              <li>Banco Provincia</li>
              <li>Casa</li>
              <li>
                Caja de Previsi&oacute;n Social para Escribanos Provincia de Buenos
                Aires
              </li>
              <li>Consolidar Salud SA.</li>
              <li>Corte Suprema de Justicia Prestador</li>
              <li>Dasuten</li>
              <li>Europ Assistance Argentina SA.</li>
              <li>Federada Salud Nacional</li>
              <li>Femeba Salud</li>
              <li>Femeba Salud - Luz y Fuerza</li>
              <li>Fundaci&oacute;n Comei</li>
              <li>Fundacion SS. Techint</li>
              <li>Futbolistas Argentinos Agremiados (Ambu)</li>
              <li>Galeno Argentina SA.</li>
              <li>Hip&oacute;dromo de La Plata</li>
              <li>Ioma</li>
              <li>Luis Pasteur</li>
              <li>Mita</li>
              <li>Medicus</li>
              <li>Medlife</li>
              <li>Viajantes Vendedores</li>
              <li>Osap</li>
              <li>Actores</li>
              <li>Agentes de Propaganda M&eacute;dica</li>
              <li>Osde</li>
              <li>Osmeba Salud</li>
              <li>Ospe</li>
              <li>Osppra</li>
              <li>Ospsa</li>
              <li>Ossimra</li>
              <li>Personal de Escriban&iacute;as</li>
              <li>Omint SA.</li>
              <li>Opdea</li>
              <li>Osfentos</li>
              <li>Ospida</li>
              <li>Osseg</li>
              <li>Patrones de Cabotaje de R&iacute;os y Puertos</li>
              <li>Primed SA.</li>
              <li>Sadaic</li>
              <li>Serve Salud</li>
              <li>Swiss Medical SA.</li>
              <li>Trabajadores de Farmacia</li>
              <li>Universal Assistance SA.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </body>
</html>
