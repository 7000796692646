<html>

<head>
    <meta name="robots" content="noindex">
</head>

<body>
    <div>
        <h3 class="subtitulo">Atención de Pacientes Quirúrgicos - Cirugía Programada</h3>
        <h6 class="subtitulo size-reducido">Contacto</h6> <br>
        <div class="texto">
            Teléfono: (0221) 412 9400, interno 1225 <br>
            Mail: cirugias.programadas@hospitalespanol.org.ar
        </div>
        <br>
        <br>
        <div class="texto">
            En caso de tener una Cirugía Programada, deberás dirigirte primero al puesto de Atención 
            de Pacientes Quirúrgicos, ubicado en planta baja al lado de la Central de Turnos. Allí se 
            te hará entrega de los CI (Consentimientos Informados) y se te asignarán los turnos (que 
            correspondan) para los siguientes estudios Pre Quirúrgicos:
            <ul class="lista">
                <li>Hemostasia básica | grupo y factor</li>
                <li>Rutina de laboratorio</li>
                <li>Electrocardiograma</li>
                <li>Vacuna antitetánica</li>
                <li>Placa de tórax (excepto embarazadas)</li>
            </ul>
        </div>
        <br>
        <div class="texto">
            Todo/a paciente que requiera internación para cirugía, NECESITARÁ DADORES DE SANGRE 
            (entre 2 y 12 según el procedimiento). <br>
            Cirugía General (2) | Cardiotorácica (4) | 
            Reparación de Aneurisma (6) | By Pass Coronario (4) | Cirugía Cardivascular (12) | 
            Ortopedia (4) | Reemplazo total de Cadera (4) | Reemplazo total de Rodilla (4) | 
            Obstetricia/ Ginecología (4) | Histerectomía Radical (4) | Placenta Acreta (6) | 
            Urología (2) | Nefrectomía Radical (3) | Trasplante Renal (2).
        </div>

        <b>IMPORTANTE</b> <br><br>
        <div class="texto">
            Si para tu cirugía se necesita algún tipo de accesorio (por ejemplo, una prótesis), por 
            favor deberás comunicarte con una antelación de 48 horas con tu médico/a para constatar 
            que el pedido se haya realizado y confirmar la provisión del mismo. Si tenes prescripta 
            alguna medicación crónica deberás informárselo al/a la médico/a y traerla de tu domicilio.
        </div>

        <h6 class="subtitulo size-reducido">Pre quirúrgico cardiológico - Electrocardiograma</h6> <br>
        <div class="texto">
            Podes solicitar turno para Electrocardiograma con la orden médica en mano, en el sector de Atención de 
            Pacientes Quirúrgicos.
        </div>

        <h2 class="subtitulo">Rutina de laboratorio</h2>
        <h6 class="subtitulo size-reducido">Laboratorio - subsuelo</h6>
        <h6 class="subtitulo size-reducido">Contacto</h6>  <br>
        <div class="texto">
            Línea directa: (0221) 412 9440 | Whatsapp: (221) 420 0794 <br>
            Horario de atención: lunes a sábados desde las 7:00hs.
        </div>

        <h4 class="subtitulo">Hemostasia básica | Grupo y Factor</h4>
        <h6 class="subtitulo size-reducido">Hematología | Hemoterapia - subsuelo</h6> 
        <h6 class="subtitulo size-reducido">Contacto</h6> <br>
        <div class="texto">
            Whatsapp: (221) 504 7019 <br>
            Correo: hematologia@hospitalespanol.org.ar <br>
            Presentarse con ayuno previo de 3 horas (de grasas y lácteos) <br>
            Extracciones de lunes a sábados de 7.00 a 10.00hs.
        </div>

        <h2 class="subtitulo">Placa de tórax</h2>
        <h6 class="subtitulo size-reducido">Diagnóstico por Imágenes - subsuelo</h6>
        <h6 class="subtitulo size-reducido">Contacto</h6> <br>
        <div class="texto">
            Whatsapp: (221) 476 9962 <br>
            Horario de atención: lunes a viernes de 7.15 a 21.00hs | sábados de 7.15 a 14.00hs. <br><br>
            La placa de tórax se realiza por orden de llegada de lunes a viernes de 8.00 a 20.00hs. y sábados de 8.00 a
            13:00hs.
        </div>

        <h2 class="subtitulo">Preparación para la Cirugía</h2>
        <h6 class="subtitulo size-reducido">Presentarse en ayunas de 12 horas.</h6> <br> <br>
        <div class="texto">
            Bañarse dentro de las dos horas previas a la cirugía y previamente cortar las uñas al ras, cuidando de no
            lesionar la piel. Si están pintadas quitar todo el esmalte. En caso de cirugía con colocación de implante,
            bañar con jabón antiséptico también los dos días previos y dos horas antes de la cirugía.
        </div>
        <br>
        <div class="texto">
            <b>¿Cómo realizar el baño?</b> Humedecer el cuerpo con abundante agua. Salir de la misma y lavar con jabón
            antiséptico o jabón neutro nuevo. Hacer hincapié en la cabeza (cabello, cuello, boca), axilas, ombligo, uñas
            de pies y manos, ingle y zona perineal. Enjuagar y secar con mayor meticulosidad que lo habitual. No
            utilizar perfume, desodorante, talco, maquillaje u otros cosméticos. Colocar ropa limpia.
        </div>
        <br>

        <div class="texto">Antes de ir al quirófano, Enfermería brindará el equipo de ropa quirúrgica (botas, bata,
            gorro, tapaboca) que deberá utilizar quitándose todas las prendas de vestir, incluyendo la ropa interior.
        </div>
        <br>

        <div class="texto">Si tiene prótesis dentaria -parcial o total-, ocular y/o auditiva, se solicitará dejarlas con
            un familiar o acompañante antes de ser trasladado al quirófano.</div>
        <br>

        <div class="texto">Se sugiere presentarse en el Hospital sin accesorios y elementos personales como alianzas o
            bijouterie.</div>

        <b>IMPORTANTE</b><br><br>
        <div class="texto">
            Todo/a paciente que requiera internación para cirugía necesitará dadores de
            sangre (entre 2 y 12 según el procedimiento).<br>
            Cirugía General (2) - Cardiotorácica (4) - Reparación de Aneurisma (6) - By Pass Coronario
            (4) - Cirugía Cardiovascular (12) - Ortopedia (4) - Reemplazo total de Cadera (4) - Reemplazo total de
            Rodilla (4) - Obstetricia/ Ginecología (4) - Histerectomía Radical (4) - Placenta Acreta (6) - Urología (2)
            - Nefrectomía Radical (3) - Trasplante Renal (2)</div>
        <br>

        <div class="texto cursiva"> 
            <b>No suspender los medicamentos que toma excepto por indicación médica.</b> <br>
        </div>

        <div class="texto">
            Dependiendo del tipo de cirugía y si está indicado por el médico/a, se procederá al rasurado
            correspondiente.
        </div>

    </div>

</body>

</html>