<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Medicina Nuclear</h4>
        <div class="container">
          <p>
            Para solicitar turno de <strong>Cardiología y General</strong> comunicarse al:
          </p>
          <p>
            <img
              src="../../assets/images/ecocardiogramas/icono_whatsapp.png"
            />
            WhatsApp: <strong> +54 9 221
            476-9962 </strong> de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Turno presencial: Central de Turnos, Planta Baja de lunes a
            viernes de 7:00 a 21:30hs y sábados de 7:00 a 14:30hs.
          </p>
  
          <p>
            Para solicitar turno de <strong>Endocrinología</strong> comunicarse al:
          </p>
          <p>
            <img
              src="../../assets/images/ecocardiogramas/icono_whatsapp.png"
            />
            WhatsApp: <strong> +54 9 221
            476-9962 </strong> de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.
          </p>
          <p>
            Turno presencial: Central de Turnos, Planta Baja de lunes a
            viernes de 7:00 a 21:30hs y sábados de 7:00 a 14:30hs.
          </p>
          <p>
            *presentar orden médica y confirmar teléfono de contacto. 
            El técnino se comunicará con el paciente para coordinar turno (fecha y horario).
          </p>
  
          <p><strong>Equipo Médico: </strong></p>
  
          <p>Cardiología: Dra. Silvina Ponce | Dr. Marcelo Uriarte | Dr. Anibal Mele</p>
          <p>General: Dr. Claudio Gianbelluca | Dra. Mónica Sarti </p>
          <p>Endocrinología: Dra. Maria Cristina Vucetich</p>
  
          <p><strong>Estudios:</strong></p>
          <ul>
            <li>Curva de captación tiroidea | Cámara gama planar estática tiroidea/paratiroides</li>
            <li>Barrido corporal total para ca. de tiroides pre y post dosis</li>
            <li>Dosis terapéutica para tirotoxicosis | Dosis terapéutica para ca. tiroides</li>
            <li>Perfusión miocardioa de reposo y esfuerzo</li>
            <li>Spec gatillado | Centellograma osea total</li>
            <li>Perfusión pulmonar | Centellograma de glándulas salivales </li>
            <li>Centellograma hepático | Centellograma renal</li>
            <li>Radiorenograma | Marcación ganglio centinela</li>
            <li>Estudio de hemorragia digestiva en Cámara Gamma</li>
          </ul>
  
        </div>
      </div>
    </div>
  </div>
  
</body>

</html>