<!--<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"
 
  (click)="cerrarDialogo(false)"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>-->
<h1 mat-dialog-title class="tituloTerm" style="padding-left: 0%; padding-right: 3.5%">Términos y condiciones</h1>
<div mat-dialog-content>
    <pdf-viewer src="../assets/pdfs/PORTAL-DE-PACIENTES-TERMINOS-Y-CONDICIONES-2023.pdf"
                [original-size]="false"
                [autoresize]="false" 
                [show-borders]="false"
                style="max-width:80vw;max-height: 40vh">
                </pdf-viewer>

  <form [formGroup]="termsForm">
        <input 
                type="checkbox" 
                formControlName="check"
        />        
        <span class="subTerm" >  Acepto los términos y condiciones</span>            
     
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-center" *ngIf="!loading">
  
    <button mat-raised-button 
            type="submit" 
            form="formAsignacion"
            class="boton"
            (click)="onSubmit()"
            [disabled]=" !this.termsForm.value.check || loadingSubmit"
            [class.spinnerButton]="loadingSubmit"> Aceptar
    </button>
   
</div>
