import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, delay } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService} from '../_services/alert.service';
import { FacturasService} from '../_services/facturas.service';
import { Observable } from 'rxjs';
import { UrlResolverService } from '../_services/urlResolver.service';
import { EstudiosService } from '../_services/estudios.service';
import { UserService } from '../_services/user.service';
import * as moment from 'moment';

export interface UserData {
  nroDocumento: string;
  tipoDocumento: string;
  usuario: string;
  sucursal: string;
  estadoValidacion: string;
}

@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.css']
})
export class OrdenesComponent implements OnInit {

  ordenes: any = [];
  cargando: boolean = true;
  selected;
  horusRestfullUrl;
  selectedUrl;
  userData = {} as UserData;
  mobileDevice: boolean;

  constructor(private facturasService: FacturasService,
              private urlResolver: UrlResolverService,
              private estudiosService: EstudiosService,
              private userService: UserService,
              public router: Router) {

  }


  async ngOnInit() {

    await this.getMobile();
    this.userService.getUserData().subscribe(
      (data) => {
        this.userData.nroDocumento = data.nroDocumento;
        this.userData.tipoDocumento = data.tipoDocumento;
        this.userData.usuario = data.usuario;
        this.userData.estadoValidacion = data.estadoValidacion;
        this.cargarPedidos();
      }
    );    
  }

  async getMobile(){
    this.mobileDevice = false;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobileDevice = true;
     }    
  }

  cargarPedidos() {
    this.estudiosService
      .getPedidosPaciente(
        this.userData.nroDocumento,
        this.userData.tipoDocumento,
        10
      )
      .subscribe(
        (data) => {
          if (data.ok) {            
            this.ordenes = data.pedidos;
            this.ordenes = this.ordenes.sort((a, b) =>
              moment(b.fechaHoraPedida, 'DD/MM/YYYY HH:mm').diff(
                moment(a.fechaHoraPedida, 'DD/MM/YYYY HH:mm')
              )
            );
          } else {
            this.ordenes = [];
          }
          this.cargando = false;
          },
        (error) => {
          console.log(error);
          this.cargando = false;
        }
      );
      
  }

  verOrden(orden) {    
    this.selected = orden;
    this.selectedUrl = this.estudiosService.imprimirOrdenPedido(orden, this.userData.usuario);
  }


  resetList() {
    this.selected = null;
  }

  validacionAprobada(){     
    //console.log('aprobada: '+(this.userData.tipoDocumento!='9'));
    
    return this.userData.tipoDocumento != '9';
  }


  validacionPendiente(){ 
    // console.log('pendiente: '+(this.userData.tipoDocumento == '9' && this.userData.estadoValidacion=='PENDIENTE'));
    // console.log('tipoDoc: '+this.userData.tipoDocumento);
    // console.log('estadoVa: '+this.userData.estadoValidacion);
          
    return (this.userData.tipoDocumento == '9' && this.userData.estadoValidacion=='PENDIENTE') ;
  }

  goToValidar(){
    sessionStorage.setItem('voyAValidar', 'si');
    // this.router.navigate(['/register']);
    window.location.href='/register';
  }

}

