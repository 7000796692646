import { Component, OnInit } from '@angular/core';
import { TurnosService } from '../_services/turnos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { error } from 'protractor';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { DialogConfirmarTurnoComponent } from '../dialog-confirmar-turno/dialog-confirmar-turno.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';

@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.css'],
})
export class TurnosComponent implements OnInit {
  msjeLogin;
  msjeCrear;
  turnos: any[];
  todosLosTurnos: any[];
  profesionalSeleccionado = ' ';
  establecimientoSeleccionado: any = ' ';
  especialidades: any[];
  establecimientos: any[];
  profesionales: any[];
  todosLosProfesionales: any[];
  especialidadSeleccionada: any;
  profesional: any;
  horariosProfesional: any;
  bloqueTurnos: any;
  bloqueElegido: any = null;
  puedeTomarTurno: boolean;
  profesionalNoDisponeTurno: boolean[] = [];
  mensajeErrorTurno: string;
  loading = true;
  loadingLogin = false;

  loadingCrear = false;
  loadingTurnosProfesional = true;
  mutuales;
  currentUser;
  mutualElegida: any;
  habilitarMsjeMutualProf: boolean;
  msjeMutualProf: string;
  mensajeTurnoError: string;
  tieneWebCheckIn: boolean;
  msjeEstad: string;
  funEstad: string;
  cargado = false;
  // logueado = false;
  submittedLogin = false;
  mostrarMas = false;
  submittedCrear = false;
  loginForm: FormGroup;
  crearUsuarioForm: FormGroup;

  recoverUsernameFormReady = false;
  recoveredUsersForm: FormGroup;
  recoveredUsersFormSubmitted = false;
  recoveredUsers;
  recoverUsernameForm: FormGroup;
  existenTurnos: boolean = false;

  constructor(
    private turnosService: TurnosService,
    private router: Router,
    private as: AuthenticationService,
    private us: UserService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private dialog:MatDialog
  ) {}

  ngOnInit() {
    // if (this.authenticationService.currentUserValue !== null) {
    if (
      localStorage.getItem('currentUser') !== null &&
      localStorage.getItem('username') !== 'visitante'
    ) {
      // this.logueado = true;
      this.iniciarCarga();
      this.us.obtenerCoberturasPaciente().subscribe((x) => {
        this.mutuales = x;
        this.mutualElegida = this.mutuales[0];
      });
    } else {
      this.authenticationService.login('visitante', 'v1s1t4nt3').subscribe(
        (data: any) => {
          if (data.token) {
            this.iniciarCarga();
          }
        },
        (error) => {
          console.log(error);
        }
      );
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
      });

      this.recoverUsernameForm = this.formBuilder.group({
        //  dni: ['', [Validators.required, Validators.maxLength(8), Validators.minLength(7), Validators.pattern('[0-9]*')]],
        mail: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ],
        ],
      });

      this.recoveredUsersForm = this.formBuilder.group({
        user: ['', Validators.required],
      });

      this.crearUsuarioForm = this.formBuilder.group({
        firstName: [
          '',
          [Validators.required, Validators.pattern('[a-zA-Z ]*')],
        ],
        lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        mail: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ],
        ],
        tel: [
          '',
          [
            Validators.required,
            Validators.maxLength(16),
            Validators.minLength(7),
            Validators.pattern('[0-9]*'),
          ],
        ],
        dni: [
          '',
          [
            Validators.required,
            Validators.maxLength(8),
            Validators.minLength(6),
            Validators.pattern('[0-9]*'),
          ],
        ],
        date: ['', [Validators.required]],
        gender: ['', Validators.required],
        location: ['No suministrado'],
        city: ['La Plata'],
        cod: ['1900'],
      });
    }
  }
  get fc() {
    return this.loginForm.controls;
  }
  get f() {
    return this.crearUsuarioForm.controls;
  }
  get rec() {
    return this.recoveredUsersForm.controls;
  }
  get ru() {
    return this.recoverUsernameForm.controls;
  }
  logueado() {
    return localStorage.getItem('username') !== 'visitante';
  }
  iniciarSesion(): void {
    this.submittedLogin = true;
    if (this.loginForm.valid) {
      this.loadingLogin = true;
      // this.authenticationService.logout();

      this.authenticationService
        .login(this.loginForm.value.username, this.loginForm.value.password)
        .pipe(first())
        .subscribe(
          (data: any) => {
            this.loadingLogin = false;

            if (data.token) {
              // this.logueado = true;
              this.us.obtenerCoberturasPaciente().subscribe((x) => {
                this.mutuales = x;
                this.mutualElegida = this.mutuales[0];
              });
              // this.us.getUserData().subscribe((respGetUserData) => {
              //   this.us.temporal = respGetUserData.tipoDocumento === 9;
              // });

              document.getElementById('closeModalLogin').click();
              this.reservarTurno(
                JSON.parse(sessionStorage.getItem('bloqueElegido'))
              );
            } else {
              this.msjeLogin = 'Usuario y contraseña incorrectos';
            }
          },
          (error) => {
            console.log(error);
            this.loadingLogin = false;
          }
        );
    }
  }
  crearUsuario(): void {
    this.submittedCrear = true;
    if (this.crearUsuarioForm.invalid) {
      return;
    }
    this.loadingCrear = true;

    this.us
      .register(this.crearUsuarioForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loadingCrear = false;
          // alert(
          //   data.mensaje +
          //     ', le llegarán al mail registrado sus datos de ingreso!'
          // );
          let msjDialog = {
            ok: data.ok,
            mensaje: data.mensaje + ', le llegarán al mail registrado sus datos de ingreso!',
          }
          this.openDialog(msjDialog);
          if (data.ok) {
            this.crearUsuarioForm.reset();
            this.submittedCrear = false;
            this.mostrarMas = !this.mostrarMas;
          }
        },
        (error) => {
          this.loadingCrear = false;
          // alert('Los datos que ingreso ya existen, pruebe con otros datos');
          let msjDialog = {
            ok: false,
            mensaje: 'Los datos que ingreso ya existen, pruebe con otros datos',
          }
          this.openDialog(msjDialog);          
        }
      );
  }

  async iniciarCarga() {
    await this.turnosService
      .getEestablecimientos()
      .toPromise()
      .then(
        (respGetEstab) => {
          this.establecimientos = respGetEstab;
          this.establecimientoSeleccionado = this.establecimientos.find(
            (x) => x.codigo === 1
          );
        },
        (error) => {
          console.log(error);
        }
      );

    await this.turnosService
      .getEspecialidades()
      .toPromise()
      .then(
        (x) => {
          this.especialidades = x.especialidades;
          this.especialidadSeleccionada = this.especialidades.find(
            (x) => x.codigo === 6
          );
        },
        (error) => {
          console.log(error);
        }
      );

    await this.turnosService
      .getTurnosPorEspecialidad(
        this.especialidadSeleccionada,
        this.profesionalSeleccionado
      )
      .toPromise()
      .then(
        (x) => {
          this.ordenarVectorDiasAtencion(x);
          this.turnos = x;
          this.todosLosTurnos = x;
          this.profesionales = x;
          this.loading = false;
        },
        (error) => console.log(error)
      );
  }

  async ordenarVectorDiasAtencion(vector) {
    await vector.forEach((turno) => {
      let arreglodeTurnos = [];
      turno.diasAtencion.forEach((dia) => {
        if (this.establecimientoSeleccionado.nombreCompleto == undefined) {
          if (
            !arreglodeTurnos.hasOwnProperty(
              dia.establecimiento + ' - ' + dia.servicio
            )
          ) {
            arreglodeTurnos[dia.establecimiento + ' - ' + dia.servicio] = [];
          }
          arreglodeTurnos[dia.establecimiento + ' - ' + dia.servicio].push(dia);
          turno.tieneHorario = true;
        } else if (
          dia.establecimiento == this.establecimientoSeleccionado.nombreCompleto
        ) {
          if (!arreglodeTurnos.hasOwnProperty(dia.servicio)) {
            arreglodeTurnos[dia.servicio] = [];
          }
          arreglodeTurnos[dia.servicio].push(dia);
          turno.tieneHorario = true;
        }
      });
      turno.diasAtencion = arreglodeTurnos;
    });

    for await (let turno of vector) {
      if (turno.tieneHorario) {
        this.existenTurnos = true;
      }
    }
  }

  onChangeEspecialidad(newObj) {
    this.existenTurnos = false;
    this.turnos = null;
    this.especialidadSeleccionada = newObj;
    this.turnosService
      .getTurnosPorEspecialidad(
        this.especialidadSeleccionada,
        this.profesionalSeleccionado
      )
      .subscribe(
        (x) => {
          this.ordenarVectorDiasAtencion(x);
          this.turnos = x;
          this.todosLosTurnos = x;
          this.profesionales = x;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onChangeEstablecimiento(estab) {
    this.establecimientoSeleccionado = estab;
    this.onChangeEspecialidad(this.especialidadSeleccionada);
  }

  filtrarProfesional(newObj) {
    /* if (newObj === 'limpiar') {
      this.turnos = this.todosLosTurnos;
      this.profesionales = this.todosLosProfesionales;
    } else { */
    if (newObj === ' ') {
      this.turnos = this.todosLosTurnos;
      this.profesionalSeleccionado = ' ';
    } else {
      if (
        this.todosLosTurnos.filter(
          (turno) => turno.apellido === newObj.apellido
        ).length !== 0
      ) {
        this.turnos = this.todosLosTurnos.filter(
          (turno) => turno.apellido === newObj.apellido
        );
      } else {
        this.profesionalSeleccionado = newObj;
        this.turnosService
          .getTurnosPorEspecialidad(
            this.especialidadSeleccionada,
            this.profesionalSeleccionado
          )
          .subscribe((x) => {
            this.ordenarVectorDiasAtencion(x);
            this.turnos = x;
            this.todosLosTurnos = x;
            this.profesionales = x;
            this.especialidadSeleccionada = this.especialidades.find(
              (especialidad) =>
                especialidad.nombre === x.especialidades[0].nombre
            );
          });
      }
    }
  }
  /* 
    if (newObj === '') {
      this.turnos = this.todosLosTurnos;
    } else {
      this.profesionalSeleccionado = newObj;

      if (
        this.todosLosTurnos.filter((turno) => turno.apellido == newObj.apellido)
          .length !== 0
      ) {
        this.turnos = this.todosLosTurnos.filter(
          (turno) => turno.apellido == newObj.apellido
        );
      } else {
        this.turnosService
          .getHorariosProfesional(this.profesionalSeleccionado)
          .subscribe((x) => {
            this.turnos = x.ps;
            this.todosLosTurnos = x.ps;
            this.profesionales = x.ps;
          });
      }
    } */

  onChangeProfesional(newObj) {
    if (localStorage.getItem('buscoTurno') === 'false') {
      this.us.getUserData().subscribe(
        (respGetUserData) => {
          this.currentUser = respGetUserData.usuario;
          this.msjeEstad =
            'El usuario ' + this.currentUser + ' intento sacar un turno.';
          this.funEstad = 'FX_SolicitudDeTurno';

          this.us.guardarEstadistica(this.msjeEstad, this.funEstad).subscribe(
            (responseGuarEst) => {
              if (responseGuarEst.ok) {
                localStorage.setItem('buscoTurno', JSON.stringify(true));
              }
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );
    }
    this.profesional = newObj;
    this.turnosService
      .getHorariosProfesional(
        this.profesional,
        this.establecimientoSeleccionado.codigo
      )
      .subscribe((x) => {
        this.horariosProfesional = x.ps[0].diasAtencion;
        /* this.horariosProfesional.sort(
        (a, b) =>
          a.establecimiento.localeCompare(b.establecimiento) ||
          <any>new Date(a.diaPrimerTurnoLibre).getTime() -
            <any>new Date(b.diaPrimerTurnoLibre).getTime()
      ); */
        //Creamos un nuevo objeto donde vamos a almacenar los horarios por establecimiento.
        let arreglodeTurnos = [];
        this.horariosProfesional.forEach((x) => {
          if (
            !arreglodeTurnos.hasOwnProperty(
              x.establecimiento + ' - ' + x.servicio
            )
          ) {
            arreglodeTurnos[x.establecimiento + ' - ' + x.servicio] = [];
            /* arreglodeTurnos.length++; */
          }
          arreglodeTurnos[x.establecimiento + ' - ' + x.servicio].push(x);
        });

        //LINEA A COMENTAR SI NO ANDA
        this.horariosProfesional = arreglodeTurnos;
        Object.values(this.horariosProfesional).forEach(
          (establecimiento: any) => {
            establecimiento.sort(
              (a, b) =>
                <any>new Date(a.diaPrimerTurnoLibre).getTime() -
                <any>new Date(b.diaPrimerTurnoLibre).getTime()
            );
            this.profesionalNoDisponeTurno[
              establecimiento[0].establecimiento +
                ' - ' +
                establecimiento[0].servicio
            ] = establecimiento.filter((dia) => dia.disponible).length === 0;
          }
        );

        this.cargado = true;
        /*  this.profesionalNoDisponeTurno =
        this.horariosProfesional.filter((dia) => dia.disponible).length == 0; */
      });
  }
  unsorted() {}
  hayTurnos() {
    return Object.keys(this.horariosProfesional).length === 0;
  }
  conseguirBloqueTurno(horario) {
    this.turnosService
      .getTurnosProfesional(this.profesional, horario)
      .subscribe((x) => {
        this.bloqueTurnos = x.lista;
        this.loadingTurnosProfesional = false;
      });
  }

  abrirDialogConfirmarTurno(bloqueTurno) {
    this.dialog.open(DialogConfirmarTurnoComponent, {
      width: '500px',
      disableClose: true,
      data: {
        bloqueElegido: bloqueTurno,
        profesional: this.profesional
      }
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.irTurnosHistorial();
      }
    }, (error) => {
      console.log(error);
    });
  }

  tomarTurno(bloqueTurno) {
    if (!this.logueado()) {
      window.location.href = '/login';
    } else {
      this.abrirDialogConfirmarTurno(bloqueTurno);

      if (sessionStorage.getItem('bloqueElegido') != 'undefined' && JSON.parse(sessionStorage.getItem('bloqueElegido')) != null) {
        this.liberarTurno(JSON.parse(sessionStorage.getItem('bloqueElegido')));
      }

      this.bloqueElegido = bloqueTurno;
    }
  }

  reservarTurno(bloqueTurno) {
    

    sessionStorage.setItem('bloqueElegido', JSON.stringify(bloqueTurno));
    

    
  }

  liberarTurno(bloqueTurno) {
    this.turnosService.liberarTurno(bloqueTurno).toPromise().then(rp => {
      // console.log(rp);
    }, error => {
      console.error(error)
    })
    this.bloqueElegido = null;
    sessionStorage.setItem('bloqueElegido', null);
  }

  confirmarTurno(bloqueTurno) {
    if (!this.habilitarMsjeMutualProf) {
      this.mutualElegida = { mutual: 14, nroCarnet: '-' };
    }
    this.turnosService
      .confirmarTurno(bloqueTurno, this.mutualElegida)
      .subscribe(
        (x) => {
          if (x.ok) {
            if (x.webCheckIn == 'S') {
              this.tieneWebCheckIn = true;
            } else {
              this.tieneWebCheckIn = false;
            }

            document.getElementById('openModalConfirmarTurno').click();
            sessionStorage.setItem('bloqueElegido', null);
            this.bloqueElegido = null;
          } else {
            this.mensajeTurnoError = x.mensaje;
            this.liberarTurno(this.bloqueElegido);
            document.getElementById('openModalConfirmarTurnoError').click();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  irTurnosHistorial() {
    // this.router.navigate(['/mis-reservas']);
    window.location.href='/turnos-reservados';
  }

  resetList() {
    this.profesional = null;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  closeRecoveredUsersFormModal() {
    this.recoveredUsersFormSubmitted = false;

    document.getElementById('openRecuperarUsuarioModalButton').click();
    this.recoverUsernameForm.reset();
    this.recoveredUsersForm.reset();
    this.recoverUsernameFormReady = false;
    document.getElementById('openModalLogin').click();
  }

  recoverUsername() {
    this.recoveredUsersFormSubmitted = true;
    if (this.recoverUsernameForm.invalid) {
      return;
    }

    this.us.recoverUsername(this.recoverUsernameForm.value).subscribe(
      (data) => {
        this.recoveredUsers = data;
        if (this.recoveredUsers.length == 1) {
          this.us
            .sendMail({ user: this.recoveredUsers[0] })
            .pipe(first())
            .subscribe(
              (data) => {
                // alert(data.mensaje);
                let msjDialog = {
                  ok: true,
                  mensaje: data.mensaje,
                }
                this.openDialog(msjDialog); 
                this.closeRecoveredUsersFormModal();
              },
              (error) => {}
            );
        } else {
          this.recoverUsernameFormReady = true;
        }
      },
      (error) => {
        // this.recoverUsernameForm.controls['dni'].setErrors({'incorrect': true});
        this.recoverUsernameForm.controls['mail'].setErrors({
          incorrect: true,
        });
      }
    );
  }

  onSubmitRecoveredUsersForm() {
    this.recoveredUsersFormSubmitted = true;
    if (this.recoveredUsersForm.invalid) {
      return;
    }
    // this.supportMessageLoading = true;
    this.us
      .sendMail(this.recoveredUsersForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // alert(data.mensaje);
          let msjDialog = {
            ok: true,
            mensaje: data.mensaje,
          }
          this.openDialog(msjDialog); 
          this.closeRecoveredUsersFormModal();
        },
        (error) => {}
      );
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (!this.logueado()) {
      localStorage.clear();
      this.authenticationService.logout();
    }
  }

  openDialog(respuesta){
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {ok:respuesta.ok, mensaje:respuesta.mensaje},
      panelClass: 'dialog-sin-overflow'
    })
  }

}
