<!-- <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'> -->

<div class="menu-lateral" *ngIf="!cargando">
    <!-- Icono de Portal de Pacientes -->
    <div class="contenedor-iconoPortal">
        <img src="../../assets/images/menu-lateral/icono_portal_de_pacientes.png" id="icono_portal_de_pacientes"
        routerLink='/home' (click)="cerrar_menus()" *ngIf="!es_visitante;else icono_noClickeableBlock">
        
        <ng-template #icono_noClickeableBlock>
            <img src="../../assets/images/menu-lateral/icono_portal_de_pacientes.png" id="icono_portal_de_pacientes"
            (click)="login()">
        </ng-template>
        
    </div>
    <!-- Entradas para No Visitantes -->
    <div *ngIf="!es_visitante;else visitanteBlock">
                
        <!-- foto de perfil o icono -->
        <div class="contenedor-fotoPerfil">
            <div *ngIf="(userData_recuperado && fotoPaciente),else iconoBlock"
                id="contenedor-fotoPerfil">                      
                    <img 
                        [src]="fotoPaciente" 
                        class="foto-perfil"
                        routerLink='/editar-datos' 
                        (click)="click_foto()">
                    <img 
                    src="../../assets/images/menu-lateral/marco_foto.png"                    
                    id="marco-fotoPerfil">                                                   
            </div>
            
            <ng-template #iconoBlock >
                    <img src="../../assets/images/menu-lateral/icono_mis_datos.png" id="img_icono_mis_datos"
                    routerLink='/editar-datos' (click)="click_foto()">
            </ng-template>
        </div>

        <div *ngFor="let entrada of entradas">
            
            <!-- #no tiene submenus (Historial Clínico)-->
            <div *ngIf="entrada.length == 1;else tiene_sub_menus">
                <!-- #entrada menu seleccionada -->
                <div class='opcion_menu_sinsubmenu_seleccionada'
                *ngIf="entrada_seleccionada == entrada[0].nombre_menu;else entradaNoSeleccionada">
                    {{ entrada[0].nombre_menu }}
                </div>
                <ng-template #entradaNoSeleccionada>
                    <div class="opcion_menu"
                    (click)="selecciona_entrada(entrada[0])"
                    routerLink={{entrada[0].url}}>
                        {{ entrada[0].nombre_menu }}
                    </div>               
                </ng-template>
            </div>
            
            <ng-template #tiene_sub_menus>
                    <div *ngIf="userData_recuperado">
                        
                        <!-- la entrada está seleccionada -->
                        <div *ngIf="entrada_seleccionada == entrada[0].nombre_menu;else entradaNoSeleccionada">

                            <!-- es menu Paciente -->
                            <div class="opcion_menu_mis_datos_seleccionada"
                                *ngIf="verificarNombreMenu(entrada[0].nombre_menu);else noEsMenuPaciente1">    
                                <div *ngIf="tieneNombre();else noTieneNombre"> 
                                    {{ userData.nombres | toTitleCase }}
                                </div>
                                <ng-template #noTieneNombre>
                                    {{ userData.apellido | toTitleCase }}
                                </ng-template>                                             
                            </div>  
                                                     
                            <ng-template #noEsMenuPaciente1>
                                <div class="opcion_menu_seleccionada"
                                *ngIf="entrada_seleccionada == entrada[0].nombre_menu;else entradaNoSeleccionada">
                                        {{ entrada[0].nombre_menu }}
                                </div>
                            </ng-template>                            
                        </div>
                    </div>

                    <ng-template #entradaNoSeleccionada>
                        <!-- es menu Paciente -->
                        <div *ngIf="verificarNombreMenu(entrada[0].nombre_menu);else noEsMenuPaciente2">
                            <div class="opcion_menu_mis_datos"
                            (click)="selecciona_entrada(entrada[0])"
                            routerLink={{entrada[0].url}}
                            >                                                    
                                <div *ngIf="tieneNombre();else noTieneNombre"> 
                                    {{ userData.nombres | toTitleCase }}
                                </div>
                                <ng-template #noTieneNombre>
                                    {{ userData.apellido | toTitleCase }}
                                </ng-template>                                                                                            
                            </div> 
                            <div class="opcion_menu_mis_datos" 
                                id="opcion_menu_cerrar_sesion"
                                *ngIf="verificarNombreMenu(entrada[0].nombre_menu)"
                                (click)="logout()">
                                Cerrar sesión
                            </div> 
                            <div *ngIf="validacionPendiente()" class="validacion_pendiente">
                                Validación pendiente
                            </div>
                            <div *ngIf="validacionSinValidar() && entrada_seleccionada=='validar'" class="boton_validar_seleccionado">
                                Validá tu identidad
                            </div>
                            <div *ngIf="validacionSinValidar() && entrada_seleccionada!='validar'" class="boton_validar" (click)="goToValidar()">
                                Validá tu identidad
                            </div>
                        </div>                                               

                        <ng-template #noEsMenuPaciente2>
                            <div class="opcion_menu"
                            (click)="selecciona_entrada(entrada[0])"
                            routerLink={{entrada[0].url}}>
                                {{ entrada[0].nombre_menu }}
                            </div>
                        </ng-template>
                    </ng-template>
                    
                    <div *ngIf="entrada_seleccionada == entrada[0].nombre_menu">
                        <div class="contenedor_submenus">
                            <div *ngFor="let subEntrada of entrada">
                                <!-- entrada submenu seleccionada -->
                                <div *ngIf="(subEntrada.nombre_submenu==subentrada_seleccionada);else subentradaNoSeleccionada">
                                    <div class="opcion_submenu_seleccionada" *ngIf="noEsLaultimaSubEntrada(entrada,subEntrada);else ultima_subentrada">
                                        {{ subEntrada.nombre_submenu }}
                                    </div>
                                    <ng-template #ultima_subentrada>
                                        <div class="ultimaSubEntrada_seleccionada">
                                            {{ subEntrada.nombre_submenu }}
                                        </div>
                                    </ng-template>                                    
                                </div>
                                <ng-template #subentradaNoSeleccionada>
                                    <!-- no es el submenu de cerrar sesion ni el de una ultima subentrada -->
                                    <div class="opcion_submenu"
                                    *ngIf="(noEsCerrarSesion(subEntrada.nombre_submenu) && noEsLaultimaSubEntrada(entrada,subEntrada));else otroBloque"
                                    (click)="selecciona_subentrada(subEntrada)">
                                        {{subEntrada.nombre_submenu}}                                                                  
                                    </div>
                                    
                                    <ng-template #otroBloque> <!-- submenu Cerrar Sesión -->
                                        <div class="opcion_submenu_cerrar_sesion" (click)="logout()"
                                        *ngIf="!noEsCerrarSesion(subEntrada.nombre_submenu);else ultimaSubEntradaBlock">
                                            {{subEntrada.nombre_submenu}}
                                        </div>
                                        <ng-template #ultimaSubEntradaBlock>
                                            <div class="ultimaSubEntrada" (click)="selecciona_subentrada(subEntrada)">
                                                {{subEntrada.nombre_submenu}}
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                   
                                    <ng-template #cerrarSesionBlock>
                                        <div class="opcion_submenu_cerrar_sesion"
                                        (click)="logout()">
                                            {{ subEntrada.nombre_submenu }}
                                        </div>
                                    </ng-template>  
                                </ng-template>
                            </div>                    
                        </div>
                        <div *ngIf="verificarNombreMenu(entrada[0].nombre_menu)">                            
                            <div *ngIf="validacionPendiente()" class="validacion_pendiente">
                                Validación pendiente
                            </div> 
                            <div *ngIf="validacionSinValidar()" class="boton_validar" (click)="goToValidar()">
                                Validá tu identidad
                            </div>
                        </div>
                        
                    </div>
                    
            </ng-template>
        </div>
    </div>

    <ng-template #visitanteBlock>    
        <div class="opcion_menu_mis_datos" id="iniciar_sesion"
        (click)="login()" >            
                Iniciar sesión
        </div> 
    </ng-template>  
</div>