<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1 m-0 p-0">
      <div class="w-100" *ngIf="!selected">
        <div class="col" *ngIf="loading">Se estan cargando sus facturas</div>
        <div class="col" *ngIf="!loading && facturas.length == 0">
          <h5>No se han encontrado facturas</h5>
        </div>
        <div class="col p-0 m-0 w-100" *ngIf="!loading && facturas.length > 0">
          <h4>Factura de honorarios</h4>
          <div class="container px-0 mx-auto text-center">
            <table id="tabla-facturas" class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Fecha</th>
                  <th class="opcional" scope="col">Tipo-Serie-Numero</th>
                  <th scope="col">Descripcion</th>
                  <th scope="col">Mutual</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let factura of facturas">
                  <td>{{ factura.fecha }}</td>
  
                  <td class="opcional">
                    {{ factura.letra }} - {{ factura.prefijo }} -
                    {{ factura.numero }}
                  </td>
  
                  <td>{{ factura.descripcion_recibo | toTitleCase }}</td>
  
                  <td>{{ factura.mutual_desc }}</td>
  
                  <td (click)="setFactura(factura)" style="text-align: center">
                    <img src="../../assets/images/facturas/estudios_pdf.png" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <div class="col" *ngIf="selected">
        <div class="row mt-1">
          <div class="col-8"><h5>Factura de honorarios</h5></div>
          <div class="col-4">
            <button
              (click)="resetList()"
              class="btn btn-info"
              style="float: right"
            >
              Volver
            </button>
          </div>
        </div>
        <br />
        <div class="container" style="height: 370px">
          <embed
            type="application/pdf"
            [src]="selectedUrl | safeUrl"
            width="103.5%"
            height="100%"
            internalinstanceid="5"
          />
        </div>
      </div>
    </div>
  </div>
</body>

</html>