import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hematologia',
  templateUrl: './hematologia.component.html',
  styleUrls: ['./hematologia.component.css']
})
export class HematologiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
