<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="col-md">
        <h4>Normas de convivencia</h4>
        <div class="container">
          <img class="img-fluid mx-auto d-block mb-2" src="../../assets/images/normas-de-convivencia/normas.jpg" />
        </div>
      </div>
    </div>
  </div>
</body>

</html>