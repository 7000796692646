import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-derechos-del-paciente',
  templateUrl: './derechos-del-paciente.component.html',
  styleUrls: ['./derechos-del-paciente.component.css']
})
export class DerechosDelPacienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
