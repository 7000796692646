import { Component, OnInit } from '@angular/core';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-profesionales',
  templateUrl: './profesionales.component.html',
  styleUrls: ['./profesionales.component.css'],
})
export class ProfesionalesComponent implements OnInit {
  especialidades;
  loadingProfesionales = false;
  especialidadSeleccionada;
  turnos: any;
  profesionales: any[] = [];
  respuesta: any[];

  constructor(private ts: TurnosService) {}

  ngOnInit() {
    this.iniciarCarga();
  }

  iniciarCarga() {
    this.ts.getEspecialidadesSinAuth().subscribe((data) => {
      this.especialidades = data.especialidades;
      this.especialidadSeleccionada = this.especialidades.find(
        (x) => x.codigo === 6
      );
      this.cargarProfesionales();
    });
  }

  async cargarProfesionales() {
    this.loadingProfesionales = true;
    await this.getProfesionales(this.especialidadSeleccionada.codigo, 1);
    await this.getProfesionales(this.especialidadSeleccionada.codigo, 2);
    await this.getProfesionales(this.especialidadSeleccionada.codigo, 4);
    await this.getProfesionales(this.especialidadSeleccionada.codigo, 3);

    await this.ordenarMostrarProf();
  }

  async ordenarMostrarProf() {
    this.profesionales.sort((a, b) => (a.apellido < b.apellido ? -1 : 1));
    this.loadingProfesionales = false;
  }

  async getProfesionales(codigo, idCondicion) {
    // GET PROF X ESPECIALIDAD Y CONDICION
    await this.ts
      .getProfesionalesPorEspecialidad(
        this.especialidadSeleccionada.codigo,
        idCondicion
      )
      .toPromise()
      .then(
        (data) => {
          this.respuesta = data;
          this.respuesta.forEach((element) => {
            this.profesionales.push(element);
          });
          // this.profesionales = data.sort((a,b) => (a.apellido < b.apellido ? -1 : 1));
        },
        (error) => {
          this.loadingProfesionales = false;
          console.log(error);
        }
      );
  }

  onChangeEspecialidad(event) {
    this.profesionales = [];
    this.especialidadSeleccionada = event;
    this.cargarProfesionales();
  }
}
