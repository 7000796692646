import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { AppComponent } from '../app.component';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  
  private version_web: string;
  private currentUserSubject: BehaviorSubject<User>;
  //public currentUser: Observable<User>;
  private baseUrl: string;
  private sucursal;
  private loggedIn$: Subject<boolean> = new ReplaySubject<boolean>(1);
  private fotoPaciente = new BehaviorSubject<string>("undefined"); ////// ACA PONER EL URL A LA FOTITO POR DEFAULT: ::D:DD:DD:dDDDDDDDDD
  fotoActual = this.fotoPaciente.asObservable();

  
  constructor(private http: HttpClient,
    private _sanitizer: DomSanitizer) {

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    //this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = AppComponent.testUrl;
    this.sucursal = AppComponent.sucursal;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentUser(): Observable<User>{
    return this.currentUserSubject;
  }

  verificarParametroIntento() {
    return this.http.get<any>(this.baseUrl + '/verificar-parametro');
  }

  verificarParametroAdmisionDigital() {
    return this.http.get<any>(this.baseUrl + '/verificar-admision-digital');
  }

  guardarIntento(usuarioIntento, passwordIntento, mostrarContraseña) {
    let body = new FormData();
    body.append('usuario', usuarioIntento);
    body.append('pass', passwordIntento); 
    body.append('mostrarContrasenia', mostrarContraseña);
    body.append('dispositivo', this.getVersionWeb());
    return this.http.post<any>(this.baseUrl + '/guardar-intento', body);
  }

  login(id: string, pass: string) {
    this.currentUserSubject.next(null);
    //this.currentUser = null;
    let body = new FormData();
    body.append('id', id.toLowerCase());
    body.append('pass', pass);

    body.append('sucursal', this.sucursal);
    body.append('version', this.version_web);
    return this.http.post<any>(this.baseUrl + '/session', body).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          if (user.fechaUltimoLogueo == null) {
            localStorage.setItem('lastLog', JSON.stringify(true));
          } else {
            localStorage.setItem('lastLog', JSON.stringify(false));
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('username', id.toLowerCase());

          localStorage.setItem('buscoTurno', JSON.stringify(false));

          this.currentUserSubject.next(user);
        }
        this.loggedIn$.next(true);
        this.cambiarFotoPerfil(user.fotoPerfil);
        user.usuarioIntento = id;
        user.passwordIntento = pass;
        return user;
      })
    );
  }
  

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('username');
    localStorage.removeItem('guardoEstadisticaWC');
    this.currentUserSubject.next(null);
    //this.currentUser = null;
    this.loggedIn$.next(false);
  }

  isLogueado(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  cambiarFotoPerfil(url: String) {
    let src = null;
    if (url) {
      src = this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + url))
    }   
    this.fotoPaciente.next(src);
  }
  
  // Tipo de dispositivo en el cual se corre la app
  setVersionWeb(version: string){
    this.version_web = version;
  }
  getVersionWeb(){
    return this.version_web;
  }
}
