<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col">
      <div class="container">
        <p>
          Pasar por el Área de Internación en la Planta Baja y retirar la
          siguiente documentación:
        </p>
        <ul>
          <li><strong>Normas de funcionamiento interno.</strong></li>
          <li><strong>Reglamento.</strong></li>
        </ul>
        <p>
          Recuerde que el Hospital cuenta con habitaciones compartidas,
          individuales con acompañante y de distintos tipos de confort. Elija la
          que su obra social le cubra o la que Ud. prefiera. Para mayores
          informes comunicarse al 427 0191, interno 1506 o 1202.
        </p>

        <p>Requisitos a cumplimentar por el afiliado a IOMA:</p>
        <ul>
          <li><strong>Dos juegos de fotocopias.</strong></li>
          <li>
            <strong
              >Orden de internación (no requiere bonos ni autorización).</strong
            >
          </li>
          <li>
            <strong
              >Documento Nacional de Identidad del paciente, primera y segunda
              hoja.</strong
            >
          </li>
          <li><strong>Carnet del paciente.</strong></li>
          <li>
            <strong
              >En caso de que el paciente no sea el titular de la obra social,
              deberá adjuntar también fotocopias del Documento Nacional de
              Identidad y carnet del afiliado directo.</strong
            >
          </li>
          <li>
            <strong
              >Último recibo de sueldo del titular donde consten los aportes o
              verificación obligatoria.</strong
            >
          </li>
          <li>
            <strong
              >Si es afiliado voluntario, debe presentar fotocopias de las dos
              últimas cuotas de pago con el ticket correspondiente.</strong
            >
          </li>
        </ul>

        <div class="container">
          <p>
            Requisitos a cumplimentar por afiliados a otras obras sociales,
            consultar en Internaciones personalmente o al 427-0191, interno 1506
            o 1202.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
