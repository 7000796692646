import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { AuthenticationService } from '../_services/authentication.service';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-actualizar-contrasena',
  templateUrl: './dialog-actualizar-contrasena.component.html',
  styleUrls: ['./dialog-actualizar-contrasena.component.css']
})

export class DialogActualizarContrasenaComponent implements OnInit {

  PASSWORD_MIN_LENGTH: string = 'passMinLength';
  REGEX_MIN_LENGTH: string = '^.{11,255}$';
  PASSWORD_UPPER_CASE: string = 'passUppercase';
  REGEX_UPPER_CASE: string = '^(?=.*[A-Z])';
  PASSWORD_LOWER_CASE: string = 'passLowercase';
  REGEX_LOWER_CASE: string = '^(?=.*[a-z])';
  PASSWORD_MIN_ONE_NUM: string = 'passNum';
  REGEX_MIN_ONE_NUM: string = '^(?=.*[0-9])';
  // PASSWORD_WHITESPACE: string = 'passWhitespace';
  // REGEX_WHITESPACE: string = "^(?=.*[\\s+$])";     // SI CONTIENE ESPACIO EN BLANCO
  PASSWORD_END_WHITESPACE: string = 'passEndWhitespace';
  REGEX_END_WHITESPACE: string = "\\s+$";            // SI TERMINA EN ESPACIO EN BLANCO
  loading = true;

  actualizarContraForm: FormGroup = new FormGroup({
    actual: new FormControl(),
    password: new FormControl(),
    confirmPassword: new FormControl(),
  }, {
    validators: []
  });
  
  userData;
  submitted = false;
  mensaje;
  loadingSubmit: boolean = false;
  showPass = [ false, false, false ];
  valueProgressBar: number = 0;
  colorProgressBar: string = 'redStyle';
  arrayPasswordStrength = [];
  

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<DialogActualizarContrasenaComponent>,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {   
  }

  async ngOnInit() {
    await this.crearFormulario();
    await this.obtenerDatosUsuario();
    
  }

  async crearFormulario() {
    this.actualizarContraForm = this.formBuilder.group({
      actual: [ '', Validators.compose([ Validators.required ]) ],
      password: [ '',
        Validators.compose([ Validators.required, this.passwordValidator(this.PASSWORD_MIN_LENGTH), Validators.maxLength(255), 
          this.passwordValidator(this.PASSWORD_UPPER_CASE), this.passwordValidator(this.PASSWORD_LOWER_CASE), 
          this.passwordValidator(this.PASSWORD_MIN_ONE_NUM), this.passwordEndWhitespaceValidator(this.PASSWORD_END_WHITESPACE) ])
      ],
      confirmPassword: [ '', Validators.compose([ Validators.required ]) ]
    }, {
      validators: Validators.compose([ this.passMustNotMatch(), this.passMustMatch() ])
    });
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then((response) => {
      // console.log(response);
      this.userData = response;
      this.loading = false;
    }, (error) => {
      console.log(error);
    });
  }

  async onSubmit() {
    this.loadingSubmit = true;
    this.submitted = true;

    if (this.actualizarContraForm.invalid) {
      this.actualizarContraForm.markAllAsTouched();
      this.loadingSubmit = false;
      return;
    }
    
    await this.userService.passwordChange(this.actualizarContraForm.value).toPromise().then(data => {
      if (data.cambioOK) {
        localStorage.setItem('lastLog', JSON.stringify(false));
      }

      const respuesta = {
        ok:data.cambioOK,
        mensaje:data.mensaje
      }

      this.abrirDialogMensaje(respuesta);
    }, (error) => {
      // console.log(error);
      const respuesta = {
        ok: false,
        mensaje: 'Ocurrio un error al intentar cambiar la contraseña. Intente nuevamente.'
      }
      this.abrirDialogMensaje(respuesta);
    });

    this.actualizarContraForm.reset();
    this.submitted = false;
  }

  showHidePassword(index) {
    this.showPass[index] = !this.showPass[index];
  }

  abrirDialogMensaje(resp) {
    this.loadingSubmit = false;
    this.cerrarDialogo(true);
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: resp,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe((confirmado: boolean) => {
        if (resp.ok == false){
          this.authenticationService.logout()
          this.router.navigate(['/login'])
        }
      }, (error) => {
        console.error(error);
    });
  }

  cerrarDialogo(cambiado) {
    this.dialogRef.close(cambiado);
    this.dialogRef.afterClosed().subscribe(result => {});
  }

  cerrar(){
    this.dialogRef.close();
  }

  getPasswordStrength() {
    this.valueProgressBar = this.arrayPasswordStrength.length * 25;

    switch (this.valueProgressBar) {
      case 0:
        this.colorProgressBar = 'redStyle';
        break;
      case 25:
        this.colorProgressBar = 'redStyle';
        break;
      case 50:
        this.colorProgressBar = 'orangeStyle';
        break;
      case 75:
        this.colorProgressBar = 'yellowStyle';
        break;
      case 100:
        this.colorProgressBar = 'greenStyle';
        break;
      default:
        break;
    }
  }

  addPasswordStrength(elemento: string) {
    if (!this.arrayPasswordStrength.includes(elemento)) {
      this.arrayPasswordStrength.push(elemento);
      this.getPasswordStrength();
    }
  }

  removePasswordStrength(elemento: string) {
    if (this.arrayPasswordStrength.includes(elemento)) {
      this.arrayPasswordStrength = this.arrayPasswordStrength.filter(elmt => elmt != elemento);
      this.getPasswordStrength();
    }
  }

  getRegex(validator: string) {
    switch (validator) {
      case this.PASSWORD_MIN_LENGTH:
        return this.REGEX_MIN_LENGTH;
      case this.PASSWORD_UPPER_CASE:
        return this.REGEX_UPPER_CASE;
      case this.PASSWORD_LOWER_CASE:
        return this.REGEX_LOWER_CASE;
      case this.PASSWORD_MIN_ONE_NUM:
        return this.REGEX_MIN_ONE_NUM;
      case this.PASSWORD_END_WHITESPACE:
        return this.REGEX_END_WHITESPACE;
      default:
        break;
    }
  }

  passwordEndWhitespaceValidator(validator: string) {
    return () => {
      const passwordControl = this.actualizarContraForm.controls['password'];
      const REGEX = this.getRegex(validator);

      if (passwordControl.value && passwordControl.value.match(REGEX)) {
        this.addPasswordStrength(validator);
        return { [validator]: true }
      } else {
        this.removePasswordStrength(validator);
        return null;
      }
    }
  }

  passwordValidator(validator: string) {
    return () => {
      const passwordControl = this.actualizarContraForm.controls['password'];
      const REGEX = this.getRegex(validator);

      if (passwordControl.value && passwordControl.value.match(REGEX)) {
        this.addPasswordStrength(validator);
        return null
      } else {
        this.removePasswordStrength(validator);
        return { [validator]: true }
      }
    }
  }

  passMustNotMatch() {
    return () => {
      const actualPass = this.actualizarContraForm.controls['actual'];
      const pass = this.actualizarContraForm.controls['password'];

      if (actualPass.value === pass.value) {
        return { passMustNotMatch: true }
      } else {
        return null
      }
    };
  }

  passMustMatch() {
    return () => {
      const pass = this.actualizarContraForm.controls['password'];
      const confPass = this.actualizarContraForm.controls['confirmPassword'];

      if (pass.value !== confPass.value) {
        return { passMustMatch: true }
      } else {
        return null
      }
    };
  }

  

  // passUppercase() {
  //   return () => {
  //     const regex = '^(?=.*[A-Z])'
  //     const pass = this.actualizarContraForm.controls['password'];

  //     if (pass.value && pass.value.match(regex)) {
  //       if (!this.arrayPasswordStrength.includes('passUppercase')) {
  //         this.arrayPasswordStrength.push('passUppercase');
  //         this.getPasswordStrength();
  //       }
  //       return null
  //     } else {
  //       this.arrayPasswordStrength = this.arrayPasswordStrength.filter(elm => elm != 'passUppercase');
  //       this.getPasswordStrength();
  //       return { passUppercase: true }
  //     }
  //   }
  // }

  // lowerCase() {
  //   return () => {
  //     const regex = '^(?=.*[a-z])'
  //     const pass = this.actualizarContraForm.controls['password'];

  //     if (pass.value && pass.value.match(regex)) {
  //       if (!this.arrayPasswordStrength.includes('passLowercase')) {
  //         this.arrayPasswordStrength.push('passLowercase');
  //         this.getPasswordStrength();
  //       }
  //       return null
  //     } else {
  //       this.arrayPasswordStrength = this.arrayPasswordStrength.filter(elm => elm != 'passLowercase');
  //       this.getPasswordStrength();
  //       return { passLowercase: true }
  //     }
  //   }
  // }

  // passNum() {
  //   return () => {
  //     const regex = '^(?=.*[0-9])'
  //     const pass = this.actualizarContraForm.controls['password'];

  //     if (pass.value && pass.value.match(regex)) {
  //       if (!this.arrayPasswordStrength.includes('passNum')) {
  //         this.arrayPasswordStrength.push('passNum');
  //         this.getPasswordStrength();
  //       }
  //       return null
  //     } else {
  //       this.arrayPasswordStrength = this.arrayPasswordStrength.filter(elm => elm != 'passNum');
  //       this.getPasswordStrength();
  //       return { passNum: true }
  //     }
  //   }
  // }

  // passMinLength() {
  //   return () => {
  //     const regex = "^.{8,255}$"
  //     const pass = this.actualizarContraForm.controls['password'];

  //     if (pass.value && pass.value.match(regex)) {
  //       if (!this.arrayPasswordStrength.includes('passMinLength')) {
  //         this.arrayPasswordStrength.push('passMinLength');
  //         this.getPasswordStrength();
  //       }

  //       return null
  //     } else {
  //       if (this.arrayPasswordStrength.includes('passMinLength')) {
  //         this.arrayPasswordStrength = this.arrayPasswordStrength.filter(elm => elm != 'passMinLength');
  //         this.getPasswordStrength();
  //       }

  //       return { passMinLength: true }
  //     }
  //   }
  // }

}
