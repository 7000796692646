<div class="barra-inicial barra-inicial-celeste">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
</div>
<div mat-dialog-content [hidden]="!cargando">
    <div class="d-flex justify-content-center">
        <mat-spinner [diameter]="60"></mat-spinner>
    </div>
</div>

<div mat-dialog-content [hidden]="cargando" style="overflow: hidden !important;" [formGroup]="formValoracion">
    <div>
        <b>¿C&oacute;mo valor&aacute;s tu experiencia con el portal?</b>
    </div><br>
    <div>
        <mat-radio-group formControlName="valoracion">
            <mat-radio-button value=1 matTooltip="Muy mala"><angular-emojis [name]="'slightly_frowning_face'" size="50" class="emoji-valoracion"></angular-emojis></mat-radio-button>
            <mat-radio-button value=2 matTooltip="Mala"><angular-emojis [name]="'confused'" size="50" class="emoji-valoracion"></angular-emojis></mat-radio-button>
            <mat-radio-button value=3 matTooltip="Normal"><angular-emojis [name]="'neutral_face'" size="50" class="emoji-valoracion"></angular-emojis></mat-radio-button>
            <mat-radio-button value=4 matTooltip="Buena"><angular-emojis [name]="'smiley'" size="50" class="emoji-valoracion"></angular-emojis></mat-radio-button>
            <mat-radio-button value=5 matTooltip="Muy buena"><angular-emojis [name]="'heart_eyes'" size="50" class="emoji-valoracion"></angular-emojis></mat-radio-button>
        </mat-radio-group>
    </div><br>
    <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Contanos m&aacute;s sobre tu experiencia</mat-label>
        <textarea matInput rows="4" formControlName="comentario"></textarea>
        <mat-hint align="end"> M&aacute;x. 255</mat-hint>
    </mat-form-field>
    <p class="alert alert-danger p-1 m-1 align-middle" style="height: auto;" [hidden]="!mostrarAlerta"> Seleccione una valoraci&oacute;n </p>
</div>

<div mat-dialog-actions class="d-flex justify-content-end" *ngIf="!cargando">
    <div 
        class="boton-dialog boton-cerrar"
        color="warn" 
        (click)="cerrarDialogo()"
        > M&aacute;s tarde
    </div>

    <div 
        class="boton-dialog boton-confirmar"
        color="primary" 
        (click)="guardar()"
        [disabled]="loadingSubmit"
        [class.spinnerButton]="loadingSubmit"> Guardar
    </div>
</div>