import { Injectable } from '@angular/core';
import { Adapter } from '../_adapters/adapter';


export class User {
    constructor(id: number, 
      username: string, 
      fullName: string, 
      email: string, 
      documento:string, 
      str_fechaNacimiento: string, 
      terminosYCondiciones: string,
      campaniaActualizacion: string,
      fechaUltimoLogueo: string  ){
        this.id = id;
        this.username = username;
        this.fullName = fullName;
        this.email = email;
        this.documento = documento;
        this.str_fechaNacimiento = str_fechaNacimiento;
        this.terminosYCondiciones = terminosYCondiciones;
        this.campaniaActualizacion = campaniaActualizacion;
        this.fechaUltimoLogueo = fechaUltimoLogueo;
    }

    id: number;
    username: string;
    fullName: string;
    email: string;
    documento: string;
    str_fechaNacimiento: string;
    token: string;
    terminosYCondiciones: string;
    campaniaActualizacion: string;
    fechaUltimoLogueo: string;
}


@Injectable({
    providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {

  adapt(item: any): User {
    
    return new User(
      item.id,
      item.usuario,
      item.nombre,
      item.email,
      item.nroDocumento,
      item.str_fechaNacimiento,
      item.terminosYCondiciones,
      item.campaniaActualizacion,
      item.fechaUltimoLogueo
    );
  }

  serialize(user: User): any {
    return {id: user.id, 
            usuario: user.username, 
            nombre: user.fullName, 
            email: user.email, 
            nroDocumento: user.documento,
            str_fechaNacimiento: user.str_fechaNacimiento,
            terminosYCondiciones: user.terminosYCondiciones,
            campaniaActualizacion: user.campaniaActualizacion
          }
  }
}