import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogAutorizarCoberturaResultadoComponent } from '../dialog-autorizar-cobertura-resultado/dialog-autorizar-cobertura-resultado.component';
import { TurnosService } from '../_services/turnos.service';

@Component({
  selector: 'app-dialog-autorizar-cobertura',
  templateUrl: './dialog-autorizar-cobertura.component.html',
  styleUrls: ['./dialog-autorizar-cobertura.component.css']
})

export class DialogAutorizarCoberturaComponent implements OnInit {

  turnoAutorizar: any;
  cargando: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogAutorizarCoberturaComponent>,
    public dialog: MatDialog,
    private turnoService: TurnosService,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {
    // console.log(data)
  }

  ngOnInit() {
    this.obtenerDatosTurno();
  }

  async obtenerDatosTurno() {
    this.turnoAutorizar = await this.turnoService.getTurnoAdmision();
    this.cargando = false;
    // console.log(this.turnoAutorizar)
  }

  confirmarAutorizar() {
    this.dialogRef.close();
    this.dialog.open(DialogAutorizarCoberturaResultadoComponent, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: 600,
      maxHeight: 560
    })
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}
