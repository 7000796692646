<div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
        <div class="col-md">
            <div class="container">


                <p class="mt-4">
                    Si su cirugía requiere internación deberá presentar, sin excepción, las siguientes prácticas:
                </p>
                <ul class="font-weight-bold">
                    <li>Hemostasia básica y grupo y factor.</li>
                    <li>Rutina de laboratorio.</li>
                    <li>Electrocardiograma.</li>
                    <li>Vacuna antitetánica.</li>
                    <li>Placa de tórax (excepto embarazadas).</li>
                </ul>
                <p>
                    Si no posee estudios actualizados, podrá realizárselos en el Hospital presentando órdenes por
                    separado para cada estudio que deberá confeccionar su médico/a. En algunos casos, es probable que
                    también le haya indicado algún estudio específico; en ese caso,
                    puede presentar las órdenes en Admisión Internación para que se le indique sobre las facilidades que
                    brinda el Hospital para que pueda realizárselos con varios días de anticipación.
                </p>


                <h4 class="mt-4">Rutina de Laboratorio</h4>
                <p><b>Horario de atención:</b> lunes a sábados desde las 7:00</p>
                <p><b>Comunicarse al (+54 0221) 412 9440, línea directa | (+54 0221) 412 9400, interno 1502</b></p>
                <p>Se le informará sobre condiciones administrativas y de preparación previas a la extracción.</p>
                <p class="text-primary">*el servicio se encuentra en el Subsuelo del Hospital.</p>
                <h4 class="mt-4">Extracción | Hemostasia básica</h4>
                <p>Dirigirse al área de Hemoterapia para conocer la categoría del médico (Básico, B o C). Los afiliados
                    a IOMA deberán autorizar la orden de Hemostasia Básica y Grupo y factor en las oficinas de calle 7
                    Nº 470 entre 40 y 41. </p>
                <p>Presentarse con ayuno previo de lunes a sábados de 7:00 a 10.00hs.<br>
                    <b>Recuerde presentar al técnico la orden de rutina de Laboratorio. Esto es muy importante porque
                        tomará todas las muestras necesarias para que luego usted las lleve al Laboratorio. De esta
                        manera evitará que sea necesario sacarle dos veces sangre.</b>
                </p>

                <h4 class="mt-4">Placa de tórax</h4>
                <p>Dirigirse al área de Diagnóstico por Imágenes, en el subsuelo, donde se podrá hacer la placa de tórax por 
                    orden de llegada de lunes a viernes de 8.00 a 20.00hs y sábados de 8.00 a 13:00hs.</p>
                <p>Allí también, podrá consultar sobre la preparación previa y la documentación necesaria de los estudios 
                    que se le hayan indicado.</p>
                <p><b>IMPORTANTE</b></p>
                <p><b>Las placas deben ser realizadas hasta 48hs antes de la fecha en que está programada la cirugía; en 
                    el caso, de que la cirugía sea un lunes, la placa deberá realizarse el día sábado.</b></p>
                <p><b>Si la internación está programada para después de las 9.00hs, podrá realizar la placa de tórax el 
                    mismo día.</b></p>

                <h4 class="mt-4">Contacto</h4>
                <p><b>Teléfono: (+54 0221) 412 9400, interno 1319</b> de lunes a viernes de 7:15 a 17:00hs y sábados 
                    de 7:15 a 14:30hs.</p>

                <h4 class="mt-4">Electrocardiograma</h4>
                <p>Consultorio de pre quirúrgico cardiológico: de lunes a viernes de 8.00 a 12.00hs</p>

                <p>Los turnos se solicitan en la Central de Turnos, planta baja, con la orden médica en mano. El horario 
                    de atención es de lunes a viernes de 7:15 a 21:30hs y sábados de 7:15 a 14:30hs.</p>

                <p>También puede comunicarse al: <b>(+54 0221) 412 9470</b>, de lunes a viernes de 7:15 a 17:00hs</p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
        <div class="col-md">

            <div class="container">
                <h3>Preparación</h3>
                <ol class="mt-4">
                    <li>
                        <p>Presentarse en ayunas de 12 horas.</p>
                    </li>
                    <li>
                        <p>Bañarse dentro de las dos horas previas a la cirugía y previamente cortar las uñas al ras, 
                            cuidando de no lesionar la piel. Si están pintadas quitar todo el esmalte. En caso de cirugía con 
                            colocación de implante, bañar con jabón antiséptico también los dos días previos y dos horas antes 
                            de la cirugía.</p>
                        <p><strong>
                                <i>¿Cómo realizar el baño? Humedecer el cuerpo con abundante agua. Salir de la misma y lavar 
                                    con jabón antiséptico o jabón neutro nuevo. Hacer hincapié en la cabeza 
                                    (cabello, cuello, boca), axilas, ombligo, uñas de pies y manos, ingle y zona perineal. 
                                    Enjuagar y secar con mayor meticulosidad que lo habitual. No utilizar perfume, 
                                    desodorante, talco, maquillaje u otros cosméticos. Colocar ropa limpia. </i>
                            </strong></p>
                    </li>
                    <li>
                        <p>Antes de ir al quirófano, Enfermería brindará el equipo de ropa quirúrgica 
                            (botas, bata, gorro, tapaboca) que deberá utilizar quitándose todas las prendas de vestir, 
                            incluyendo la ropa interior.</p>
                    </li>
                    <li>
                        <p>Si tiene prótesis dentaria -parcial o total-, ocular y/o auditiva, se solicitará dejarlas con un 
                            familiar o acompañante antes de ser trasladado al quirófano.</p>
                    </li>
                    <li>
                        <p>Se sugiere presentarse en el Hospital sin accesorios y elementos personales como alianzas o bijouterie.</p>
                    </li>
                    <li>
                        <p>Importante: todo paciente que requiera internación para cirugía necesitará <b>dadores de sangre</b> 
                            (entre 2 y 12 según el procedimiento).</p>
                        <p>Cirugía General (2) - Cardiotorácica (4) - Reparación de Aneurisma (6) - By Pass Coronario (4) - Cirugía Cardiovascular (12) - 
                            Ortopedia (4) - Reemplazo total de Cadera (4) - Reemplazo total de Rodilla (4) - Obstetricia/ Ginecología (4) - 
                            Histerectomía Radical (4) - Placenta Acreta (6) - Urología (2) - Nefrectomía Radical (3) - Trasplante Renal (2)</p>
                    </li>
                    <li>
                        <p>No suspender los medicamentos que toma excepto por indicación médica.</p>
                    </li>
                    <li>
                        <p>Dependiendo del tipo de cirugía y si está indicado por el médico, se procederá al rasurado correspondiente.</p>
                    </li>
                </ol>
                <p><b>Más información: (+54 0221) 412 9400, interno 1506</b></p>
            </div>
        </div>
    </div>
</div>