<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container" *ngIf="this.validarForm">
    <div class="row">
      <h4 class="p-1 mt-1">Validación de identidad</h4>
    </div>
    <p> <strong>Recuerde que el proceso de validación puede demorar 72hs.</strong></p>
    <!-- <p>
    Para completar el proceso deberá adjuntar la foto del frente de su DNI y de
    frente de su rostro (sin accesorios).
  </p> -->
    <p>
      Para completar el proceso deberá adjuntar foto del DNI de frente y foto del
      frente de su rostro (sin accesorios).
    </p>
    <form [formGroup]="validarForm" (ngSubmit)="onSubmitValidar()">
      <div class="row py-2 d-flex align-items-center border">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
          <div class="form-group">
            <label for="dni">DNI</label>
            <input type="text" formControlName="dni" class="form-control" [ngClass]="{
              'is-invalid': submittedValidar && fvalidar.dni.errors
            }" />
            <div *ngIf="submittedValidar && fvalidar.dni.errors" class="invalid-feedback">
              <div *ngIf="fvalidar.dni.errors.required">
                Este campo es requerido
              </div>
              <div *ngIf="fvalidar.dni.errors.minlength">
                Debe tener al menos 6 digitos
              </div>
              <div *ngIf="fvalidar.dni.errors.maxlength">
                Debe tener al menos 8 digitos
              </div>
              <div *ngIf="fvalidar.dni.errors.pattern">
                Deben ser solo numeros
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-2 d-flex align-items-center border">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
          <div class="form-group">
            <label for="dni">Foto del frente del DNI</label>
            <input [disabled]="loadingValidar" type="file" (change)="onFileChange($event)" accept="image/*"
              id="fotoFrenteDNI" class="form-control" required [ngClass]="{
              'is-invalid':
                (submittedValidar && fvalidar.fotoFrenteDNI.errors) ||
                (fvalidar.fotoFrenteDNI.errors &&
                  fvalidar.fotoFrenteDNI.errors.archivoInvalido)
            }" />
            <mat-hint align="end"> Max. 25MB </mat-hint>
            <div *ngIf="submittedValidar && fvalidar.fotoFrenteDNI.errors" class="invalid-feedback">
              <div *ngIf="fvalidar.fotoFrenteDNI.errors.required">
                Este campo es requerido
              </div>
            </div>
            <div *ngIf="fvalidar.fotoFrenteDNI.errors" class="invalid-feedback">
              <div *ngIf="fvalidar.fotoFrenteDNI.errors.archivoInvalido">
                Solo se admiten imagenes
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
          <img src="./../../assets/images/validar-usuario/frenteDNI.png" alt="Foto frente DNI" class="img-fluid p-1" />
        </div>
      </div>
      <div class="row py-2 d-flex align-items-center border">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
          <div class="form-group">
            <label for="dni">Foto del frente de su rostro (sin accesorios)</label>
            <input [disabled]="loadingValidar" type="file" (change)="onFileChange($event)" accept="image/*"
              id="fotoPaciente" class="form-control" required [ngClass]="{
              'is-invalid':
                (submittedValidar && fvalidar.fotoPaciente.errors) ||
                (fvalidar.fotoPaciente.errors &&
                  fvalidar.fotoPaciente.errors.archivoInvalido)
            }" />
            <mat-hint align="end"> Max. 25MB </mat-hint>
            <div *ngIf="submittedValidar && fvalidar.fotoPaciente.errors" class="invalid-feedback">
              <div *ngIf="fvalidar.fotoPaciente.errors.required">
                Este campo es requerido
              </div>
            </div>
            <div *ngIf="fvalidar.fotoPaciente.errors" class="invalid-feedback">
              <div *ngIf="fvalidar.fotoPaciente.errors.archivoInvalido">
                Solo se admiten imagenes
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
          <img src="./../../assets/images/validar-usuario/selfie.jpg" alt="Foto frente DNI" class="img-fluid p-1" />
        </div>
      </div>
      <div class="form-group float-right mt-2">
        <button routerLink="/login" class="btn btn-secondary mr-2">
          Cancelar
        </button>
        <img *ngIf="loadingValidar" class="pl-3" />
        <button [disabled]="loadingValidar" class="btn btn-info ml-2">
          Validar

        </button>
        <mat-spinner *ngIf="loadingValidar" [diameter]="30" class="float-right align-middle ml-1"></mat-spinner>
      </div>
      <div class="text-danger" *ngIf="submittedValidar && this.validarForm.invalid">
        <h6>
          <strong>ATENCION:</strong> No fue posible completar la validación, por
          favor revise que los datos ingresados esten completos e intente
          nuevamente
        </h6>
      </div>
    </form>
  </div>
  <!-- Modal Validacion Correcta -->
  <button id="openModalValidacionCorrecta" type="button" class="btn btn-info btn-lg" data-toggle="modal"
    data-target="#modalValidacionCorrecta" [hidden]="true">
    Open The Modal Box
  </button>
  <button id="closeModalValidacionCorrecta" type="button" class="btn btn-info btn-lg" data-dismiss="modal"
    [hidden]="true">
    Open The Modal Box
  </button>
  <div class="modal fade" id="modalValidacionCorrecta" data-backdrop="false" data-dismiss="modal" data-keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5>Su turno ha sido reservado con éxito.</h5>
      </div> -->
        <div class="modal-header">
          <h5>¡Felicitaciones!</h5>
        </div>
        <div class="modal-body">
          <!-- <p>
          Su solicitud de validación se realizo correctamente. Recuerde que el
          proceso de validación puede demorar un tiempo.
        </p> -->
          <p>
            Se ha realizado de forma exitosa la solicitud de validación de
            identidad.
          </p>
          <p>El proceso se completará dentro de las 72hs.</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-info" type="button" data-dismiss="modal" (click)="irATurnos()">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Validacion Incorrecta -->
  <button id="openModalValidacionIncorrecta" type="button" class="btn btn-info btn-lg" data-toggle="modal"
    data-target="#modalValidacionIncorrecta" [hidden]="true">
    Open The Modal Box
  </button>
  <button id="closeModalValidacionIncorrecta" type="button" class="btn btn-info btn-lg" data-dismiss="modal"
    [hidden]="true">
    Open The Modal Box
  </button>
  <div class="modal fade" id="modalValidacionIncorrecta" data-backdrop="false" data-dismiss="modal"
    data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5>Su turno ha sido reservado con éxito.</h5>
      </div> -->
        <div class="modal-body">
          <p>Hubo un error al ingresar los datos de validación.</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

</body>

</html>