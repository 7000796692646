import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FinanciadoresService } from '../_services/financiadores.service';
import { UserService } from '../_services/user.service';
import { UrlResolverService } from '../_services/urlResolver.service';

@Component({
  selector: 'app-dialog-agregar-cobertura',
  templateUrl: './dialog-agregar-cobertura.component.html',
  styleUrls: ['./dialog-agregar-cobertura.component.css']
})
export class DialogAgregarCoberturaComponent implements OnInit {

  files: any [] = [];
  file;
  fileName;
  currentUser;
  cargando: boolean = false;
  disabledButton: boolean = false;
  alerta: any = {};
  botonCancelar: string = 'Cancelar';

  form = {
    dni: null,
    tipoDni: null,
    dniTemporal: 0,
    fotoFrenteDNI: {
      valor: null,
      nombre: null,
    },
    fotoPaciente: {
      valor: null,
      nombre: '',
    },
    nombreUsuario: null,
    idFinanciador: null,
    nroCarnet: null,
    idTipoValidacion: 2      // COBERTURA
  };

  listaFinanciadores: any [] = [];
  agregarCoberturaForm = new FormGroup({
    nroCarnet: new FormControl('', Validators.required),
    mutual: new FormControl(null, Validators.required), //es un objeto
    imagenCarnet: new FormControl('', Validators.required),
  });
  horusRestfullUrl: any;

  constructor(
    public finciadoresService: FinanciadoresService,
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogAgregarCoberturaComponent>,
    public urlResolver: UrlResolverService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  async ngOnInit() {
    console.log(this.data)
    await this.obtenerDatosUsuario();
    await this.listarFinanciadores();
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then((response) => {
      this.currentUser = response;
      this.form.dni = this.currentUser.nroDocumento;
      this.form.tipoDni = this.currentUser.tipoDocumento;
      this.form.nombreUsuario = this.currentUser.usuario;
    }, (error) => {
      console.log(error);
    });
  }

  async listarFinanciadores() {
    await this.urlResolver.getHorusRestfullUrl().toPromise().then((x) => {
      // console.log(x);
      if (x.ok) {
        this.finciadoresService.getFinanciadores(x.mensaje).toPromise().then(resp => {
          // console.log(resp)
          if (resp.ok) {
            this.listaFinanciadores = resp.paginador.elementos.sort((a,b) => {
              if(a.nombre < b.nombre) { return -1; }
              if(a.nombre > b.nombre) { return 1; }
            });
          }
        }, error => {
          console.log(error)
        })
      }
    });
  }

  onFileSelected(event) {
    // console.log(event)
    this.files = event.target.files;
    
    if (this.files.length > 0) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.form.fotoFrenteDNI.nombre = new String(this.file.name).replace(/[^a-zA-Z0-9.]+/g, '');
        this.form.fotoFrenteDNI.valor = (reader.result as string).split(',')[1];
      };
    }
  }

  cancelar() {
    this.dialogRef.close();
  }

  async guardar() {
    this.cargando = true;
    this.disabledButton = true;
    this.alerta = {};
    
    if (this.files.length === 0 || this.agregarCoberturaForm.invalid) {
      this.alerta.cssClass = 'alert alert-danger';
      this.alerta.mensaje = 'Debe completar todos los campos';
      this.cargando = false;
      this.disabledButton = false;
    } else if (this.data.listaFinanciadores.includes(this.agregarCoberturaForm.get('mutual').value.financiadorId)) {
      this.alerta.cssClass = 'alert alert-danger';
      this.alerta.mensaje = 'Usted ya tiene una mutual de '+ this.agregarCoberturaForm.get('mutual').value.nombre;
      this.cargando = false;
      this.disabledButton = false;
    } else {
      
      this.form.idFinanciador = this.agregarCoberturaForm.get('mutual').value.financiadorId;
      this.form.nroCarnet = this.agregarCoberturaForm.get('nroCarnet').value;
      await this.userService.validar(this.form).toPromise().then(resp => {
        // console.log(resp)
        if (resp.ok) {
          this.alerta.cssClass = 'alert alert-success';
          this.alerta.mensaje = resp.mensaje + '. El proceso de validacion puede demorar hasta 72hs hábiles.';
          this.botonCancelar = 'Cerrar';
        } else {
          this.alerta.cssClass = 'alert alert-danger';
          this.alerta.mensaje = 'No se pudo guardar la cobertura';
          this.disabledButton = false;
        }
        this.cargando = false;
      }, error => {
        console.log(error)
        this.alerta.cssClass = 'alert alert-danger';
        this.alerta.mensaje = 'No se pudo guardar la cobertura';
        this.cargando = false;
        this.disabledButton = false;
      })
    }
  }

  onKeypressEvent(e){
      
    // var k; 

    // k = e.charCode;  //         k = event.keyCode;  (los dos pueden usarse)

    // if ((k >= 48 && k <= 57) ||
    //     (k >= 65 && k <= 90) ||
    //     (k >= 97 && k <= 122) ||
    //     (k >= 164 && k<= 165)) {
    //   document.getElementById('error-nroCarnet').style.display = 'none';       
    //   return true;
    // } else {        
    //   document.getElementById('error-nroCarnet').style.display = 'inline-flex';
    //   return false;
    // }

    var re = /^[a-zA-Z0-9Ññ]+$/i;
    let ok = re.test(String.fromCharCode(e.keyCode))
    if(ok){
      document.getElementById('error-nroCarnet').style.display = 'none';  
    } else {
      document.getElementById('error-nroCarnet').style.display = 'inline-flex';
    }
    return ok;
  }
}