import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService} from '../_services/alert.service';
import { FacturasService} from '../_services/facturas.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-facturas-de-gastos',
  templateUrl: './facturas-de-gastos.component.html',
  styleUrls: ['./facturas-de-gastos.component.css']
})
export class FacturasDeGastosComponent implements OnInit {

    facturas: any = [];
    selected: any;
    horusRestfullUrl;
    selectedUrl;

  constructor(private facturasService: FacturasService, private urlResolver: UrlResolverService) { }

  ngOnInit() {
        this.getUrl();
        this.facturasService.getFacturasDeGastos().subscribe( data => this.facturas = data.facturas);
  }

  getUrl(){
    this.urlResolver.getHorusRestfullUrl().subscribe(x => this.horusRestfullUrl = x.mensaje)
  }

  crearPrefijoLink(prefijo){
    let str = "0000";
    return ((str + prefijo.toString()).substr(prefijo.toString().length));
  }

  crearNumeroLink(numero){
    let str = "00000000";
    return ((str + numero.toString()).substr(numero.toString().length));
  }


  setFactura(factura){
    this.selectedUrl = this.horusRestfullUrl + 'api/facturacionPaciente/imprimirPorPaciente/pdf/FAE' + factura.letra + this.crearPrefijoLink(factura.prefijo) + this.crearNumeroLink(factura.numero) + '.pdf' ;

    if (navigator.userAgent.indexOf("Android") > -1) {
      this.downloadPDF(this.selectedUrl);

    }else{
      this.selected=factura;
   }
  }


  resetList(){
    this.selected=null;
  }


   downloadPDF(urlPdf) {
    
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute('href', urlPdf);
    downloadLink.setAttribute('download', "factura_gasto");
    downloadLink.setAttribute('target', '_blank');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
   
  }

}
