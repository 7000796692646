<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div class="container-fluid">
    <div class="row d-flex justify-content-center mt-1">
      <div class="d-flex justify-content-center" *ngIf="cargando">
        <mat-spinner></mat-spinner>
      </div>
  
      <div class="col-md-6 mx-auto text-center" *ngIf="!cargando">
        <h5 class="subtitulo" [hidden]="!turnoAutorizar.realizoWebCheckIn">
          Admisi&oacute;n Digital - Paso 1
        </h5>
        <h5 class="subtitulo" [hidden]="turnoAutorizar.realizoWebCheckIn">
          Admisi&oacute;n Digital - Paso 2
        </h5>
        <br/>
        <p [hidden]="!turnoAutorizar.realizoWebCheckIn">
          Est&aacute;s comenzando la admisi&oacute;n digital, esto te llevar&aacute; algunos
          pasos. <br />El primero es confirmar los datos de tu cobertura, que son
          los siguientes:
        </p>
        <p [hidden]="turnoAutorizar.realizoWebCheckIn">
          Ahora necesitaremos confirmar los datos de tu cobertura:
        </p>
  
        <div class="example-container">
          <form [formGroup]="mutualForm" (ngSubmit)="confirmarAutorizacion()">
            <mat-form-field appearance="outline">
              <mat-label>Obra Social</mat-label>
              <mat-select formControlName="nombreMutual" required>
                <mat-option
                  *ngFor="let mutual of mutuales"
                  [value]="mutual"
                  (click)="cambioMutual(mutual)"
                  >{{ mutual.nomFin }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br/>
            <mat-form-field appearance="outline">
              <mat-label>N° de Afiliado</mat-label>
              <input matInput formControlName="nroCarnet" required>
            </mat-form-field>
            <br/>
            <mat-form-field [hidden]="!esFinanciador" appearance="outline">
              <mat-label>PIN de Seguridad</mat-label>
              <input
                matInput
                #pin
                placeholder="Ej. 123"
                maxlength="3"
                formControlName="pinSeguridad"
                autocomplete="off"
              />
              <mat-hint align="end">{{ pin.value.length }} / 3</mat-hint>
            </mat-form-field>
            <br [hidden]="!esFinanciador"/><br [hidden]="!esFinanciador"/>
            <p [hidden]="existeMsjeMutualDeshabilitada" style="color: red">
              {{ msjeMutualDeshabilitada }}
            </p>
            <p [hidden]="!invalidForm" style="color: red">
              {{ msjeInvalidForm }}
            </p>
            <button
              mat-flat-button color="warn"
              class="boton"
              type="button"
              (click)="cancelar()"
              style="margin-right: 3%"
            >
              Cancelar
            </button>
            <button
              mat-flat-button color="primary"
              class="boton" [class.spinnerButton]="loading"
              type="submit"
              [disabled]="!existeMsjeMutualDeshabilitada || loading"
            >
              Confirmar datos
            </button>
          </form>
        </div>
  
        <p style="padding-top: 8%;">Tené a mano tu tarjeta por si tu obra social requiere un copago o un diferenciado.</p>
      </div>
    </div>
  </div>
</body>

</html>