<div *ngIf="!cargando;else cargandoBlock" class="container-fluid justify-content-center">
    <div *ngIf="validacionAprobada();else noValidadoBlock" class="row d-flex justify-content-center mt-1 m-0 p-0">        
           
        
        <div class="col justify-content-center">
            
            <div class="contenedor" *ngIf="estudios.length > 0 && !selectedEstudio">
                <div class="botones d-flex justify-content-center">
                    <button (click)="navigateTo()" class="boton"><mat-icon>add</mat-icon>Agregar documento</button>
                    <button (click)="setHashHC()" class="boton"><mat-icon>share</mat-icon>Compartir Historial</button>
                </div>

                <figure class="figure-infinite-scroll" style="margin-top: 20px;">

                    <div class="contenedor-cards-simil-tabla justify-content-center">
                            <div class="card-simil-tabla" *ngFor="let estudio of estudios">
                                <ng-container *ngIf="verificarEstado(estudio)">
                                    <div>
                                        <div *ngIf="estudio.admisionAdministrativa == 'PDT';else normalBlock">
                                            <p style="font-style: italic;">Admisión administrativa pendiente</p>
                                        </div>
                                        <ng-template #normalBlock>
                                            <div class="titulo_tarjeta D-DINCondensed-Bold">
                                                {{estudio.fechast}}
                                            </div>
                                        </ng-template>              
                                    </div>
                                    <div *ngIf="estudio.nombreProfesional">
                                        <p>Profesional: <strong>{{ estudio.nombreProfesional | toTitleCase }}</strong></p>
                                    </div>
                                    <div *ngIf="estudio.especialidadAtencion">
                                        <p>Especialidad: <strong>{{ estudio.especialidadAtencion | toTitleCase }}</strong></p>
                                    </div>
                                    <div *ngIf="estudio.observacion">
                                        Motivo: <strong>{{ estudio.observacion | toTitleCase }}</strong>                                                             
                                    </div>

                                    <div style="display: flex; flex-direction: column; justify-content: center;">

                                        <button *ngIf="(estudio.tipoEpisodio == 'Estudio' || 
                                        estudio.tipoEpisodio == 'Registro paciente' || 
                                        (estudio.tipoEpisodio == 'Internacion' && estudio.tiene) ||
                                        estudio.tipoEpisodio == 'Registros de medicos');else mostrarTipoEpisodio" 
                                        class="boton boton-card mat-focus-indicator mat-flat-button mat-button-base ng-tns-c186-6 ng-star-inserted"
                                        (click)="selectEstudio(estudio)">
                                        <div class="contenido-boton"><i class="material-icons"> description </i> {{mensajeBoton(estudio.tipoEpisodio)}} </div>
                                        </button>

                                        <button *ngIf="estudio.tipoEpisodio == 'Registro paciente'"
                                        id="borrar-documento" 
                                        class="boton boton-card mat-focus-indicator mat-flat-button mat-button-base ng-tns-c186-6 ng-star-inserted"
                                        (click)="deleteDocument(estudio)">
                                        
                                        <div class="contenido-boton"><i class="material-icons"> delete</i> BORRAR REGISTRO</div>
                                        </button>  
                                    </div>

                                    
                                    <ng-template #mostrarTipoEpisodio>
                                        <p id="tipo-episodio"><strong> {{estudio.tipoEpisodio ? (estudio.tipoEpisodio | toTitleCase) : '-'}} </strong></p>
                                    </ng-template>

                                   
                                    
                                </ng-container>
                            </div>
                    </div>
                </figure>

                
                    <!-- <div class="d-flex flex-sm-row flex-column justify-content-end align-content-between mb-3"> -->
                
            </div>

            <div class="alert alert-danger" *ngIf="estudios.length == 0 && !error_alert">
                <h6>No tenés historial clínico.</h6>
            </div>
            <div *ngIf="error_alert" class="alert alert-danger" role="alert">
                <h6>No se pudo obtener tu historial, intentalo mas tarde.</h6>
            </div>
        </div>
        
    </div>
    <ng-template #noValidadoBlock>
        <div *ngIf="validacionPendiente();else sinValidarBlock">
            Tu usuario aún se encuentra en <b>proceso de VALIDACIÓN.</b> <br><br>
      
            Vas a poder acceder a tu Historial Clínico y realizar otras gestiones desde el Portal, una vez que se haya completado el proceso de validación. <br>
      
            *tiempo estimado de validación de usuarios: 72hs
          </div>
        <ng-template #sinValidarBlock>      
        <div style="margin-top: 3vh;">
            Te recordamos que para poder acceder a tu Historial Clínico y realizar otras gestiones desde el Portal, es necesario que se validen tus datos. <br>
            *para completar los datos personales sugerimos tener cerca el DNI.</div>
        <button      
            class="btn boton_validar"
            (click)="goToValidar()"
            style="margin-top: 3vh;"> Validá tu identidad</button>
        </ng-template>
      </ng-template>
</div>
<ng-template #cargandoBlock>
    <div>        
        <mat-spinner></mat-spinner>
    </div>
</ng-template>