<!DOCTYPE html>
<html>

<head>
    <title>Ingresar</title>

    <!-- google stylesheet link -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

</head>

<body>
    <div class="container-fluid center-block">
        <br />
    
        <mat-card style="text-align: center">
            <mat-card-title class="mat-card-title" style="color: #0d3453; font-family: Arial, Helvetica; text-align: center"><img src="../../assets/images/login/logo HE.png" alt="hospital_logo" style="width: 55px; height: 45px; margin: auto !important" />
                <span class="align-middle ml-3" style="font-size: 21px">Portal de Pacientes</span>
            </mat-card-title>
    
            <form [formGroup]="loginForm" (keydown.enter)="$event.preventDefault()">
                <mat-card-content>
                    <div class="alert alert-danger p-1 m-2" *ngIf="msje != null">
                        {{ msje }}
                    </div>
    
                    <br />
                    <mat-form-field>
                        <input 
                        id="input_usuario" 
                        (keypress)="onKeypressEvent($event)" 
                        (keyup.enter)="onSubmit()" 
                        matInput
                        type="text" 
                        formControlName="username" 
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                        placeholder="Usuario"
                        required 
                        pattern="[a-zA-Z0-9\u00f1_.-]*"/>
                    </mat-form-field>
    
                    <div id="error-caracter-no-permitido"  style="display: none;">
                        
                        <div style="padding-top: .3vh;" class="alert alert-danger p-0 m-2">
                            <mat-icon 
                            style="transform: scale(.9);">error</mat-icon>
                            Caracter '<b>{{caracter_no_permitido}}</b>' no permitido.
                        </div> 
                    </div>                
    
                    <div *ngIf="submitted && f.username.errors">
                        <div *ngIf="f.username.errors.required" class="alert alert-danger p-1 m-1">El usuario es requerido.</div>
                    </div>
                    <div *ngIf="!submitted && f.username.errors && f.username.errors.pattern">
                        <div *ngIf="f.username.errors.pattern" class="alert alert-danger p-1 m-1">El usuario contiene caracteres especiales, acentos o espacios.</div>
                    </div>
                    
                    <div class="form-field-container">
                    <mat-form-field class="fieldpass">
                      <input
                        id="input_password"
                        (keyup.enter)="onSubmit()"
                        matInput [type]="showPass ? 'text' : 'password'"
                        formControlName="password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                        placeholder="Contraseña"
                        required
                      />
                    </mat-form-field>
                    <button
                      matTooltip="{{showPass ? 'Ocultar contraseña' : 'Mostrar contraseña'}}"
                      (click)="showHidePassword()"
                      class="showbt"
                      mat-stroked-button
                    >
                      {{showPass ? 'OCULTAR' : 'MOSTRAR'}}
                    </button>
                  </div>
                       
                    <div *ngIf="submitted && f.password.errors" class="alert alert-danger p-1 m-1">
                        <div *ngIf="f.password.errors.required">
                            La contraseña es requerida.
                        </div>
                    </div>
    
                    <div id="bloq_mayus_activado" style="display: none" class="alert alert-danger p-1 m-1">
                        Las maýusculas están activas.
                    </div>
    
                    
                </mat-card-content>
    
                <mat-card-actions class="m-0 p-0">
                    <button [disabled]="loading || f.password.errors || f.username.errors" type="button" class="btn btn-lg btn-outline-info" id="iniciarSesion" (click)="onSubmit()">
                        iniciar sesión
                    </button>
                    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
                </mat-card-actions>
            </form>
       
        <div class="container align-items-center" style="width: 100%;">
            <div class="d-flex justify-content-center">            
                <div class="align-self-center opcion-login botonesUsuario" id="recuperar-datos" (click)="openDialogOlvideMisDatos()">                                  
                    <span class="material-symbols-outlined iconitos">badge</span>
                    <!-- <img src="./../../assets/images/login/OlvideMisDatos.png" class="img-fluid iconitos" /> -->
                    Recuperar usuario/contraseña                 
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="align-self-center opcion-login botonesUsuario" (click)="irRegistro()">            
                    <span class="material-symbols-outlined iconitos">person_add</span>
                    <!-- <img src="./../../assets/images/login/crearUsuario.png" class="img-fluid iconitos" /> -->
                    Crear usuario                   
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="align-self-center opcion-login botonesUsuario" (click)="openDialogSoporteTecnico()">            
                    <!--<img src="./../../assets/images/login/soporteTecnico.png" class="img-fluid iconitos" />-->
                    <span class="material-symbols-outlined iconitos">handyman</span>
                    Soporte técnico                   
                </div>
            </div>
                
            
            
            

          
        </div>

       
    </mat-card>

    <div class="container-imagenes">
        <!-- <img class="logos" style="margin-right: 10%" src="../../assets/images/login/logo_efector.jpg" />
        <a href="https://cidcam.org"><img class="logos" style="margin-right: 9%" src="../../assets/images/login/logo_cidcam.jpg" />
        </a>
        <a href="http://www.cenas.org.ar/">
            <img class="logos" src="../../assets/images/login/logo_cenas.jpg" />
        </a> -->
        <img class="logos" src="../../assets/images/login/Efector -Cenas-Cidcam.png">
    </div>
</div>




<!-- Modal Preguntas Frecuentes -->
<!--
<div class="modal fade" id="PreguntasFrecuentesModal" data-backdrop="false" data-dismiss="modal" tabindex="-1" role="dialog" aria-labelledby="PreguntasFrecuentesModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="PreguntasFrecuentesModalLabel">
                    Preguntas frecuentes
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
        </mat-card>
    </div>
--> 

    <!-- Modal Preguntas Frecuentes -->
    <div class="modal fade" id="PreguntasFrecuentesModal" data-backdrop="false" data-dismiss="modal" tabindex="-1" role="dialog" aria-labelledby="PreguntasFrecuentesModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="PreguntasFrecuentesModalLabel">
                        Preguntas frecuentes
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <ul>
                        <li>
                            ¿Qué es el Portal del Paciente y que beneficios me ofrece?
                            <p>
                                El portal del paciente es su espacio personal desde el que podrá realizar trámites y acceder a información sobre su salud en cualquier momento y lugar, sin necesidad de trasladarse al hospital, evitando esperar y hacer colas.
                            </p>
                        </li>
                        <li>
                            ¿Qué trámites puedo realizar?
                            <p>
                                Inicialmente puede consultar, reservar y cancelar sus turnos. Gradualmente iremos agregando información y funcionalidad.
                            </p>
                        </li>
                        <li>
                            ¿A qué información médica puedo acceder?
                            <p>
                                Tiene acceso a los documentos relativos a Pruebas de diagnóstico por imágenes. Nota: Debido al formato y resolución de las imágenes archivadas en el Portal del Paciente, no es recomendable su uso para segundas opiniones médicas.
                            </p>
                        </li>
                        <li>
                            ¿Qué necesito para acceder a mi Portal del Paciente?
                            <p>
                                Ingresar al portal a través de la página del hospital Español o directamente por la URL pacientes.hospitalespaniol.org.ar. Una vez en la página de inicio debe ingresar su completar su nombre de usuario y contraseña.
                            </p>
                        </li>
                        <li>
                            ¿Cómo obtengo mi usuario y contraseña?
                            <p>
                                <!--La obtención del usuario y contraseña es un trámite muy sencillo que se realza personalmente en el mostrador de Informes ubicado en el hall central de hospital.	-->
                            </p>
                        </li>
                        <li>
                            ¿Qué tengo que hacer en caso de haber olvidado mi usuario y/o contraseña de acceso?
                            <p>
                                Acceder a la página inicial del Portal del Paciente y pulsar el botón de "olvidé mi contraseña". Accederá a una página que le solicitará la dirección de email que facilitó en el momento del registro. Tras la validación recibirá un correo electrónico con
                                la información necesaria para ingresar.
                            </p>
                        </li>
                        <li>
                            ¿Qué tengo que hacer si no puedo acceder a mi Portal del Paciente?
                            <p>
                                Antes que nada, verifique que su sistema está funcionando, abriendo cualquier aplicación (por ejemplo un navegador de Internet). Compruebe que dispone de conectividad, accediendo desde una nueva pestaña del navegador a una página cualquiera, (diarios
                                de información, por ejemplo). Si usa varios navegadores, trate de acceder a una misma página web desde un segundo navegador. Recuerde que nuestro sitio esta homologado para Chrome e Internet Explorer y no podemos asegurar que
                                funcione correctamente en otros navegadores. Finalmente, si nada de esto dio resultado, puede enviarnos un correo a soporte.ti@hospitalespanol.org.ar donde un técnico le brindará ayuda.
                            </p>
                        </li>
                        <li>
                            ¿Qué tipo de turnos puedo reservar?
                            <p>
                                El portal permite sacar turnos de consultas. Para internaciones y prácticas de mayor complejidad, puede hacerlo personalmente en el hall del hospital o llamando a los siguientes teléfonos:
                            </p>
    
                            <ul>
                                <li>Consultorios: (0221) 412- 9470</li>
                                <li>Diagnóstico por Imágenes: (0221) 412-9470 int 1319</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" data-dismiss="modal">
              Aceptar
            </button>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Modal Soporte Tecnico -->
    
    <!-- <div class="modal fade" id="SoporteTecnicoModal" data-backdrop="false" data-dismiss="modal" tabindex="-1" role="dialog" aria-labelledby="SoporteTecnicoModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="SoporteTecnicoModalLabel">
                        Contáctese con soporte técnico
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="supportMessageForm" (ngSubmit)="supportMessage()">
                        <div class="form-group">
                            <label for="nombreApellido">Nombre y apellido</label>
                            <input type="text" formControlName="nombreApellido" class="form-control" [ngClass]="{
                    'is-invalid':
                      supportMessageSubmitted && sp.nombreApellido.errors
                  }" />
                            <div *ngIf="supportMessageSubmitted && sp.nombreApellido.errors" class="invalid-feedback">
                                <div *ngIf="sp.nombreApellido.errors.required">
                                    Este campo es requerido
                                </div>
                                <div *ngIf="sp.nombreApellido.errors.pattern">Solo letras</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" formControlName="email" class="form-control" [ngClass]="{
                    'is-invalid': supportMessageSubmitted && sp.email.errors
                  }" />
                            <div *ngIf="supportMessageSubmitted && sp.email.errors" class="invalid-feedback">
                                <div *ngIf="sp.email.errors.required">
                                    Este campo es requerido
                                </div>
                                <div *ngIf="sp.email.errors.pattern || sp.email.errors.email">
                                    Este campo debe ser un mail (ejemplo@gmail.com)
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="asunto">Asunto</label>
                            <input type="text" formControlName="asunto" class="form-control" [ngClass]="{
                    'is-invalid': supportMessageSubmitted && sp.asunto.errors
                  }" />
                            <div *ngIf="supportMessageSubmitted && sp.asunto.errors" class="invalid-feedback">
                                <div *ngIf="sp.asunto.errors.required">Este campo es requerido</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="mensaje">Mensaje</label>
                            <input type="text" formControlName="mensaje" class="form-control" [ngClass]="{
                    'is-invalid': supportMessageSubmitted && sp.mensaje.errors
                  }" />
                            <div *ngIf="supportMessageSubmitted && sp.mensaje.errors" class="invalid-feedback">
                                <div *ngIf="sp.mensaje.errors.required">Este campo es requerido</div>
                            </div>
                        </div>
    
                        <button [disabled]="supportMessageLoading" class="btn btn-info" style="float: right">
                Enviar
              </button>
                        <img *ngIf="supportMessageLoading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                        />
                    </form>
                </div>
            </div>
        </div>
    </div> -->
    
    <!-- Modal Recuperar Contraseña -->
    <button id="openContraseñaModalButton" type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#RecuperarContraseñaModal" [hidden]="true">
      Open The Modal Box
    </button>
    
    <div class="modal fade" id="RecuperarContraseñaModal" data-backdrop="false" data-dismiss="modal" tabindex="-1" role="dialog" aria-labelledby="RecuperarContraseñaModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="RecuperarContraseñaModalLabel">
                        Olvido su contraseña?
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="recoverPasswordForm" (ngSubmit)="recoverPassword()">
                        <div class="form-group">
                            <label for="username">Nombre de usuario</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && rp.username.errors }" />
    
                            <div *ngIf="submitted && rp.username.errors" class="invalid-feedback">
                                <div *ngIf="rp.username.errors.required">
                                    username is required
                                </div>
                                <div *ngIf="rp.username.errors.incorrect">
                                    No existe ese usuario
                                </div>
                            </div>
                        </div>
    
                        <button style="float: right" type="submit" class="btn btn-info">
                Enviar
              </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Modal Recuperar Usuario -->
    
    <button id="openRecuperarUsuarioModalButton" type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#RecuperarUsuarioModal" [hidden]="true">
      Open The Modal Box
    </button>
    
    
    
    <button id="openModalVideo" type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#modalVideo" [hidden]="true">
      Open The Modal Box
    </button>
    <!--
    <div class="modal fade" id="modalVideo" tabindex="-1" data-backdrop="false" data-dismiss="modal" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="SoporteTecnicoModalLabel">Noticias</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
                                    </button>
                </div>
                <div class="modal-body p-0 m-0">
                    <img class="img-fluid" src="../../assets/images/habpremium.gif" />
                </div>
            </div>
        </div>
    </div>
    -->
    
</body>
</html>