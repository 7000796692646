import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Datos {
  nombreUsuario: string,
  email: string
}

@Component({
  selector: 'app-dialog-mensaje-confirmar-registro',
  templateUrl: './dialog-mensaje-confirmar-registro.component.html',
  styleUrls: ['./dialog-mensaje-confirmar-registro.component.css'],
})

export class DialogMensajeConfirmarRegistroComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogMensajeConfirmarRegistroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Datos
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}

  selectOption(opcion){    
    this.dialogRef.close(opcion);
  }

  
}
