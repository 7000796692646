import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diagnostico-prenatal-y-mujer',
  templateUrl: './diagnostico-prenatal-y-mujer.component.html',
  styleUrls: ['./diagnostico-prenatal-y-mujer.component.css']
})
export class DiagnosticoPrenatalYMujerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
