import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ObrasSocialesPrepagasComponent } from './obras-sociales-prepagas/obras-sociales-prepagas.component';
import { HospitalDelDiaComponent } from './hospital-del-dia/hospital-del-dia.component';
import { NefrologiaYDialisisComponent } from './nefrologia-y-dialisis/nefrologia-y-dialisis.component';
import { TerapiaIntensivaComponent } from './terapia-intensiva/terapia-intensiva.component';
import { RecomendacionesComponent } from './recomendaciones/recomendaciones.component';
import { DerechosDelPacienteComponent } from './derechos-del-paciente/derechos-del-paciente.component';
import { NormasDeConvivenciaComponent } from './normas-de-convivencia/normas-de-convivencia.component';
import { VisitasComponent } from './visitas/visitas.component';
import { EgresosComponent } from './egresos/egresos.component';
import { TramitesAdministrativosComponent } from './tramites-administrativos/tramites-administrativos.component';
import { EstudiosPrequirurgicosComponent } from './estudios-prequirurgicos/estudios-prequirurgicos.component';
import { PreparacionComponent } from './preparacion/preparacion.component';
import { AdmisionComponent } from './admision/admision.component';
import { CardiologiaComponent } from './cardiologia/cardiologia.component';
import { EcocardiogramasComponent } from './ecocardiogramas/ecocardiogramas.component';
import { EcografiaGeneralComponent } from './ecografia-general/ecografia-general.component';
import { EcografiaPediatricaComponent } from './ecografia-pediatrica/ecografia-pediatrica.component';
import { DiagnosticoPrenatalYMujerComponent } from './diagnostico-prenatal-y-mujer/diagnostico-prenatal-y-mujer.component';
import { HematologiaComponent } from './hematologia/hematologia.component';
import { HemodinamiaComponent } from './hemodinamia/hemodinamia.component';
import { LaboratorioComponent } from './laboratorio/laboratorio.component';
import { MamografiasComponent } from './mamografias/mamografias.component';
import { MedicinaNuclearComponent } from './medicina-nuclear/medicina-nuclear.component';
import { RadiologiaComponent } from './radiologia/radiologia.component';
import { ResonanciaMagneticaComponent } from './resonancia-magnetica/resonancia-magnetica.component';
import { FacturasDeGastosComponent } from './facturas-de-gastos/facturas-de-gastos.component';
import { FacturasDeHonorariosComponent } from './facturas-de-honorarios/facturas-de-honorarios.component';
import { HistorialClinicoComponent } from './historial-clinico/historial-clinico.component';
import { TurnosPacienteHistoricoComponent } from './turnos-paciente-historico/turnos-paciente-historico.component';
import { MisReservasComponent } from './mis-reservas/mis-reservas.component';
import { WebCheckinComponent } from './web-checkin/web-checkin.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { AutorizarCoberturaComponent } from './autorizar-cobertura/autorizar-cobertura.component';
import { AuthGuard } from './_guards/auth.guard';
import { HeaderComponent } from './header/header.component';
import { PagoComponent } from './pago/pago.component';
import { VisualizarEstudioComponent } from './visualizar-estudio/visualizar-estudio.component';
import { VisualizarHistorialComponent } from './visualizar-historial/visualizar-historial.component';
import { ProfesionalesComponent } from './profesionales/profesionales.component';
import { ValidarUsuarioComponent } from './validar-usuario/validar-usuario.component';
import { OrdenesComponent } from './ordenes/ordenes.component';
import { ActualizarDatosComponent } from './actualizar-datos/actualizar-datos.component';
import { TomografiaComputarizadaComponent} from './tomografia-computarizada/tomografia-computarizada.component';
import { EstudiosExternosComponent} from './estudios-externos/estudios-externos.component'
import { EpisodioComponent } from './episodio/episodio.component';
import { CancelarTurnoComponent } from './cancelar-turno/cancelar-turno.component';
import { MisCoberturasComponent } from './mis-coberturas/mis-coberturas.component';
import { HomeComponent } from './home/home.component';
import { ReservarTurnoComponent } from './reservar-turno/reservar-turno.component';
import { FacturasComponent } from './facturas/facturas.component'
import { ReservarTurnoMedicoComponent } from './reservar-turno-medico/reservar-turno-medico.component';
import { InternacionComponent } from './internacion/internacion.component';
import { PacientesQuirurgicosComponent } from './pacientes-quirurgicos/pacientes-quirurgicos.component';
import { AreasInternacionPrestacionesEspecialesComponent } from './areas-internacion-prestaciones-especiales/areas-internacion-prestaciones-especiales.component';
import { IngresoEgresoComponent } from './ingreso-egreso/ingreso-egreso.component';
import { TurnosComponent } from './turnos/turnos.component';



const routes: Routes = [
  {
    path: '', redirectTo: '/login',
    pathMatch: 'full' 
  },
  { path: 'login', 
    component: LoginComponent 
  },
  { path: 'register', 
    component: RegisterComponent 
  },
  { 
    path: 'profesionales', 
    component: ProfesionalesComponent 
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'obras-sociales-prepagas',
    component: ObrasSocialesPrepagasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'obras-sociales-prepagas',
    component: ObrasSocialesPrepagasComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'hospital-de-dia',
  //   component: HospitalDelDiaComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'nefrologia-dialisis',
  //   component: NefrologiaYDialisisComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'terapia-intensiva',
  //   component: TerapiaIntensivaComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'recomendaciones',
    component: RecomendacionesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'derechos-normas',
    component: DerechosDelPacienteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'normas-de-convivencia',
    component: NormasDeConvivenciaComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'visitas-egresos', component: VisitasComponent, canActivate: [AuthGuard] },
  // { path: 'egresos', component: EgresosComponent, canActivate: [AuthGuard] },
  // {
  //   path: 'tramites-de-cirugías',
  //   component: TramitesAdministrativosComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'prequirurgicos-preparacion',
  //   component: EstudiosPrequirurgicosComponent,
  // },
  {
    path: 'preparacion',
    component: PreparacionComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'admision', component: AdmisionComponent, canActivate: [AuthGuard] },
  {
    path: 'cardiologia',
    component: CardiologiaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ecocardiogramas',
    component: EcocardiogramasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ecografia-general',
    component: EcografiaGeneralComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ecografia-pediatrica',
    component: EcografiaPediatricaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'diagnostico-prenatal-y-mujer',
    component: DiagnosticoPrenatalYMujerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hematologia',
    component: HematologiaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hemodinamia',
    component: HemodinamiaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'laboratorio',
    component: LaboratorioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mamografias',
    component: MamografiasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'medicina-nuclear',
    component: MedicinaNuclearComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'radiologia',
    component: RadiologiaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resonancia-magnetica',
    component: ResonanciaMagneticaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tomografia-computarizada',
    component: TomografiaComputarizadaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'factura-de-gastos',
    component: FacturasDeGastosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'factura-de-honorarios',
    component: FacturasDeHonorariosComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'mis-ordenes',
  //   component: OrdenesComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'historial-clinico',
    component: HistorialClinicoComponent,
    canActivate: [AuthGuard],
  },
  
  // {
  //   path: 'turnos-paciente-historico',
  //   component: TurnosPacienteHistoricoComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'turnos-reservados',
    component: MisReservasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'web-checkin',
    component: WebCheckinComponent,
    canActivate: [AuthGuard],
  },
  { path: 'noticias', component: NoticiasComponent, canActivate: [AuthGuard] },
  {
    path: 'autorizar-cobertura',
    component: AutorizarCoberturaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'respuesta-pago',
    component: PagoComponent,
    canActivate: [AuthGuard],
  },
  { path: 'ver-estudio/:hash', component: VisualizarEstudioComponent },
  {
    path: 'ver-historial-clinico/:hash',
    component: VisualizarHistorialComponent,
  },
  {
    path: 'validarUsuario',
    component: ValidarUsuarioComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'editar-datos',
    component: ActualizarDatosComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'estudios-externos',
    component: EstudiosExternosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'episodio',
    component: EpisodioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancelar-turno/:nroMatricula/:orden/:fecha/:desdeHora',
    component: CancelarTurnoComponent,
  },
  {
    path: 'mis-coberturas',
    component: MisCoberturasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reservar-turno',
    component: ReservarTurnoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comprobantes-de-pago',
    component: FacturasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reservar-turno-medico',
    component: ReservarTurnoMedicoComponent,
  },
  {
    path: 'internacion',
    component: InternacionComponent
  },
  {
    path: 'pacientes-quirurgicos',
    component: PacientesQuirurgicosComponent
  },
  {
    path: 'areas-internacion-prestaciones-especiales',
    component: AreasInternacionPrestacionesEspecialesComponent
  },
  // {
  //   path: 'ingreso-egreso',
  //   component: IngresoEgresoComponent
  // }
  { path: 'turnos', component: TurnosComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
