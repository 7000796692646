import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-reprogramar-turno',
  templateUrl: './dialog-reprogramar-turno.component.html',
  styleUrls: ['./dialog-reprogramar-turno.component.css']
})
export class DialogReprogramarTurnoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  } 

  irADiagnosticoPorImagenes(){
    window.open('https://www.hospitalespanol.org.ar/servicios-y-profesionales/servicios-ambulatorios/#seccion-2', '_blank'); 
  }
}
