<div class="container mt-3">
    <div *ngIf="loading; else loadingBlock" class="text-center">
        <div class="subtitulo" style="margin-bottom: 5vh;">Agregar documento</div>
        <p style="text-align: center;padding: 0 10vw;">
            Adjuntá a tu Historial Clínico todos los documentos de los estudios realizados en otras Instituciones y que necesites compartir con tu médico/a.
        </p>


        <form class="form margin-form" [formGroup]="form" (ngSubmit)="saveData()" novalidate>

            <div class="form-group d-flex flex-column col-xl-5 m-auto col-lg-5 col-md-6">
                <!-- Description field -->
                <mat-form-field appearance="outline">
                    <mat-label>Descripción del estudio</mat-label>
                    <input matInput formControlName="description" type="text">
                    <mat-error *ngIf="getForm.description.invalid && submitted">{{getErrorMessageDescription()}}</mat-error>
                    <mat-hint>Se permiten solo letras, números, puntos y comas.</mat-hint>
                </mat-form-field>

                <!-- Date field -->
                <mat-form-field class="mt-3" appearance="outline">
                    <mat-label>Fecha de realización</mat-label>
                    <input matInput formControlName="date" [max]="maxDate" [matDatepicker]="medicalStudyDate">
                    <mat-error *ngIf="getForm.date.invalid && submitted">{{getErrorMessageDate()}}</mat-error>
                    <mat-datepicker-toggle matSuffix [for]="medicalStudyDate"></mat-datepicker-toggle>
                    <mat-datepicker #medicalStudyDate></mat-datepicker>
                </mat-form-field>

                <!-- File upload field -->
                <input type="file" class="file-input" formControlName="file" (change)="onFileSelected($event)" #fileUpload accept="application/pdf">
                <div class="file-upload">
                    {{ fileName || "No se ha seleccionado ningun archivo."}}
                    <button mat-mini-fab color="primary" type="button" class="upload-btn" (click)="fileUpload.click()">
                        <mat-icon>file_upload</mat-icon>
                    </button>
                    <mat-error *ngIf="getForm.file.invalid && submitted">{{getErrorMessageFile()}}</mat-error>
                </div>

                <div class="col margin-form">
                    <button mat-raised-button type="button" (click)="back()" class="boton borrar_usuario mr-3">Cancelar</button>
                    <button mat-raised-button type="submit" class="boton">Confirmar</button>
                </div>
            </div>


        </form>
    </div>
    <ng-template #loadingBlock>
        <mat-progress-bar class="barra-progreso-celeste" mode="indeterminate">
        </mat-progress-bar>
    </ng-template>

</div>