<div class="container-fluid">
  <!-- <div class="row" style="margin-bottom: 1%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <button mat-icon-button [mat-dialog-close]="false" style="float: right;">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </div>
  </div> -->
  <div class="row" style="margin-bottom: 1%;">
    <h4>Agregar cobertura</h4>
  </div>

  <form [formGroup]="agregarCoberturaForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <mat-form-field>
          <mat-label>Seleccionar</mat-label>
          <mat-select formControlName="mutual" required>
            <mat-option *ngFor="let fi of listaFinanciadores" [value]="fi"> {{fi.nombre}} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <mat-form-field>
          <input matInput placeholder="Nro. afiliado/a" formControlName="nroCarnet" (keypress)="onKeypressEvent($event)" required>
          <!-- <mat-error *ngIf="agregarCoberturaForm.invalid">{{getErrorMessage('seccion'+seccion.numSeccion)}}</mat-error> -->
        </mat-form-field>
        <div class="alert alert-danger" id="error-nroCarnet" style="display: none;">El número de carnet solo debe contener números y letras.</div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div style="display: inline-grid;">
          <label for="img">Foto de carnet de tu cobertura: *</label>
          <input style="transform: scale(.9);" formControlName="imagenCarnet" type="file" id="img" name="img" accept="image/png, image/jpeg" (change)="onFileSelected($event)">
        </div>
      </div>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-center" style="margin-top: 5%;">    
        
          <button mat-raised-button color="warn" class="ml-2" (click)="cancelar()">{{ botonCancelar }}</button>
          <button mat-raised-button color="primary" class="ml-2" (click)="guardar()" [class.spinner]="cargando" [disabled]="disabledButton">Agregar</button>

    </div>

    <div class="row" style="margin-top: 3vh">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <p [ngClass]="alerta.cssClass">{{alerta.mensaje}}</p>
      </div>
    </div>
  </form>

  <!-- <div class="row" [hidden]="!cargando" style="margin-top: 5%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-spinner style="margin: auto;"></mat-spinner>
    </div>
  </div> -->
  
</div>