import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-campania-de-actualizacion',
  templateUrl: './dialog-campania-de-actualizacion.component.html',
  styleUrls: ['./dialog-campania-de-actualizacion.component.css']
})
export class DialogCampaniaDeActualizacionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCampaniaDeActualizacionComponent>,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  cerrarDialogo(){
    this.dialogRef.close();
  }

  actualizar(){
    this.dialogRef.close();
    // window.location.href='/editar-datos';
    this.router.navigate(['editar-datos']);
  }

}
