<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <div class="container">
        <p>
          El acompañamiento y las visitas proporcionan un gran beneficio a los
          pacientes. Sin embargo, las visitas masivas indiscriminadas y fuera
          del horario establecido pueden resultar perjudiciales para la salud y
          la evolución normal del paciente. En ningún caso podrán acudir más de
          dos visitantes a un mismo tiempo (En Terapia Intensiva y Unidad
          Coranaria sólo uno) debiendo hablar siempre en tono bajo, evitando
          ruidos y conservando el orden.
        </p>
        <ul>
          <li>
            <p>
              <strong>Internacion General:</strong> de 9.30 a 11 y de 16.30 a
              19.
            </p>
          </li>
          <li>
            <p>
              <strong>Unidad Coronaria:</strong> de 12.30 a 13 y de 19.30 a 20
            </p>
          </li>
          <li>
            <p>
              <strong>Terapia Intensiva:</strong> de 12 a 12.15 y de 20 a 20.15
            </p>
          </li>
          <li>
            <p><strong>Neonatología:</strong> de 11.30 a 12 y de 16.30 a 17</p>
          </li>
        </ul>

        <p>
          En muchas situaciones estos horarios pueden alterarse y en ese caso,
          será informado sobre las modificaciones.
        </p>
        <p>
          La necesidad de mantener un ambiente que favorezca la comodidad y
          reposo de las personas ingresadas, aconseja el máximo respeto a las
          normas de acceso y permanencia de familiares y visitantes. Si el
          paciente o los profesionales lo consideran necesario para su
          restablecimiento, se pueden restringir las visitas. En ese caso, por
          favor, siga las instrucciones del personal de la planta.
        </p>
        <p>
          En el momento del ingreso del paciente se le proporcionará una tarjeta
          de acompañante que podrá ser intercambiada libremente entre sus
          familiares (no válido para UCO o UTI). Estas tarjetas deberán ser
          entregadas a la enfermera de la planta en el momento del alta.
        </p>
        <p>
          Las personas que presenten catarro, gripe, diarrea, lesiones en la
          piel o hayan pasado recientemente alguna enfermedad infecciosa no
          pueden entrar en la zona de hospitalización, ya que pueden provocar
          graves problemas a los pacientes ingresados.
        </p>
        <p>
          Los niños menores de 12 años no pueden acudir de visita a los
          hospitales.
        </p>
        <p>
          Si los familiares que acompañan al paciente necesitan algún tipo de
          justificante pueden solicitarlo de lunes a viernes en Internación, en
          horario de 8 a 15.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row d-flex justify-content-center mt-1">
    <div class="col-md">
      <h4>Egresos</h4>
      <div class="container">
        <p>
          <strong
            >El acompañamiento y las visitas proporcionan un gran beneficio a
            los pacientes.</strong
          >
        </p>

        <p>
          Cuando el paciente tenga firmada el alta médica,
          <strong>Enfermería</strong> ordenará la documentación de la Historia
          Clínica y entregará los estudios que pudiera tener hechos el paciente,
          para que un familiar gestione el alta administrativa en el
          <strong>Área de Facturación</strong> y también en la
          <strong
            >Asociación de Profesionales del Hospital Español (APHE)</strong
          >, ambas en planta baja.
        </p>
        <p>
          Cumplidos estos trámites se le extenderá por escrito el alta
          administrativa que deberá entregar al Coordinador responsable del piso
          antes de movilizar al paciente.
        </p>
      </div>
    </div>
  </div>
</div>
