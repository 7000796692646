import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-obras-sociales-prepagas',
  templateUrl: './obras-sociales-prepagas.component.html',
  styleUrls: ['./obras-sociales-prepagas.component.css']
})
export class ObrasSocialesPrepagasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
