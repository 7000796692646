import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from '../_services/urlResolver.service';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class FinanciadoresService {

  public horusRestfullUrl: string;

  constructor(
    private http: HttpClient,
    private urlResolver: UrlResolverService
  ) { 
    this.horusRestfullUrl = AppComponent.SMApi;
  }

  getUrl() {
    this.urlResolver.getHorusRestfullUrl().toPromise().then((x) => {
      console.log(x);
      this.horusRestfullUrl = x.mensaje;
    });
  }

  getFinanciadores(horusRestfullUrl) {
    return this.http.get<any>(horusRestfullUrl + '/api/financiadores/listar/ACT/null/null');
  }

}
