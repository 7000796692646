import { Component, OnInit } from '@angular/core';
import { TurnosService } from '../_services/turnos.service';
import { EstudiosService } from '../_services/estudios.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';

@Component({
  selector: 'app-turnos-paciente-historico',
  templateUrl: './turnos-paciente-historico.component.html',
  styleUrls: ['./turnos-paciente-historico.component.css'],
})
export class TurnosPacienteHistoricoComponent implements OnInit {
  historico: any = [];
  loading = true;

  constructor(
    private turnosService: TurnosService,
    private estudioService: EstudiosService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getHistorico();
  }

  async getHistorico() {
    await this.turnosService
      .getHistoricoPaciente()
      .toPromise()
      .then((x) => {
        this.historico = x.lista;
        this.historico.forEach((turno) => {
          turno.estudiosPedido = [];
          if (turno.nroPedido && turno.nroPedido != 0) {
            this.estudioService.getPedidoEstudio(turno.nroPedido).subscribe(
              (respGetPedido) => {
                turno.estudiosPedido =
                  respGetPedido.pedidos[0].estudiosPaciente;
              },
              (error) => {
                console.log(error);
              }
            );
          }
        });
        this.loading = false;
      });
  }

  cancelarTurno(turno) {
    this.turnosService.cancelarTurno(turno).subscribe((data) => {
      if (data.ok) {
        this.abrirDialogMensaje(data);
      }
    });
  }

  abrirDialogConfirmacion(turno) {
    const config = {
      mensaje:'cancelar el turno',
      titulo: 'Confirmar', 
      estasPor: true     
    }
    this.dialog.open( DialogConfirmacionComponent, {
      data: config,
      panelClass: 'dialog-sin-overflow'
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.cancelarTurno(turno);
          } else {
            this.dialog.closeAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
 
  abrirDialogMensaje(resp) {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: resp,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          this.dialog.closeAll();
        //  this.router.navigate(['/turnos']);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
