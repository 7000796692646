  <div class="barra-inicial barra-inicial-celeste">
    <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
  </div>
    <div class="contenedor">

      <div mat-dialog-content style="text-align: center; max-height: 99vh; overflow-x: hidden; max-width: 90vh;"> 
        <h1 mat-dialog-title class="tituloDialog" style="text-align: center;"><b>RECUPERACIÓN DE DATOS</b></h1>     
        <b>Hemos enviado los DATOS DE RECUPERACIÓN a tu correo</b> {{this.emailModif}}.
        </div>

        <div mat-dialog-content style="text-align: left; overflow-y: visible; max-height: 99vh; overflow-x: hidden; max-width: fit-content;"> 
            Seguí los siguientes pasos para <b>INICIAR SESIÓN</b>
            <ul>
                <li>Ingresá a tu mail y verificá que hayas recibido nuestro correo.</li>
                <li>En caso de no encontrarlo en tu bandeja de entrada, chequeá la bandeja de correo no deseado.</li>
                <li>Ingresá al Portal con el usuario y clave que aparecen en el correo enviado.</li>
                <li>Una vez que ingreses a tu Portal, actualizá la clave por una propia.</li>
            </ul>
      </div>
      
      
      <div mat-dialog-actions class="d-flex justify-content-center" style="overflow: visible;">
        <button 
              class="boton-dialog boton-confirmar"
              color="primary"
              (click)="cerrarDialogo()"        
              > Aceptar
        </button>
      </div> 
    </div>
  