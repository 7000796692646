<div *ngIf="!cargando;else cargandoBlock" class="container-fluid">
  <div *ngIf="validacionAprobada();else noValidadoBlock" class="row d-flex justify-content-center mt-1 m-0 p-0">
    <div class="w-100" *ngIf="!selected">
      
      <div class="alert alert-danger" *ngIf="ordenes.length == 0">
        <h6>No se encontraron órdenes.</h6>
      </div>

      <div class="col p-0 m-0 w-100" *ngIf="ordenes.length > 0">
        <figure class="table-container">
          <table>
            <thead>
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Servicio</th>
                <th scope="col">Profesional</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let orden of ordenes">
                <td>{{ orden.fechaHoraPedida.substr(0,10)}}</td>

                <td>{{ orden.nombreServicio | toTitleCase }}</td>

                <td>{{ orden.solicitante | toTitleCase }}</td>

               <!-- <td (click)="verOrden(orden)" style="text-align: center">

                  <img src="../../assets/images/facturas/estudios_pdf.png" 
                        style = "height:20px !important  ;cursor: pointer"/>
                </td>-->
                <td>
                  <!-- <mat-icon 
                  (click)="verOrden(orden)"
                  matTooltip="Ver orden">picture_as_pdf</mat-icon> -->
                  <button 
                    (click)="verOrden(orden)">Ver orden</button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <td colspan=4>Hospital Español - Dedicados a cuidar la salud de las personas</td>   
            </tfoot>
          </table>
        </figure>
      </div>
    </div>

    <div *ngIf="selected">
        
        <div style="display: block;">
          <button
          (click)="resetList()"
          class="btn btn-info"
          style="float: right"
          >
            Volver
          </button>

          <h5>Orden</h5>       
          
          <ul>
            <li><b>Servicio: </b>{{selected.nombreServicio | toTitleCase}}</li>
            <li><b>Solicitante: </b>{{selected.solicitante | toTitleCase}}</li>
          </ul>
        </div>
        
            
      
      <div *ngIf="!mobileDevice; else mobileTemplate">
        <div class="container" style="height: 425px">
          <embed
            type="application/pdf"
            [src]="selectedUrl | safeUrl"
            width="100%"
            height="100%"
            internalinstanceid="5"
          />
        </div>
      </div>
      <ng-template #mobileTemplate>
        <div style="margin-top: 2vh;">
        <p>Podés ver tu orden en formato PDF presionando el siguiente botón</p>
        <a mat-raised-button class="mt-2 mx-auto" color="primary" href="{{ selectedUrl }}">Descargar orden</a>
        </div>
      </ng-template>
      
    </div>
  </div>
  <ng-template #noValidadoBlock>
    <div *ngIf="validacionPendiente();else sinValidarBlock">
      Tu usuario aún se encuentra en <b>proceso de VALIDACIÓN.</b> <br><br>

      Vas a poder acceder a tus Órdenes y realizar otras gestiones desde el Portal, una vez que se haya completado el proceso de validación. <br>

      *tiempo estimado de validación de usuarios: 72hs
    </div>
    <ng-template #sinValidarBlock>      
      <div style="margin-top: 3vh;">
        Te recordamos que para poder acceder a tus Órdenes y realizar otras gestiones desde el Portal, es necesario que se validen tus datos. <br>
        *para completar los datos personales sugerimos tener cerca el DNI.</div>
      <button      
        class="btn boton_validar"
        (click)="goToValidar()"
        style="margin-top: 3vh;"> Validá tu identidad</button>
    </ng-template>
  </ng-template>
</div>
<ng-template #cargandoBlock>
  <div>    
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
