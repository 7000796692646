import { Component, OnInit } from '@angular/core';
import { MercadoPagoService } from '../_services/mercado-pago.service';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { HttpParams } from '@angular/common/http';


export interface Cobrar {
  nroBoleta: number;
  nroTransaccion: number;
  importeTotal: number;
}

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css'],
})
export class PagoComponent implements OnInit {

  tokenMercadoPago: string;
  resultadoPago: boolean = false;
  cargando: boolean = true;
  msjeResultado: string;
  urlActual;
  urlArray;
  idPagoArray;
  idPago: any = null;
  currentUser: any = 'usuariox'

  constructor(
    private mercadoPagoService: MercadoPagoService,
    private router: Router,
    private userService: UserService
  ) { }

  async ngOnInit() {
    // await this.obtenerDatosUsuario();
    await this.obtenerUrlActual();
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then(response => {
      this.currentUser = response.usuario;
    }, (error) => {
      console.log(error);
    });
  }

  guardarEstadistica(mensaje: String, funcion: String) {
    this.userService.guardarEstadistica(mensaje, funcion).toPromise().then((respGuardarEstad) => {
      // console.log(respGuardarEstad);
    }, (error) => {
      console.log(error);
    });
  }

  async getDetallePago(idPago) {
    await this.mercadoPagoService.getPago(idPago).toPromise().then(rp => {
      // console.log(rp);
      this.resultadoPago = rp.ok;
      this.msjeResultado = rp.mensaje;
    }).catch(error => {
      console.log(error)
      this.resultadoPago = false;
      this.msjeResultado = "No se puedo procesar el pago (COD: 1012)";
    }).finally(() => {
      this.cargando = false;
    });
  }

  async obtenerUrlActual() {
    this.urlActual = window.location.href;
    this.urlArray = this.urlActual.split('?');
    const params = new HttpParams({ fromString: this.urlArray[1] });
    this.idPago = params.get('payment_id');
    
    if (this.idPago && this.idPago != null) {
      await this.getDetallePago(this.idPago);
    } else {
      this.resultadoPago = false;
      this.msjeResultado = "No se puedo procesar el pago (COD: 1013)";
      this.cargando = false;
    }
    
    this.guardarEstadistica('idPago: '+ this.idPago + ' ' + this.msjeResultado, 'FX_BackUrl');
  }

  async getTokenMercadoPago() {
    await this.mercadoPagoService.getTokenMercadoPago().toPromise().then(async rpGetTokenMp => {
      // console.log(rpGetTokenMp);
      if (rpGetTokenMp.ok) {
        this.tokenMercadoPago = rpGetTokenMp.mensaje;
        await this.mercadoPagoService.getDetallePago(this.idPago, this.tokenMercadoPago).toPromise().then((responsePago) => {
          // console.log(responsePago);
          if (responsePago.status === 'approved') {
            this.msjeResultado = 'El pago se realizó exitosamente.';
            this.resultadoPago = true;
            this.cargando = false;
            this.guardarEstadistica('El pago se realizó exitosamente - usuario('+this.currentUser+').', 'FX_MensajeDePago');
          } else {
            this.msjeResultado = 'No se puedo procesar el pago (COD: 1013)';
            this.resultadoPago = false;
            this.cargando = false;
            this.guardarEstadistica('El pago tiene estado: '+ responsePago.status +' - usuario('+this.currentUser+').', 'FX_MensajeDePago');
          }
        }, (error) => {
          console.log(error);
          this.msjeResultado = 'No se puedo procesar el pago (COD: 1010)';
          this.resultadoPago = false;
          this.cargando = false;
          this.guardarEstadistica('COD: 1010: '+ error +' - usuario('+this.currentUser+').', 'FX_MensajeDePago');
        });
      } else {
        this.msjeResultado = 'No se puedo procesar el pago (COD: 1011)';
        this.resultadoPago = false;
        this.cargando = false;
        this.guardarEstadistica('No se puedo procesar el pago (COD: 1011) - usuario('+this.currentUser+').', 'FX_MensajeDePago');
      }
    }, (error) => {
      console.log(error);
      this.msjeResultado = 'No se puedo procesar el pago (COD: 1012)';
      this.resultadoPago = false;
      this.cargando = false;
      this.guardarEstadistica('No se puedo procesar el pago (COD: 1012) - usuario('+this.currentUser+').', 'FX_MensajeDePago');
    });
  }

  finalizar() {
    this.router.navigate(['turnos-reservados']);
  }
}
